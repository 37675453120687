import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '../../context/AuthProvider';

dayjs.extend(utc);
dayjs.extend(timezone);
// const tz = 'America/Buenos_Aires';

export const formatUTC = (date) => {
  const { user } = useAuth();
  return dayjs.tz(date, user.country.time_zone).format();
};
