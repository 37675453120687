import { useQuery } from '@tanstack/react-query';
import axiosInstance from './prospects';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';

export const getCoaches = async (coach_location) => {
  const location_id = getLocationId();
  return fetchData(
    `/coaches?location_id=${coach_location ? coach_location : location_id}`
  );
};
export const getCoach = async (id) => {
  const location_id = getLocationId();
  return fetchData(`/coaches/${id}/?location_id=${location_id}`);
};
export const useGetCoaches = (coach_location) => {
  return useQuery({
    queryKey: ['coaches', coach_location],
    queryFn: async () => getCoaches(coach_location),
    select: (data) =>
      data
        // .sort((a, b) => a.id - b.id)
        .map((coach) => ({
          ...coach,
          name: coach.first_name + ' ' + coach.last_name,
        }))
        .sort((a, b) => b.active - a.active),
  });
};
export const updateCoach = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coaches/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createCoach = async (data) => {
  const location_id = getLocationId();

  return await fetchData(`/coaches?location_id=${location_id}`, 'POST', data);
};
export const createCoachLocation = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coach_locations?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteCoachLocation = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/coach_locations/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const deleteCoach = async (id, data) => {
  const location_id = getLocationId();

  return await fetchData(
    `/coaches/${id}?location_id=${location_id}`,
    'DELETE',
    data
  );
};
export const createCoachHistories = async (data) => {
  const location_id = getLocationId();
  return fetchData(
    `/member_coach_histories?location_id=${location_id}`,
    'POST',
    data
  );
};
export const getCoachHistories = async (member_id, isProspect) => {
  const location_id = getLocationId();
  // return [];
  if (isProspect) {
    return [];
  }
  return fetchData(
    `/member_coach_histories?location_id=${location_id}&member_id=${member_id}`
  );
};

export default axiosInstance;
