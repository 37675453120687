import { useState } from 'react';
import * as Sentry from '@sentry/react';

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        // console.log(JSON.parse(value));
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      Sentry.captureException(new Error(err));
      return defaultValue;
    }
  });
  const removeValue = (keyName) => {
    try {
      window.localStorage.removeItem(keyName);
    } catch (err) {
      Sentry.captureException(new Error(err));
    }
    setStoredValue(defaultValue);
  };
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      Sentry.captureException(new Error(err));
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue, removeValue];
};
