import { IoCheckmarkDoneCircle } from "react-icons/io5";

export const copy_link_success = (messageApi) => {
    messageApi.open({
      type: 'success',
      icon:<></>,
      content: <div className='flex flex-row gap-3 items-center '>
         <IoCheckmarkDoneCircle color='#1EA05A'  />
        <p>Link copiado al portapapeles</p>
      </div>,

 
    });
  };
  export const loadingLink = (messageApi) => {
    messageApi.open({
      type: 'loading',
      content: 'Generando Link..'
   
    });
  };
  export const closeMessageApi = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.'
    );
  };