import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect

} from 'react';
import reducer from './reducer';
// import CableApp from '../services/actionCable';
// import { getLocationId } from '../services/utils/getLocation';

const initialState = {
  user: null,
  locations:[],
  // cable: null, 

};

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
 
  return (
    <GlobalContext.Provider value={{ state,dispatch}}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
