import { useQuery } from '@tanstack/react-query';
import axiosInstance, { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
// Función para obtener el location_id del localStorage

export const getClasses = async () => {
  const location_id = getLocationId();

  return fetchData(`/gym_classes?location_id=${location_id}`);
};
export const getClass = async (id) => {
  const location_id = getLocationId();

  return fetchData(`/gym_classes/${id}?location_id=${location_id}`);
};
export const updateClasses = async (id, data) => {
  return await fetchData(`/gym_classes/${id}`, 'PATCH', data);
};
export const createClasses = async (data) => {
  return await fetchData(`/gym_classes`, 'POST', data);
};
export const deleteClasses = async (id) => {
  return await fetchData(`/gym_classes/${id}`, 'DELETE');
};
export const useGetClasses = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['classes'],
    queryFn: async () => getClasses(location_id),
  });
};

export const getClassSlots = async () => {
  const location_id = getLocationId();
  return fetchData(`/time_slots?location_id=${location_id}`);
};
export const getClassSlot = async (id) => {
  const location_id = getLocationId();
  return fetchData(`/time_slots/${id}?location_id=${location_id}`);
};
export const createTimeSlot = async (data) => {
  return await fetchData(`/time_slots`, 'POST', data);
};
export const updateTimeSlot = async (id, data) => {
  return await fetchData(`/time_slots/${id}`, 'PATCH', data);
};
export const deleteTimeSlot = async (id) => {
  return await fetchData(`/time_slots/${id}`, 'DELETE');
};
export const getGymClassTypes = async () => {
  const location_id = getLocationId();
  return fetchData(`/gym_class_types?location_id=${location_id}`);
};

export const getDayReserves = async (location_id, scheduled_at) => {
  // const location_id = getLocationId();
  return fetchData(
    `/day_reserves?location_id=${location_id}&scheduled_at=${scheduled_at}`
  );
};

export const useGetDay_reserves = (scheduled_at) => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['day_reserves', scheduled_at],
    queryFn: async () => getDayReserves(location_id, scheduled_at),
  });
};

export const useGetClassSlots = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['classes'],
    queryFn: async () => getClassSlots(location_id),
  });
};

export const delete_member_gym_class = async (id) => {
  const location_id = getLocationId();

  return fetchData(`/reserves/${id}/?location_id=${location_id}`, 'DELETE');
};
export const update_member_gym_class = async (id, fields) => {
  const location_id = getLocationId();

  return fetchData(
    `/update_has_attended/${id}/?location_id=${location_id}`,
    'PATCH',
    fields
  );
};
export const add_member_gym_class = async (fields) => {
  const location_id = getLocationId();
  return fetchData(`/reserves?location_id=${location_id}`, 'POST', fields);
};
export default axiosInstance;
