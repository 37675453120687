import React, { useMemo, useState, useEffect, useRef } from 'react';
import Card from './Card';
import { Tooltip } from 'antd';
import { useGetAssignmentsChart } from '../../api/charts';

const TotalContacts = ({ sortedResults, maxWeek, setMaxWeek, weeks_arr }) => {
  const totalContacted = useMemo(
    () => sortedResults?.reduce((acc, curr) => acc + curr.contacted, 0) || 0,
    [sortedResults]
  );
  const totalMustContact = useMemo(
    () => sortedResults?.reduce((acc, curr) => acc + curr.must_contact, 0) || 0,
    [sortedResults]
  );
  const contactPercentage = ((totalContacted / totalMustContact) * 100).toFixed(
    0
  );

  const getColorClass = () => {
    if (contactPercentage > 80) return 'text-primaryGreen';
    if (contactPercentage > 70) return 'text-primaryYellow';
    return 'text-primaryRed';
  };

  return (
    <div>
      <span className={`font-DrukWideMedium text-lg ${getColorClass()}`}>
        <Tooltip title='Total Contactados'>
          <span>{totalContacted}</span>
        </Tooltip>
        /
        <Tooltip title='Total a Contactar'>
          <span>{totalMustContact}</span>
        </Tooltip>{' '}
        (
        <Tooltip title='Porcentaje de Contacto'>
          <span>{isNaN(contactPercentage) ? 0 : contactPercentage}%</span>
        </Tooltip>
        )
        <div className='flex flex-row items-center gap-3 mt-2'>
          {weeks_arr.map((week, i) => (
            <Tooltip title={`Semana ${week}`} key={i}>
              <button
                onClick={() => {
                  if (maxWeek.some((m) => m == week)) {
                    let result = maxWeek.filter((m) => m !== week);
                    if (result.length == 0) {
                      setMaxWeek(weeks_arr.slice(-1));
                    } else {
                      setMaxWeek(maxWeek.filter((m) => m !== week));
                    }
                  } else {
                    setMaxWeek([...maxWeek, week]);
                  }
                }}
                className={`rounded-md ${
                  maxWeek.includes(week) ? 'bg-lightGreen' : 'bg-primaryGrey'
                } h-7 w-7 flex items-center justify-center ease-in-out duration-300`}
              >
                <p
                  className={`font-MessinaSansSemiBold ${
                    maxWeek.includes(week) ? 'text-black' : 'text-white'
                  }`}
                >
                  {week}
                </p>
              </button>
            </Tooltip>
          ))}
        </div>
      </span>
    </div>
  );
};

const TopResults = ({ topResults, others }) => {
  const getClassForRate = (rate) => {
    if (rate * 100 === 100) return 'text-primaryGreen';
    if (rate * 100 > 70) return 'text-primaryYellow';
    return 'text-primaryRed';
  };

  return (
    <div className='pt-1'>
      {topResults.map((item, i) => (
        <div
          key={i}
          className='flex flex-row gap-4 justify-between items-center'
        >
          <span
            className={`text-md ${getClassForRate(item.rate_contact)} w-[50%]`}
          >
            {i === 7 ? 'Otros' : item.coach_name}
          </span>
          <span
            className={`text-md ${getClassForRate(
              item.rate_contact
            )} w-[50%] flex justify-end`}
          >
            {i === 7 ? (
              `${others.reduce(
                (acc, curr) => acc + curr.contacted,
                0
              )}/${others.reduce(
                (acc, curr) => acc + parseInt(curr.must_contact),
                0
              )}
              (${(
                (others.reduce((acc, curr) => acc + curr.contacted, 0) /
                  others.reduce(
                    (acc, curr) => acc + parseInt(curr.must_contact),
                    0
                  )) *
                100
              ).toFixed(0)}%)`
            ) : (
              <>
                <Tooltip title='Contactos'>{item.contacted}</Tooltip>/{' '}
                <Tooltip title='Socios a contactar'>
                  {item.must_contact}
                </Tooltip>{' '}
                <Tooltip title='Tasa de contacto'>
                  (
                  {isNaN(item.rate_contact)
                    ? 0
                    : (item.rate_contact * 100).toFixed(0)}
                  %)
                </Tooltip>
              </>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default function AssignmentsChart() {
  const { data, isLoading } = useGetAssignmentsChart();
  const [maxWeek, setMaxWeek] = useState([0]);
  const [initialHeight, setInitialHeight] = useState(null);
  const containerRef = useRef(null);
  const [weeks_arr, setWeeks_arr] = useState([1, 2, 3, 4]);
  const sortedResults = useMemo(() => {
    if (maxWeek[0] == 0) return [];

    return (
     Object.values( data?.results
      ?.filter((item) => maxWeek.includes(item.week_number))
      .filter((t)=>t.location_id == 58 && t.coach_name == 'Milo Dojas' ? false : true) // remove milo from miraflores
      .sort((a, b) => b.contacted - a.contacted).reduce((acc, curr) => {
        let key = `${curr.coach_name}`
        
        if(!acc[key]){
          acc[key] = {
            coach_name: curr.coach_name,
            contacted: 0,
            must_contact: 0,
            rate_contact: 0
          }
        }
      
        acc[key].contacted += curr.contacted
        acc[key].must_contact += curr.must_contact
        acc[key].rate_contact = acc[key].contacted / acc[key].must_contact
        return acc
      }, {}))
         || []
    );
  }, [maxWeek]);
  useEffect(() => {
    if (data && !isLoading && maxWeek[0] == 0) {
      if (data.results.length == 0) return;
      const max = data.results.reduce((acc, curr) =>
        acc.week_number > curr.week_number ? acc : curr
      );
      setMaxWeek([max.week_number]);
      setWeeks_arr(
        data.results
          .sort((a, b) => a.week_number - b.week_number)
          .reduce((acc, curr) => {
            if (!acc.includes(curr.week_number)) {
              acc.push(curr.week_number);
            }
            return acc;
          }, [])
      );
    }
  }, [data, isLoading, maxWeek]);

  useEffect(() => {
    if (containerRef.current && initialHeight === null) {
      setInitialHeight(containerRef.current.clientHeight);
    }
  }, [initialHeight, containerRef.current]);

  const topResults = sortedResults.slice(0, 8);
  const others = sortedResults.slice(7);

  return (
    <Card
      title='Asignaciones'
      insight={false}
      size='small'
      icon=''
      description={data?.description?.description}
      TopInsight={
        data &&
        !isLoading && (
          <div
            ref={containerRef}
            style={{
              height: initialHeight !== null ? `${initialHeight}px` : 'auto',
            }}
            className='gap-3 flex-1 pt-3 flex flex-col justify-between'
          >
            <TotalContacts
              weeks_arr={weeks_arr}
              sortedResults={sortedResults}
              maxWeek={maxWeek}
              setMaxWeek={setMaxWeek}
            />

            <TopResults topResults={topResults} others={others} />
          </div>
        )
      }
    />
  );
}
