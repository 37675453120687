// import { AbilityBuilder, createMongoAbility } from '@casl/ability';
// import { useGlobalContext } from '../context/GlobalState';

// const Roles = {
//   ADMIN: 1,
//   MANAGER: 2,
//   COACH: 3,
//   SALES: 5,
//   OWNER: 6,
// };

// const getUserRoles = () => {
//   const { state: user } = useGlobalContext();
//   return user.user.roles;
// };

// const defineAbilitiesForAdmin = (can) => {
//   can('manage', 'all');
// };

// const defineAbilitiesForManagerOrOwner = (can) => {
//   can('update', 'Calendar');
//   can('create', 'User');
//   can('create', 'Coach');
//   can('create', 'TimeSlot');
// };

// const defineAbilitiesForManagerSalesOrOwner = (can) => {
//   can('create', 'Stock');
// };

// const defineAbilitiesForExcludingCoach = (can) => {
//   can('access', 'Balance');
// };

// export const defineAbilitiesFor = (roles) => {
//   const { can, cannot, rules } = new AbilityBuilder(createMongoAbility);

//   if (roles.some((role) => role.id === Roles.ADMIN)) {
//     defineAbilitiesForAdmin(can);
//   }

//   if (!roles.some((role) => role.id === Roles.COACH)) {
//     defineAbilitiesForExcludingCoach(can);
//   }

//   if (roles.some((role) => [Roles.MANAGER, Roles.OWNER].includes(role.id))) {
//     defineAbilitiesForManagerOrOwner(can);
//   }

//   if (
//     roles.some((role) =>
//       [Roles.MANAGER, Roles.SALES, Roles.OWNER].includes(role.id)
//     )
//   ) {
//     defineAbilitiesForManagerSalesOrOwner(can);
//   }

//   // Las reglas que se aplican a todos los roles
//   can('create', 'Member');
//   can('create', 'Prospect');

//   return createMongoAbility(rules);
// };

import { useGlobalContext } from '../context/GlobalState';

// Mapeo de roles para mayor claridad
export const Roles = {
  ADMIN: 1,
  MANAGER: 2,
  COACH: 3,
  SALES: 5,
  OWNER: 6,
  HQ: 7,
};

export const getUserRoles = () => {
  const { state: user } = useGlobalContext();
  return user?.user?.roles;
};

export const isAdmin = (roles) =>
  roles?.some((role) => role.id === Roles.ADMIN) || false;
const hasRole = (roles, validRoles) =>
  isAdmin(roles) || roles?.some((role) => validRoles.includes(role.id));
export const isUserSuperAdmin = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN]);
};
export const isUserAdmin = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const isUserManager = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};

export const canCreateHQUser = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canCreateManagerUser = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.OWNER, Roles.HQ]);
};
export const canCreateOwnerUser = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canCreateSalesUser = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.OWNER, Roles.HQ, Roles.MANAGER]);
};

export const canCreateMember = () => true;

export const canCreateProspect = () => true;

export const canCreateStock = () => {
  const roles = getUserRoles();
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.SALES,
    Roles.OWNER,
    Roles.HQ,
  ]);
};
export const canModifyUserSale = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};

export const canUpdateCalendar = () => {
  const roles = getUserRoles();
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.OWNER,
    Roles.SALES,
    Roles.HQ,
  ]);
};

export const canCreateUser = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};
export const canCreateCoach = () => {
  const roles = getUserRoles();

  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};
export const canCreateTimeSlot = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};
export const canAccessRegionConfigs = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN]);
};
export const canAccessLocations = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canCreateSaleGoals = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ, Roles.MANAGER, Roles.OWNER]);
};
export const canUpdateProducts = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canAccessLocationGoals = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN]);
};
export const canUpdateConsumable = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};

export const canUpdateMemberships = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canUpdateUsers = () => {
  const roles = getUserRoles();
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.OWNER,
    Roles.HQ,
    Roles.SALES,
  ]);
};
export const canUpdateCoaches = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.SALES, Roles.HQ]);
};
export const canUpdateSales = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canUpdateClasses = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ, Roles.MANAGER, Roles.OWNER]);
};
export const canUpdateTimeSlots = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.HQ, Roles.OWNER]);
};
// modal member
export const canUpdateMemberLocation = () => {
  const roles = getUserRoles();
  return hasRole(roles, [Roles.ADMIN, Roles.HQ]);
};
export const canUpdateMemberCoach = () => {
  const roles = getUserRoles();
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.SALES,
    Roles.OWNER,
    Roles.HQ,
  ]);
};
export const canCreateDebit = () => {
  const roles = getUserRoles();
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.SALES,
    Roles.OWNER,
    Roles.HQ,
  ]);
};
export const canCreateSale = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.SALES,
    Roles.OWNER,
    Roles.HQ,
  ]);
};
export const canCreateFreeProduct = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};

export const canReturnProduct = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.OWNER,
    Roles.HQ,
    Roles.SALES,
  ]);
};
export const canCreateCreditNote = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [Roles.ADMIN, Roles.MANAGER, Roles.OWNER, Roles.HQ]);
};
export const canUpdateProductSessions = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [Roles.ADMIN, Roles.HQ, Roles.OWNER, Roles.MANAGER]);
};
export const canUpdateProductDays = () => {
  const roles = getUserRoles();
  // return false;
  return hasRole(roles, [Roles.ADMIN, Roles.HQ, Roles.OWNER, Roles.MANAGER]);
};
