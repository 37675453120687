import { Tag } from 'antd';
import {
  createdAtColumn,
  formatDateColumn,
  generateColumn,
  IDCOLUMN,
  sorterText,
} from './ColumnUtils';

export const RegionConfigTypeColumns = [
  IDCOLUMN,
  generateColumn('nombre', 'name', '15%', false, sorterText('first_name')),
  generateColumn(
    'Descripcion',
    'description',
    '15%',
    false,
    sorterText('last_name')
  ),
  generateColumn('Paises', 'countries', '20%', (tags) => (
    <div className=' flex flex-wrap '>
      {tags.map((tag, i) => {
        let color = tag.length > 3 ? 'geekblue' : 'green';
        return (
          <Tag
            className={`${tags.length > 1 && 'mt-2'} py-1`}
            color={color}
            bordered={false}
            key={i}
          >
            {tag.description}
          </Tag>
        );
      })}
    </div>
  )),
  generateColumn('Sedes', 'locations', '20%', (tags) => (
    <div className=' flex flex-wrap '>
      {tags.map((tag, i) => {
        let color = tag.length > 3 ? 'geekblue' : 'green';
        return (
          <Tag
            className={`${tags.length > 1 && 'mt-2'} py-1`}
            color={color}
            bordered={false}
            key={i}
          >
            {tag.name}
          </Tag>
        );
      })}
    </div>
  )),

  createdAtColumn,
];
