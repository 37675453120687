import { useQuery } from '@tanstack/react-query';
import axiosInstance from './prospects';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';
import { getCreditNotes, getRefunds } from './sales';
import dayjs from 'dayjs';
export const getReports = async (
  location_id,
  id,
  start,
  end,
  month_date,
  active_checkbox,
  isSelectAttendance,
  selectedClass,
  selectSameMoment
) => {
  if (!id) return [];
  if (!location_id || location_id?.length == 0) return [];
  let location = location_id ? location_id : getLocationId();
  if (Array.isArray(location) && location.length > 1) {
    location = location.join(',');
  }

  if (id == 6) {
    return getIndicatorCrmReport(location, id, start, end);
  }
  if (id == 'returns') {
    return getRefunds(start, end, false, location);
  }
  if (id == 'credit_notes') {
    return getCreditNotes(start, end, false, location);
  }
  if (month_date) {
    if (selectSameMoment != null) {
      return fetchData(
        `/report_json/${id}/?location_id=${location}&date_month=${month_date}&same_moment_sales=${selectSameMoment}`
      );
    }
    return fetchData(
      `/report_json/${id}/?location_id=${location}&date_month=${month_date}`
    );
  }
  if (id == 14) {
    let report;
    if (active_checkbox) {
      report = await fetchData(
        `/report_json/${id}/?location_id=${location}&debit_state=active`
      );
    } else {
      report = await fetchData(`/report_json/${id}/?location_id=${location}`);
    }
    return report;
  }
  if (id == 5) {
    let qReport = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}`;
    if (isSelectAttendance !== null) {
      if (selectedClass) {
        qReport = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}&class_name=${selectedClass}&has_attended=${isSelectAttendance}`;
      } else {
        qReport = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}&has_attended=${isSelectAttendance}`;
      }
    } else {
      if (selectedClass) {
        qReport = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}&class_name=${selectedClass}`;
      }
    }

    let report = await fetchData(qReport);
    report = report.reduce((accumulator, current) => {
      const index = accumulator.findIndex(
        (item) => item.class_hour === current.class_hour
      );

      if (index === -1) {
        // Si no se encuentra, agrega un nuevo objeto
        accumulator.push({
          class_hour: current.class_hour,
          total: 1,
          totalsByDayOfWeek: {
            [current.dia_semana.trim().toLowerCase()]: current.reserves,
          },
        });
      } else {
        // Si se encuentra, incrementa el total en 1 y suma las reservas al día de la semana correspondiente
        accumulator[index].total += 1;
        const dayOfWeek = current.dia_semana.trim().toLowerCase();
        accumulator[index].totalsByDayOfWeek[dayOfWeek] =
          (accumulator[index].totalsByDayOfWeek[dayOfWeek] || 0) +
          current.reserves;
      }

      return accumulator;
    }, []);
    return report;
  }
  if (id == 21) {
    let q = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}`;
    let report = await fetchData(q);
    let report1 = report
      .sort((a, b) => a.start_date < b.start_date)
      // .sort((a, b) => a.week_number > b.week_number)
      .reduce((acc, item) => {
        const key = `${item.member_id}-${item.coach_name}`; //-${item.member_period}
        let last_antiquity = report
          .sort((a, b) => a.start_date < b.start_date)
          .find((i) => i.member_id == item.member_id);
        if (!acc[key]) {
          acc[key] = {
            member_id: item.member_id,
            member_name: item.member_name,
            coach_name: item.coach_name,
            antiquity: last_antiquity
              ? last_antiquity.antiquity
              : item.antiquity,
            member_period: item.member_period,
            location_id: item.location_id,
            location_name: item.location_name,
            total_uses: 0,
            week_number: [],
          };
        }
        if (!acc[key].week_number[item.week_number]) {
          acc[key].week_number.push({
            week: parseInt(item.week_number),
            week_range: item.week_range,
            assigned_state: item.assigned_state,
            weekly_uses: item.weekly_uses,
          });
          acc[key].week_number = acc[key].week_number.sort(
            (a, b) => a.week > b.week
          );
          acc[key].total_uses += item.weekly_uses;
        }
        // Ahora estamos seguros de que acc[key] está definido, por lo que podemos proceder a sumar
        return acc;
      }, {});
    return Object.values(report1);
  }
  if (id == 18) {
    let q = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}`;
    if (isSelectAttendance !== null) {
      q = `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}&has_attended=${isSelectAttendance}`;
    }
    let report = await fetchData(q);
    // let report = await fetchData(
    //   `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}&has_attended=${
    //     isSelectAttendance ? isSelectAttendance : false
    //   }`
    // );
    report = report.reduce((acc, item) => {
      const key = `${item.member_id}`;
      if (!acc[key]) {
        acc[key] = {
          member_id: item.member_id,
          member_name: item.member_name,
          coach_name: item.coach_name,
          actual_state: item.actual_state,
          [dayjs(item.date_reserve).format('MMMM YYYY')]: 0, // Inicializar total_reserves
        };
      }
      // Ahora estamos seguros de que acc[key] está definido, por lo que podemos proceder a sumar
      if (!acc[key][dayjs(item.date_reserve).format('MMMM YYYY')]) {
        acc[key][dayjs(item.date_reserve).format('MMMM YYYY')] = 0;
      }

      acc[key][dayjs(item.date_reserve).format('MMMM YYYY')] += item.reserves;

      return acc;
    }, {});
    return Object.values(report);
  }
  let report = await fetchData(
    `/report_json/${id}/?location_id=${location}&start_date=${start}&end_date=${end}`
  );

  return report;
};
const getIndicatorCrmReport = async (location, id, start, end) => {
  const location_id = location;
  const response = await fetchData(
    `/report_json/${id}/?location_id=${location_id}&start_date=${start}&end_date=${end}`
  );
  const groupedData = response.reduce((acc, item) => {
    if (!acc[item.user_id]) {
      acc[item.user_id] = {
        user_id: item.user_id,
        username: item.username,
        location_id: item.location_id,
        // crm_date: item.crm_date,
      };
    }
    const crmCountField = `crm_count_${item.action_type_id}`;
    if (!acc[item.user_id][crmCountField]) {
      acc[item.user_id][crmCountField] = 0;
    }
    acc[item.user_id][crmCountField] += item.count_crm;
    return acc;
  }, {});
  const result = Object.values(groupedData);
  return result;
};
export const useGetReports = (
  location_id,
  id,
  start,
  end,
  month_date,
  active_checkbox,
  isSelectAttendance,
  selectedClass,
  selectSameMoment
) => {
  const location = location_id ? location_id : getLocationId();
  return useQuery({
    queryKey: month_date
      ? ['reports', location, id, month_date, selectSameMoment]
      : active_checkbox
      ? ['reports', location, id, active_checkbox]
      : isSelectAttendance !== null
      ? ['reports', location, id, start, end, isSelectAttendance]
      : selectedClass
      ? ['reports', location, id, start, end, selectedClass]
      : ['reports', location, id, start, end],
    queryFn: async () =>
      getReports(
        location_id,
        id,
        start,
        end,
        month_date,
        active_checkbox,
        isSelectAttendance,
        selectedClass,
        selectSameMoment
      ),
    // select: (data) => data.filter((d) => d.location_id == 1),
  });
};
export default axiosInstance;
