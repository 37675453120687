import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import dayjs from 'dayjs';
import { USE_FORM_SALE_GOAL_TYPES } from '../services/Constants/FORMS';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification, ConfigProvider, DatePicker } from 'antd';
import { formatError } from '../services/utils/formatError';

import { openNotificationFields } from '../services/utils/openNotificationFields';

import { canAccessRegionConfigs, isUserAdmin } from '../services/can_user';
import { t } from 'i18next';
import {
  createSaleGoalType,
  deleteSaleGoalType,
  getSaleGoalType,
  updateSaleGoalType,
  useGetSalesGoalsTypes,
} from '../api/sales_objectives';
import { SalesGoalsTypesColumns } from '../services/Constants/LocationColumns';
import { getLocationId } from '../services/utils/getLocation';

const start = dayjs().startOf('month');
const end = dayjs();
const location_id = getLocationId();
export default function SalesGoalsTypes() {
  const columns = SalesGoalsTypesColumns();
  const TITLE = t('sales_goals_types', 'Tipos objetivos comerciales');
  const TITLE_SINGULAR = t('sale_goal_type', 'Tipo objetivo comercial');
  const FORM_SALE = USE_FORM_SALE_GOAL_TYPES();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { data, isLoading } = useGetSalesGoalsTypes(

  );
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();

  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  const handleDelete = async (id) => {
    await useDeleteSaleGoalType.mutateAsync(id);
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t(
              'sale_goal_type_success',
              'Tipo objetivo comercial actualizado correctamente'
            )
          : t(
              'sale_goal_type_error',
              'Ocurrió un error al actualizar el tipo objetivo comercial'
            ),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_SALE);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let sale = {
        data: data,
      };
      await useCreateSaleGoalType.mutateAsync(sale);
      return setopenDrawer({ visible: false, record: null });
    }
    let sale = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateSaleGoalType.mutateAsync(sale);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let sale_goal_type;
        if (record) {
          sale_goal_type = await getSaleGoalType(record.id);
          FORM.forEach((item) => {
            // if(item.name == 'price'){
            //  return setValue(item.name, parseFloat(product[item.name]));
            // }
            setValue(item.name, sale_goal_type[item.name]);
          });
        }
        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateSaleGoalType = useMutation({
    mutationFn: (sale) => updateSaleGoalType(sale.id, sale.data),
    onSuccess: () => {
      openNotification('success');

      queryClient.invalidateQueries({
        queryKey: ['sales_goals_types', location_id], // arreglar el query key q coincida con el useGetsALE GOALTYPES
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateSaleGoalType = useMutation({
    mutationFn: (sale) => createSaleGoalType(sale.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sales_goals_types', location_id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteSaleGoalType = useMutation({
    mutationFn: (id) => deleteSaleGoalType(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sales_goals_types', location_id],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  return (
    <>

          <TableCustom
            // hideTitle
            filterTable={filterTable}
            data={data}
            canUpdate={isUserAdmin()}
            // multipleTables
            canCreate={canAccessRegionConfigs()}
            openDrawer={openDrawer}
            setfilterTable={setfilterTable}
            handleOpenDrawer={handleOpenDrawer}
            title={TITLE}
            title_singular={TITLE_SINGULAR}
            searchInput={searchInput}
            onSubmit={onSubmit}
            control={control}
            FORM={FORM}
            handleDelete={handleDelete}
            originalColumns={columns}
            register={register}
            handleSubmit={handleSubmit}
            setsearchInput={setsearchInput}
            isLoading={isLoading}
            mutateLoading={
              useUpdateSaleGoalType.isLoading || useCreateSaleGoalType.isLoading
            }
          />
  
        {contextHolder}
 
    </>
  );
}
