import React from 'react';
import CardHeader from './CardHeader';
import { ResponsiveContainer } from 'recharts';

export default function Card({
  children,
  title,
  insight,
  icon,
  multipleInsights,
  bottomInsight,
  Extraclass,
  TopInsight,
  percentage,
  todayTotal,
  popover,
  description,
  description_last_month,
  percentage2,
  description_last_month2,
  headerChildren
}) {
  return (
    <div
      className={`bg-primaryDark rounded-lg flex flex-column justify-between p-5 ${Extraclass}`}
    >
      <CardHeader
        description_last_month={description_last_month}
        description={description}
        popover={popover}
        title={title}
        description_last_month2={description_last_month2}
        icon={icon}
        multipleInsights={multipleInsights}
        insight={insight}
        percentage2={percentage2}
        percentage={percentage}
        todayTotal={todayTotal}
        headerChildren={headerChildren}
      />
      {TopInsight && TopInsight}
      {children && (
        <ResponsiveContainer width='100%' height={170}>
          {children}
        </ResponsiveContainer>
      )}
      {bottomInsight && (
        <div>
          <h2 className=' font-DrukWideMedium text-primaryGreen lg:text-3xl md:text-xl text-xl'>
            {bottomInsight}
          </h2>
        </div>
      )}
    </div>
  );
}
