import react from 'react';
import CallSvg from '../../assets/icons/CallSvg';
import EmailSvg from '../../assets/icons/EmailSvg';
import NoteSvg from '../../assets/icons/NoteSvg';
import PinSvg from '../../assets/icons/PinSvg';
import WhatsAppSvg from '../../assets/icons/WhatsAppSvg';
import AppSvg from '../../assets/icons/AppSvg';
import SaleSvg from '../../assets/icons/SaleSvg';

import { useTranslation } from 'react-i18next';

const useCRMOptions = () => {
  const { t, ready } = useTranslation();

  // Asegúrate de que las traducciones estén listas antes de construir el array
  if (!ready) return [];

 const CRM_OPTIONS = [
  {
    type: 'note',
    icon: <NoteSvg />,
    icon_lg: <NoteSvg large />,
    placeholder:  t('crm.note_placeholder','Escribir nota'),
    title: t('crm.note','Nota'),
    action_type_id: 5,
  },
  {
    type: 'email',
    icon: <EmailSvg />,
    icon_lg: <EmailSvg large />,
    placeholder: t('crm.email_placeholder','Asunto'),
    title: 'Email',
    hasDateHeader: true,

    action_type_id: 4,
  },
  {
    type: 'WhatsApp',
    icon: <WhatsAppSvg />,
    icon_lg: <WhatsAppSvg large />,
    hasDateHeader: true,
    placeholder:  t('crm.whatsapp_placeholder','Resumen'),
    title: 'Whatsapp',
    action_type_id: 2,
    placeholderDate:t('crm.whatsapp_date','Fecha Conversacion'),
  },
  {
    type: 'app',
    icon: <AppSvg />,
    icon_lg: <AppSvg large />,
    placeholder: 'App',
    title: 'APP',
    action_type_id: 1,
    show: false,
  },
  {
    type: 'coach_modify',
    // icon: <AppSvg />,
    // icon_lg: <AppSvg large />,
    placeholder: 'App',
    action_type_id: -1,
    show: false,
    title: t('crm.coach_modify','Modificación Coach'),
  },
  {
    type: 'call',
    icon: <CallSvg />,
    icon_lg: <CallSvg large />,
    hasDateHeader: true,
    placeholder: t('crm.whatsapp_placeholder','Resumen'),
    title: 'Llamada',
    action_type_id: 3,
    placeholderDate:t('crm.call_placeholderDate','Fecha Llamada'),
  },
  {
    type: 'Pin',
    icon: <PinSvg />,
    icon_lg: <PinSvg large />,
    hasDateHeader: true,
    title: t('crm.alert','Alerta'),
    placeholder: t('crm.alert','Alerta'),
    action_type_id: 6,
    hasUserSelect: true,
    placeholderDate: t('crm.alert_date','Fecha Alerta'),
  },
  {
    type: 'Pin',
    icon: <PinSvg />,
    icon_lg: <PinSvg large />,
    title: t('crm.others','Otros'),
    placeholder: t('crm.others','Otros'),
    action_type_id: 7,
    show: false,
  },
  {
    type: 'sale',
    action_type_id: 0,
    icon: <SaleSvg />,
    icon_lg: <SaleSvg large />,
    hasDateHeader: true,
    title: t('sales.title_singular','Venta'),
    placeholder: t('sales.title_singular','Venta'),
  },
  {
    type: 'app_internal',
    icon: <AppSvg />,
    icon_lg: <AppSvg large />,
    placeholder: 'App Internal',
    title: 'APP Internal',
    action_type_id: 8,
    show: false,
  },
];

  return CRM_OPTIONS;
}
export { useCRMOptions };
