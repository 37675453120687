import React, { useMemo, useState } from 'react';
import { ConfigProvider, DatePicker, Tooltip, Popover } from 'antd';
import es_ES from 'antd/locale/es_ES';
import CardRounded from '../CardRounded';
import SpinIndicador from '../helpers/SpinIndicador';
import { t } from 'i18next';
import { getLocationId } from '../../services/utils/getLocation';
import dayjs from 'dayjs';
import { useAuth } from '../../context/AuthProvider';
export const DateRangePicker = ({ start_date, end_date, setStartDate, setEndDate, datepickerFrom, datepickerAt }) => {
  const handleChange = (date, index) => {
    if (index === 0) {
      datepickerFrom.current.blur();
      setStartDate(date);
    } else {
      datepickerAt.current.blur();
      setEndDate(date);
    }
  };

  return (
    <CardRounded styles={' mt-6 py-1 relative flex flex-row  w-full lg:w-[25%]'}>
      {[t('from', 'Desde'), t('to', 'Hasta')].map((item, i) => (
        <div
          key={i}
          className='relative border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center'
        >
          <span className='opacity-40 absolute top-2 font-MessinaSansSemiBold'>{item}</span>
          <ConfigProvider locale={es_ES}>
            <DatePicker
              allowClear={false}
              ref={i === 0 ? datepickerFrom : datepickerAt}
              onChange={(date) => handleChange(date, i)}
              value={i === 0 ? start_date : end_date}
              showToday
              className='bg-transparent outline-none border-none h-12 w-full appearance-none shadow-none hover:bg-primaryGrey focus:bg-primaryGrey'
            />
          </ConfigProvider>
        </div>
      ))}
    </CardRounded>
  );
};

export const StatCard = ({ isLoading, title, value, tooltipTitle, PopoverComponent, onClick, isActive }) => (

  <CardRounded styles={`mt-6 relative px-5 flex items-center flex cursor-pointer  ${isActive ? '!bg-[#CEF5CE]' : 'bg-primaryDark'}`} onClick={onClick}>
        <Tooltip title={tooltipTitle} zIndex={50}>
      <div className='flex flex-col items-center justify-center'>
        {isLoading ? (
          <SpinIndicador color={'fill-secondaryGrey'} />
        ) : PopoverComponent ? (
          <Popover align={{ offset: [0, 50] }} placement='bottomLeft' color='#222' content={PopoverComponent}  >
            <h3 className={`text-center font-DrukWideMedium ${isActive ? 'text-black':'text-white'} text-lg lg:text-xl`}>{value}</h3>
          </Popover>
        ) : (
          <h3 className={`text-center font-DrukWideMedium ${isActive ? 'text-black':'text-white'}  text-lg lg:text-xl`}>{value}</h3>
        )}
        <h3 className={`text-center mt-2 ${isActive ? 'text-black':'text-white'}`}>{title}</h3>
      </div>
      </Tooltip>

  </CardRounded>
);

export const StatsSection = ({ isLoading, data, convertedProspects, hours, minutes, PopProspectsConverted, PopProspects, ac, onFilterChange }) => {
  const {user} = useAuth()
    const stats = useMemo(() => [
    {
      title: t('without_contact', 'Sin contactar'),
      value: data.filter((p) => p &&  !p.last_crm && !p.converted_date).length,
      className: data.filter((p) => p && !p.last_crm && !p.converted_date).length > 0 ? 'text-primaryRed' : 'text-white',
      filter: (p) =>  p &&!p.last_crm && !p.converted_date,
    },
    {
      title: t('in_progress', 'En proceso'),
      value: data.length - convertedProspects - data.filter((p) => p.discharge_reason_name).length,
      className: 'text-white',
      filter: (p) => !p.converted_date && !p.discharge_reason_name,
      tooltipTitle:
      t('desc_in_progress',
        'Los prospectos en proceso son aquellos que no han sido convertidos y aún no tienen razón de no compra' )
    },
    {
      title: t('convertions', 'Convertidos'),
      value: convertedProspects,
      PopoverComponent: () => PopProspectsConverted({ data,isActive:ac }),
      filter: (p) => p &&  p.converted_date && ![33, 32, 207].includes(p.converted_subscription?.membership?.product.parent_product_id),
    },
    {
      title: 'RNC',
      value: data.filter((p) => p.discharge_reason_name).length,
      PopoverComponent: () => PopProspects({ data, title: t('prospect.discharge_reason', 'Por qué no compró?') ,isActive:ac}),
      filter: (p) => p.discharge_reason_name,
    },
    {
      title: 'Promedio de respuesta',
      value: `${hours}h ${minutes}m`,
      tooltipTitle: t(
        'desc_response_time',
        'El tiempo de respuesta promedio se calcula en base al tiempo que pasó entre la creación del prospecto y el primer contacto.'
      ),
      condition: getLocationId() !== 1,
      filter: (p) => {
        const hour = dayjs(p.created_at).tz(user?.country?.time_zone).format('HH');
        const day = dayjs(p.created_at).tz(user?.country?.time_zone).day(); // 0 para domingo, 6 para sábado
        
        // Excluir registros entre 22:00 y 06:00 y los creados en sábado o domingo
        const isExcludedTime = (hour >= '22' || hour <= '06') || (day === 0 || day === 6);
        
        // Excluir productos con IDs 33, 32, 207
        const isExcludedProduct = [33, 32, 207].includes(p.converted_subscription?.membership?.product.parent_product_id);
        
        // Incluir solo los prospectos que no están excluidos por tiempo o producto
        return !isExcludedTime && !isExcludedProduct;
      }
    },
  ], [data, convertedProspects, hours, minutes,ac]);

  return (
    <div className='flex flex-row gap-5 w-full lg:w-fit flex-wrap lg:flex-nowrap'>
      {stats.map(
        ({ title, value, PopoverComponent, tooltipTitle, condition = true, filter }, index) =>
          condition && (
            <StatCard
              key={index}
              isLoading={isLoading}
              title={title}
              value={value}
              tooltipTitle={tooltipTitle}
              PopoverComponent={PopoverComponent}
              isActive={ac == filter}
              onClick={() => onFilterChange(filter)}
            />
          )
      )}
    </div>
  );
};