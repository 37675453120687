import { t } from 'i18next'
import React from 'react'
import { RiAddFill } from 'react-icons/ri'
export default function AddItemTable({title,onClick}) {

  return (
    <button onClick={()=>onClick()} className={` border-lightGreen border  rounded-full ease-in-out duration-500 transition-opacity  hover:opacity-70  flex items-center justify-between  w-52 `}>
    <div className='h-14 w-14  rounded-full  bg-lightGreen flex items-center justify-center'>
    <RiAddFill  color={'#1EA05A'} size={30} />
    </div>
    <p className='text-lightGreen font-MessinaSansSemiBold pr-4'>{t('new','Nuevo')} {title}</p>
  </button>
  )
}
