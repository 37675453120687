import React from 'react';
import ReactDOM from 'react-dom/client';
import "./services/i18n"; 
import './assets/styles/index.css';
import { ThemeProvider } from '@material-tailwind/react';
import { GlobalProvider, useGlobalContext } from './context/GlobalState';
import { AuthProvider } from './context/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import CustomerIOTracker from './services/CustomerIo';
import {  HelmetProvider } from 'react-helmet-async';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

import { createBrowserRouter, RouterProvider, redirect,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import Dashboard from '../src/pages/Dashboard';
import Members from '../src/pages/Members';
import Reports from '../src/pages/Reports';
import Login from '../src/pages/Login';
import { rootLoader } from './router/loaders/rootLoader';
import Prospects from '../src/pages/Prospects';
import Layout from '../src/layout/Layout';
import NotFound from '../src/pages/NotFound';
import CalendarClasses from '../src/pages/CalendarClasses';
import CalendarCoaches from '../src/pages/CalendarCoaches';
import Products from '../src/pages/Products';
import Users from '../src/pages/Users';
import Consumables from '../src/pages/Consumables';
import Discounts from '../src/pages/Discounts';
import Sales from '../src/pages/Sales';
import Gym_classes from '../src/pages/Gym_classes';
import ProductSessions from '../src/pages/ProductSessions';
import Memberships from '../src/pages/Memberships';
import Coaches from '../src/pages/Coaches';
import { ConsumableLoader, DiscountsLoader, MembershipLoader, ProductLoader, SessionsLoader } from './router/loaders/ProductsLoaders';
import { UsersLoader } from './router/loaders/UsersLoaders';
import { BalanceLoader, StockLoader, TransactionsLoader } from './router/loaders/AccountingLoaders';
import Payments from './pages/Payments';
import Transactions from './pages/Transactions';
import Balances from './pages/Balances';
import ProductStocks from './pages/ProductStocks';
import TimeSlots from './pages/TimeSlots';
import { ClassesLoader, TimeSlotsLoader } from './router/loaders/ClassesLoaders';
import RegionConfigTypes from './pages/RegionConfigTypes';
import { RegionConfigLoader } from './router/loaders/ConfigLoaders';
import * as Sentry from "@sentry/react";
import Locations from './pages/Locations';
import Countries from './pages/Countries';
import SalesGoalsTypes from './pages/SalesGoalsTypes';
import SalesGoals from './pages/SalesGoals';
import Test from './pages/ClientMpUpload';
import ClientMpUpload from './pages/ClientMpUpload';
import Leads from './pages/Leads';
if(process.env.ENVIROMENT !== 'development'){
Sentry.init({
  dsn: "https://64fe6a97182f8a50e875bdd9f6627579@o1177915.ingest.sentry.io/4505946876870656",
  // Integrations for Sentry error tracking
  environment: process.env.ENVIROMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Build configuration for Sentry
  // build: {
  //   sourcemap: true, // Source map generation must be turned on
  // },

  // Plugins for Sentry error tracking
  plugins: [
    // Put the Sentry vite plugin after all other plugins
    // sentryVitePlugin({
    //   org: "bigg-bk",
    //   project: "javascript-react",
    //   authToken:'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',
    // }),
  ],

  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
}
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

 const router = sentryCreateBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Layout />,
    loader: rootLoader(queryClient),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Dashboard />
      
      },
      {
        path: '/members',
        element: <Members />,
   
      },
      {
        path: '/prospects',
        element: <Prospects />,
      },
      {
        path: '/leads',
        element: <Leads />,
      },
      {
        path: '/users',
        element: <Users />,
        loader: UsersLoader(queryClient)
      },
      {
        path: '/calendar',
        element: <CalendarClasses />,
      },
      {
        path: '/products',
        element: <Products />,
        loader: ProductLoader(queryClient),
      },
      {
        path: '/consumables',
        element: <Consumables />,
        loader: ConsumableLoader(queryClient),
      },
      {
        path: '/memberships',
        element: <Memberships />,
        loader: MembershipLoader(queryClient),
      },
      {
        path: '/discounts',
        element: <Discounts />,
        loader: DiscountsLoader(queryClient),
      },
      
      {
        path: '/sessions',
        element: <ProductSessions />,
        loader: SessionsLoader(queryClient),
      },
      {
        path: '/gym_classes',
        element: <Gym_classes />,
        loader: ClassesLoader(queryClient),
      },
      {
        path: '/time_slots',
        element: <TimeSlots />,
        loader: TimeSlotsLoader(queryClient),
      },
      {
        path: '/sales',
        element: <Sales />,
        // loader: SalesLoader(queryClient),
      },
      {
        path: '/payments',
        element: <Payments />,
        // loader: PaymentsLoader(queryClient),
      },
      {
        path: '/transactions',
        element: <Transactions />,
        loader: TransactionsLoader(queryClient),
      },
      {
        path: '/balances',
        element: <Balances />,
        // loader: BalanceLoader(queryClient),
        // loader: async () => {
        //   return redirect('https://eye.bigg.fit/es/balances');

        // }
      },
      {
        path: '/product_location_stocks',
        element: <ProductStocks />,
        loader: StockLoader(queryClient),
      },
      {
        path: '/coaches',
        element: <Coaches />,
      },
      {
        path: '/calendar_coaches',
        element: <CalendarCoaches />,
      },
      {
        path: '/reports',
        element: <Reports />,

      },
      {
        path: '/locations',
        element: <Locations />,
      },
      {
        path: '/countries',
        element: <Countries />,
      },
      {
        path: '/region_config_types',
        element: <RegionConfigTypes />,
        loader: RegionConfigLoader(queryClient),
      },
      {
        path: '/sales_goals_types',
        element: <SalesGoalsTypes />,
      },
      {
        path: '/sales_goals',
        element: <SalesGoals />,
      },
     
      {
        path: '/help_center',
           loader: async () => {
            window.open('https://biggeye.kb.help', '_blank');
            return redirect(window.location.href);
        }
      },
    ],
  },
  {
    path: '/mp',
    element: <ClientMpUpload />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <AuthProvider>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </AuthProvider>
      </GlobalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </HelmetProvider>
);
