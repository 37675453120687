// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';

const locizeOptions = {
  projectId: '3190ae84-e1db-4ef6-b801-75f4161c1701',
  apiKey: 'ce91a5fc-9fc9-4ace-bcdf-232312e0c93b', // YOU should not expose your apps API key to production!!!
  referenceLng: 'es-419',
};

i18n
  .use(Backend)
  .use(LastUsed)
  .use(locizePlugin) // will show the incontext editor only if passing ?incontext=true

  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es-419',
    debug: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  });

export default i18n;
