import { redirect } from 'react-router-dom';
import { getAvailableLocations,  } from '../../api/locations';
import { getUser } from '../../api/users';
import { customerIoIdentify } from '../../services/utils/CustomerIoUtils';
import { getPaymentPlatforms } from '../../api/mp';
// import i18n from '../../services/i18n';
import * as Sentry from '@sentry/react';
export const addKey = (data) =>
  data.sort((a, b) => a.id - b.id).map((item) => ({ ...item, key: item.id }));
export const useGenericQuery = (queryKey, queryFn) => ({
  queryKey,
  queryFn: async () => queryFn(),
  select: (data) => addKey(data),
});

export const rootLoader =
  (queryClient) =>
  async ({ params }) => {
    const value = window.localStorage.getItem('user');
    if(!value) return redirect('/login')
    let user = JSON.parse(value);
    if (!user) {
      return redirect('/login');
    }

    try {
      const locations = await getAvailableLocations();
      if(locations && locations.locations.length > 0){
        const location = locations.locations.find((item) => item.id === user.location_id);
        //update user and save it to localstorage
        if (location) {
          user.country = {
            id: location.country_id,
            name: location.country_name,
            currency: location.currency,
            currency_simbol: location.currency_simbol,
            currency_locale:location.locale,
            time_zone: location.time_zone,
          };
          window.localStorage.setItem('user', JSON.stringify(user));
        }
      }
      const fetch_me = await getUser(user.user_id);
      try {
        customerIoIdentify(user.user_id, user.email);
      } catch (cioError) {
        Sentry.captureException(new Error(cioError.message), {
          extra: {
            user: user,
            screen: 'rootLoader',
            endpoint: 'customerIoIdentify',
          },
        });
      }
      Sentry.setUser(user);
      return {
        user: user,
        locations: locations.mappedLocations,
        available_locations: locations.locations,
        fetch_me:fetch_me,
      };
    } catch (error) {
      const value = window.localStorage.getItem('user')
      const user = JSON.parse(value);
      const failedEndpoint = error.config ? error.config.url : 'Unknown endpoint';

      Sentry.captureException(new Error(error.message),{
        extra: {
          user: user,
          screen: 'rootLoader',
          endpoint: failedEndpoint,
          single_access: user.single_access_token,
        },
      });
      window.localStorage.removeItem('user')
      return redirect('/login');
    }
  };
