import React from 'react'

export default function AppSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 110
    height = 110
  }
  return (
<svg width="118" height="124" viewBox="0 0 118 124" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59 3.64453L74.3138 14.8158L93.2718 14.7808L99.0935 32.8204L114.451 43.9334L108.559 61.9497L114.451 79.966L99.0935 91.079L93.2718 109.119L74.3138 109.084L59 120.255L43.6861 109.084L24.7282 109.119L18.9064 91.079L3.54883 79.966L9.44057 61.9497L3.54883 43.9334L18.9064 32.8204L24.7282 14.7808L43.6861 14.8158L59 3.64453Z" stroke="#CEF5CE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.5938 61.9493L53.17 76.5256L82.3226 47.373" stroke="#CEF5CE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  )
}
