import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId, mappedLocations } from '../services/utils/getLocation';
import axiosInstance from './prospects';
import { useQuery } from '@tanstack/react-query';
export const getChatbot_leads = async (start, end) => {
  const location_id = getLocationId();
  return await fetchData(`/chatbot_leads?location_id=${location_id}`);
};
export const useGetLeads = (start, end) => {
  return useQuery({
    queryKey: ['chatbot_leads', start, end],
    queryFn: async () => getChatbot_leads(start, end),
    select: (data) => data.sort((a, b) => a.id > b.id),
  });
};
export const getLead = async (id, isMember) => {
  const location_id = getLocationId();
  const lead = await fetchData(`/leads/${id}?location_id=${location_id}`);
  return lead;
};
export const useGetLead = (id) => {
  return useQuery({
    queryKey: ['lead', id],
    queryFn: async () => getLead(id),
    select: (data) => ({
      ...data,
      ...data.user,
      name: `${data.user.first_name} ${data.user.last_name}`,
    }),
    // start: formatUTC(item.start),
  });
};

export const patchLead = async (id, data) => {
  const location_id = getLocationId();
  return await axiosInstance.patch(
    `/leads/${id}?location_id=${location_id}`,
    data
  );
};
