import axiosInstance from './gym_classes';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';
import { useQuery } from '@tanstack/react-query';
export const getDiscounts = async () => {
  const location_id = getLocationId();
  return await fetchData(`/discounts?location_id=${location_id}`);
};
export const getDiscount = async (id) => {
  const result = await fetchData(`/discounts/${id}`);
  if (!result) return {};
  return result;
};
export const useGetDiscounts = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['discounts', location_id],
    queryFn: async () => getDiscounts(),
  });
};
export const updateDiscounts = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/discounts/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createDiscounts = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/discounts?location_id=${location_id}`, 'POST', data);
};
export const deleteDiscounts = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/discounts/${id}?location_id=${location_id}`,
    'DELETE'
  );
};

export default axiosInstance;
