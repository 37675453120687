import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { getProducts, getStatuses } from '../api/products';
import { USE_FORM_SESSION } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetSessions } from '../router/loaders/ProductsLoaders';
import { notification } from 'antd';
import { USESessionsColumns } from '../services/Constants/ProductColumns';
import { formatError } from '../services/utils/formatError';
import {
  createSession,
  deleteSession,
  getSession,
  updateSession,
} from '../api/sessions';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canUpdateProducts, isUserAdmin } from '../services/can_user';
import { t } from 'i18next';

export default function ProductSessions() {
  const columns = USESessionsColumns();
  const TITLE = t('sessions', 'Sesiones');
const TITLE_SINGULAR = t('session', 'Sesión');
  const { data, isLoading } = useQuery(useGetSessions());
  const FORM_SESSION = USE_FORM_SESSION();
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  
  const handleDelete = async (id) => {
    await useDeleteSession.mutateAsync(id);

  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('session_success', 'Sesión actualizada correctamente')
          :  t('session_error', 'Ocurrió un error al actualizar la sesión'),
...openNotificationFields(type, description)
    });
  };

  const [FORM, setFORM] = useState(FORM_SESSION);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let session = {
        data: data,
      };
      await useCreateSession.mutateAsync(session);
      return setopenDrawer({ visible: false, record: null });
    }
    let session = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateProduct.mutateAsync(session);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let session;
        if (record) {
          session = await getSession(record.id);
          FORM.forEach((item) => {
            setValue(item.name, session[item.name]);
          });
        }
        const products = await getProducts();
        const status_id = await getStatuses();
      
        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'product_id') {
              return {
                ...item,
                options: products
                  .filter((membership) => membership.product_type_id == 3 && !data.map((m)=>m.product_id).includes(
                    membership.id
                  ))
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
              };
            }
            if (item.name === 'status_id') {
              return {
                ...item,
                options: status_id.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateSession = useMutation({
    mutationFn: (product) => updateSession(product.id, product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['sessions'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateSession = useMutation({
    mutationFn: (product) => createSession(product.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sessions'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteSession = useMutation({
    mutationFn: (id) => deleteSession(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sessions'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={isUserAdmin()}
        canUpdate={canUpdateProducts()}

        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        handleDelete={handleDelete}
        mutateLoading={useUpdateSession.isLoading || useCreateSession.isLoading}
      />
      {contextHolder}
    </>
  );
}
