import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';

import { USE_FORM_DISCOUNTS } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetDiscounts } from '../router/loaders/ProductsLoaders';
import { notification} from 'antd';
import {  USEDiscountsColumns } from '../services/Constants/ProductColumns';
import { createDiscounts, deleteDiscounts, getDiscount, updateDiscounts } from '../api/discounts';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { getRegion_config_types } from '../api/config';
import { canUpdateProducts, isUserAdmin } from '../services/can_user';
import { formatError } from '../services/utils/formatError';
import { t } from 'i18next';

export default function Discounts() {
  const originalColumns = USEDiscountsColumns();
  const TITLE =t('discounts','Descuentos')
const TITLE_SINGULAR =t('discount','Descuento')
  const { data, isLoading } = useQuery(useGetDiscounts());
  const FORM_DISCOUNTS = USE_FORM_DISCOUNTS();
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [columns, setColumns] = useState(originalColumns);

  useEffect(() => {
    const createFilters = (data, key) =>
      [...new Set(data.map((d) => d[key]))]
        .filter(Boolean)
        .map((value) => ({ text: value, value }));
    setfilterTable(isLoading ? [] : data);
    if (data) {
      setColumns(
        columns.map((column) => {
          if (column.dataIndex === 'region_config_type_name') {
            return {
              ...column,
              filters: createFilters(data, 'region_config_type_name'),
              onFilter: (value, record) => record.region_config_type_name === value,
            };
          }
     
          return column;
        }))
      }
  }, [data]);
 

const handleDelete = async (id) => {
    await useDeleteDiscounts.mutateAsync(id);

  };
  const openNotification = (type,description) => {
    api[type]({
      message:
        type == 'success'
          ? t('discount_success','Descuento actualizado correctamente')
          : t('discount_error','Ocurrió un error al actualizar el descuento'),
...openNotificationFields(type,description)
    });
  };
 
  const [FORM, setFORM] = useState(FORM_DISCOUNTS);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   status_id: 'true',
    // },
  });
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let product = {
        data: data,
      };
      await useCreateDiscounts.mutateAsync(product);
      return setopenDrawer({ visible: false, record: null });
    }
    let product = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateDiscounts.mutateAsync(product);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let discount;
        if (record) {
          discount = await getDiscount(record.id);
          FORM.forEach((item) => {
            FORM.forEach((item) => {
              if(item.name == 'amount'){
               return setValue(item.name, parseFloat(discount[item.name]));
              }
              // if(item.name == 'status_id'){
              //   return setValue('status_id',discount.active);
              //  }
              setValue(item.name, discount[item.name]);
            });

          });
        }
        const region_config_types = await getRegion_config_types();
        setFORM(
          FORM.map((item) => {
            if (item.name === 'region_config_type_id') {
              return {
                ...item,
                options: region_config_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );
        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
    

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
    
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateDiscounts = useMutation({
    mutationFn: (product) => updateDiscounts(product.id, product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['discounts']
      });
    },
    onError: (error) => formatError(error, openNotification)

  });
  const useCreateDiscounts = useMutation({
    mutationFn: (product) => createDiscounts(product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['discounts']
      });
        },
    onError: (error) => formatError(error, openNotification)

  });
  const useDeleteDiscounts = useMutation({
    mutationFn: (id) => deleteDiscounts(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['discounts']
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification)
      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={isUserAdmin()}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        canUpdate={canUpdateProducts()}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        handleDelete={handleDelete}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateDiscounts.isLoading || useCreateDiscounts.isLoading}
      />
      {contextHolder}
    </>
  );
}
