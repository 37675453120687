import React from 'react';
import {
  createdAtColumn,
  generateColumn,
  IDCOLUMN,
  product_type_column,
  sorterText,
} from './ColumnUtils';
import { BiCheckboxChecked } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
export const CheckboxRenderer = (text) => (
  <span>{text ? <BiCheckboxChecked size={29} color='#1EA05A' /> : ''}</span>
);
import dayjs from 'dayjs';
const commonColumns = [
  IDCOLUMN,
  generateColumn('Nombre', 'name', '15%', false, sorterText('name')),
  //   generateColumn('Descripción', 'description','15%',false,sorterText('description')),
];

export const useLocationColumns= ()=>
  {
    const { t, ready } = useTranslation();
  if (!ready) return [];
  return [ 
  IDCOLUMN,
  generateColumn('Nombre', 'name', '10%', false, sorterText('name')),
  generateColumn(
    'País',
    'country_name',
    '7%',
    false,
    sorterText('country_name')
  ),
  generateColumn(
    'Zona Horaria',
    'time_zone',
    '8%',
    false,
    sorterText('time_zone')
  ),
  generateColumn('Moneda', 'currency', '7%', false, sorterText('currency')),
  generateColumn(
    'Dirección',
    '',
    '10%',
    (text, record) =>
      `   ${
        record.address
          ? `${record.address?.address_line_1} ${record.address?.state} ${record.address?.state} ${record.address?.zip_code}`
          : ''
      }`
  ),
  generateColumn('Telefono', 'phone', '7%', false, sorterText('phone')),

  generateColumn('Slug', 'slug', '10%', false, sorterText('slug')),
  generateColumn('Activo', 'active', '7%', CheckboxRenderer, {
    sorter: (a, b) => a.active - b.active,
    filters: [
      {
        text: 'Activo',
        value: true,
      },
      {
        text: 'Inactivo',
        value: false,
      },
    ],
    onFilter: (value, record) => record.active === value,
  }),
  createdAtColumn
]
}
export const CountriesColumns = [
  IDCOLUMN,
  generateColumn(
    'Nombre',
    'description',
    '15%',
    false,
    sorterText('description')
  ),
  generateColumn('Moneda', 'currency', '10%', false, sorterText('currency')),
  generateColumn('Cod', 'phone_code', '10%', false, sorterText('phone_code')),
  createdAtColumn,
];
export const SalesGoalsTypesColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    IDCOLUMN,
    generateColumn(
      t('first_name', 'Nombre'),
      'name',
      '15%',
      false,
      sorterText('name')
    ),
    generateColumn(
      t('description', 'Descripción'),
      'description',
      '15%',
      false,
      sorterText('description')
    ),

    createdAtColumn,
  ];
};
export const SalesGoalsColumns = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
generateColumn('ID', 'key', '5%', (t,r)=>r.group_key, {
      sorter: (a, b) => a.group_key - b.group_key,
      defaultSortOrder: 'ascend',
    }),

    generateColumn(
      t('sale_goal_type', 'Tipo objetivo'),
      'sale_goal_type_name',
      '15%',
      false,
      sorterText('sale_goal_type_name')
    ),
    generateColumn(
      t('start', 'Inicio'),
      'start_date',
      '15%',
      (text) => dayjs(text).format('DD/MM/YYYY'),
      sorterText('start_date')
    ),
    generateColumn(
      t('end', 'Fin'),
      'end_date',
      '15%',
      (text) => dayjs(text).format('DD/MM/YYYY'),
      sorterText('end_date')
    ),
    // generateColumn(
    //   t('amount', 'Monto'),
    //   'amount',
    //   '15%',
    //   (text) => parseFloat(text * 100),
    //   sorterText('amount')
    // ),
    // generateColumn(
    //   t('amount', 'Monto'),
    //   'amount',
    //   '15%',
    //   (text) => parseFloat(text * 100),
    //   sorterText('amount')
    // ),
    // generateColumn(
    //   t('amount', 'Monto'),
    //   'amount',
    //   '15%',
    //   (text) => parseFloat(text * 100),
    //   sorterText('amount')
    // ),
  
    // generateColumn('order', 'order', '15%', false, sorterText('order')),
    createdAtColumn,
  ];
};
