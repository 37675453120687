import React, { useMemo, useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';

import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import DrawerForm from '../components/DrawerForm';
import { Select, notification, message,Tooltip } from 'antd';

import dayjs from 'dayjs';
import {
  DeleteCoachClass,
  getCalendarClasses,
  getCalendarCoaches,
  getCoachClass,
  updateCoachClass,
  updateCoachClassStatus,
  useGetCalendarCoaches,
} from '../api/calendar';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { MdCheck, MdOutlineFrontHand } from 'react-icons/md';

import { useAuth } from '../context/AuthProvider';
import { formatError } from '../services/utils/formatError';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getLocationId } from '../services/utils/getLocation';
import SpinIndicador from '../components/helpers/SpinIndicador';
import { t } from 'i18next';
const start =
  dayjs().day() === 0
    ? dayjs().subtract(1, 'week').day(1).format('YYYY-MM-DD')
    : dayjs().day(1).format('YYYY-MM-DD');
const end =
  dayjs().day() === 0
    ? dayjs().subtract(1, 'week').day(7).format('YYYY-MM-DD')
    : dayjs().day(7).format('YYYY-MM-DD');
export default function CalendarCoaches() {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const { isLoading: isLoadingCoaches, data: coaches,isFetching } = useGetCalendarCoaches(
    startDate,
    endDate
  );
  // const [changeWeek, setchangeWeek] = useState(startDate)
  const [initial_selectedClassCoach, setinitial_selectedClassCoach] = useState(
    []
  );
  const { user } = useAuth();
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const refCalendar = React.createRef();
  const [api, contextHolder] = notification.useNotification();
  const [availableClasses, setavailableClasses] = useState([]);
  const [selectedCoach, setselectedCoach] = useState(false);
  const [selectedClassCoach, setselectedClassCoach] = useState([]);
  const queryClient = useQueryClient();
  const [loadingCopy, setloadingCopy] = useState(false);
  const [messageApi, messageContext] = message.useMessage();

  const openNotification = (type, title, description) => {
    api[type]({
      message: title,
      ...openNotificationFields(type, description),
    });
  };
  const formatClasses = useMemo(() => {
    if (!coaches) return [];
    if(isFetching) return [];
    if (isLoadingCoaches) return [];
    const formattedClasses = [];
    coaches.forEach((clase, index) => {
   
      const formattedClass = {
        title:
          clase.description +
          ' ' +
          clase.classes
            .map(
              (c, i) =>
                dayjs(c.start).tz(user.country.time_zone).format('HH:mm') +
                (i < clase.classes.length - 1 ? '/' : '')
            )
            .map((c, i) => c)
            .join(''),
        start: dayjs(clase.start)
          .tz(user.country.time_zone)
          .format('YYYY-MM-DD'),
        id: clase.id,
        color: '#CEF5CE',
        classNames: ['eventCoach'],
        extendedProps: {
          coach_id: clase.coach_id,
        },
        resourceId: clase.coach_id, // Asignamos recursos alternados
      };
      formattedClasses.push(formattedClass);
    });
    return formattedClasses;
  }, [isFetching,coaches]);

useEffect(() => {
 if(isFetching){
  init_loading()
 } else {
  messageApi.destroy();
 }
}, [isFetching])
  const formatCoaches = useMemo(() => {
    if (!coaches) return [];
    if(isFetching) return [];
    if (isLoadingCoaches) return [];
    const formattedClasses = [];
    coaches.forEach((clase, index) => {
    
        const formattedClass = {
          name: clase.coach_name,
          class_count: coaches
            .filter((c) => c.coach_id == clase.coach_id)
            .reduce((acc, curr) => acc + curr.classes.length, 0),
          color: '#CEF5CE',
          id: clase.coach_id, // Asignamos recursos alternados
          editable: true,
        };
        formattedClasses.push(formattedClass);
     
    });
    return formattedClasses;
  }, [isFetching,coaches]);
  const useDeleteCoachClass = useMutation({
    mutationFn: (id) => DeleteCoachClass(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar_week', startDate, endDate],
      });
      openNotification(
        'success',
         t('class_deleted','Clase eliminada correctamente'),
         t('class_deleted_success','La clase fue eliminada correctamente')
        // 'La clase de ' +
        //   selectedCoach.coach_name +
        //   ' fue eliminada correctamente'
      );
    

      setopenDrawer({
        visible: false,
        record: null,
      });
     
    
    },
    onError: (error) => formatError(error, openNotification),
  });
  // return
  const handleUpdate = async () => {
    let toRemove = initial_selectedClassCoach.filter(
      (coach) =>
        !selectedClassCoach
          .map((c) => c.coach_gym_class_id)
          .includes(coach.coach_gym_class_id)
    );
    if (toRemove.length > 0) {
      toRemove.forEach(async (data, i) => {
        await useDeleteCoachClass.mutateAsync(data.coach_gym_class_id);
      });
      queryClient.invalidateQueries({
        queryKey: ['calendar_week', startDate, endDate],
      });
    }
    selectedClassCoach.forEach(async (coach, i) => {
      // return console.log('coach',coach);
      const data = {
        id: openDrawer.record.id,
        data: {
          location_id: getLocationId(),
          coach_id: selectedCoach.coach_id,
          gym_class_id: openDrawer.record.id,
          scheduled_at: coach.start,
        },
      };

      if (
        initial_selectedClassCoach
          .map((c) => c.coach_gym_class_id)
          .includes(coach.coach_gym_class_id)
      ) {
        if (
          initial_selectedClassCoach.length &&
          selectedClassCoach.length &&
          i == selectedClassCoach.length - 1
        ) {
          openNotification(
            'success',
            t('classes_updated','Clases actualizadas correctamente'),
            t('the_classes_of','Las clases de')+  ' ' +
              selectedCoach.coach_name +
              ' ' + t('were_updated','fueron actualizadas correctamente')
          );
          setopenDrawer({
            visible: false,
            record: null,
          });
        }
      } else {
        await useUpdateCoach.mutateAsync(data);
      }
    });
   
    queryClient.invalidateQueries({
      queryKey: ['calendar_week', startDate, endDate],
    });
  };
  const useUpdateCoach = useMutation({
    mutationFn: (data) => updateCoachClass(data.data),
    onSuccess: (data) => {
      if(selectedCoach){
        openNotification(
          'success',
          t('classes_updated','Clases actualizadas correctamente'),
          t('the_classes_of','Las clases de')+  ' ' +
            selectedCoach.coach_name +
            ' ' + t('were_updated','fueron actualizadas correctamente')
        );
        setopenDrawer({
          visible: false,
          record: null,
        });
      }
     
      // queryClient.invalidateQueries('cal');
    },
    onError: (error) => formatError(error, openNotification),
  });
  useEffect(() => {
    if (openDrawer.visible == false && selectedClassCoach.length > 0) {
      setselectedClassCoach([]);
      setinitial_selectedClassCoach([]);
    }
  }, [openDrawer, selectedClassCoach]);
  const copyWeekCoaches = async () => {
    
    setloadingCopy(true);
    processing();
    const calendarClasses = await getCalendarClasses(startDate, endDate);
    if (!calendarClasses || calendarClasses.length == 0)
      return openNotification(
        'error',
         t('error_copy_title','Error al copiar'),
        t('error_copy_description','No hay clases para copiar')
      );
    const calendarCoachesNext = await getCalendarCoaches(startDate, endDate);

    if (calendarCoachesNext.length > 0) {
      setloadingCopy(false);
      messageApi.destroy();
      return openNotification(
        'error',
         t('error_copy_title','Clases ya agendadas para esta semana'),
        t('error_copy_description','Para copiar la semana anterior no debe haber clases agendadas para esta semana')
      );
    }

   const calendarCoachesPast = await getCalendarCoaches(
      dayjs(startDate).subtract(7, 'day').format('YYYY-MM-DD'),
      dayjs(endDate).subtract(7, 'day').format('YYYY-MM-DD')
    );

    const copyClasses = calendarClasses.map((c) => ({
      ...c,
      scheduled_at: dayjs(c.scheduled_at).tz(user.country.time_zone).format(),
    }));

    const reduceByCoach = calendarCoachesPast.reduce((accumulator, current) => {
      // Si el coach_id aún no es una clave en el acumulador, añádela con un array vacío
      if (!accumulator[current.coach_id]) {
        accumulator[current.coach_id] = [];
      }
      const classesWithGymId = current.classes.map((classItem) => ({
        ...classItem,
        gym_class_id: current.gym_class_id,
        start: dayjs(classItem.start).tz(user.country.time_zone).format(),
        end: dayjs(classItem.end).tz(user.country.time_zone).format(),
      }));

      accumulator[current.coach_id].push(...classesWithGymId);

      return accumulator;
    }, {});

    const allPromises = [];

    for (let coachId in reduceByCoach) {
      if (reduceByCoach.hasOwnProperty(coachId)) {
        reduceByCoach[coachId].forEach((classItem) => {
          if (
            copyClasses.some(
              (c) =>
                c.id == classItem.gym_class_id &&
                dayjs(c.start).format('HH:mm') ==
                  dayjs(classItem.start).format('HH:mm')
            )
          ) {
            const data = {
              id: classItem.gym_class_id,
              data: {
                location_id: getLocationId(),
                coach_id: coachId,
                gym_class_id: classItem.gym_class_id,
                scheduled_at: dayjs(dayjs(classItem.start)).add(7, 'day').format(),
              },
            };
  
            // Agregar la promesa de la mutación al arreglo de promesas
            allPromises.push(useUpdateCoach.mutateAsync(data));
          }
        });
      }
    }
  
    // Esperar a que todas las promesas en el arreglo se resuelvan
    await Promise.all(allPromises);
    queryClient.invalidateQueries({
      queryKey: ['calendar_week', startDate, endDate],
    });
    openNotification(
      'success',
      t('classes_updated','Clases actualizadas correctamente'),
      t('classes_copy_success','Se copio la semana anterior correctamente')
    );
    messageApi.destroy();
    setloadingCopy(false);
  };
  console.log('availableClasses',availableClasses,selectedClassCoach);

  const processing = () => {
    messageApi.open({
      type: 'loading',
      content:  t('copying_week','Copiando semana anterior...'),
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };
  const init_loading = () => {
    messageApi.open({
      type: 'loading',
      content: 'Cargando...',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };
  const useUpdateCoachClassStatus = useMutation({
    mutationFn: (data) => updateCoachClassStatus(data.id,data.data),
    onSuccess: (data) => {
      openNotification('success',t('assist_calendar_success','Asistencia tomada correctamente'));
    //  setcoachAttendance(false)
 
    setselectedClassCoach(selectedClassCoach.map((c) =>{
      if(c.coach_gym_class_id == data.coach_gym_class.id){
        return {...c,coach_has_attended:!c.coach_has_attended}
      }
      return c
    }))
      messageApi.destroy();
    },
    onError: (error) => {
      formatError(error, openNotification)
      messageApi.destroy();
    },
  });
  return (
    <div className='h-screen pl-[3rem]  mr-10  mt-10'>
      <FullCalendar
        ref={refCalendar}
        resourcesInitiallyExpanded={true}
        // eventMinWidth={150}
        plugins={[resourceTimelinePlugin]} //dayGridPlugin,interactionPlugin,timeGridPlugin
        initialView='resourceTimelineWeek'
        customButtons={{
          copyweek: {
            text: t('calendar.copy_week', 'Copiar semana anterior'),
            click: async () => {
              if (loadingCopy) return;
              await copyWeekCoaches();
            },
          },
        }}
        eventContent={(arg) => {
          return (
            <div className='flex flex-col justify-center items-center h-max'>
              <p className='text-lg text-black  font-MessinaSansSemiBold '>
                {arg.timeText}
              </p>
              <p className='text-md text-black  font-MessinaSansSemiBold break-words  w-[95%]'>
                {arg.event.title}
              </p>
            </div>
          );
        }}
        headerToolbar={{
          // left: '',
          left: 'prev,next today copyweek', //copyweek
          center: 'title',
          right: '',
        }}
        locale={'es'}
        buttonText={{
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
        }}
        displayEventTime={false}
        editable={true}
      
        selectable={true}
        datesSet={async (date) => {
          // setchangeWeek(!changeWeek)
          if (!isLoadingCoaches) {
            setStartDate(dayjs(date.start).format('YYYY-MM-DD'));
            setEndDate(dayjs(date.end).subtract(1,'day').format('YYYY-MM-DD'));
          }

          // Aquí puedes agregar el código que deseas ejecutar al avanzar a la semana siguiente
        }}
        resourceAreaColumns={[
          {
            field: 'name',
            headerContent: 'Coach',
            width: '12%',
          },
          {
            field: 'class_count',
            headerContent: t('gym_classes.title', 'Clases'),
            width: '5%',
          },
        ]}
        duration={{
          days: 7,
        }}
        slotLabelInterval={{
          days: 1,
        }}
        schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
        aspectRatio={1.5}
        slotLabelFormat={{
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
          omitCommas: true,
        }}
        resourceAreaWidth={'15%'}
        slotMaxTime='23:00' // Hora máxima
        eventClick={async (info) => {
          // return;
          const dateClick = dayjs(info.event.start)
            .tz(user.country.time_zone)
            .format('YYYY-MM-DD');
          info.jsEvent.preventDefault();

          const calendarDay = await getCalendarCoaches(dateClick, dateClick);
          const calendarClasses = await getCalendarClasses(
            dateClick,
            dateClick
          );


          const findCoachClasses = calendarDay.find(
            (c) =>
              c.coach_id == info.event.extendedProps.coach_id &&
              c.id == info.event.id
          );
          // console.log('info.event.start',info.event.id,calendarDay,info.event.extendedProps.coach_id);
          const availableClasses = calendarClasses.filter(
            (c) => c.id == info.event.id
          );
          setavailableClasses(
            availableClasses.map((c) => ({
              ...c,
              scheduled_at: dayjs(c.scheduled_at)
                .tz(user.country.time_zone)
                .format(''),
            }))
          );
          setselectedClassCoach(
            findCoachClasses?.classes?.map((c) => ({
              ...c,
              start: dayjs(c.start).tz(user.country.time_zone).format(''),
              end: dayjs(c.end).tz(user.country.time_zone).format(''),
            }))
          );
          setinitial_selectedClassCoach(findCoachClasses?.classes);

          setselectedCoach({
            coach_id: findCoachClasses.coach_id,
            coach_name: findCoachClasses.coach_name,
            scheduled_at: findCoachClasses.start,
            coach_gym_class_id: findCoachClasses.coach_gym_class_id,
          });
          setopenDrawer({ visible: true, record: info.event });
        }}
        eventBackgroundColor='#CEF5CE'
        firstDay={1}
        resources={formatCoaches}
        events={formatClasses}
      />
      <DrawerForm
        title={ t('classes_of','clases de')+  ' ' + selectedCoach.coach_name}
        openDrawer={openDrawer}
        handleOpenDrawer={(visible, record) =>
          setopenDrawer({ visible, record })
        }
        width={'30%'}
      >
        <div className=' relative bg-background flex flex-col'>
          <div>
            <p className='mt-1'>
              Clases asignadas el{' '}
              {dayjs(selectedCoach.scheduled_at)
                .tz(user.country.time_zone)
                .format('LL')}{' '}
              para{' '}
              <span className=' text-primaryGreen'>
                {' '}
                {selectedCoach.coach_name}
              </span>{' '}
            </p>
            <Select
   
              showSearch
              placeholder={
                <div className='flex items-center font-MessinaSansSemiBold'>
                  <span className='text-white'>{t('assigned_classes','Asignar clases...')}</span>
                </div>
              }
              mode='multiple'
              allowClear
              optionFilterProp='children'
              onChange={(value, option) => {
                setselectedClassCoach(
                  value.map((c) => ({
                    start: dayjs(c).tz(user.country.time_zone).format(''),
                    coach_gym_class_id:
                      initial_selectedClassCoach.find(
                        (clase) =>
                          dayjs(clase.start)
                            .tz(user.country.time_zone)
                            .format('') == c
                      )?.coach_gym_class_id ?? null,
                  }))
                );
              }}
              value={selectedClassCoach.map((c) => c.start)}
              // onSearch={onSearch}
              bordered={false}
              rootClassName='text-white'
              className={`bg-primaryGrey text-white outline-none   rounded-sm  font-DrukWideMedium py-1  border-2  
         border-transparent my-5
             `}
              style={{
                width: '100%',
                color: '#FFFFFF',
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              optionLabelProp='title'
              options={availableClasses?.map((c) => ({
                value: c.scheduled_at,
                label:
                  c.gym_class_name +
                  ' ' +
                  dayjs(c.start).tz(user.country.time_zone).format('HH:mm'),
                title:
                  c.gym_class_name +
                  ' ' +
                  dayjs(c.start).tz(user.country.time_zone).format('HH:mm'),
              }))}
            />
          </div>
        </div>
      <div>
        <p className='mt-1'>
              {t('assist_classes_of','Asistencia de clases de')}
              <span className=' text-primaryGreen'>
                {' '}
                {selectedCoach.coach_name}
              </span>
            </p>
            {selectedClassCoach.map((c, i) => (
              <div
                key={i}
                className='flex flex-row justify-between items-center my-3 pb-5 border-b-[1px] '
              >
            
                <p className='text-white'>
                  {
availableClasses.find((clase) => clase.scheduled_at == c.start)?.gym_class_name
                  } {dayjs(c.start).tz(user.country.time_zone).format('HH:mm')}
                </p>
                <div
          onClick={async (e) => {
            e.stopPropagation();
     
            await useUpdateCoachClassStatus.mutateAsync({
              id: c.coach_gym_class_id,
              data: {
                has_attended: !c.coach_has_attended,
              },
            });
            // usePatch_member_gym_class.mutate(reserve);
          }}
          className={`rounded-full  ${
            c?.coach_has_attended ? 'border-darkGreen' :  'border-secondaryGrey'
          } border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200`}
        >
                <Tooltip
            title={`${
              !c?.coach_has_attended ? 'Marcar presente' : 'Marcar ausente'
            }`}
          >
            {c?.coach_has_attended ? (
              <MdCheck size={25} color='#1EA05A' />
            ) : (
              <MdOutlineFrontHand size={25} color={'#CCCCCC'} />
            )}
          </Tooltip>
        </div>
          
              </div>
            ))}
        </div> 
        <button
          onClick={() => handleUpdate()}
          disabled={useUpdateCoach.isLoading || useDeleteCoachClass.isLoading}
          className='uppercase flex flex-row items-center justify-center gap-6 !text-black font-MessinaSansBold text-xl bg-lightGreen rounded-md px-5 h-14 w-full mt-10'
          type='submit'
        >
          {t('update_classes','Actualizar clases')}
          {(useUpdateCoach.isLoading || useDeleteCoachClass.isLoading) && (
            <SpinIndicador />
          )}
        </button>
      </DrawerForm>
      {messageContext}
      {contextHolder}
    </div>
  );
}
