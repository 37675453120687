import React, { useState, useMemo } from 'react';
import { useGetMemberships } from '../../api/memberships';
import {
  Select,
  Dropdown,
  Tooltip,
  DatePicker,
  ConfigProvider,
  notification,
} from 'antd';
import { CiLink } from 'react-icons/ci';

import es_ES from 'antd/locale/es_ES';
import { MdOutlineDiscount } from 'react-icons/md';

import SelectPaymentMethod from '../SelectPaymentMethod';
import { useGetDiscounts } from '../../api/discounts';
import dayjs from 'dayjs';
import FooterSaleModal from '../helpers/FooterSaleModal';
import {
  getCountry,
  getLocationId,
  getLocationRegionConfig,
} from '../../services/utils/getLocation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDebit } from '../../api/debits';
import { useAuth } from '../../context/AuthProvider';
import { formatPrice } from '../../services/utils/formatPrice';
import { useGlobalContext } from '../../context/GlobalState';
import { useGetPointOfSales } from '../../api/locations';
import { t } from 'i18next';
import MPCardHolder from '../Desktop/MPCardHolder';
export default function ModalDebit({
  visible,
  member_id,
  setClose,
  openNotification,
  paymentPlatform,
  user_id,
  user_email,
  document_types,
  member_name,
}) {
  if (!visible) return null;
  const productRef = React.useRef();
  const queryClient = useQueryClient();
  const { state } = useGlobalContext();

  const { user } = useAuth();
  const current_country = getCountry()
  console.log('current_country',current_country);
  
  const [api, contextHolder] = notification.useNotification();
  const { data: point_of_sales, isLoading: isLoadingPoints } =
    useGetPointOfSales();
  const [openAddCreditCard, setopenAddCreditCard] = useState(false);

  const { data: memberships, isLoading } = useGetMemberships();
  const { data: discounts, isLoading: isLoadingDiscounts } = useGetDiscounts();
  const datepickerRef = React.useRef();
  const [paymentType, setpaymentType] = useState(null);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([
    { paymentType: null, amount: 0 },
  ]);

  const [selectedProduct, setselectedProduct] = useState(null);
  const categorizeMemberships = useMemo(() => {
    if (!memberships) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = memberships.filter(
      (product) =>
        product?.product.recurrent &&
        AvailableRegionConfig.includes(
          product.product.available_region_config_type_id
        )
    );
    return filterByRegionConfig.map((product) => ({
      value: product.product_id,
      label: product.name + '-' + formatPrice(product.price),
      className: `${
        selectedProduct?.id == product.product_id ? 'bg-lightGreen' : ''
      }`,
      title: product.name,
    }));
  }, [memberships]);
  const handleCreateSale = async () => {
    // return console.log('selectedPaymentMethods',selectedPaymentMethods);
    // return  navigator.clipboard.writeText(`http://192.168.232.16:3001/mp?location_id=${getLocationId()}&product_id=${selectedProduct.product_id}&product_amount=${selectedProduct.price}&user_id=${user_id}&owner_user_id=${user_id}`)
    if (!selectedProduct?.discount?.id) {
      openNotification(
        'error',
        t('must_select_discount', 'Debe seleccionar un descuento')
      );
      return;
    }
    const debit_splits_attributes = selectedPaymentMethods.map((p) => ({
      location_id: getLocationId(),
      point_of_sale_id: point_of_sales?.[0]?.id,
      payment_method_id: p.paymentType,
      price_percentage: p.amount,
      credit_card_id: p.credit_card_id ? p.credit_card_id : null,
    }));
    const data = {
      user_id: user.user_id,
      location_id: getLocationId(),
      channel: 'bigg-eye-web',
      discount_id: selectedProduct?.discount?.id,
      membership_id: selectedProduct.id,
      start_at: selectedProduct.start_at,
      end_at: selectedProduct.end_at,
      member_id,
      // debit:true,
      debit_splits_attributes,
    };
    // return console.log('debit', debit);
    await useCreatDebit.mutateAsync(data);
  };

  const useCreatDebit = useMutation({
    mutationFn: (data) => createDebit(data),
    onSuccess: (response) => {
      openNotification(
        'success',
        t('debit_processed_correct', 'Debito procesado correctamente')
      );

      queryClient.invalidateQueries({
        queryKey: ['profile', member_id],
      });
      setClose();
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const categorizeDiscounts = useMemo(() => {
    if (!discounts) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = discounts.filter(
      (d) =>
        AvailableRegionConfig.includes(d.region_config_type_id) &&
        d.active &&
        d.recurrent
    ); //remove cdp and gympass product
    return filterByRegionConfig.map((discount) => ({
      key: discount.id,
      label: discount.name + '-' + parseFloat(discount.amount) + '%',
      className:
        discount.id == selectedProduct?.discount?.id ? 'bg-lightGreen' : '',
      disabled: discount.id == 124, // discount bonificar proporcional
    }));
  }, [discounts]);
  const needLinkMp = () => {
    return (
      paymentPlatform &&
      selectedPaymentMethods?.some(
        (l) => l?.paymentType == 52 && !l?.credit_card_id
      )
    );
  };

  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        visible ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      {contextHolder}
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[45%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <div className='flex flex-col  w-full  pb-5'>
          <h1 className='text-xl lg:text-3xl  font-MessinaSansSemiBold text-center'>
            {t('sales.debit', 'Agregar Nuevo Débito Automático')}
          </h1>
          <div className='flex flex-row items-center gap-3 mt-10'>
            <Select
              ref={productRef}
              loading={isLoading}
              showSearch
              placeholder={
                <div className='flex items-center z-10'>
                  <span className='text-white'>
                    {t('sales.search_products', 'Buscar productos...')}
                  </span>
                </div>
              }
              onChange={(value, option) => {
                productRef.current.blur();
                if (!value) {
                  return setselectedProduct(null);
                }
                let membership = memberships.find((m) => m.product.id == value);
                membership.start_at = current_country.id == 1 ?  dayjs().add(1, 'month').startOf('month') : dayjs().format('YYYY-MM-DD');
                membership.price = 100;
                membership.countProducts = 1;
                if (selectedProduct && selectedProduct.discount) {
                  membership.discount = selectedProduct.discount;
                }
                setselectedProduct(membership);
              }}
              allowClear
              value={selectedProduct?.product?.id}
              optionFilterProp='children'
              bordered={false}
              rootClassName=' text-white '
              className={`bg-primaryGrey text-white rounded-sm  py-2 border-[1px]  ${
                selectedProduct?.id ? 'border-lightGreen' : 'border-transparent'
              }`}
              style={{
                width: '45%',
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={categorizeMemberships}
            />
            <div className='flex flex-row item-center gap-4'>
              <Dropdown
                arrow
                loading={isLoadingDiscounts}
                menu={{
                  items: isLoadingDiscounts ? [] : categorizeDiscounts,
                  onClick: ({ key, label }) => {
                    if (key == selectedProduct?.discount?.id) {
                      setselectedProduct({
                        ...selectedProduct,
                        discount: null,
                      });

                      return;
                    }
                    setselectedProduct({
                      ...selectedProduct,
                      discount: discounts.find((d) => d.id == key),
                    });
                  },
                  selectedKeys: [selectedProduct?.discount?.id],
                }}
                placement='bottom'
                trigger={['click']}
              >
                <div
                  className={`bg-primaryGrey h-12 rounded-sm px-4 max-w-[12rem] flex items-center justify-center hover:cursor-pointer border-[1px] ${
                    selectedProduct?.discount?.id
                      ? 'border-lightGreen '
                      : 'border-transparent'
                  }`}
                >
                  {selectedProduct?.discount?.id ? (
                    <p className='text-xs font-MessinaSansSemiBold truncate'>
                      {selectedProduct.discount?.name}
                    </p>
                  ) : (
                    <Tooltip
                      title={t('sales.apply_discount', 'Aplicar descuento')}
                    >
                      <MdOutlineDiscount />
                    </Tooltip>
                  )}
                </div>
              </Dropdown>

              {selectedProduct && (
                <div className='flex flex-row items-center gap-3'>
                  <ConfigProvider locale={es_ES}>
                    <Tooltip
                      title={t('sales.start_debit', 'Fecha inicio debito')}
                    >
                      <DatePicker
                        placeholder={t('start', 'Inicio')}
                        ref={datepickerRef}
                        allowClear={false}
                        disabledDate={(current) => {
                          if(current_country.id !== 1){
                            return current && current.isBefore(dayjs(), 'day');
                          }
                          // Deshabilita todas las fechas que no sean el primer día del mes
                          return current && (
                            (current.date() !== 1) || // No es el primer día del mes
                            current.isBefore(dayjs(), 'day') // Es anterior a la fecha actual
                          );
                        }}
                        value={
                          selectedProduct.start_at
                            ? dayjs(selectedProduct.start_at, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) => {
                          console.log('dateString', dateString);
                          datepickerRef.current.blur();
                          setselectedProduct({
                            ...selectedProduct,
                            start_at: dateString,
                          });
                        }}
                        style={{ width: 130 }}
                        name='started_at'
                        format={'YYYY-MM-DD'}
                        showToday
                        className=' shadow-none bg-primaryGrey outline-none border-none  h-12 rounded-sm w-32 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                      />
                    </Tooltip>
                  </ConfigProvider>
                  <ConfigProvider locale={es_ES}>
                    <Tooltip title={t('sales.end_debit', 'Fecha fin debito')}>
                      <DatePicker
                        placeholder={t('end', 'Fin')}
                        ref={datepickerRef}
                        allowClear={false}
                        // defaultPickerValue={today}
                        onChange={(date, dateString) => {
                          datepickerRef.current.blur();
                          if (dateString == selectedProduct?.end_at) {
                            return setselectedProduct({
                              ...selectedProduct,
                              end_at: null,
                            });
                          }
                          setselectedProduct({
                            ...selectedProduct,
                            end_at: dateString,
                          });
                        }}
                        style={{ width: 130 }}
                        defaultValue={false}
                        value={
                          selectedProduct.end_at
                            ? dayjs(selectedProduct.end_at, 'YYYY-MM-DD')
                            : null
                        }
                        name='end_at'
                        showToday
                        format={'YYYY-MM-DD'}
                        className='shadow-none bg-primaryGrey outline-none border-none  h-12 rounded-sm w-32 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                      />
                    </Tooltip>
                  </ConfigProvider>
                </div>
              )}
            </div>
          </div>
          <SelectPaymentMethod
            paymentPlatform={paymentPlatform}
            paymentType={paymentType}
            setSelectedPaymentMethods={setSelectedPaymentMethods}
            selectedPaymentMethods={selectedPaymentMethods}
            debit={true}
            totalAmount={100}
            showPercentage
            owner_user_id={user_id}
            selectedProducts={[selectedProduct]}
            product_name={selectedProduct?.name}
            openAddCreditCard={openAddCreditCard}
            setopenAddCreditCard={setopenAddCreditCard}
          />
          {openAddCreditCard && (
            <div className='pt-8'>
              <h1 className='text-2xl  font-MessinaSansSemiBold  mb-8'>
                {t(
                  'sales.add_card_paymentMethod',
                  'Agregar nuevo método de pago'
                )}
              </h1>
              <MPCardHolder
                totalAmount={1}
                paymentPlatform={paymentPlatform}
                email={user_email}
                modalSale={openAddCreditCard}
                selectedProducts={[selectedProduct]}
                openNotification={openNotification}
                document_types={document_types}
                setClose={(card_id) => {
                  setopenAddCreditCard(false);
                  setSelectedPaymentMethods(
                    selectedPaymentMethods.map((pm) => {
                      if (pm.paymentType == 52 || pm.paymentType == 48) {
                        return {
                          ...pm,
                          credit_card_id: card_id,
                        };
                      }
                      return pm;
                    })
                  );
                }}
                user_id={user_id}
              />
            </div>
          )}
        </div>
        <FooterSaleModal
          // bg='bg-primaryWhite'
          isLoading={useCreatDebit.isLoading}
          handleCreateSale={handleCreateSale}
          setmodalSale={setClose}
          //   title={needLinkMp()&&
          //   <div className='flex-row flex items-center gap-2'><CiLink size={20} className='' />
          // <span>Procesar y generar link de Mercado Pago</span></div>
          // }
          legend={
            needLinkMp() &&
            'Recordá que luego de procesarlo podras generar el Link de Mercado Pago'
          }
        />
        {/* <button 
        onClick={()=>{
          console.log('selectedProduct',selectedProduct);
          navigator.clipboard.writeText(`gym.bigg.fit/mp?location_id=${getLocationId()}&product_id=${selectedProduct.product_id}&product_amount=${selectedProduct.price}&user_id=${user_id}&owner_user_id=${user_id}`)
                }}
        className='flex flex-row items-center gap-2  border-b-[1px] mt-2'> <CiLink size={20} className='self-start mb-[-4px]' />
        Generar link de Mercado Pago</button> */}
      </div>
    </div>
  );
}
