import React, { useState,useEffect } from 'react';
import HeaderClasses from './HeaderClasses';
import ListClassMembers from './ListClassMembers';
import ListCdpMembers from './ListCdpMembers';
import {
  add_member_gym_class,
  delete_member_gym_class,
  update_member_gym_class,
} from '../../api/gym_classes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification, message } from 'antd';
import { useGetCalendarClasses } from '../../api/calendar';
import { add_member_trial_class, useGetTrialReserves } from '../../api/gym_trial_classes';
import { openNotificationFields } from '../../services/utils/openNotificationFields';
import { t } from 'i18next';
import { useAuth } from '../../context/AuthProvider';
import { track_attendance_reserve_class, track_deleted_reserve_class, track_reserve_class } from '../../services/utils/CustomerEvents';
import { customerIoIdentify } from '../../services/utils/CustomerIoUtils';
import * as Sentry from "@sentry/react";

const currentDay = dayjs();
export default function MainClasses({
  title,
  large,
  isCdp,
  setModalMemberOpen,
}) {
  const [day_selected, setday_selected] = useState(currentDay);
  const { isLoading: isLoadingClases, data: classes} = useGetCalendarClasses(
    day_selected.format('YYYY-MM-DD'),
    day_selected.format('YYYY-MM-DD')
  );
 const {user}= useAuth();
  const [day_selected_trial, setday_selected_trial] = useState(currentDay);

  const { isLoading:isLoadingTrials, isError, data:reserves_trials, error } = useGetTrialReserves(
    day_selected_trial.format('YYYY-MM-DD')
  );
  const [class_selected, setclass_selected] = useState();
  const [hour_selected, sethour_selected] = useState();

  const [class_selected_trial, setclass_selected_trial] = useState();
  const [hour_selected_trial, sethour_selected_trial] = useState();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, messageContext] = message.useMessage();
  const [trial_date_slots, settrial_date_slots] = useState(currentDay);

  const { isLoading: isLoadingTrialClassesSlots, data: trialClassesSlots} =
    useGetCalendarClasses(trial_date_slots.format('YYYY-MM-DD'), trial_date_slots.format('YYYY-MM-DD'));

  const handleSelectMember = async (member) => {

    if(!member.last_subscription){
      return openNotification('error', t('reserves.no_subscription','El miembro no tiene una suscripción activa'));
    }
    if(member.last_subscription.membership_id == 3){
      return openNotification('error', t('reserves.no_gympas','El socio es de Wellhub. Por favor solicitale que reserve desde la app de Wellhub'));
    }
    let formatTime = dayjs(hour_selected).format('HH:mm');
    const reseveBody = {
      owner_type: 'Member',
      gym_class_id: class_selected,
      scheduled_at: dayjs(`${dayjs(day_selected).format('YYYY-MM-DD')} ${formatTime}`).utc().format(),
      has_attended: false,
      subscription_id:member.last_subscription.id,
      channel: 'bigg-eye-web',
    };
    // return console.log(reseveBody);
    await useAdd_member_gym_class.mutateAsync(reseveBody);
  };
  const handleSelectProspect = async (prospect) => {
    // console.log('prospect')
    const reseveBody = {
      owner_type: 'Prospect',
      prospect_id: prospect.id,
      gym_class_id: class_selected_trial,
      scheduled_at: hour_selected_trial,
      has_attended: false,
      channel: 'bigg-eye-web',
      user_id:user.user_id,
      prospect_user_id:prospect.user_id,
    };
    // return console.log(reseveBody);
    await useAdd_prospect_gym_class.mutateAsync(reseveBody,prospect);
  };
  const useAdd_member_gym_class = useMutation({
    onMutate: (reserve) => {
      processing()
    },
    mutationFn: (reserve) => add_member_gym_class(reserve),
    onSuccess: (data) => {
      messageApi.destroy();
      if(data.wait_list){
  openNotification('warning',t('reserves.wait_list_desc','Se ha agregado al socio a la lista de espera'),t('reserves.wait_list_title','Lista de espera confirmada'))
      } else {
      openNotification('success');
      }
      console.log('data',data);
      
      try {
        customerIoIdentify(data.subscription.owner.email,data.subscription.owner.email)
        let update_class = data
        update_class.hour_class_local = dayjs(update_class.scheduled_at).tz(user.country.time_zone).format('HH:mm')
        track_reserve_class(update_class)
      } catch (error) {
        Sentry.captureException(new Error(error.message), { 
          extra: { 
            screen: 'useAdd_member_gym_class'
          } 
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['day_reserves',dayjs(hour_selected).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')],
      });
    },
    onError: (error) => {
      messageApi.destroy();
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useAdd_prospect_gym_class = useMutation({
    onMutate: (reserve) => {
      processing()
    },
    mutationFn: (reserve) => add_member_trial_class(reserve),
    onSuccess: (data,variables) => {
      messageApi.destroy();
      queryClient.invalidateQueries({
        queryKey: [
          'day_reserves_trial',
          dayjs(hour_selected_trial).format('YYYY-MM-DD'),
        ],
      });
      openNotification('success');
      customerIoIdentify(variables.prospect_user_id)
      track_reserve_class({
        ...data,
        trial_class:true,
        hour_class_local: dayjs(data.reserve.scheduled_at).tz(user.country.time_zone).format('HH:mm'),
      })
     
    },
    onError: (error) => {
      messageApi.destroy();
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const openNotification = (type, description,title) => {
    api[type]({
      message:
      title ? title:  type == 'success'
          ? t('reserves.success','Reserva guardada con éxito')
          :   t('reserves.error','Ocurrió un error al guardar la reserva'),
     ...openNotificationFields(type, description)
    });
  };
  const processing = () => {
    messageApi.open({
      type: 'loading',
      content: 'Procesando',
      duration: 0,
    });
  };
  const useDelete_member_gym_class = useMutation({
    onMutate: (reserve) => {
      processing();
    },
    mutationFn: (reserve) => delete_member_gym_class(reserve.id), // Pass a function that returns the promise
    onSuccess: (data,variables) => {
      messageApi.destroy();
      console.log('variables',data,variables);
      customerIoIdentify(variables.subscription.owner.email,variables.subscription.owner.email)

      track_deleted_reserve_class({
        ...data,
        trial_class:  isCdp ?  true : false
      })
      if (isCdp) {
        queryClient.invalidateQueries({
          queryKey: [
            'day_reserves_trial',
            dayjs(hour_selected_trial).format('YYYY-MM-DD'),
          ],
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['day_reserves', dayjs(hour_selected).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')],
      });
    },
    onError: (error) => {
      messageApi.destroy();
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const usePatch_member_gym_class = useMutation({
    onMutate: (reserve) => {
      processing();
    },
    mutationFn: (reserve) =>
      update_member_gym_class(reserve.id, {
        has_attended: !reserve.has_attended,
      }),

    onSuccess: (data) => {
      messageApi.destroy();
      customerIoIdentify(data.subscription.owner.email,data.subscription.owner.email)
      track_attendance_reserve_class({
        ...data,
        trial_class:  data.subscription.owner_type == 'Prospect' ?  true : false
      })
      if (data.subscription.owner_type == 'Prospect') {
        queryClient.invalidateQueries({
          queryKey: [
            'day_reserves_trial',
            dayjs(hour_selected_trial).format('YYYY-MM-DD'),
          ],
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['day_reserves', dayjs(hour_selected).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')],
      });
    },
    onError: (error) => {
      messageApi.destroy();
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });

  return (
    <div
      className={` lg:w-fit sm:w-[100%] ${
        large ? 'flex-grow ' : '  pt-10 lg:pt-0 lg:w-[42%]'
      }`}
    >
      {contextHolder}
      {messageContext}
      <HeaderClasses
        day_selected={day_selected}
        setday_selected={setday_selected}
        day_selected_trial={day_selected_trial}
        setday_selected_trial={setday_selected_trial}
        title={title}
        large={large}
        isCdp={isCdp}
        isLoadingClases={isLoadingClases}
        classes={classes}
        handleSelectMember={handleSelectMember}
        hour_selected={hour_selected}
        sethour_selected={sethour_selected}
        class_selected={class_selected}
        setclass_selected={setclass_selected}
        trialClassesSlots={trialClassesSlots}
        trial_date_slots={trial_date_slots}
        settrial_date_slots={settrial_date_slots}
        isLoadingTrialClassesSlots={isLoadingTrialClassesSlots}
        class_selected_trial={class_selected_trial}
        setclass_selected_trial={setclass_selected_trial}
        hour_selected_trial={hour_selected_trial}
        sethour_selected_trial={sethour_selected_trial}
        handleSelectProspect={handleSelectProspect}
      />
      {isCdp ? (
        <ListCdpMembers
          reserves_trials={reserves_trials}
          isLoadingTrials={isLoadingTrials}
          setModalMemberOpen={(data) => setModalMemberOpen({...data,
            customer_type:'Prospect',
            extra:{
            class_id:class_selected_trial,
            date_selected:dayjs(day_selected_trial).format('YYYY-MM-DD')
          }})}
          useDelete_member_gym_class={useDelete_member_gym_class}
          usePatch_member_gym_class={usePatch_member_gym_class}
        />
      ) : (
        <ListClassMembers
          classes={classes}
          reserves_trials={reserves_trials}
          day_selected={day_selected}
          
          setModalMemberOpen={setModalMemberOpen}
          class_selected={class_selected}
          hour_selected={hour_selected}
          useDelete_member_gym_class={useDelete_member_gym_class}
          usePatch_member_gym_class={usePatch_member_gym_class}
        />
      )}
    </div>
  );
}
