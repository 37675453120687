import axiosInstance from './gym_classes';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';
export const getPayment_methods = async () => {
  const location_id = getLocationId();
  return await fetchData(`/payment_methods?location_id=${location_id}`);
};
export const useGetPayment_methods = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['payment_methods', location_id],
    queryFn: async () => getPayment_methods(),
    cacheTime: 1000 * 60 * 60 * 1,
    staleTime: 1000 * 60 * 60 * 1,
  });
};
export const useGetLocationPayment_methods = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['location_payment_methods', location_id],
    queryFn: async () => getLocationPayment_methods(),
    cacheTime: 1000 * 60 * 60 * 1,
    staleTime: 1000 * 60 * 60 * 1,
    select: (data) =>
      data.map((item) => ({
        ...item,
        ...item.payment_method,
      })),
  });
};

export const getLocationPayment_methods = async () => {
  const location_id = getLocationId();
  return await fetchData(
    `/location_payment_methods?location_id=${location_id}`
  );
};
export const getPayments = async (start, end) => {
  const location_id = getLocationId();
  return await fetchData(
    `/payments?location_id=${location_id}&start=${start}&end=${end}`
  );
};
export const getPayment = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/payments/${id}/?location_id=${location_id}`);
};
export const useGetPayments = (start, end) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['payments', start, end, location_id],
    queryFn: async () => getPayments(start, end),
  });
};
export const updatePayment = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/payments/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createPayment = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/payments?location_id=${location_id}`, 'POST', data);
};
export const deletePayment = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/payments/${id}?location_id=${location_id}`,
    'DELETE'
  );
};

export default axiosInstance;
