
import React from 'react'

export default function ProspectsSvg() {
  return (
    <svg width="25" height="25" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.19774 26.5341C2.19774 27.1624 2.70532 27.67 3.33359 27.67H23.708C24.3363 27.67 24.8439 27.1624 24.8439 26.5341V18.2636C24.8439 12.0093 19.7751 6.94057 13.5208 6.94057C7.2665 6.94057 2.19774 12.0093 2.19774 18.2636V26.5341ZM4.75342 18.2636C4.75342 13.4221 8.67922 9.49625 13.5208 9.49625C18.3624 9.49625 22.2882 13.4221 22.2882 18.2636V25.1143H9.68729V19.0445C9.68729 18.8493 9.52756 18.6896 9.33234 18.6896H7.77053C7.57531 18.6896 7.41558 18.8493 7.41558 19.0445V25.1143H4.75342V18.2636ZM3.04608 9.30102L4.4517 7.8954C4.56174 7.78536 4.56174 7.60434 4.4517 7.4943L2.04156 5.08416C1.98819 5.03131 1.91612 5.00166 1.84101 5.00166C1.7659 5.00166 1.69383 5.03131 1.64046 5.08416L0.234836 6.48978C0.181989 6.54315 0.152344 6.61522 0.152344 6.69033C0.152344 6.76543 0.181989 6.83751 0.234836 6.89088L2.64498 9.30102C2.75502 9.41106 2.93249 9.41106 3.04608 9.30102ZM26.8139 6.48978L25.4083 5.08416C25.3549 5.03131 25.2828 5.00166 25.2077 5.00166C25.1326 5.00166 25.0605 5.03131 25.0072 5.08416L22.597 7.4943C22.5442 7.54767 22.5145 7.61974 22.5145 7.69485C22.5145 7.76996 22.5442 7.84203 22.597 7.8954L24.0026 9.30102C24.1127 9.41106 24.2937 9.41106 24.4037 9.30102L26.8139 6.89088C26.9239 6.77729 26.9239 6.59981 26.8139 6.48978ZM24.8794 29.9417H2.16224C1.53397 29.9417 1.02639 30.4493 1.02639 31.0775V31.9294C1.02639 32.0856 1.15417 32.2134 1.31035 32.2134H25.7313C25.8874 32.2134 26.0152 32.0856 26.0152 31.9294V31.0775C26.0152 30.4493 25.5076 29.9417 24.8794 29.9417ZM12.5269 4.66886H14.5147C14.6709 4.66886 14.7986 4.54107 14.7986 4.38489V0.977324C14.7986 0.821143 14.6709 0.693359 14.5147 0.693359H12.5269C12.3708 0.693359 12.243 0.821143 12.243 0.977324V4.38489C12.243 4.54107 12.3708 4.66886 12.5269 4.66886Z" fill="black"/>
    </svg>
  )
}
