import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { getProducts, getStatuses } from '../api/products';

import { USE_FORM_CONSUMABLE } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetConsumables } from '../router/loaders/ProductsLoaders';
import { notification } from 'antd';
import { USEConsumablesColumns } from '../services/Constants/ProductColumns';
import {
  createConsumables,
  deleteConsumables,
  getConsumable,
  updateConsumables,
} from '../api/consumables';
import { formatError } from '../services/utils/formatError';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import {
  canUpdateConsumable,
  canUpdateProducts,
  isUserAdmin,
} from '../services/can_user';
import { getLocationId, getLocationRegionConfig } from '../services/utils/getLocation';
import { t } from 'i18next';
import { useGlobalContext } from '../context/GlobalState';


export default function Consumables() {
  const columns = USEConsumablesColumns();
  const TITLE = t('consumables', 'Consumibles');
const TITLE_SINGULAR = t('consumable', 'Consumible');
  const { data, isLoading } = useQuery(useGetConsumables());
  const FORM_CONSUMABLE = USE_FORM_CONSUMABLE();
  const isAdmin = isUserAdmin();
  const { state } = useGlobalContext();

  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const location_id = getLocationId();
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [FORM, setFORM] = useState(FORM_CONSUMABLE);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });

  const handleDelete = async (id) => {
    await useDeleteConsumable.mutateAsync(id);
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('consumable_success', 'Consumible actualizado correctamente')
          : t('consumable_error', 'Ocurrió un error al actualizar el consumible'),
      ...openNotificationFields(type, description),
    });
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let consumable = {
        data: data,
      };
      await useCreateConsumable.mutateAsync(consumable);
      return setopenDrawer({ visible: false, record: null });
    }
    let consumable = {
      id: openDrawer.record.id,
      data: {
        ...data,
        product_attributes: {
          id: data.product_id,
          price: data.price,
        },
      },
    };
    await useUpdateConsumable.mutateAsync(consumable);

    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let consumable;
        if (record) {
          consumable = await getConsumable(record.id);
          FORM.forEach((item) => {
            if (item.name == 'price') {
              return setValue(item.name, parseFloat(consumable.product.price));
            }
            setValue(item.name, consumable[item.name]);
          });
        }
        const products = await getProducts();
        const status = await getStatuses();
        const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(config => config.id);


        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'status_id') {
              return {
                ...item,
                options: status.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'product_id') {
              return {
                ...item,
                options: products
                  .filter(
                    (consumable) =>
                      consumable.product_type_name == 'Consumable'
                       &&
                      !data.map((m) => m.product_id).includes(consumable.id)
                      && AvailableRegionConfig.includes(consumable.available_region_config_type_id)
                  )
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                    disabled: isAdmin ? false : true,
                  })),
              };
            }

            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateConsumable = useMutation({
    mutationFn: (consumable) =>
      updateConsumables(consumable.id, consumable.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['consumables', location_id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateConsumable = useMutation({
    mutationFn: (consumable) => createConsumables(consumable.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['consumables', location_id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteConsumable = useMutation({
    mutationFn: (id) => deleteConsumables(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['consumables', location_id],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);
      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={canUpdateConsumable()}
        canUpdate={canUpdateConsumable()}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        handleDelete={handleDelete}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateConsumable.isLoading}
      />
      {contextHolder}
    </>
  );
}
