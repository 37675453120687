import { addKey } from '../router/loaders/rootLoader';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import axiosInstance from './prospects';
import { useQuery } from '@tanstack/react-query';

export const getBalance = async () => {
  const location_id = getLocationId();
  let balance = await fetchData(`/balances?location_id=${location_id}`);
  return balance;
};
export const getSalesBalance = async (start, end) => {
  const location_id = getLocationId();
  let balance = await fetchData(
    `/sales_balances?location_id=${location_id}&start=${start}&end=${end}`
  );
  return balance;
};
export const useGetSalesBalance = (start, end) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['sales_balances', location_id, start, end],
    queryFn: async () => getSalesBalance(start, end),
    select: (data) =>
      (data = {
        balances: data.balances.map((item) => ({
          ...item,
          key: item.id,
        })),
      }),
  });
};
export const updateBalance = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/balances/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createBalance = async (data) => {
  return await fetchData(`/balances`, 'POST', data);
};
export const deleteBalance = async (id) => {
  return await fetchData(`/balances/${id}`, 'DELETE');
};
export default axiosInstance;
