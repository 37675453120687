import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';

import { USE_FORM_TIME_SLOT } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { USETimeSlotColumn  } from '../services/Constants/ClasesColumns';
import { formatError } from '../services/utils/formatError';
import { useGetTimeSlots } from '../router/loaders/ClassesLoaders';
import {
  createTimeSlot,
  deleteTimeSlot,
  getClassSlot,
  getClasses,
  updateTimeSlot,
} from '../api/gym_classes';
import dayjs from 'dayjs';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canCreateTimeSlot, canUpdateTimeSlots } from '../services/can_user';
import { t } from 'i18next';

export default function TimeSlots() {
  const columns = USETimeSlotColumn();
  const TITLE = t('time_slots.title', 'Horarios');
const TITLE_SINGULAR = t('time_slots.title_singular', 'Horario');
  const FORM_TIME_SLOT = USE_FORM_TIME_SLOT();
  const { data, isLoading } = useQuery(useGetTimeSlots());
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  const handleDelete = async (id) => {
    await useDeleteClasses.mutateAsync(id);
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('time_slot_success', 'Horario actualizado correctamente')
          : t('time_slot_error', 'Ocurrió un error al actualizar el horario'),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_TIME_SLOT);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const daysSelected = {
      monday: data.days.includes('Lunes'),
      tuesday: data.days.includes('Martes'),
      wednesday: data.days.includes('Miercoles'),
      thursday: data.days.includes('Jueves'),
      friday: data.days.includes('Viernes'),
      saturday: data.days.includes('Sábado'),
      sunday: data.days.includes('Domingo'),
    };
    const fromTo = {
      from: parseInt(data.rangeHours[0].format('Hmm')),
      to: parseInt(data.rangeHours[1].format('Hmm')),
    };
    if (!openDrawer.record) {
      let timeSlot = {
        gym_class_id: data.gym_class_id,
        ...daysSelected,
        ...fromTo,
        rush_hour: data.rush_hour,
      };
      await useCreateClasses.mutateAsync(timeSlot);
      return setopenDrawer({ visible: false, record: null });
    }
    let timeSlot = {
      id: openDrawer.record.id,
      data: {
        ...daysSelected,
        ...fromTo,
        rush_hour: data.rush_hour,
      },
    };
    await useUpdateClasses.mutateAsync(timeSlot);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let gym_class;
        if (record) {
          gym_class = await getClassSlot(record.id);
          const trueDays = [];
          if (gym_class.monday) trueDays.push('Lunes');
          if (gym_class.tuesday) trueDays.push('Martes');
          if (gym_class.wednesday) trueDays.push('Miercoles');
          if (gym_class.thursday) trueDays.push('Jueves');
          if (gym_class.friday) trueDays.push('Viernes');
          if (gym_class.saturday) trueDays.push('Sábado');
          if (gym_class.sunday) trueDays.push('Domingo');
          setValue('gym_class_id', gym_class.gym_class?.id);
          setValue('rangeHours', [
            dayjs(gym_class.from.toString().padStart(4, '0'), 'HHmm'),
            dayjs(gym_class.to.toString().padStart(4, '0'), 'HHmm'),
          ]);
          setValue('days', trueDays);

          FORM.forEach((item) => {
            if (item.name === 'days') {
              return {
                ...item,
                options: [
                  t('monday_singular', 'Lunes'),
                  t('tuesday_singular', 'Martes'),
                  t('wednesday_singular', 'Miércoles'),
                  t('thursday_singular', 'Jueves'),
                  t('friday_singular', 'Viernes'),
                  t('saturday_singular', 'Sábado'),
                  t('sunday_singular', 'Domingo'),
                ].map((item) => ({
                  label: item,
                  value: item,
                })),
              };
            }
          });
        }
        const classes = await getClasses();
        setFORM(
          FORM.map((item) => {
            if (item.name === 'days') {
              return {
                ...item,
                options: [
                  'Lunes',
                  'Martes',
                  'Miercoles',
                  'Jueves',
                  'Viernes',
                  'Sábado',
                  'Domingo',
                ].map((item) => ({
                  label: item,
                  value: item,
                })),
              };
            }
            if (item.name === 'gym_class_id') {
              return {
                ...item,
                options: classes
                  .filter((c) => c.parent_id !== null)
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
              };
            }

            return item;
          })
        );
        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateClasses = useMutation({
    mutationFn: (timeSlot) => updateTimeSlot(timeSlot.id, timeSlot.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['time_slots'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateClasses = useMutation({
    mutationFn: (timeSlot) => createTimeSlot(timeSlot),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['time_slots'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteClasses = useMutation({
    mutationFn: (id) => deleteTimeSlot(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['time_slots'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={canCreateTimeSlot()}
        openDrawer={openDrawer}
        canUpdate={canUpdateTimeSlots()}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        handleDelete={handleDelete}
        mutateLoading={useUpdateClasses.isLoading || useCreateClasses.isLoading}
      />
      {contextHolder}
    </>
  );
}
