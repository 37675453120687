import { getClassSlots, getClasses } from '../../api/gym_classes';
import { useGenericQuery } from './rootLoader';

//Classes
export const useGetClasses = () => useGenericQuery(['gym_classes'], getClasses);
export const ClassesLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetClasses();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

//Time slots
export const useGetTimeSlots = () => useGenericQuery(['time_slots'], getClassSlots);
export const TimeSlotsLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetTimeSlots();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };