import { notification, Space, Button } from 'antd';
import { copy_link_success } from '../../components/ModalMembers/MessageApi';
export const openNotificationFields = (type, description) => {
  return {
    description: <div>{description && <p>{description}</p>}</div>,
    placement: 'bottomRight',
    style:
      type == 'success'
        ? { backgroundColor: '#CEF5CE', color: '#000' }
        : type == 'warning' ? 
        { backgroundColor: '#FFD700', color: '#000' }
        : { backgroundColor: '#ED4C5C', color: '#000' },
  }
 
};

export const openLinkNotification = (type, description, title,linkToCopy,api,close,messageApi) => {
  const key = `open${Date.now()}`;
  const btn =  linkToCopy ?(
    <Space>
      <Button type="link" size="small" style={{color:'#ED4C5C'}} onClick={() => api.destroy()}>
       Cancelar
      </Button>
      <button 
   className=' flex flex-row items-center bg-darkGreen text-white px-4 h-10 justify-center rounded-md'
     onClick={() => {
       navigator.clipboard.writeText(linkToCopy);
      api.destroy(key)
      // success()
      copy_link_success(messageApi)
      }}>
      <p className='font-MessinaSansBold uppercase'>Copiar link</p>
      </button>
    </Space>
  ) :<></>
  api.open({
    message: title,
    description: <div>{description && <p>{description}</p>}</div>,
    placement: 'bottomRight',
    style:
    type == 'success'
      ? { backgroundColor: '#CEF5CE', color: '#000' }
      : { backgroundColor: '#ED4C5C', color: '#000' },
    btn,
    key,
    onClose: close,
    duration:6,
  });
};