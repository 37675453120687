import React from 'react';
import {
  createdAtColumn,
  generateColumn,
  IDCOLUMN,
  sorterText,
} from './ColumnUtils';
import dayjs from 'dayjs';
import { BiCheckboxChecked ,BiXCircle} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

export const USEClassColumn = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    IDCOLUMN,
    generateColumn(t('first_name', 'Nombre'), 'name','10%'),
    generateColumn(t('description', 'Descripción'), 'description','8%'),
    generateColumn(t('type', 'Tipo'), 'gym_class_type_name','6%'),
    // generateColumn(t('location', 'Sede'), 'location_name','6%'),
    generateColumn(
      t('region_config', 'Conf. Regional'),
      'region_config_type_name'
    ),
    generateColumn(t('max_attendances', 'Cupo máximo'), 'max_attendances','6%'),
    generateColumn(t('wait_list_amount', 'Cupo lista de espera'), 'wait_list_amount','7%'),
    // generateColumn('gympass_class_id', 'gympass_class_id','6%'),
    generateColumn(t('duration', 'Duración'), 'duration', '7%', (text) => (
      <span>{text + ' minutos'}</span>
    )),
    generateColumn(t('active', 'Activo'), 'active', '9%', (text) => (
      <span>
        {text == true ? <BiCheckboxChecked size={29} color='#1EA05A' /> : <BiXCircle size={29} color={'#ED4C5C'} />}
      </span>
    )),
    createdAtColumn,
  ];
};
export const USETimeSlotColumn = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    // generateColumn(
    //   t('timeslot.slot_id', 'Slot ID'),
    //   'id',
    //   '7%',
    //   false,
    //   sorterText('id')
    // ),
    generateColumn(
      t('timesolet.class_id', 'Clase ID'),
      '',
      '7%',
      (text) => <span>{text?.gym_class.id}</span>,
      {
        sorter: {
          compare: (a, b) => a.gym_class.id - b.gym_class.id,
        },
      }
    ),
    generateColumn(
      t('gym_classes.title_singular', 'Clase'),
      '',
      '10%',
      (text) => <span>{text?.gym_class.name}</span>
    ),
    generateColumn(
      t('rush_hour', 'Hora Pico'),
      'rush_hour',
      '7%',
      (text) => text === true ? <BiCheckboxChecked size={29} color='#1EA05A' /> : <BiXCircle size={29} color={'#ED4C5C'} />
    ),
    generateColumn(t('fromLabel', 'Desde'), 'from', '7%', (text) =>
      dayjs(text.toString().padStart(4, '0'), 'HHmm').format('HH:mm')
    ),
    generateColumn(t('toLabel', 'Hasta'), 'to', '7%', (text) =>
      dayjs(text.toString().padStart(4, '0'), 'HHmm').format('HH:mm')
    ),
    generateColumn(
      t('monday_singular', 'Lunes'),
      'monday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('tuesday_singular', 'Martes'),
      'tuesday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('wednesday_singular', 'Miércoles'),
      'wednesday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('thursday_singular', 'Jueves'),
      'thursday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('friday_singular', 'Viernes'),
      'friday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('saturday_singular', 'Sábado'),
      'saturday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    generateColumn(
      t('sunday_singular', 'Domingo'),
      'sunday',
      '7%',
      (text) => text === true && <BiCheckboxChecked size={29} color='#1EA05A' />
    ),
    createdAtColumn,
  ];
};
