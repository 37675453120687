import React, { useState, useEffect,useMemo } from 'react';
import { notification, ConfigProvider, DatePicker, Popover, Tooltip } from 'antd';
import { useOutletContext } from 'react-router-dom';

import TableCustom from '../components/TableCustom';
import { USEPROSPECTColumns } from '../services/Constants/UsersColumns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../services/utils/formatError';
import { deleteProspect, useGetProspects } from '../api/prospects';
import dayjs from 'dayjs';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import PopProspects from '../components/helpers/popovers/PopProspects';
import PopProspectsConverted from '../components/helpers/popovers/PopProspectsConverted';
import { t } from 'i18next';
import { getLocationById, getLocationId } from '../services/utils/getLocation';
import { useGlobalContext } from '../context/GlobalState';
import { useAuth } from '../context/AuthProvider';
import { isUserSuperAdmin } from '../services/can_user';
import { DateRangePicker, StatsSection } from '../components/prospects/HeaderCharts';
const start = dayjs().startOf('month');
const end = dayjs().add(1, 'day');
export default function Prospects() {
  const PROSPECTColumns = USEPROSPECTColumns()
  const {state}  = useGlobalContext()
  const {user} = useAuth()
  const TITLE = t('prospects','Prospectos');
const TITLE_SINGULAR = t('prospect','Prospecto');
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { isLoading, isError, data, error } = useGetProspects(
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD')
  );
  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();

  const [filterTable, setfilterTable] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(PROSPECTColumns);
  const [columns,setColumns] = useState(PROSPECTColumns);
  const [api, contextHolder] = notification.useNotification();
  const [searchInput, setsearchInput] = useState('');
  const [activeFilter, setActiveFilter] = useState('');
    
  const queryClient = useQueryClient();
  
  const handleDelete = async (id) => {
    await useDeleteProspect.mutateAsync(id);
  
  };
  const canViewChildren = () => {
    return  state.user.roles.map((i)=>i.id).some((i) => i == 1 || i == 7) || [1, 7].includes(getLocationById(state.locations, getLocationId())?.location_category_id);
  }

  const createFilters = (data, key) =>
    [...new Set(data.map((d) => d[key]))]
      .filter(Boolean)
      .map((value) => {
        if (value === 'WEB' && canViewChildren()) {
          return { text: value, value, children: createFilters(data, 'utm_source') }
        }
        return { text: value, value }
      });

  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
    if (data) {
      setColumns(
        PROSPECTColumns
        .filter((column) => { 
          if(column.title == "TIEMPO RESPUESTA" &&  getLocationId() == 1 ){
            return
          }
          return column
        })
.
        map((column) => {
          if (column.dataIndex === 'channel_name' ||
          column.dataIndex === 'campaign_name' ||
          column.dataIndex === 'approach_type_name' ||
   column.dataIndex === 'discharge_reason_name'
          ) {
            return {
              ...column,
              filterMode: 'tree',
              filterSearch: true,
              showSorterTooltip: {
                target: 'full-header',
              },
              filters: createFilters(data, column.dataIndex),
              onFilter: (value, record) => {
                return record[column.dataIndex]
                  ? Array.isArray(record[column.dataIndex])
                    ? record[column.dataIndex].filter((item) =>
                        item.name?.toLowerCase().includes(value.toLowerCase())
                      ).length > 0
                    : column.dataIndex == 'channel_name' ? 
                    record[column.dataIndex].toLowerCase().includes(value.toLowerCase()) 
                    || record['utm_source']?.toString()?.includes(value.toLowerCase())
                    :   record[column.dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                  : '';
              },
            };
          }
     
          return column;
        }))
      }
  }, [data,isLoading]);
  const useDeleteProspect = useMutation({
    mutationFn: (id) => deleteProspect(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: [
          'prospects',
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD'),
        ],
      });
      // setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);
      // setConfirmLoading(false);
    },
  });
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('prospects.updated','Prospecto actualizado correctamente')
          : t('prospects.error','Ocurrió un error al actualizar el prospecto'),
      ...openNotificationFields(type, description),
    });
  };
  const filterNight = useMemo(() => {
    if (!data) return []
   return data.filter((p) => {
     const hour = dayjs(p.created_at).tz(user?.country?.time_zone).format('HH');
     const day = dayjs(p.created_at).tz(user?.country?.time_zone).day(); // 0 para domingo, 6 para sábado
     // Excluir registros entre 22:00 y 06:00 y los creados en sábado o domingo
     return !(hour >= '22' || hour <= '06') && !(day === 0 || day === 6);
 }).filter((p)=>![33,32,207].includes(p.converted_subscription?.membership?.product.parent_product_id))
  }, [data]);
  const averageResponseTimeMinutes = useMemo(() => {
     let filterWithFIrstCrm = filterNight.filter(record => record.first_crm).length 
    const totalMinutes = filterNight.reduce((sum, record) => {
      const diffMinutes = record.first_crm ? dayjs(record.first_crm.sent_at).tz(user.country.time_zone).diff(dayjs(record.created_at).tz(user.country.time_zone), 'minutes') : 0;
      return sum + Math.max(diffMinutes, 0);
    }, 0);
    if (filterWithFIrstCrm === 0) return 0;
    return totalMinutes / filterWithFIrstCrm
  }, [filterNight]);

 
  // // Convertir el promedio a horas y minutos
  const hours = Math.floor(averageResponseTimeMinutes / 60);
  const minutes = Math.floor(averageResponseTimeMinutes % 60);
  const convertedProspects = useMemo(() => data?.filter((p) => p.converted_date && ![33, 32, 207].includes(p.converted_subscription?.membership?.product.parent_product_id)).length, [data]);
  
  
  const handleFilterChange = (filter) => {
    if (activeFilter == filter) {
      setfilterTable(data);
      setActiveFilter(null);
    } else {
      // Activar el nuevo filtro
      setfilterTable(data.filter(filter));
    setActiveFilter(filter.toString());
    }
  };
  return (
    <div className='h-screen pl-[1rem] lg:pl-[3rem]    mt-8 gap-9  '>
      {contextHolder}
      <div className='mb-10 flex-grow flex flex-col mt-2 '>
        <h2 className='text-2xl font-MessinaSansSemiBold'>
          {TITLE} {isLoading ? '' : data.length}
        </h2>
        <div className={`flex flex-row justify-between mr-10 flex-wrap `}>
        <DateRangePicker
        start_date={start_date}
        end_date={end_date}
        setStartDate={setstart_date}
        setEndDate={setend_date}
        datepickerFrom={datepickerFrom}
        datepickerAt={datepickerAt}
      />
      <StatsSection
        isLoading={isLoading}
        data={isLoading ? []:data}
        convertedProspects={convertedProspects}
        hours={hours}
        minutes={minutes}
        PopProspectsConverted={PopProspectsConverted}
        PopProspects={PopProspects}
        ac={activeFilter}
        onFilterChange={handleFilterChange}
      />
        </div>
      </div>
      <div className='w-[100%] mt-[-40px] '>
        <TableCustom
          hideTitle
          handleDelete={handleDelete}
          multipleTables
          filterTable={filterTable}
          data={data}
          openDrawer={() => {}}
          setfilterTable={setfilterTable}
          handleOpenDrawer={(visible, record) => {
            if (record) {
              setModalMemberOpen({
                id: record.member_id ? record.member_id : record.id,
                isProspect: record.member_id ? false : true,
                customer_type:record.member_id ? 'Member' : 'Prospect'
              });
            } else {
              setTypeMemberCreate(true);
            }
          }}
          title={TITLE}
          title_singular={TITLE_SINGULAR}
          searchInput={searchInput}
          onSubmit={() => {}}
          originalColumns={columns}
          handleSubmit={() => {}}
          setsearchInput={setsearchInput}
          isLoading={isLoading}
          setColumns={setColumns}

        />
      </div>
    </div>
  );
}
