import { getTransactions } from '../../api/Transactions';
import { getBalance } from '../../api/balances';
import { getPayments } from '../../api/payments';
import { getProducstStocks } from '../../api/products';
import { getLocationId } from '../../services/utils/getLocation';
import { useGenericQuery } from './rootLoader';

//Sales
// export const useGetSales = () => useGenericQuery(['sales'], getSales);
// export const SalesLoader =
//   (queryClient) =>
//   async ({ params }) => {
//     const query = useGetSales();
//     return (
//       queryClient.getQueryData(query.queryKey) ??
//       (await queryClient.fetchQuery(query))
//     );
//   };
//Payments
// export const useGetPayments = () => useGenericQuery(['payments'], getPayments);
// export const PaymentsLoader =
//   (queryClient) =>
//   async ({ params }) => {
//     const query = useGetPayments();
//     // ⬇️ return data or fetch it
//     return (
//       queryClient.getQueryData(query.queryKey) ??
//       (await queryClient.fetchQuery(query))
//     );
//   };

//Transactions
export const useGetTransactions = () =>
  useGenericQuery(['transactions'], getTransactions);
export const TransactionsLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetTransactions();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

//Stock
export const useGetStock = () => useGenericQuery(['stock'], getProducstStocks);
export const StockLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetStock();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
//Balance
export const useGetBalance = (location_id) =>
  useGenericQuery(['balance', location_id], getBalance);
export const BalanceLoader =
  (queryClient) =>
  async ({ params }) => {
    const location_id = getLocationId();
    const query = useGetBalance(location_id);
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
