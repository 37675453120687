import React, { useRef, useState, useEffect } from 'react';
import { Select, notification,Checkbox } from 'antd';
import SelectPaymentMethod from '../SelectPaymentMethod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthProvider';
import { formatPrice } from '../../services/utils/formatPrice';
import FooterSaleModal from '../helpers/FooterSaleModal';
import { formatError } from '../../services/utils/formatError';
import dayjs from 'dayjs';
import {  createCreditNotes, createRefund, useGetCreditNotes, useGetRefunds } from '../../api/sales';
import { useGetDischargeReasons } from '../../api/prospects';
import { useCallback } from 'react';
import { updateSubscription } from '../../api/subscriptions';
import { t } from 'i18next';
export default function ModalReturn({
  sale,
  setClose,
  openNotification,
  dischargeReasons,
}) {
  if (!sale) return null;
  const dischargeRef = useRef(null);
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { data: dischargeReasonsList, isLoading: isLoadingDischarge } = 
  dischargeReasons?.length> 0 ? {data:dischargeReasons,isLoading:false}:
  useGetDischargeReasons();
  const { data: creditNotes,
  isLoading:isLoadingCreditNotes } =
  useGetCreditNotes(sale.id);
  const { data: refunds,
    isLoading:isLoadingRefunds }
  = useGetRefunds(sale.id);
  const dischargeReasonsFilter = dischargeReasonsList?.filter(
    (reason) => reason.discharge_reason_type_id == 9
  );

  const [selectedProduct, setselectedProduct] = useState({ countProducts: 1 });
  const [api, contextHolder] = notification.useNotification();
  const [selectedReason, setselectedReason] = useState(null);
  const [checked_payment, setchecked_payment] = useState([])
  const calculateTotal = useCallback(() => {
    if (!sale) return 0;
    if(creditNotes?.length>0 || refunds?.length > 0){
      return sale.payments?.reduce((a, b) => a + parseFloat(b.amount), 0) - creditNotes?.reduce((a, b) => a + parseFloat(b.amount), 0) - refunds?.reduce((a, b) => a + parseFloat(b.amount), 0);
    }
    return sale.payments?.reduce((a, b) => a + parseFloat(b.amount), 0);
  },[creditNotes,refunds,sale])
  
  useEffect(() => {
    if (sale && !isLoadingCreditNotes && !isLoadingRefunds) {
      setselectedProduct({
        product: sale.product,
        countProducts: 1,
        subscription_id: sale.subscription_id,
        price: 
         parseFloat(sale.payments?.reduce((a, b) => a + parseFloat(b.amount), 0)  - refunds?.reduce((a, b) => a + parseFloat(b.amount), 0) - creditNotes?.reduce((a, b) => a + parseFloat(b.amount), 0)).toFixed(2)
        // sale.product.price
        ,
        selectedPaymentMethods: [{ paymentType: null, amount: calculateTotal() }],
      });
    }
  }, [sale,isLoadingCreditNotes,isLoadingRefunds]);
   
  // const hasMultiplePaymentPlatform = useCallback(() => {
  //   return sale.payments.filter((p) => p.payment_method_id == 52).length > 1;
  // }, [sale]);

  const useCreateRefund = useMutation({
    mutationFn: (refund) => createRefund(refund.data),
    onSuccess: () => {
      openNotification(
        'success',
        t('refund_success','Devolución generada con éxito'),
        t('refund_success_description','La devolución se generó correctamente')
      );
      queryClient.invalidateQueries({
        queryKey: ['member_cc', sale.customer_id,sale.customer_type],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ['sales', sale.customer_id,sale.customer_type],
      // });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateCreditNote = useMutation({
    mutationFn: (refund) => createCreditNotes(refund.data),
    onSuccess: () => {
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useUpdateMembership = useMutation({
    mutationFn: (membership) =>
      updateSubscription(membership.id, membership.data),
    onSuccess: (data) => {
      // openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', sale.customer_id],
      });
    },
    onError: (error) => {
      console.log('error', error);
      formatError(error, openNotification)
    },
  });

  const handleCreateRefund = async () => {
    let payments = selectedProduct.selectedPaymentMethods.filter(
      (payment) => payment.paymentType != null
    );
    if (!selectedReason) {
       openNotification('warning' ,t('select_reason_refund','Seleccioná un motivo de devolución'));
       return
    }
    if (payments.length == 0 ) 
      return openNotification(
        'warning',
     t('select_payment_method_or_cancel','Seleccioná un método de pago o cancelá el producto')
      );
    
   
    if(sale.payments.some((p)=>p.payment_method_id == 52) && selectedProduct.selectedPaymentMethods.reduce
      ((a, b) => a + parseFloat(b.amount), 0) > sale.payments.find((p)=>p.payment_method_id == 52).amount){
        console.log('error');
        return openNotification('warning',
          t('warning_plaform_higher','No se puede realizar una devolución mayor al monto pagado con el método de pago integración'),t('warning_refund_paymentPlatform','Ocurrio un error al hacer la devolución'));
    }
      if(calculateTotal() == 0){
        const subscription_id = selectedProduct.subscription_id
        let credit_note = {
          data: {
            sale_id: sale.id,
            location_id: sale.location_id,
            customer_id: sale.customer_id,
            customer_type: sale.customer_type,
            point_of_sale_id: sale.point_of_sale_id,
            user_id: user.user_id,
            discharge_reason_id: selectedReason,
            amount: selectedProduct.selectedPaymentMethods.reduce(
              (a, b) => a + parseFloat(b.amount),
              0
            ),
            currency: sale.currency,
          },
        };
        if(subscription_id){
          let updatedMembership = {
            id: subscription_id,
            data: {
              owner_id: sale.customer_id,
              owner_type: sale.customer_type,
              days_left: 0,
              sessions_left: 0,
            },
          };
          await useUpdateMembership.mutateAsync(updatedMembership);
        }
         await useCreateCreditNote.mutateAsync(credit_note);
      }
// return console.log('sale',payments);
    if (payments.length == 0) {
      payments = sale.payments.map((p) => ({
        ...p,
        paymentType: p.payment_method_id,
      }));
    }
    payments.forEach(async (paymentMethod) => {
      let refund = {
        data: {
          payment_method_id: paymentMethod.paymentType,
          amount: paymentMethod.amount,
          currency: sale.currency,
          sale_id: sale.id, 
          location_id: sale.location_id,
          customer_id: sale.customer_id,
          customer_type: sale.customer_type,
          point_of_sale_id: sale.point_of_sale_id,
          discharge_reason_id: selectedReason,
          user_id: user.user_id,
          payment_id:
            sale.payments.find(
              (payment) =>
                payment.payment_method_id == paymentMethod.paymentType
            )?.id || sale.payments[0].id,
        },
      };
      await useCreateRefund.mutateAsync(refund);
    });
  
   
    setClose();
  };
  if (!selectedProduct.product) return null;

  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        sale ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      {contextHolder}
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[45%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <div className='flex flex-col  w-full  pb-5'>
          <h1 className='text-3xl  font-MessinaSansSemiBold '>{t('refund_title','Devolución')}</h1>
          
          <div className=' mb-1 flex flex-row items-center gap-3 mt-10'>
        
            <div
              className={`bg-primaryGrey text-white rounded-sm   py-1 border-[1px] border-lightGreen`}
            >
              <p className='py-2 mx-2'>{selectedProduct?.product?.name}</p>
            </div>
            <div className='flex flex-row item-center gap-4'>
              <div className=' bg-secondaryGrey h-12 flex items-center justify-center px-3 rounded-sm  text-black font-MessinaSansSemiBold text-lg'>
                <p>
                  <span className='mr-1'>$</span>
                </p>
                {selectedProduct?.price}
              </div>
            </div>
          </div>
          {/* LIST OF PAYMENTS */}
          {sale.payments.map((payment) => ( <div
              className='  w-fit  mt-3 rounded-sm py-2 flex-row flex gap-2'
              key={payment.id}
            >
              <div className='bg-primaryGrey py-2  px-4 '>
                <span className=' text-lightGreen underline'>
                  {formatPrice(payment.amount, payment.currency)}
                </span>{' '}
                {t('pay_with','pagados con')}{' '}
                <span className=' text-lightGreen underline'>
                  {payment.payment_method_name}
                </span>
                
              </div>
             {/* { hasMultiplePaymentPlatform() &&
              payment.payment_method_id == 52 &&
             <div className='flex flex-row items-center ml-2'>
                <Checkbox
          className='mr-2'
            onClick={() => {
              if(checked_payment.includes(payment.id)){
                setchecked_payment(checked_payment.filter((p)=>p != payment.id));
              }
              else{
                setchecked_payment([...checked_payment,payment.id]);
              }
              }}
            checked={
              checked_payment.includes(payment.id)
            }
          />
          <p>Devolver este pago</p>
          </div>} */}
            </div>
          ))}
            {creditNotes.map((payment) => (
            <div
              className=' bg-primaryGrey w-fit px-4 mt-3 rounded-sm py-2'
              key={payment.id}
            >
              <span>
                <span className=' text-primaryRed underline'>
                  -{formatPrice(payment.amount, payment.currency)}
                </span>{' '}
                {t('reason_credit_note','en nota de crédito con el motivo')} {' '}
                <span className=' text-primaryRed underline'>
                  {payment.discharge_reason}
                </span>
              {' '}  {t('generated_in','generada el')} {dayjs(payment.created_at).format('DD/MM/YYYY')} {t('by','por')} {payment.user_name}
              </span>
            </div>
          ))}
           {refunds.map((payment) => (
            <div
              className=' bg-primaryGrey w-fit px-4 mt-3 rounded-sm py-2'
              key={payment.id}
            >
              <span>
                <span className=' text-primaryRed underline'>
                  -{formatPrice(payment.amount, payment.currency)}
                </span>{' '}
                {t('reason_refund','en devolución con el motivo')} {' '}
                <span className=' text-primaryRed underline'>
                  {payment.discharge_reason}
                </span>
              {' '}  {t('generated_in','generada el')} {dayjs(payment.created_at).format('DD/MM/YYYY')} {t('by','por')} {payment.user_name}
              </span>
            </div>
          ))}
          { !isLoadingCreditNotes && !isLoadingRefunds&& <SelectPaymentMethod
            totalAmount={parseFloat(calculateTotal())}
            setSelectedPaymentMethods={(selectedPaymentMethods) => {
              if(selectedPaymentMethods.filter((p)=>p.paymentType != null).length == 0){
                if(!isLoadingCreditNotes && !isLoadingRefunds && selectedProduct.selectedPaymentMethods){
                  return setselectedProduct({
                    ...selectedProduct,
                    selectedPaymentMethods: [{
                      paymentType: null,
                      amount: calculateTotal(),
                      hasDiscount:false,
                      discountAmount:0
                    
                    }]
                  });
                }
              }
              if(selectedPaymentMethods.some((p)=>p.paymentType ==52) && !sale.payments.some((p)=>p.payment_method_id == 52)){
                return openNotification('warning',t('warning_refund_paymentPlatform_desc','No se puede realizar una devolución porque no se realizó ningún pago con este método'),t('warning_refund_paymentPlatform','Ocurrio un error al hacer la devolución'));
              }
            setselectedProduct({
              ...selectedProduct,
              selectedPaymentMethods: selectedPaymentMethods,
            });
            }}
            selectedPaymentMethods={selectedProduct.selectedPaymentMethods}
            selectedProducts={[selectedProduct]}
          />}
        </div>
        <div className='pt-6'>
          <h2 className='text-xl font-MessinaSansSemiBold'>
            {t('return.reason', 'Motivo de devolución')}
          </h2>
          <Select
          loading={isLoadingDischarge}
            ref={dischargeRef}
            // loading={isLoadingProducts && isLoadingConsumables}
            showSearch
            placeholder={
              <div className='flex items-center z-10'>
                <span className='text-white'>
                {t('select_reason_refund','Seleccioná el motivo de devolución...')}
                </span>
              </div>
            }
            allowClear
            optionLabelProp='title'
            value={selectedReason}
            // value={selectedProduct.product_id}
            optionFilterProp='children'
            onChange={(value) => {
              dischargeRef.current.blur();
              setselectedReason(value);
            }}
            bordered={false}
            rootClassName=' text-white '
            className={`mt-5 bg-primaryGrey text-white rounded-sm py-2
  
       `}
            style={{
              width: '45%',
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={dischargeReasonsFilter?.map((reason) => ({
              label:
                reason.name +
                (reason.description ? ' - ' + reason.description : ''),
              value: reason.id,
              title: reason.name,
            }))}
          />
        </div>
       {calculateTotal() == 0 && <p className='pt-1 text-primaryRed w-[75%]'>
          {t('refund_note','Recorda que al ser una venta con monto $0, se generará una nota de crédito y se cancelará el producto asociado a esta venta')}
</p>}
        {
          !isLoadingCreditNotes && !isLoadingRefunds &&
          <FooterSaleModal
          // totalAmount={0}
          totalAmount={selectedProduct?.selectedPaymentMethods?.reduce(
            (a, b) => a + parseFloat(b.amount),
            0
          )}
          totalSale={
            calculateTotal()
          }
          cancelProducts={sale.cancelProducts}
          ModalReturn
          isLoading={useCreateRefund.isLoading || useCreateCreditNote.isLoading || useUpdateMembership.isLoading} 
          handleCreateSale={handleCreateRefund}
          setmodalSale={setClose}
        />}
      </div>
    </div>
  );
}
