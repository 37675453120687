import { use } from 'i18next';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId, mappedLocations } from '../services/utils/getLocation';
import axiosInstance from './prospects';
import { useQuery } from '@tanstack/react-query';

export const getLocations = async () => {
  const location_id = getLocationId();
  try {
    const locations = await fetchData(`/locations?location_id=${location_id}`);
    return locations;
  } catch (error) {
    console.log('error', error);
    // Manejo del error
    console.error('Error al obtener las ubicaciones:', error);
    throw new Error('Error al obtener las ubicaciones');
  }
};
export const useGetLocations = () => {
  return useQuery({
    queryKey: ['locations'],
    queryFn: async () => getLocations(),
    cacheTime: 1000 * 60 * 12,
    staleTime: 1000 * 60 * 12,
  });
};
export const getAvailableLocations = async () => {
  try {
    const locations = await fetchData(`/available_locations`);
    // Agregar el código para mapear las ubicaciones
    return mappedLocations(locations);
  } catch (error) {
    console.log('error', error);
    // Manejo del error
    console.error('Error al obtener las ubicaciones:', error);
    throw new Error('Error al obtener las ubicaciones');
  }
};
export const useGetPointOfSales = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['point_of_sales', location_id],
    queryFn: async () => getPointSales(),
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
  });
};
export const getPointSales = async () => {
  const location_id = getLocationId();
  return await fetchData(`/point_of_sales?location_id=${location_id}`);
};
export const getCountries = async () => {
  const location_id = getLocationId();
  return await fetchData(`/countries?location_id=${location_id}`);
};
export const useGetCountries = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['countries', location_id],
    queryFn: async () => getCountries(),
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
  });
};
export const updateLocation = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/locations/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const getLocation = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/locations/${id}?location_id=${location_id}`);
};
export const createLocation = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/locations?location_id=${location_id}`, 'POST', data);
};
export const deleteLocation = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/locations/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const getLocationCategories = async () => {
  const location_id = getLocationId();
  return await fetchData(`/location_categories?location_id=${location_id}`);
};
export const useGetLocationCategories = () => {
  return useQuery({
    queryKey: ['location_categories'],
    queryFn: async () => getLocationCategories(),
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
  });
};
export const getAddressTypes = async () => {
  const location_id = getLocationId();
  return await fetchData(`/address_types?location_id=${location_id}`);
};
export default axiosInstance;
