import { t } from 'i18next';
import React from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
export default function SalesHeader({ setopenSales,isBalanceOpen ,styles}) {

  
  return (
    <button
      className={`flex flex-row items-center gap-2 ${styles}`}
      onClick={() => setopenSales(true)}
    >
      <AiOutlineShoppingCart size={20} />
      <p className='xs:hidden hidden lg:flex'>{isBalanceOpen() == 'open' ? t('header.close_sale') : t('header.open_sale')}</p>
    </button>
  );
}
