import React, { useState, useRef, useEffect } from 'react';
import CardRounded from '../CardRounded';
import AddButton from '../helpers/AddButton';
import { BsFilterLeft } from 'react-icons/bs';
import { BiTrash } from 'react-icons/bi';
import CalendarSvg from '../../assets/icons/CalendarSvg';
import { Select, Skeleton, Tooltip, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/es';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCommentsCrm, removeAlert } from '../../api/crm';
import Seguimiento from '../../assets/icons/Seguimiento';
import { useAuth } from '../../context/AuthProvider';
import { formatPrice } from '../../services/utils/formatPrice';
import {  compareByCreatedAtOrSentAt } from '../../services/utils/compareByDate';
import { t } from 'i18next';
import { isUserManager } from '../../services/can_user';
import { useLoaderData } from 'react-router-dom';
import { getLocationById } from '../../services/utils/getLocation';
import { useGlobalContext } from '../../context/GlobalState';
import { CiCircleCheck } from "react-icons/ci";
import {
  MdOutlineModeEditOutline,
} from 'react-icons/md';
import { GrUpdate } from "react-icons/gr";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.locale('es');
export default function CRM({
  handleOpenCRM,
  CRM_OPTIONS,
  member_id,
  crm_list,
  isLoading,
  sales,
  handleOpenReturn,
  coachHistories
}) {
  console.log('member_id',crm_list);
  
  if (!member_id) return null;
  const queryClient = useQueryClient();
  const data = useLoaderData();
  const {state,dispatch} = useGlobalContext()

  const [filterSelected, setfilterSelected] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageApi, messageContext] = message.useMessage();
  const [appComments, setappComments] = useState([]);
  const { user } = useAuth();

  const filterRef = useRef(null);
  const onChangeFilter = (value) => {
    filterRef.current.blur();
    setfilterSelected(value);
  };
  const confirm = async (id) => {
    // console.log(e);
    processing();
    setConfirmLoading(true);
    try {
      const result = await useDelete_CRM.mutate(id);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
    // setConfirmLoading(true);
  };
  const cancel = (e) => {
    setOpen(false);
  };
  const useDelete_CRM = useMutation({
    mutationFn: (alert) => removeAlert(alert),
    onSuccess: () => {
      // openNotification('success');
      messageApi.destroy();

      queryClient.invalidateQueries({
        queryKey: ['crm', member_id],
      });
    },
    onError: (error) => {
      messageApi.destroy();

      // openNotification('error');
    },
  });
  const processing = () => {
    messageApi.open({
      type: 'loading',
      content: 'Procesando',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };

  const fetchComments = async (crms_ids) => {
    try {
      const data = await getCommentsCrm(crms_ids);
      setappComments(data.response);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // useEffect para llamar a fetchComments cuando appCrms cambie
  useEffect(() => {
    if(!crm_list) return;
    const appCrms = crm_list?.filter((crm) => crm.action_type_id == 1);

    if (appCrms.length > 0) {
      const crms_ids = appCrms.map((crm) => crm.id); // Obtener los IDs de los crms
      fetchComments(crms_ids);
    }
  }, [crm_list]);
const isFuture = (alert)=>{
  const now = dayjs().format('YYYY-MM-DD')
  const alertDate = dayjs(alert.sent_at).format('YYYY-MM-DD')
  return dayjs(alertDate).isAfter(now)
}
  return (
    <>
      <CardRounded styles='py-6  mt-6 lg:mt-0'>
        <div className='flex flex-row items-center gap-7 px-6 '>
          <h2 className='text-2xl font-MessinaSansSemiBold text-white'>
            {t('crm.title', 'Seguimiento')}
          </h2>
          <Select
            ref={filterRef}
            showSearch
            placeholder={
              <div className='flex items-center gap-3'>
                <BsFilterLeft size={19} color='#FFF' />
                <span className='text-white font-MessinaSansSemiBold'>
                  {t('filter', 'Filtrar')}
                </span>
              </div>
            }
            allowClear
            mode='tags'
            optionFilterProp='children'
            onChange={onChangeFilter}
            value={filterSelected}
            bordered={false}
            rootClassName='text-white'
            className={`bg-primaryGrey text-white outline-none   rounded-md  font-DrukWideMedium py-1`}
            style={{
              width: 200,
              color: '#FFFFFF',
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={CRM_OPTIONS.map((option, i) => ({
              value: option.action_type_id,
              label: option.title,
            }))}
          />
          <div className='ml-auto'>
            <AddButton
              title={t('crm.add', 'Nuevo CRM')}
              greenColor
              onClick={() => handleOpenCRM(true)}
            />
          </div>
        </div>
        <div className='overflow-y-scroll max-h-[85vh] mt-6 px-6 overflow-x-hidden'>
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 40 }} />
          ) : crm_list.length === 0 ? (
            <div className='flex flex-col jic py-8'>
              <Seguimiento />
              <h2 className='text-xl font-MessinaSansRegular text-[#888] text-center mt-8'>
                {t('crm.empty', 'Cargar seguimiento con el + para comenzar')}
              </h2>
            </div>
          ) : (
            Array.isArray(crm_list) &&
            Array.isArray(sales) &&
            Array.isArray(coachHistories)&&
            [ ...(crm_list ? crm_list : []), ...(sales ? sales : []) ,...(coachHistories ? coachHistories : [])]
            // .sort((a, b) => compareByCreatedAt(a, b))
              .sort((a, b) => compareByCreatedAtOrSentAt(a, b))
              .filter((action) =>
                filterSelected.length > 0
                  ? filterSelected.includes(action.action_type_id)
                  : true
              )
              .map((action, i) => (
                <div
                  key={i}
                  className='py-5 border-b-[1px] border-b-primaryGrey last:border-b-transparent'
                >
                  <div className='flex flex-row items-center gap-7'>
                    {action.type == 'sale'
                      ? CRM_OPTIONS.find((crm) => crm.action_type_id == 0)?.icon
                      : CRM_OPTIONS.find(
                          (crm) => crm.action_type_id == action.action_type_id
                        )?.icon}
                    {action.type !== 'sale' && action.type !== 'coach_history'  ? (
                      <span>
                        {action.action_type_id !== 6 &&
                          `${action.user_first_name} ${
                            action.user_last_name
                          } ${t('crm.crm_source', 'se contactó por')} `}
                        {action.action_type_id == 6 &&
                          `${
                            action.creator_id !== action.user_id
                              ? action.user_id == user.user_id
                                ? `${action.creator_name} ${t(
                                    'crm.crm_alert_title',
                                    'te programó una alerta'
                                  )}`
                                : `${action.creator_name}  ${t(
                                    'crm.crm_alert_title_v2',
                                    'programó una alerta para'
                                  )}  ${!action.user_first_name ? `BIGG ${
                                    getLocationById(state.locations,action.location_id)?.name}`   :`${action.user_first_name} ${
                                    action.user_last_name
                                  }` } `
                              : `${action.user_first_name} ${
                                  action.user_last_name
                                } ${t(
                                  'crm.crm_alert_title_v3',
                                  'programó una alerta'
                                )}`
                          } ${action.performed_at ? 'para el ' + dayjs(action.sent_at).format('YYYY-MM-DD'):''}`}
                        {action.action_type_id !== 6 && (
                          <span className=' font-MessinaSansSemiBold'>
                            {
                              CRM_OPTIONS.find(
                                (crm) =>
                                  crm.action_type_id == action.action_type_id
                              )?.title
                            }
                            {action.updated_at !== action.created_at && (
                              <span className='text-xs  opacity-60 ml-1'>
                                {' '}
                                ({t('crm.edited', 'Editado')})
                              </span>
                            )
                            }
                          </span>
                        )}
                        {((action.sent_at && !action.performed_at) || (action.sent_at && action.action_type_id !== 6)) && (
                          <div>
                            <p className='opacity-75 font-MessinaSansSemiBold'>
                              {action.action_type_id == 6
                                ? t('crm.program_to', 'Programada para el')
                                :  t(
                                    'crm.date_conversation',
                                    'Fecha Conversación'
                                  )}{' '}
                                  {/* {dayjs(action.sent_at_original).utc().tz(user.country.time_zone).format('YYYY-MM-DD hh:mm')} */}
                              { dayjs(action.sent_at_original).utc().tz(user.country.time_zone).format(action.action_type_id == 6 ?'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm A')}{' '}
                            </p>
                          </div>
                        )}
                        {action.sent_at && action.performed_at &&  action.action_type_id == 6 &&(
                          <div className='bg-lightGreen rounded-full px-3 py-1  self-start w-max border-[1px] border-lightGreen mt-2 flex flex-row items-center gap-2'>
                            <CiCircleCheck  color='#1EA05A' size={15}/>
                            <span className='font-MessinaSansSemiBold text-darkGreen z-50 text-xs'>
                             { t('crm.task_done', 'Tarea realizada el')}{' '}
                              {dayjs(action.performed_at).tz(user.country.time_zone).format('YYYY-MM-DD')}{' '}
                            </span>
                          </div>
                        )}
                      </span>
                    ) :  action.type == 'coach_history'  ? (
                      <div className='flex gap-7'>
                       <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primaryBlue relative">
  <GrUpdate className="z-10" color="#111" />
</div>
                      <span className='font-MessinaSansSemiBold'>
                        {t('coach_assignment_modify','Modificación de coach asignado')}
</span>
                      </div>
                    ):(
                      <span className='font-MessinaSansSemiBold'>
                        {t('sales.title_singular', 'Venta')} {action.id} |{' '}
                        {t('seller', 'Vendedor')} {action.seller_name}
                      </span>
                    )}
                    <span className='ml-auto text-primaryGrey flex gap-3'>
                      <CalendarSvg />
                      {dayjs(action.created_at).tz(user.country.time_zone).format('LLL A')}
                    </span>
                  </div>
                  <div
                    className={`${
                      action.type !== 'sale' && 'pl-16'
                    } mt-2 flex justify-between items-start`}
                  >
                    <div
                      className={`${
                        action.type !== 'sale' ? 'w-[80%]' : 'w-[100%]'
                      }`}
                    >
                      {action.type == 'sale' ? (
                        <div className='bg-primaryGrey rounded-sm w-full py-3 flex flex-row items-center px-5 gap-8'>
                          <p className='font-MessinaSansSemiBold'>
                            {action.product.name}
                          </p>
                          <p>{formatPrice(action.total, action.currency)}</p>
                          {action.payments.map((p) => (
                            <Tooltip
                              // zIndex={1}
                              title={`${formatPrice(p.amount, p.currency)} en ${
                                p.payment_method_name
                              } el ${dayjs(p.created_at)
                                .tz(p.time_zone)
                                .format('DD/MM/YYYY HH:mm A')}`}
                              key={i}
                            >
                              <div className='px-3 bg-lightGreen rounded-full'>
                                <p className=' text-center text-darkGreen py-1 font-MessinaSansSemiBold'>
                                  {p.payment_method_name}
                                </p>
                              </div>
                            </Tooltip>
                          ))}
                          <Tooltip
                            title={t('refund_operation', 'Devolver Operacion')}
                          >
                            <button
                              onClick={() => handleOpenReturn(action)}
                              className=' bg-primaryRed rounded-full h-8 w-8 flex items-center justify-center ml-auto ease-in-out duration-300 hover:opacity-80 hover:scale-105'
                            >
                              <svg
                                width='12'
                                height='10'
                                viewBox='0 0 12 10'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M2.98228 0.811523L1.16309 2.37084L2.98228 4.19004'
                                  stroke='#80000D'
                                  stroke-width='1.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                />
                                <path
                                  d='M1.16309 2.37109H7.13889C8.92769 2.37109 10.4467 3.83165 10.5164 5.61966C10.5902 7.50903 9.02904 9.12812 7.13889 9.12812H2.72188'
                                  stroke='#80000D'
                                  stroke-width='1.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                      ) : action.type == 'coach_history' ? (
                          <div>
                            <span>El coach asignado fue modificado a {action.coach_name} con el motivo {action.discharge_reason}</span>
                          </div>
                      ): (
                        <div>
                          {action.message.split('\n').map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                          {action.action_type_id == 1 && (
                            <div className='bg-gray-900 p-2 rounded-lg shadow-md'>
                              <div className='flex justify-between items-center  border-gray-700 pb-2 mb-2'>
                                <div>
                                  <span className='text-gray-400'>
                                    {appComments.filter(
                                      (comment) => comment.crm_id == action.id
                                    ).length == 0
                                      ? 'Sin'
                                      : appComments.filter(
                                          (comment) =>
                                            comment.crm_id == action.id
                                        ).length}{' '}
                                    Comentarios
                                  </span>
                                </div>
                              </div>
                              <div className='mt-2 space-y-4'>
                                {appComments
                                  .filter(
                                    (comment) => comment.crm_id == action.id
                                  )
                                  .map((item, i) => (
                                    <div
                                      key={i}
                                      className='bg-gray-800 p-3 rounded-lg shadow-sm'
                                    >
                                      <p className='text-sm text-gray-400'>
                                        {dayjs(item.created_at).format(
                                          'YYYY-MM-DD'
                                        )}
                                      </p>
                                      <p className='mt-1 text-gray-100'>
                                        {item.message}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className=' self-center  flex flex-row gap-2'>
                    {isUserManager() && (data.available_locations.map((d)=>d.id).includes(action.location_id) || action.creator_id == user.user_id ) && [5,4,2,6].includes(action.action_type_id) && (
                   <Tooltip title={t('edit', 'Editar')}>
                   <div className='rounded-full  border-primaryGray border-[1px] p-1 cursor-pointer hover:opacity-60 ease-in-out duration-200 '>
                        <MdOutlineModeEditOutline
                          size={16}
                          color='#ccc'
                          onClick={() => handleOpenCRM(true, action)}
                        />
                      </div>
                    </Tooltip>
                    )}
                    {action.type !== 'sale' &&
                    (isUserManager() && data.available_locations.map((d)=>d.id).includes(action.location_id) || action.creator_id == user.user_id ) &&
                     (
                      <div className='cursor-pointer'>
                        <Popconfirm
                          title={t(
                            'crm.confirm_delete',
                            'Estás seguro que deseas eliminar esta acción?'
                          )}
                          // description='Se agregará una sesión al socio.'
                          onConfirm={() => confirm(action.id)}
                          onCancel={cancel}
                          okText={t('accept', 'Aceptar')}
                          placement='left'
                          open={open == action.id}
                          cancelText={t('cancel', 'Cancelar')}
                          okButtonProps={{
                            loading: confirmLoading,
                            className:
                              'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
                          }}
                        >
                          <BiTrash
                            size={25}
                            color='#ED4C5C'
                            onClick={() => setOpen(action.id)}
                          />
                        </Popconfirm>
                      </div>
                      
                    )}
                    
                   
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </CardRounded>
      {messageContext}
    </>
  );
}
