import { useQueries, useQuery } from '@tanstack/react-query';
import axiosInstance, { getProspect } from './prospects';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';
import dayjs from 'dayjs';
import { getMemberCrm } from './crm';
import { compareByCreatedAt } from '../services/utils/compareByDate';
import { getCustomerSales, getProspectSales } from './sales';
import { getCoachHistories } from './coaches';
import { getLead } from './leads';
export const getMembers = async () => {
  const location_id = getLocationId();
  return await fetchData(`/members?location_id=${location_id}`);
};
export const getViewMembers = async () => {
  const location_id = getLocationId();
  return await fetchData(`/view_members?location_id=${location_id}`);
};
export const getMembersPopulate = async (all) => {
  const location_id = getLocationId();
  return await fetchData(
    `/members_populate?location_id=${location_id}&all=${all ? true : false}`
  );
};
export const useGetMemberCC = (id, customer_type) => {
  return useQuery({
    queryKey: ['member_cc', id, customer_type],
    queryFn: async () => getMemberCC(id, customer_type),
  });
};
export const getMemberCC = async (id, customer_type) => {
  const location_id = getLocationId();
  return await fetchData(
    `/unpaid_sales?location_id=${location_id}&customer_id=${id}&customer_type=${customer_type}`
  );
};
export const useGetMembersPopulate = (all) => {
  return useQuery({
    queryKey: ['members_populate'],
    queryFn: async () => getMembersPopulate(all),
    select: (data) => data.sort((a, b) => a.name > b.name),
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });
};

export const useGetMembers = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['members', location_id],
    queryFn: async () => getViewMembers(),
    //cache for 2 minutes
    cacheTime: 1000 * 60 * 2,
    staleTime: 1000 * 60 * 2,
    select: (data) =>
      data
        .sort((a, b) => a.id - b.id)
        .map((member) => ({
          ...member,
          name: member.first_name + ' ' + member.last_name,
          subscriptions: JSON.parse(member.subscriptions).flat(),
          // member.subscriptions
          //   .filter((suscription) =>
          //     isValidSuscription(
          //       suscription.started_at,
          //       suscription.days_left,
          //       suscription.sessions_left,
          //       suscription.membership.product.parent_product_id
          //     )
          //   )
          //   .map((subscription) => ({
          //     name: subscription?.membership?.product.name,
          //     ...subscription,
          //   })),
          debits:
            member.debits == null
              ? []
              : JSON.parse(member.debits)
                  .flat()
                  .map((debit) => ({
                    ...debit,
                    name: debit.product_name,
                  })),
        })),
  });
};
export const getProfile = async (id, isProspect, already_fetched) => {
  console.log(
    'id, isProspect, already_fetched',
    id,
    isProspect,
    already_fetched
  );

  const location_id = getLocationId();
  let member = await fetchData(`/members/${id}/?location_id=${location_id}`);
  if (already_fetched) return member;
  if (member.prospect_id && !isProspect) {
    console.log('enters?');

    const prospect = await getProspect(member.prospect_id, true);
    return {
      ...prospect,
      ...member,
      prospect: prospect,
    };
  }
  return member;
};

export const useGetMemberSales = (customer_id, customer_type) => {
  return useQuery({
    queryKey: ['sales', customer_id, customer_type],
    queryFn: async () =>
      customer_type == 'Member'
        ? getCustomerSales(customer_id)
        : getProspectSales(customer_id),
    select: (data) => data.sort((a, b) => compareByCreatedAt(a, b)),
  });
};
export const getMemberData = (isProspect, id, customer_type) => {
  if (customer_type == 'Lead') {
    return useQueries({
      queries: [
        {
          queryKey: ['lead', id],
          queryFn: async () => getLead(id),
          select: (data) => ({
            ...data,
            ...data.user,
            name: `${data.first_name} ${data.last_name}`,
          }),
        },
        {
          queryKey: ['crm', id, 'Lead'],
          queryFn: async () => getMemberCrm(id, 'Lead'),
          select: (data) => data.sort((a, b) => compareByCreatedAt(a, b)),
        },
        {
          queryKey: ['sales', id, 'Lead'],
          queryFn: async () => [], // Return empty array for sales when Lead
          enabled: false, // Disables this query
        },
        // Placeholder for coachHistories, will return empty data
        {
          queryKey: ['member_coaches', id, 'Lead'],
          queryFn: async () => [], // Return empty array for coachHistories when Lead
          enabled: false, // Disables this query
        },
      ],
    });
  }

  return useQueries({
    queries: [
      {
        queryKey: [isProspect ? 'prospect' : 'profile', id],
        queryFn: async () => (isProspect ? getProspect(id) : getProfile(id)),
        select: (data) => ({
          ...data,
          ...data.user,
          name: `${data.user.first_name} ${data.user.last_name}`,
        }),
      },
      {
        queryKey: ['crm', id],
        queryFn: async () =>
          getMemberCrm(id, isProspect ? 'Prospect' : 'Member'),
        select: (data) => data.sort((a, b) => compareByCreatedAt(a, b)),
      },
      {
        queryKey: ['sales', id, isProspect ? 'Prospect' : 'Member'],
        queryFn: async () =>
          isProspect ? getProspectSales(id) : getCustomerSales(id),
        select: (data) =>
          data
            .sort((a, b) => compareByCreatedAt(a, b))
            .map((p) => ({
              ...p,
              type: 'sale',
              action_type_id: 0,
              message: '',
            })),
      },
      {
        queryKey: ['member_coaches', id],
        queryFn: async () => getCoachHistories(id, isProspect),
        select: (data) =>
          data
            .map((c) => ({ ...c, type: 'coach_history', action_type_id: -1 }))
            .sort((a, b) => compareByCreatedAt(a, b)),
      },
    ],
  });
};

export const createMember = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/members?location_id=${location_id}`, 'POST', data);
};
export const convertMember = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/turn_to_member?location_id=${location_id}`,
    'POST',
    data
  );
};
export const updateMember = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/members/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const deleteMember = async (id) => {
  const location_id = getLocationId();

  return await fetchData(`/members/${id}?location_id=${location_id}`, 'DELETE');
};
export const getTrainings = async (id, customer_type, customer_location) => {
  const location_id = getLocationId();
  return await fetchData(
    `/customer_reserves/?location_id=${
      customer_location ? customer_location : location_id
    }&customer_type=${customer_type}&customer_id=${id}`
  );
};
export const useGetTrainings = (id, customer_type, location_id) => {
  return useQuery({
    queryKey: ['training', id, customer_type],
    queryFn: async () => getTrainings(id, customer_type, location_id),
    select: (data) =>
      data.sort((a, b) =>
        dayjs(a.scheduled_at).isAfter(dayjs(b.scheduled_at)) ? -1 : 1
      ),
  });
};
export const get_mobile_member = async (token) => {
  return await fetchData(`/mobile/member`, 'GET', null, token);
};

export default axiosInstance;
