import { t } from 'i18next';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function createFormField(
  name,
  label,
  type,
  className,
  options = [],
  mode = undefined,
  step,
  subfields,
  disabledDate
) {
  return {
    name,
    label,
    type,
    className,
    options,
    mode,
    step,
    subfields,
    disabledDate,
  };
}
// Can not select days before today and today

const ADDRESS = [
  createFormField('address_type_id', 'Tipo', 'select', 'half-width', []),
  createFormField('country_id', 'País', 'select', 'half-width', []),
  createFormField('address_line_1', 'Dirección', 'text', 'half-width', ['']),
  createFormField('city', 'Ciudad', 'text', 'half-width', ['']),
  createFormField('state', 'Estado', 'text', 'half-width', ['']),
];
export const USER_COMMON_FIELDS = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'first_name',
      t('first_name', 'Nombre'),
      'text',
      'half-width'
    ),
    createFormField(
      'last_name',
      t('last_name', 'Apellido'),
      'text',
      'half-width'
    ),
    createFormField('email', 'Email', 'email', 'full-width'),
    createFormField('phone', t('phone', 'Telefono'), 'text', 'full-width'),
    createFormField(
      'password',
      t('password', 'Contraseña'),
      'password',
      'full-width'
    ),
    createFormField(
      'password_confirmation',
      t('password_confirmation', 'Repetir Contraseña'),
      'password',
      'full-width'
    ),
    createFormField(
      'document_type_id',
      t('type', 'Tipo'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'document_number',
      t('document_number', 'Numero de documento'),
      'number',
      'full-width'
    ),
    createFormField(
      'birthdate',
      t('birthdate', 'Fecha de Nacimiento'),
      'date',
      'full-width'
    ),
  ];
};
export const USE_PROSPECT_COMMON_FIELDS = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'channel_id',
      t('prospect.source', 'Fuente'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'approach_type_id',
      t('prospect.how_did_you_know_us', 'Como nos conocio?'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'campaign_id',
      t('prospect.campaing', 'Campaña'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'discharge_reason_id',
      t('prospect.discharge_reason', 'Por que no compro?'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'referrer_id',
      t('prospect.referrer', 'Referido por'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'owner_user_id',
      t('prospect.owner_user_id', 'Vendedor asignado prospecto'),
      'select',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_PROSPECT = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'first_name',
      t('first_name', 'Nombre'),
      'text',
      'half-width'
    ),
    createFormField(
      'last_name',
      t('last_name', 'Apellido'),
      'text',
      'half-width'
    ),
    createFormField('email', 'Email', 'email', 'full-width'),
    createFormField('phone', t('phone', 'Telefono'), 'tel', 'full-width'),
    createFormField(
      'password',
      t('password', 'Contraseña'),
      'password',
      'full-width'
    ),
    createFormField(
      'password_confirmation',
      t('password_confirmation', 'Repetir Contraseña'),
      'password',
      'full-width'
    ),
    createFormField('gender', t('gender', 'Género'), 'select', 'full-width', [
      {
        label: 'Masculino',
        value: 'm',
      },
      {
        label: 'Femenino',
        value: 'f',
      },
      {
        label: 'Otro',
        value: 'o',
      },
    ]),
    createFormField(
      'channel_id',
      t('prospect.source', 'Fuente'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'approach_type_id',
      t('prospect.how_did_you_know_us', 'Como nos conocio?'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'point_of_sale_id',
      t('point_of_sale', 'Punto de venta'),
      'select',
      'full-width',
      []
    ),
  ];
};

export const USE_FORM_USER = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  const user_fields = USER_COMMON_FIELDS();
  return [
    ...user_fields,
    createFormField('gender', t('gender', 'Género'), 'select', 'full-width', [
      {
        label: 'Masculino',
        value: 'm',
      },
      {
        label: 'Femenino',
        value: 'f',
      },
      {
        label: 'Otro',
        value: 'o',
      },
    ]),
    createFormField(
      'roles',
      t('roles', 'Roles'),
      'select',
      'full-width',
      [],
      'multiple'
    ),
    createFormField(
      'location_ids',
      t('locations', 'Sedes'),
      'select',
      'full-width',
      [],
      'multiple'
    ),
    createFormField(
      'active',
      t('active', 'Activo'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_COACH = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  const user_fields = USER_COMMON_FIELDS();
  return [
    ...user_fields,
    createFormField(
      'observation',
      t('observation', 'Observación'),
      'text',
      'full-width'
    ),
    createFormField(
      'locations',
      t('locations', 'Sedes'),
      'select',
      'full-width',
      [],
      'multiple'
    ),

    createFormField(
      'active',
      t('active', 'Activo'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};

export const USE_FORM_TRANSACTION = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'point_of_sale_id',
      t('point_of_sale', 'Punto de venta'),
      'select',
      'full-width',
      []
    ),

    createFormField(
      'transaction_type',
      t('type', 'Tipo'),
      'select',
      'full-width',
      [
        {
          label: t('income', 'Ingreso'),
          value: 'income',
        },
        {
          label: t('expense', 'Egreso'),
          value: 'expense',
        },
      ]
    ),
    createFormField(
      'transaction_category_id',
      t('category', 'Categoria'),
      'select',
      'full-width',
      []
    ),

    createFormField(
      'payment_method_id',
      t('sales.payment_method', 'Metodo de pago'),
      'select',
      'full-width',
      []
    ),
    ,
    createFormField(
      'amount',
      t('amount_nosign', 'Monto (sin signo)'),
      'number',
      'half-width',
      [],
      false,
      '0.01'
    ),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'half-width'
    ),
  ];
};
export const USE_FORM_PRODUCT = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'product_type_id',
      t('type', 'Tipo'),
      'select',
      'full-width',
      []
    ),
    createFormField('name', t('first_name', 'Nombre'), 'text', 'half-width'),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'half-width'
    ),
    createFormField(
      'price',
      t('price', 'Precio'),
      'number',
      'half-width',
      '',
      '',
      '0.1'
    ),
    createFormField(
      'parent_product_id',
      t('parent_product', 'ID Producto padre'),
      'select',
      'half-width'
    ),

    createFormField(
      'region_config_type_id',
      t('regional_config', 'Configuracion regional'),
      'select',
      'half-width',
      []
    ),
    createFormField(
      'available_region_config_type_id',
      t('available_regional_config', 'Region disponible'),
      'select',
      'half-width',
      []
    ),
    createFormField(
      'recurrent',
      t('allows_recurrent_charge', 'Permite cobro recurrente'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_CONSUMABLE = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'product_id',
      t('product', 'Producto'),
      'select',
      'full-width',
      []
    ),
    createFormField('brand', t('brand', 'Marca'), 'text', 'half-width'),
    createFormField(
      'price',
      t('price', 'Precio'),
      'number',
      'half-width',
      '',
      '',
      '0.1'
    ),
    createFormField(
      'status_id',
      t('state_singular', 'Estado'),
      'select',
      'full-width',
      [
        { label: 'active', value: 1 },
        { label: 'paused', value: 0 },
      ]
    ),
  ];
};
export const USE_FORM_MEMBERSHIP = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'product_id',
      t('product', 'Producto'),
      'select',
      'full-width',
      []
    ),

    createFormField(
      'sessions_amount',
      t('sessions', 'Sesiones'),
      'number',
      'half-width'
    ),
    createFormField('days_amount', t('days', 'Días'), 'number', 'half-width'),

    createFormField(
      'status_id',
      t('state_singular', 'Estado'),
      'select',
      'full-width'
    ),
  ];
};
export const USE_FORM_DISCOUNTS = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField('name', t('first_name', 'Nombre'), 'text', 'half-width'),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'half-width'
    ),
    createFormField(
      'amount',
      t('total_singular', 'Total'),
      'number',
      'half-width',
      [],
      '',
      '0.001'
    ),
    createFormField(
      'region_config_type_id',
      'Configuracion regional',
      'select',
      'half-width',
      []
    ),
    createFormField('active', t('active', 'Activo'), 'checkbox', 'full-width'),
    createFormField(
      'recurrent',
      t('allows_recurrent_charge', 'Permite cobro recurrente'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_SESSION = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'product_id',
      t('product', 'Producto'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'sessions_amount',
      t('sessions', 'Sesiones'),
      'number',
      'half-width'
    ),
    createFormField('days_amount', t('days', 'Días'), 'number', 'half-width'),
    createFormField(
      'status_id',
      t('state_singular', 'Estado'),
      'select',
      'full-width'
    ),
  ];
};
export const USE_FORM_SALE = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'point_of_sale_id',
      t('point_of_sale', 'Punto de venta'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'product_name',
      t('product', 'Producto'),
      'text',
      'full-width'
    ),
    createFormField(
      'customer_name',
      t('client_singular', 'Cliente'),
      'text',
      'full-width'
    ),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'half-width'
    ),
    createFormField(
      'discount_id',
      t('discount', 'Descuento'),
      'select',
      'half-width',
      []
    ),
    createFormField('amount', t('amount', 'Monto'), 'number', 'half-width'),
    createFormField(
      'quantity',
      t('quantity_singluar', 'Cantidad'),
      'number',
      'half-width'
    ),
    // createFormField('tax_amount', 'Monto impuestos', 'number', 'half-width'),
    createFormField(
      'invoice_number',
      t('sales.invoice_number', 'Número de factura'),
      'number',
      'half-width'
    ),

    createFormField(
      'total',
      t('total_singular', 'Total'),
      'number',
      'half-width'
    ),
  ];
};
export const USE_FORM_PERSONAL_DATA = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'first_name',
      t('first_name', 'Nombre'),
      'text',
      'half-width',
      ['']
    ),
    createFormField(
      'last_name',
      t('last_name', 'Apellido'),
      'text',
      'half-width'
    ),
    createFormField(
      'location_id',
      t('location', 'Sede'),
      'select',
      'half-width',
      []
    ),

    createFormField(
      'username',
      'Nombre usuario',
      t('username', 'Nombre usuario'),
      'text',
      'half-width'
    ),
    createFormField('email', 'Email', 'email', 'full-width'),
    createFormField('phone', t('phone', 'Telefono'), 'text', 'full-width'),
    createFormField(
      'document_type_id',
      t('type', 'Tipo'),
      'select',
      'full-width',
      []
    ),
    createFormField('gender', t('gender', 'Género'), 'select', 'full-width', [
      {
        label: 'Masculino',
        value: 'm',
      },
      {
        label: 'Femenino',
        value: 'f',
      },
      {
        label: 'Otro',
        value: 'o',
      },
    ]),
    createFormField(
      'document_number',
      t('document_number', 'Numero de documento'),
      'number',
      'full-width'
    ),
    createFormField(
      'birthdate',
      ('birthdate', 'Fecha de Nacimiento'),
      'date',
      'full-width'
    ),
    createFormField('instagram', 'Instagram', 'text', 'full-width'),
  ];
};
export const USE_FORM_PAYMENT = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'point_of_sale_id',
      t('point_of_sale', 'Punto de venta'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'payment_method_id',
      t('sales.payment_method', 'Metodo de pago'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'customer_id',
      t('client_singular', 'Cliente'),
      'text',
      'full-width'
    ),

    createFormField(
      'operation_number',
      t('sales.operation_number', 'Número de operación'),
      'text',
      'full-width'
    ),

    createFormField('amount', t('amount', 'Monto'), 'number', 'half-width'),
  ];
};
export const USE_FORM_COUNTRIES = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField('name', t('name', 'Nombre'), 'text', 'full-width'),
    createFormField('code', t('code', 'Codigo'), 'text', 'full-width'),
    createFormField('active', t('active', 'Activo'), 'checkbox', 'full-width'),
  ];
};
export const USE_FORM_COACH_LOCATION = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'coach_id',
      t('users.user', 'Usuario'),
      'select',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_STOCK = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'product_id',
      t('product', 'Producto'),
      'select',
      'full-width',
      []
    ),
    createFormField('units', t('units', 'Unidades'), 'number', 'half-width'),
  ];
};
export const USE_FORM_GYM_CLASS = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'parent_id',
      t('parent_gym_class', 'Clase padre'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'name',
      t('first_name', 'Nombre'),
      'text',
      'full-width',
      []
    ),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'full-width'
    ),

    createFormField(
      'gym_class_type_id',
      t('type', 'Tipo'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'location_id',
      t('location', 'Sede'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'region_config_type_id',
      t('regional_config', 'Configuracion regional'),
      'select',
      'half-width',
      []
    ),
    createFormField(
      'duration',
      t('duration', 'Duración'),
      'number',
      'full-width'
    ),
    createFormField(
      'max_attendances',
      t('max_attendances', 'Cupo máximo'),
      'number',
      'full-width'
    ),
    createFormField(
      'wait_list_amount',
      t('wait_list_amount', 'Cupo lista de espera'),
      'number',
      'full-width'
    ),
    createFormField(
      'active',
      t('active', 'Activo'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_TIME_SLOT = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'gym_class_id',
      t('gym_classes.title_singular', 'Clase'),
      'select',
      'full-width',
      []
    ),

    createFormField(
      'rangeHours',
      t('rangeHours', 'Rango horario'),
      'TimePicker',
      'full-width'
    ),
    createFormField(
      'days',
      t('days', 'Días'),
      'select',
      'full-width',
      [
        { label: t('monday_singular', 'Lunes'), value: 'monday' },
        { label: t('tuesday_singular', 'Martes'), value: 'tuesday' },
        { label: t('wednesday_singular', 'Miércoles'), value: 'wednesday' },
        { label: t('thursday_singular', 'Jueves'), value: 'thursday' },
        { label: t('friday_singular', 'Viernes'), value: 'friday' },
        { label: t('saturday_singular', 'Sábado'), value: 'saturday' },
        { label: t('sunday_singular', 'Domingo'), value: 'sunday' },
      ],
      'multiple'
    ),
    createFormField(
      'rush_hour',
      t('rush_hour', 'Hora pico'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const FORM_REGION_CONFIG = [
  createFormField('name', 'Configuracion regional', 'text', 'half-width'),
  createFormField('description', 'Descripción', 'text', 'full-width'),
  createFormField(
    'countries',
    'Paises',
    'select',
    'full-width',
    [],
    'multiple'
  ),
  createFormField('locations', 'Sedes', 'select', 'full-width', [], 'multiple'),
];
export const USE_FORM_LOCATION = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField('name', t('first_name', 'Nombre'), 'text', 'half-width'),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'full-width'
    ),
    createFormField(
      'country_id',
      t('country', 'País'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'location_category_id',
      t('category', 'Categoria'),
      'select',
      'full-width',
      []
    ),
    createFormField('phone', t('phone', 'Telefono'), 'text', 'full-width'),

    ...ADDRESS,
    createFormField('latitude', t('latitude', 'Latitud'), 'text', 'full-width'),
    createFormField(
      'longitude',
      t('longitude', 'Longitud'),
      'text',
      'full-width'
    ),
    createFormField(
      'active',
      t('active', 'Activo'),
      'checkbox',
      'full-width',
      []
    ),
  ];
};
export const USE_FORM_SALE_GOAL_TYPES = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField('name', t('first_name', 'Nombre'), 'text', 'half-width'),
    createFormField(
      'description',
      t('description', 'Descripción'),
      'text',
      'full-width'
    ),
  ];
};
export const USE_FORM_SALE_GOAL = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  const disabledDateStart = (current) => {
    // Can not select days before today and today
    return current && current.date() !== 1;
  };
  const disabledDateEnd = (current) => {
    if (!current) return false; // Permitir todas las fechas si no hay una fecha actual seleccionada

    const lastDayOfMonth = current.endOf('month').date();
    return current.date() !== lastDayOfMonth;

    // Solo permitir seleccionar el último día del mes que se está viendo
  };
  return [
    createFormField(
      'sale_goal_type_id',
      t('sale_goal_type', 'Tipo objetivo comercial'),
      'select',
      'half-width'
    ),
    createFormField(
      'start_date',
      t('start', 'Inicio'),
      'date',
      'full-width',
      [],
      '',
      '',
      '',
      disabledDateStart
    ),
    createFormField(
      'end_date',
      t('end', 'Fin'),
      'date',
      'full-width',
      [],
      '',
      '',
      '',
      disabledDateEnd
    ),
    createFormField(
      'order_1',
      'Objetivo 1',
      'group',
      'full-width',
      [],
      '',
      null,
      [
        createFormField('amount_1', 'Monto', 'text', 'half-width', []),
        // createFormField('product_id', 'Rol', 'select', 'half-width', []),
      ]
    ),
    createFormField(
      'order_2',
      'Objetivo 2',
      'group',
      'full-width',
      [],
      '',
      null,
      [
        createFormField('amount_2', 'Monto', 'number', 'half-width', []),
        // createFormField('product_id', 'Rol', 'select', 'half-width', []),
      ]
    ),
    createFormField(
      'order_3',
      'Objetivo 3',
      'group',
      'full-width',
      [],
      '',
      null,
      [
        createFormField('amount_3', 'Monto', 'number', 'half-width', []),
        // createFormField('product_id', 'Rol', 'select', 'half-width', []),
      ]
    ),
    createFormField(
      'order_4',
      'Objetivo 4',
      'group',
      'full-width',
      [],
      '',
      null,
      [
        createFormField('amount_4', 'Monto', 'number', 'half-width', []),
        // createFormField('product_id', 'Rol', 'select', 'half-width', []),
      ]
    ),
    createFormField(
      'order_5',
      'Objetivo 5',
      'group',
      'full-width',
      [],
      '',
      null,
      [
        createFormField('amount_5', 'Monto', 'number', 'half-width', []),
        // createFormField('product_id', 'Rol', 'select', 'half-width', []),
      ]
    ),
  ];
};

export const USE_FORM_EDIT_BALANCE = () => {
  const { t, ready } = useTranslation();
  if (!ready) return [];
  return [
    createFormField(
      'point_of_sale_id',
      t('point_of_sale', 'Punto de venta'),
      'select',
      'full-width',
      []
    ),
    createFormField(
      'user_open',
      t('users.user_open', 'Usuario Apertura'),
      'select',
      'full-width'
    ),
    createFormField(
      'user_close',
      t('users.user_close', 'Usuario Cierre'),
      'select',
      'full-width'
    ),
    createFormField(
      'balance_opened_amount',
      t('balance.total_opened', 'Total Apertura'),
      'number',
      'full-width'
    ),
    createFormField(
      'total_closed',
      t('balance.close_amount', 'Total Cierre'),
      'number',
      'full-width'
    ),
  ];
};
