import React, { useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import CardRounded from '../CardRounded';
import { t } from 'i18next';
import { Tag, Tooltip } from 'antd';
import { useMemberCreditCards } from '../../api/mp';
import { formatExtraInfo } from '../../services/membersUtils';
import SpinIndicador from './SpinIndicador';
export default function PaymentMethodsMember({
  owner_user_id,
  selectedPaymentMethods,
  setSelectedPaymentMethods,
  openAddCreditCard,
  setopenAddCreditCard,
  index,
  paymentMethod
}) {
  const { data: credit_cards, isLoading } = useMemberCreditCards(owner_user_id);
  useEffect(() => {
    if (credit_cards && !isLoading) {
      if (credit_cards.length == 1) {
        let updateMethod = {...paymentMethod, credit_card_id: credit_cards[0].id}
        setSelectedPaymentMethods( selectedPaymentMethods.map((pm,i) => {
          if(i == index) {
            return updateMethod
          }
          return pm
        }
        ))
      } else if (credit_cards.length > 1) {
        let updateMethod = {...paymentMethod, credit_card_id: credit_cards.find((cc) => cc.preferred).id}
        setSelectedPaymentMethods( selectedPaymentMethods.map((pm,i) => {
          if(i == index) {
            return updateMethod
          }
          return pm
        }
        ))
}
    }
  }, [credit_cards,isLoading]);
  const addButton = () => {
    return (
      <Tooltip title={t('sales.add_paymentMethod', 'Agregar forma de pago')}>
        <div
          onClick={() => setopenAddCreditCard(true)}
          className='mt-4 self-center h-8 w-8 bg-lightGreen rounded-full items-center justify-center flex  cursor-pointer'
        >
          <AiOutlinePlus color='#111' size={22} />
        </div>
      </Tooltip>
    );
  };
 if (isLoading) return <div className='pt-8'>
  <SpinIndicador />
 </div>
  // console.log(formatExtraInfo(credit_cards[0].extra_info))
  return (
    <div className={`pt-8 ${openAddCreditCard && 'hidden'}`}>
      <h2 className='text-xl font-MessinaSansSemiBold mb-5'>Forma de pago</h2>
      <div className='flex flex-items gap-5'>
        {credit_cards.length == 0 && (
          <CardRounded
            styles={` flex flex-col bg-primaryGrey items-center justify-center max-w-max  border-2 border-darkGreen border-dashed  py-5 px-5`}
          >
            No hay tarjetas guardadas
            <button
              onClick={() => setopenAddCreditCard(true)}
              className='mt-2 text-darkGreen text-center underline'
            >
              Agregar nueva tarjeta
            </button>
          </CardRounded>
        )}
        {credit_cards?.map((cc) => (
          <CardRounded
          key={cc.id}
            onClick={() => {
              setSelectedPaymentMethods(selectedPaymentMethods.map((pm,i) => {
                if (pm.credit_card_id == cc.id) {
                  return {
                    ...pm,
                    credit_card_id: null
                  }
                }
                if(i == index) {
                  return {
                    ...pm,
                    credit_card_id: cc.id
                  }
                } 
                
                return pm
              }))
            }}
            styles={`cursor-pointer bg-primaryGrey max-w-max  border-2 ${
              cc.id == selectedPaymentMethods[index].credit_card_id
                ? 'border-darkGreen'
                : 'border-transparent'
            }  py-5 `}
          >
            <div className='flex flex-col  items-start gap-2 px-4'>
              <div className=''>
                {!formatExtraInfo(cc.extra_info) ? (
                  <span>ID: {cc.id}</span>
                ):  <img
                width={50}
                height={50}
                style={{ objectFit: 'contain', minHeight: 50 }}
                //  className=' object-contain'
                src={
                  formatExtraInfo(cc.extra_info)?.payment_method?.secure_thumbnail
                }
                alt='American_express'
              />}
              
              </div>
              <div className=' items-center gap-1'>
                <p>
                  {cc.card_brand}{' '}
                  {formatExtraInfo(cc.extra_info)?.first_six_digits} ***{' '}
                  {cc.last_four}
                </p>
                <p>Vence {cc.expiration_date}</p>
                {cc.preferred && (
                  <Tag className='mt-1' color={cc.preferred && 'green'}>
                    {' '}
                    {cc.preferred && 'Preferida'}
                  </Tag>
                )}
              </div>
            </div>
          </CardRounded>
        ))}
        {credit_cards.length > 0 && addButton()}
      </div>
    </div>
  );
}
