export const getLocationId = () => {
  if (window.location.pathname == '/mp') return null;
  const value = window.localStorage.getItem('user');
  if (value) {
    const user = JSON.parse(value);

    if (user) {
      return user.location_id;
    }
  }
  return null;
};
export const getCountry = () => {
  if (window.location.pathname == '/mp') return null;
  const value = window.localStorage.getItem('user');
  if (value) {
    const user = JSON.parse(value);
    if (user) {
      return user.country;
    }
  }
  return null;
};
export const getLocationName = () => {
  const value = window.localStorage.getItem('user');
  const user = JSON.parse(value);
  return user.location_name;
};

export const getMaster_account_id = () => {
  const value = window.localStorage.getItem('user');
  const user = JSON.parse(value);
  return user.master_account_id;
};

export const getCurrency = () => {
  const value = window.localStorage.getItem('user');
  const user = JSON.parse(value);
  if (user) {
    return user.country?.currency;
  }
  return 'ARS';
};
export const getCurrencyLocale = () => {
  const value = window.localStorage.getItem('user');
  const user = JSON.parse(value);
  if (user && user.country?.currency_locale) {
    return user.country?.currency_locale;
  }
  return 'es-AR';
};
export const getTimeZone = () => {
  const value = window.localStorage.getItem('user');
  const user = JSON.parse(value);
  if (user) {
    return user.country?.time_zone;
  }
  return 'America/Buenos_Aires';
};
export const getLocationRegionConfig = (locations) => {
  const location_id = getLocationId();
  const foundLocation = locations.find((country) =>
    country.locations.find((location) => location.id == location_id)
  );
  return (
    foundLocation?.locations.find((location) => location.id == location_id)
      ?.region_config_types || []
  );
};
export const getLocationCountry = (locations, location_id) => {
  const foundLocation = locations.find((country) =>
    country.locations.find((location) => location.id == location_id)
  );
  return foundLocation;
};
export const getLocationById = (locations, location_id) => {
  if (!locations) return null;
  for (const country of locations) {
    const location = country.locations.find(
      (location) => location.id == location_id
    );
    if (location) {
      return location;
    }
  }
  return null; // Retorna null si no se encuentra la ubicación
};

export const mappedLocations = (locations) => {
  var mappedLocations = locations.reduce((acumulador, objeto) => {
    var { country_name, time_zone } = objeto;
    var objetoEncontrado = acumulador.find(
      (elem) => elem.country === country_name
    ) || { country: country_name, time_zone: time_zone, locations: [] };
    objetoEncontrado.locations.push(objeto);
    return acumulador.includes(objetoEncontrado)
      ? acumulador
      : [...acumulador, objetoEncontrado];
  }, []);
  // Sort the locations by ascending order
  mappedLocations
    .sort((a, b) => {
      if (a.country < b.country) {
        return -1;
      }
      if (a.country > b.country) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      //now sort a.locations and b.locations by location.name
      a.locations.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      b.locations.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

  return {
    mappedLocations,
    locations,
  };
};
