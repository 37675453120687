import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import SideBar from './SideBar';
import { Outlet, useLoaderData } from 'react-router-dom';
import ModalSales from '../components/Desktop/ModalSales';
import ModalMember from '../pages/ModalMember';
import { Analytics } from '@vercel/analytics/react';
import CreateProspect from '../pages/CreateProspect';
import ModalOpenSales from '../components/Desktop/ModalOpenSales';
import ModalTransaction from '../components/Desktop/ModalTransaction';
import ScrollToTop from './ScrollToTop';
import { notification } from 'antd';
import ModalCRM from '../components/ModalMembers/ModalCRM';
import { useCRMOptions } from '../services/utils/CRM_OPTIONS';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { useGlobalContext } from '../context/GlobalState';
import ModalReturn from '../components/ModalMembers/ModalReturn';
import WhatsAppSvg from '../assets/icons/WhatsAppSvg';
import ProspectsSvg from '../assets/icons/ProspectsSvg';
import { t } from 'i18next';
import { useQueryClient } from '@tanstack/react-query';
import { getLocationId } from '../services/utils/getLocation';
import actioncable from 'actioncable';
import { Helmet } from 'react-helmet-async';


export default function Layout() {
  const queryClient = useQueryClient();
  const { state, dispatch } = useGlobalContext();
  const [isReady, setIsReady] = useState(false);

  const data = useLoaderData();

  const CRM_OPTIONS = useCRMOptions();
  const [isOpenMobileMenu, setisOpenMobileMenu] = useState(false)
  const [modalSale, setmodalSale] = useState(false);
  const [openSales, setopenSales] = useState(false);
  const [openReturn, setopenReturn] = useState(false);
  const [modalTransaction, setmodalTransaction] = useState(false);
  const [modalMemberOpen, setModalMemberOpen] = useState({
    id: null,
    isProspect: false,
    extra: null,
    customer_type:''
  });
  const handleOpenReturn = (sale) => {
    if (!openReturn) {
      setopenReturn(sale);
      return;
    }
    setopenReturn(false);
  };
  const [typeMemberCreate, setTypeMemberCreate] = useState(false);
  const [openCRM, setOpenCRM] = useState({ visible: false });
  const [api, contextHolder] = notification.useNotification();
 
  const openNotification = (type, description, title) => {
    api[type]({
      message: title
        ? title
        : type == 'success'
        ? t('operation_success', 'Operación exitosa')
        : t('operation_error', 'Ocurrió un error'),
      ...openNotificationFields(type, description),
    });
  };
  const openNotificationProspect = (data) => {
    api['info']({
      placement: 'bottomRight',
      duration: null,
      closeIcon: null,
      message: t('new_prospect', 'Nuevo Prospecto'),
      icon: <ProspectsSvg bg={'#1EA05A'} />,
      style: { backgroundColor: '#CEF5CE', color: '#000' ,width:430},
      description: (
        <div className='flex flex-row items-center justify-between'>
          <div className='w-[60%]'>
            <p className='text-xl font-MessinaSansBold'>
              {data.first_name + ' ' + data.last_name}
            </p>
            <p>{data.email}</p>
            <p>{data.phone}</p>
          </div>
          <div>
          <button
            onClick={() => {
              setModalMemberOpen({
                id: data.id,
                isProspect: true,
                customer_type:'Prospect'
              });
              api.destroy();
            }}
            className='text-darkGreen hover:text-white hover:bg-darkGreen ease-in-out transition-all duration-300 flex flex-row items-center border-2 border-darkGreen pl-2 w-full mb-4 h-10 justify-center rounded-md'
          >
       
            <p className='  font-MessinaSansBold uppercase'>VER PERFIL</p>
          </button>
          <button
            onClick={() => {{
              
              window.open('https://wa.me/' + data.phone, '_blank')
              api.destroy();

            }}}
            className=' flex flex-row items-center bg-darkGreen text-white pl-2 pr-5 h-10 justify-center rounded-md'
          >
            {' '}
            <WhatsAppSvg bg={'#1EA05A'} />
            <p className='font-MessinaSansBold uppercase'>Contactar</p>
          </button>
          </div>
        </div>
      ),
    });
  };
  useEffect(() => {
    console.log('state.user',state.user);
    if (!state.user) {
      setIsReady(true);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: data.fetch_me,
          roles: data.fetch_me.roles,
        },
      });
   
    } else {

      setIsReady(true);
    }
    const CableApp = {};
    const cableUrl = process.env.cable_url;

    CableApp.cable = actioncable.createConsumer(
      `${cableUrl}?token=${data.fetch_me.single_access_token}`
    );
    const chatChannel = CableApp.cable.subscriptions.create(
      { channel: 'LeadsChannel', location_id: getLocationId() },
      {
        received(data) {
            queryClient.invalidateQueries({
              queryKey:['prospects_no_contact',getLocationId()]
            });
            openNotificationProspect({...data.user,id:data.prospect.id})
        },
        connected() {
          console.log('Conectado al canal!', chatChannel);
        },
        disconnected() {
          console.log('Desconectado del canal!');
        },
      }
    );
    return () => {
      chatChannel.unsubscribe();
    };
  }, []);
  if (!isReady) {
    return <div>Cargando...</div>; // O un componente de carga según tu diseño
  }
  return (
    <>
      <Helmet>
        <meta property="og:title" content={'BIGG'} />
        <meta property="og:description" content={'BIGG EYE'} />
        <meta property="og:image" content="/src/assets/images/iconios.png" />
<meta property="og:url" content="/src/assets/images/iconios.png" />
        <title>BIGG EYE</title>
      </Helmet>
  
    <div className=' bg-background flex flex-auto overflow-x-hidden'>
      <ScrollToTop />
      <Analytics />

      <SideBar
           isOpenMobileMenu={isOpenMobileMenu}
           setisOpenMobileMenu={setisOpenMobileMenu}
      />

      <main className='w-full   lg:ml-[100px] last:mt-20'>
        {contextHolder}
        {/* <button  onClick={handleStartAudio1} >sonar sonido</button> */}

        <Header
        isOpenMobileMenu={isOpenMobileMenu}
        setisOpenMobileMenu={setisOpenMobileMenu}
          setTypeMemberCreate={setTypeMemberCreate}
          openSales={openSales}
          setopenSales={setopenSales}
          setModalMemberOpen={setModalMemberOpen}
        />
        <ModalReturn
          openNotification={openNotification}
          sale={openReturn}
          setClose={handleOpenReturn}
          dischargeReasons={[]}
        />
        <ModalSales
          openNotification={openNotification}
          modalSale={modalSale}
          setmodalSale={setmodalSale}
        />

        <ModalMember
          modalMemberOpen={modalMemberOpen}
          setModalMemberOpen={setModalMemberOpen}
        />
        <ModalCRM
          visible={openCRM?.visible}
          CRM_OPTIONS={CRM_OPTIONS}
          handleOpenCRM={() => setOpenCRM(!openCRM.visible)}
          type={openCRM?.type}
          start={openCRM?.start}
          end={openCRM?.end}
          openNotification={openNotification}
        />
        <ModalOpenSales
          openNotification={openNotification}
          openSales={openSales}
          setopenSales={setopenSales}
        />
        <ModalTransaction
          modalTransaction={modalTransaction}
          setmodalTransaction={setmodalTransaction}
          openNotification={openNotification}
        />
        <CreateProspect
          setModalMemberOpen={setModalMemberOpen}
          typeMemberCreate={typeMemberCreate}
          setTypeMemberCreate={setTypeMemberCreate}
        />
        <Outlet
          context={[
            modalMemberOpen,
            setModalMemberOpen,
            setmodalSale,
            setmodalTransaction,
            setTypeMemberCreate,
            setOpenCRM,
            openCRM,
            handleOpenReturn,
          ]}
        />
      </main>
    </div>
    </>
  );
}
