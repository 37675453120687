import React, { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { MdCheck, MdOutlineFrontHand } from 'react-icons/md';
import { Tooltip, Popconfirm, Tag } from 'antd';
import { formatSuscriptionDaysLeft } from '../../services/membersUtils';
import imgUrl from '../../assets/images/iconios.png';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';

export const ReserveList = ({
  filteredReserves,
  useDelete_member_gym_class,
  usePatch_member_gym_class,
  setModalMemberOpen,
  checkAttendance,
  wait_list_members,
  toggle_wait_list
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  const cancel = (e) => {
    setOpen(false);
  };

  const confirm = async (reserve) => {
    setConfirmLoading(true);
    try {
      const result = await useDelete_member_gym_class.mutateAsync(reserve);
      console.log('result', result);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
    // setConfirmLoading(true);
  };
  const reservesToShow = toggle_wait_list ? wait_list_members : filteredReserves;
   if (reservesToShow.length == 0) {
    return (
      <div className='h-full m-auto flex jic p-20'>
              <h2 className='text-center m-auto'>
                { 
                toggle_wait_list ? t('reserves.no_wait_list','No hay socios anotados en lista de espera') :
                t('reserves.no_reserves','No hay socios anotados en esta clase')}
              </h2>
            </div>
    );
  }
  return reservesToShow.map((reserve, i) => {
    if (!reserve) return;
    if (!reserve.subscription) return;
    return (
      <div
        key={i}
        className={`px-5 py-5 border-b-[1px] last:border-none last:pb-7 border-b-primaryGrey flex flex-row items-center hover:cursor-pointer hover:bg-primaryGrey smooth-t ${
          reserve?.subscription?.owner_type == 'Prospect' && 'bg-lightGreen'
        }`}
        onClick={() =>
          setModalMemberOpen({
            id: reserve?.subscription?.owner_id,
            isProspect:
              reserve?.subscription?.owner_type == 'Member' ? false : true,
            customer_type: reserve?.subscription?.owner_type,
          })
        }
      >
        {toggle_wait_list && <div className='h-8 w-8 bg-primaryRed rounded-full items-center justify-center flex mr-2'>
          <p className='font-DrukWideMedium'>{i + 1}</p>
        </div>}
        <img
          className='h-12 w-12 rounded-full object-cover'
          onError={(e) => {
            e.target.src = imgUrl;
          }}
          src={`https://bigg-app-profile-prod.s3.amazonaws.com/new_profile_pictures/${reserve?.subscription?.owner_id}`}
          alt='description of image'
        />
        {/* <div className='h-12 w-12 bg-white rounded-full'></div> */}
        <div className='mx-4'>
          <p
            className={` mb-1 text-md lg:text-lg ${
              reserve?.subscription?.owner_type == 'Prospect' &&
              'text-darkGreen'
            }`}
          >
            {reserve?.subscription?.owner.first_name +
              ' ' +
              reserve?.subscription?.owner.last_name}
          </p>
          <p
            className={`text-xs  ${
              reserve?.subscription?.owner_type == 'Prospect'
                ? 'text-darkGreen'
                : 'text-secondaryGrey'
            } `}
          >
            N° {reserve?.subscription?.owner_id} |{' '}
            {reserve?.subscription?.location_name}
            {reserve.member_state && (
            <Tooltip title={reserve.member_state == "high_risk" ?  t('no_training_in_30_days','No entrena hace 30 dias'): reserve.member_state == 'risk'? t('trained_5_times','Entreno 5 veces o menos') : reserve.member_state == 'good' ? t('trained_5_8','Entreno entre 5 y 8 veces'):t('trained_more_8','Entreno mas de 8 veces')} >
            <Tag
            color={
              reserve.member_state == 'high_risk'
                ? 'red'
                : reserve.member_state == 'risk'
                ? 'orange'
                : reserve.member_state == 'good'
           ? 'blue'
                : 'green'
            }
            className={`text-xs ${!isMobile ? 'ml-2' : 'mt-1'} items-center justify-center text-center`}>
              {t(reserve.member_state)}
            </Tag>
          </Tooltip>
          )}
          </p>
        </div>
        {reserve?.reserves_count <= 15 && (
          <Tooltip
            title={t(
              'bigginner_reserve',
              'Este socio es BIGGinner por contar con menos de 15 reservas'
            )}
          >
            <div className='ml-2 bg-primaryRed h-6 w-6  flex items-center font-MessinaSansSemiBold text-sm text-black justify-center rounded-full'>
              B
            </div>{' '}
          </Tooltip>
        )}
        {reserve?.subscription?.owner_type == 'Member' && (
          <div className='ml-5 flex gap-2 desktop'>
            <Tooltip title={t('product.sessions_left', 'Sesiones restantes')}>
              <span className='font-MessinaSansSemiBold '>
                {reserve?.subscription?.sessions_left}
              </span>
            </Tooltip>
            <span>/</span>
            <Tooltip title={t('days_left', 'Días restantes')}>
              <span className='font-MessinaSansSemiBold'>
                {formatSuscriptionDaysLeft(
                  reserve?.subscription?.started_at,
                  reserve?.subscription?.days_left,
                  reserve?.subscription?.owner_id
                )}
              </span>
            </Tooltip>
          </div>
        )}
        {reserve.subscription.owner.coach && (
          <Tooltip title={t('assign_coach', 'Coach asignado')}>
            <p className={`ml-5 text-xs ${ reserve?.subscription?.owner_type == 'Prospect' ? 'text-darkGreen': 'text-secondaryGrey'} opacity-60 cursor-pointer font-MessinaSansSemiBold`}>
              {reserve.subscription.owner.coach.first_name +
                ' ' +
                reserve.subscription.owner.coach.last_name}
            </p>
          </Tooltip>
        )}

        {reserve?.subscription?.owner_type == 'Prospect' && (
          <div className=' border-darkGreen border-[1px] rounded-full px-3 py-2 ml-5'>
            <span className=' font-MessinaSansSemiBold text-darkGreen text-md uppercase'>
              {t('cdp', 'clase de prueba')}
            </span>
          </div>
        )}     
        <div className='flex flex-row gap-3 ml-auto items-center'>
        
          <div
            onClick={(e) => {
              e.stopPropagation();
              usePatch_member_gym_class.mutate(reserve);
            }}
            className={`rounded-full  ${
              reserve?.has_attended
                ? 'border-darkGreen'
                : reserve?.subscription?.owner_type == 'Prospect'
                ? 'border-primaryGrey'
                : 'border-secondaryGrey'
            } border-[1px] w-fit h-fit p-2  cursor-pointer hover:opacity-60 ease-in-out duration-200`}
          >
            <Tooltip
              title={`${
                !reserve?.has_attended
                  ? t('mark_present', 'Marcar presente')
                  : t('mark_absent', 'Marcar ausente')
              }`}
            >
              {reserve?.has_attended ? (
                <MdCheck size={isMobile ? 15 : 25} color='#1EA05A' />
              ) : (
                <MdOutlineFrontHand
                  size={isMobile ? 15 : 25}
                  color={
                    reserve?.subscription?.owner_type == 'Prospect'
                      ? '#444444'
                      : '#CCCCCC'
                  }
                />
              )}
            </Tooltip>
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className=' rounded-full  border-primaryRed border-[1px] w-fit h-fit p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'
          >
            {/* <Tooltip title="Cancelar reserva"> */}
            <Popconfirm
              title={t(
                'reserves.delete_title',
                '¿Estás seguro de cancelar la reserva?'
              )}
              description={t(
                'reserves.delete_description',
                'Se agregará una sesión al socio.'
              )}
              onConfirm={() => confirm(reserve)}
              onCancel={cancel}
              okText={t('accept', 'Aceptar')}
              open={open == reserve.id}
              cancelText={t('cancel', 'Cancelar')}
              okButtonProps={{
                loading: confirmLoading,
                className:
                  'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
              }}
            >
              <BiTrash
                size={isMobile ? 15 : 25}
                color='#ED4C5C'
                onClick={() => setOpen(reserve.id)}
              />
              {/* onClick={()=>setOpen(true)} */}
            </Popconfirm>
            {/* </Tooltip> */}
          </div>
        </div>
      </div>
    );
  });
};
