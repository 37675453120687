import React from 'react' 
import dayjs from 'dayjs'
import { isUserAdmin } from '../../../services/can_user';
import { getLocationById, getLocationId } from '../../../services/utils/getLocation';
import { useGlobalContext } from '../../../context/GlobalState';


export default function ConvertionTooltip({active,payload,view}) {
  const {state}  = useGlobalContext()
    if (active && payload && payload?.length > 0) {
      console.log('Object.keys(payload[0]?.payload)',Object.keys(payload[0]?.payload));
        return (
          <div className=' bg-background px-10 py-6 rounded-md '>
            <p className='label font-MessinaSansBold capitalize'>{`Mes: ${dayjs(
              payload[0]?.payload?.date
            ).format('MMMM YYYY')}`}</p>
{view !== 'General' ? (
Object.keys(payload[0]?.payload)
.filter((key) => !key.includes('converted-') && key !== 'date' && key !== 'month' && key !== 'year')
.sort((a, b) => (payload[0]?.payload[a] > payload[0]?.payload[b] ? -1 : 1))
.map((key) => {
if (payload[0]?.payload[key] > 0) {
  return (
    <p className='label text-[#2BE947]' key={key}>
      {`${key} : ${payload[0]?.payload[key]}`}
    </p>
  );
}
return null;
})
) : (
<>
{Object.keys(payload[0]?.payload)
.filter((key) => key.includes('c-'))
.sort((a, b) => (payload[0]?.payload[a] > payload[0]?.payload[b] ? -1 : 1))
.map((key) => {
  if (payload[0]?.payload[key] > 0) {
    return (
      <React.Fragment key={key}>
        <p className='label text-[#FFA500]'>
          {`${key.slice(2)} : ${payload[0]?.payload[key]}`}
          <span className='text-white'> / </span>
          <span className='text-[#2BE947]'>
            {payload[0].payload[`converted-${key.replace('c-', '')}`]}
          </span>
        </p>
        {key.slice(2) === 'WEB' &&  (isUserAdmin() || [1,7].includes(getLocationById(state.locations,getLocationId())?.location_category_id))   &&(
          <div className='flex flex-col ml-2 capitalize text-[#FFA500] text-sm opacity-90'>
            {Object.keys(payload[0]?.payload)
              .filter((key) => key.includes('u-'))
              .map((key) => (
                <p className='label text-[#FFA500]'>
                {`${key.slice(2)} : ${payload[0]?.payload[key]}`}
                <span className='text-white'> / </span>
                <span className='text-[#2BE947]'>
                  {payload[0].payload[`converted-${key.replace('u-', '')}`] || 0}
                </span>
              </p>
                // <p key={key}>{`${key.slice(2)} : ${payload[0]?.payload[key]}`}</p>
                // <span className='text-white'> / </span>

              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
  return null;
})}
<p className={`label text-[#2BE947] mt-2`}>
{`Convertidos : ${payload[0].value}`}
</p>
<p className='label font-MessinaSansBold'>
{`Tasa conversión ${(payload[0].value / payload[1]?.value * 100).toFixed(1)}% `}
</p>
</>
)}

          </div>
        );
      }
}
