import React, { useMemo } from 'react';
import { useGetDischargeReasons } from '../../../api/prospects';
import SpinIndicador from '../SpinIndicador';
import { t } from 'i18next';

export default function PopProspects({ data, title,isActive }) {
  const { data: dischargeReasons, isLoading: isLoadingDischarge } =
    useGetDischargeReasons(7);

  const dischargeReasonsWithCount = useMemo(() => {
    if (isLoadingDischarge) return [];
    // 1. Calcular las cuentas de cada motivo de descarga en data
    if (!data) return [];
    const counts = data?.reduce((acc, d) => {
      if (!acc[d.discharge_reason_id]) {
        acc[d.discharge_reason_id] = 0;
      }
      acc[d.discharge_reason_id] += 1;
      return acc;
    }, {});

    // 2. Crear una nueva lista incluyendo la cuenta de elementos coincidentes
    return dischargeReasons.map((reason) => ({
      ...reason,
      count: counts[reason.id] || 0,
      percentage: (((counts[reason.id] || 0) / data.length) * 100).toFixed(1),
    }));
  }, [data, dischargeReasons, isLoadingDischarge]);

  // 3. Ordenar la lista basado en la cuenta
  const sortedDischargeReasons = useMemo(() => {
    return [...dischargeReasonsWithCount].sort((a, b) => b.count - a.count);
  }, [dischargeReasonsWithCount]);

  if (isLoadingDischarge) return <SpinIndicador />;

  // 4. Mapear sobre la lista ordenada para renderizar
  return (
    <div className='flex flex-col p-0 m-0'>
      <h2 className={`text-lg ${isActive ? 'text-blak':'text-white'} font-MessinaSansSemiBol text-center`}>
        {title}
      </h2>
      {sortedDischargeReasons.map((reason, i) => (
        <div
          key={reason.id}
          className={`${
            i < sortedDischargeReasons.length - 1
              ? 'border-b-primaryGrey border-b-[1px] '
              : 'border-b-transparent'
          } py-2 flex flex-row justify-between`}
        >
          <p className='text-white mr-4'>
            {reason.name + ` (Tasa ${reason.percentage}%)`}
          </p>
          <p className='text-white font-DrukWideMedium'>{reason.count}</p>
        </div>
      ))}
    </div>
  );
}
