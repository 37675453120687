

import React from 'react'

export default function SaleSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.2764" cy="17.6279" r="17.2764" fill="#888888" fill-opacity="0.533333"/>
<path d="M15.2058 24.0522C15.7126 24.0522 16.1235 23.6413 16.1235 23.1345C16.1235 22.6277 15.7126 22.2168 15.2058 22.2168C14.699 22.2168 14.2881 22.6277 14.2881 23.1345C14.2881 23.6413 14.699 24.0522 15.2058 24.0522Z" fill="black" stroke="black" stroke-width="0.5"/>
<path d="M21.6296 24.0522C22.1364 24.0522 22.5473 23.6413 22.5473 23.1345C22.5473 22.6277 22.1364 22.2168 21.6296 22.2168C21.1228 22.2168 20.7119 22.6277 20.7119 23.1345C20.7119 23.6413 21.1228 24.0522 21.6296 24.0522Z" fill="black" stroke="black" stroke-width="0.5"/>
<path d="M23.465 13.4984H13.2877L12.9114 11.5713C12.89 11.4661 12.8323 11.3717 12.7485 11.3047C12.6646 11.2376 12.5599 11.202 12.4526 11.2042H10.6172V12.1219H12.0763L13.8291 20.9318C13.8506 21.037 13.9083 21.1314 13.9921 21.1985C14.0759 21.2655 14.1806 21.3011 14.288 21.2989H22.5473V20.3812H14.6643L14.288 18.5458H22.5473C22.6534 18.5484 22.7571 18.5142 22.8407 18.4489C22.9244 18.3836 22.9828 18.2914 23.0062 18.1879L23.9239 14.0582C23.9392 13.9902 23.9389 13.9195 23.9228 13.8515C23.9067 13.7836 23.8754 13.7203 23.8312 13.6663C23.7869 13.6123 23.7309 13.5691 23.6675 13.54C23.604 13.511 23.5348 13.4967 23.465 13.4984ZM22.1802 17.6281H14.1136L13.4712 14.4162H22.8914L22.1802 17.6281Z" fill="black" stroke="black" stroke-width="0.5"/>
</svg>
  )
}
