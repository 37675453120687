import { Select } from 'antd';
import React, { useMemo } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';

import { useGetCoaches } from '../../api/coaches';
import { t } from 'i18next';
export default function SelectCoach({
  setValue,
  value,
  isProspect,
  disabled,
  coach_location,
  classNames,
  initialCoach,
  isLoading,
}) {
  const { isLoading: isLoadingCoaches, data: coaches } =
    useGetCoaches(coach_location);

  const selectRef = React.useRef();
  const filteredCoaches = useMemo(() => {
    let c = coaches?.filter((coach) => coach.active);
    if (!c) return [];
    if (!c.find((coach) => coach.id == value) && initialCoach) {
      let formatCoach = {
        id: value,
        user: initialCoach,
        ...initialCoach,
        active: true,
      };
      c.push(formatCoach);
    }
    return c;
  }, [coaches, value]);
  return (
    <Select
      ref={selectRef}
      // defaultValue={value}
      disabled={disabled}
      placeholder={
        <div className='flex flex-row items-center gap-2'>
          <AiOutlineUser />
          {isLoading
            ? t('loading', 'Cargando...')
            : (!value
                ? t('assing_to_coach', 'Asignar coach')
                : t('assign_coach', 'Coach asignado'))}
                {isProspect &&
              ' clase de prueba'}
        </div>
      }
      bordered={false}
      value={value}
      onSelect={(newValue) => {
        if (newValue == value) {
          setValue(undefined);
        }
      }}
      onChange={(newValue, option) => {
        setValue(newValue, option);
        selectRef?.current.blur();
      }}
      suffixIcon={disabled ? <></> : <BsChevronDown color='#ADFF19' />}
      optionLabelProp='title'
      className={`mt-10  relative bg-primaryDark rounded-md  py-3 px-5  flex flex-row items-center justify-between ${classNames}`}
      loading={isLoadingCoaches}
      options={filteredCoaches?.map((user) => ({
        label: user.first_name + ' ' + user.last_name,
        value: user.id,
        disabled: !user.active,
        title: !user.active
          ? t('inactive', 'Inactivo')
          : t('assign_coach', 'Coach asignado') +
            (isProspect ? ` ${t('cdp', 'clase de prueba')}: ` : ': ') +
            user.first_name +
            ' ' +
            user.last_name,
            coach_user_id: user.user_id,
      }))}
    />
  );
}
