import React from 'react';

export default function FitnessSvg(props) {
  const color = props.color || "#ADFF19";
  const personalColor = props.personalColor || "#66970E"
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.6162" cy="17.7119" r="17.2764" fill={color}/>
    <path d="M23.6256 13.8992C23.4716 14.2035 23.2666 14.4831 23.0754 14.7683C22.7628 15.2353 22.445 15.6989 22.1253 16.1614C21.7959 16.6382 21.4578 16.7079 20.9571 16.4118C20.4635 16.1198 19.9691 15.8301 19.4523 15.5262C18.8747 16.5179 18.3039 17.4972 17.7222 18.4957C18.1259 18.8945 18.5213 19.2839 18.9156 19.6748C19.1419 19.8989 19.3668 20.1245 19.5917 20.3505C19.9837 20.7445 20.086 21.3696 19.8143 21.8557C19.3267 22.7279 18.8335 23.5974 18.3282 24.4591C17.9838 25.046 17.1986 25.0475 16.8958 24.4711C16.7597 24.2121 16.753 23.9486 16.9085 23.6859C17.3189 22.9929 17.7248 22.2965 18.1322 21.6016C18.166 21.5435 18.1982 21.4843 18.2361 21.4168C17.7533 20.9986 17.2792 20.5934 16.8118 20.1804C16.4655 19.8746 16.1143 19.5728 15.7901 19.2445C15.2673 18.7146 15.1151 18.1175 15.4854 17.3679C15.9056 16.5175 16.2601 15.6349 16.6424 14.7664C16.6544 14.7395 16.66 14.7099 16.6761 14.6574C16.3561 14.7046 16.054 14.7428 15.7553 14.7987C15.6961 14.8096 15.6357 14.8804 15.5994 14.9385C15.3535 15.3298 15.1177 15.7274 14.87 16.1172C14.7977 16.2315 14.71 16.344 14.6065 16.4294C14.3325 16.6554 13.999 16.6565 13.7464 16.4515C13.4937 16.2469 13.43 15.9508 13.6036 15.6266C13.8869 15.0985 14.1886 14.5802 14.4828 14.0577C14.5409 13.9543 14.5971 13.8493 14.6601 13.7489C14.825 13.4858 15.0608 13.3186 15.3677 13.2725C16.1582 13.1533 16.9482 13.0319 17.7409 12.9311C18.3496 12.8535 18.9193 13.0158 19.4519 13.3014C20.1217 13.6604 20.6437 14.2043 21.1861 14.7211C21.2168 14.7507 21.2494 14.7784 21.2993 14.8227C21.3828 14.7057 21.4623 14.5974 21.5384 14.4872C21.8292 14.0652 22.1174 13.6421 22.409 13.2208C22.6459 12.8786 23.0132 12.7725 23.3344 12.9502C23.6856 13.1436 23.819 13.5176 23.6256 13.8992Z" fill={personalColor}/>
    <path d="M16.5428 20.5947C16.4611 20.7101 16.3686 20.833 16.2846 20.962C15.3315 22.4158 14.1258 23.6579 12.9542 24.9281C12.6592 25.2478 12.2589 25.3081 11.9175 25.1136C11.6128 24.94 11.4501 24.5656 11.5479 24.2111C11.5813 24.0904 11.6495 23.9667 11.7342 23.8745C12.2911 23.2673 12.874 22.6838 13.4122 22.0609C13.67 21.7625 13.8537 21.399 14.0617 21.059C14.4294 20.4582 14.7895 19.8529 15.1433 19.2656C15.6171 19.7154 16.0717 20.1471 16.5428 20.5947Z" fill={personalColor}/>
    <path d="M20.7706 11.4038C20.7653 12.1444 20.0854 12.8524 19.376 12.8562C18.7081 12.8599 18.1406 12.2543 18.1406 11.5376C18.1406 10.821 18.7429 10.2034 19.4757 10.1992C20.1851 10.1947 20.7754 10.7438 20.7706 11.4038Z" fill={personalColor}/>
    </svg>
    
  );
}
