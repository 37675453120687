import React from 'react';
import Drawer from './helpers/Drawer';
import { AiOutlineClose } from 'react-icons/ai';
import { t } from 'i18next';


export default function DrawerForm({
  openDrawer,
  handleOpenDrawer,
  width,
  children,
  title,
}) {
  if(!openDrawer) return null

  return (
    <Drawer
    
    openDrawer={openDrawer.visible} setopenDrawer={()=>handleOpenDrawer(false,null)} width={width}
    >
      <div className='mb-8 border-primaryGrey border-b-[1px] flex p-2 jic pb-4'>
        <h2 className='text-3xl font-MessinaSansSemiBold'>{openDrawer.record ?t('edit','Editar'):t('new','Nuevo')} {title}</h2>
        <button className='' onClick={() => handleOpenDrawer(false,null)}>
          <AiOutlineClose
            onClick={() => handleOpenDrawer(false,null)}
            color='#fff'
            size={30}
          />
        </button>
      </div>
      {children}
    </Drawer>
  );
}
