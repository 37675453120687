import React, { useMemo } from 'react';
import SpinIndicador from '../SpinIndicador';

export default function PopProspectsConverted({ data }) {
const convertedMembers = useMemo(() => {
    // 1. Calcular las cuentas de cada motivo de descarga en data
    if(!data) return [];
    let convertedList = data?.filter((m)=>m.converted_subscription &&   ![33,32,207].includes(m.converted_subscription?.membership?.product.parent_product_id))
    const converted = convertedList.reduce((acc, item) => {
        const productId = item.converted_subscription.membership.product.id;
        const productName = item.converted_subscription.membership.product.name;
        // If the product id hasn't been added to the accumulator yet, initialize it
        if (!acc[productId]) {
          acc[productId] = {
            name: productName,
            count: 0
          };
        }
        // Increment the count for the product id
        acc[productId].count += 1;
        return acc;
      }, {});
      const productCountArray = Object.values(converted);

return productCountArray.sort((a, b) => b.count - a.count).map((item) => ({
    ...item,
    percentage: (((item.count) / convertedList.length) * 100).toFixed(1),
  }));      

  }, [data]);

  if (!data) return <SpinIndicador />;
  if(!convertedMembers) return
  // 4. Mapear sobre la lista ordenada para renderizar
  return (
    <div className='flex flex-col p-0 m-0'>
      {convertedMembers.map((product, i) => (
        <div
          key={i}
          className={`${
            i < convertedMembers.length - 1
              ? 'border-b-primaryGrey border-b-[1px] '
              : 'border-b-transparent'
          } py-2 flex flex-row justify-between`}
        >
          <p className='text-white mr-4'>{product.name + ` (Tasa ${product.percentage}%)`}</p>
          <p className='text-white font-DrukWideMedium'>
            {product.count}
          </p>
        </div>
      ))}
    </div>
  );
}

