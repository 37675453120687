import { useQueries, useQuery } from '@tanstack/react-query';
import { getLocationId } from '../services/utils/getLocation';
import { fetchData } from '../services/utils/axiosInstance';
import axios from 'axios';
export const useMemberCreditCards = (id) => {
  return useQuery({
    queryKey: ['credit_cards', id],
    queryFn: async () => getMemberCreditCards(id),
    select: (data) => data?.filter((card) => !card.deleted),
  });
};
export const getMemberCreditCards = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/credit_cards?location_id=${location_id}&user_id=${id}`
  );
};

export const createMemberCreditCard = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/credit_cards?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteMemberCreditCard = async (id, data) => {
  const location_id = getLocationId();

  return await fetchData(
    `/credit_cards/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
3;
export const useGetPaymentPlatforms = (
  location_id,
  token,
  enabled = { enabled: true }
) => {
  return useQuery({
    queryKey: ['payment_platforms', location_id],
    queryFn: async () => getPaymentPlatforms(location_id, token),
    enabled: enabled.enabled,
  });
};
export const getPaymentPlatforms = async (location, token) => {
  const location_id = getLocationId() || location;

  return await fetchData(
    `/payment_platforms?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};

export const pay_by_link_tokens = async (data) => {
  return await fetchData(`/pay_by_link_tokens`, 'POST', data);
};

export const getPayByLinkToken = async (id, location_id) => {
  // const location_id = getLocationId();
  return await fetchData(`/pay_by_link_token/${id}?location_id=${location_id}`);
};

export const patchPayByLinkToken = async (id, data, location_id) => {
  return await fetchData(
    `/pay_by_link_tokens/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
