import React, { useState } from 'react';
import CardRounded from './CardRounded';
import {
  ConfigProvider,
  DatePicker,
  message,
  notification,
  Skeleton,
} from 'antd';
import es_ES from 'antd/lib/locale/es_ES';
import dayjs from 'dayjs';
import { useGetChatbotCrms } from '../api/crm';
import { getTimeZone } from '../services/utils/getLocation';
const first_day = dayjs().weekday(1);
const today = dayjs();
const { RangePicker } = DatePicker;

export default function LeadsChatbot({setModalMemberOpen}) {
  const datepickerRef = React.useRef();
  const [messageApi, messageContext] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const [date_selected, setDate_selected] = useState([first_day, today]);
  const { data, isLoading } = useGetChatbotCrms(
    date_selected[0].format('YYYY-MM-DD'),
    date_selected[1].format('YYYY-MM-DD')
  );
  const rangePresets = [
    {
      label: 'Ultimos 7 Dias',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Ultimos 15 Dias',
      value: [dayjs().add(-15, 'd'), dayjs()],
    },
    {
      label: 'Ultimos 30 Dias',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
  ];
  console.log('data', data);

  return (
    <CardRounded styles={`max-h-[55rem] pt-1 w-[58%] px-5   `}>
      <div className=' top-0  bg-primaryDark pt-6 relative'>
        <div className='flex flex-row justify-between items-center gap-5 column-mobile'>
          <h2 className='text-white font-bold text-2xl font-MessinaSansSemiBold'>
            {/* {t('alerts.title', 'Alertas')} */}
            Chats Bot
          </h2>
          <ConfigProvider locale={es_ES}>
            <RangePicker
              ref={datepickerRef}
              presets={rangePresets}
              onChange={(dates, dateString) => {
                datepickerRef.current.blur();
                // setdateSelected(date);
                //   setDate_selected([dayjs(dateString[0]), dayjs(dateString[1])]);
              }}
              allowClear={false}
              defaultPickerValue={date_selected}
              defaultValue={date_selected}
              format={'YYYY-MM-DD'}
              showToday
              style={{
                width: 240,
              }}
              className='hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey bg-primaryGrey outline-none border-none py-1 '
            />
          </ConfigProvider>
        </div>
      </div>

      <div className='min-h-[31rem] mt-5'>
        {isLoading && <Skeleton active />}
        {data?.map((item, index) => (
          <div
            className={`flex flex-row mb-2 last:border-none items-center border-b-[1px] border-b-primaryGrey py-3 column-mobile justify-between`}
          >
          <button   onClick={() =>
                    setModalMemberOpen({
                      id: item.customer_id,
                      isProspect: item.customer_type == 'Lead' ? false : true,
                      customer_type:item.customer_type
                    })
                  }>
          <span className=' text-lightGreen font-MessinaSansSemiBold '>
              {item.user_first_name + ' ' + item.user_last_name}
            </span>
          </button>

            <p className='w-[50%] line-clamp-6'>{item.message}</p>
            <p>
              {dayjs(item.created_at)
                .tz(getTimeZone())
                .format('DD/MM/YYYY HH:mm A')}
            </p>
          </div>
        ))}
      </div>

      {contextHolder}
      {messageContext}
    </CardRounded>
  );
}
