import React from 'react';
import { LineChart, XAxis, Tooltip, Line, Label, Legend } from 'recharts';

import Card from './Card';
import { useCdpConvertion, useGetSalesChart } from '../../api/charts';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';
export default function ConvertionCdp({chart,view}) {
  // const { data: chart, isLoading } = useCdpConvertion();

  const channel_types = useMemo(() => {
    if (chart?.results) {
      return (
        chart?.results.sort((a, b) =>
          dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
        )
          .map((item) => ({
            ...item,
            [item.type]: parseFloat(item.quantity),
          }))
      );
      //reduce by month
    }
    return [];
  }, [chart]);
  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const year = new Date(item.date).getFullYear();
      const month = dayjs(item.date).month();

      const key = dayjs(item.date).format('YYYY-MM');
      if (!acc[key]) {
        acc[key] = { year, month: month + 1, CDP: 0, Members: 0, date: key };
      }
      if(item.type == 'Members'){
        acc[key]['CDP'] += parseFloat(item.quantity);
  
      }
      acc[key][item.type] += parseFloat(item.quantity);

      return acc;
    }, {});
  };
  const groupByChannelView = (data) => {
    return data.reduce((acc, item) => {
      const year = new Date(item.date).getFullYear();
      const month = new Date(item.date).getMonth();
      const key = dayjs(item.date).format('YYYY-MM');
      if (!acc[key]) {
        acc[key] = {
          year,
          month: month + 1,
          Online: 0,
          Otros: 0,
          Referido: 0,
          Visita: 0,
          date: key,
        };
      }
      const categoryKey = `${item.category}`;
      const channelKeyConverted = `converted-${item.category}`;

      if (acc[key][categoryKey] === undefined) {
        acc[key][categoryKey] = 0;
      }
      if (acc[key][channelKeyConverted] === undefined) {
        acc[key][channelKeyConverted] = 0;
      }

      if (item.type == 'CDP') {
        acc[key][channelKeyConverted] += parseFloat(item.quantity);
        // acc[key]['Prospects'] += parseFloat(item.quantity);
      }
      acc[key][categoryKey] =
        (acc[key][categoryKey] || 0) + parseFloat(item.quantity);
      return acc;
    }, {});
  };
  const formattedData = groupByMonth(channel_types);
  const formattedDataChannels = groupByChannelView(channel_types);

  const first_month = useMemo(() => {
    if (chart?.results) {
      let first = chart.results.sort((a, b) =>
        dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
      );
      if (first && first.length > 0) {
        const date = dayjs(first[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const last_month = useMemo(() => {
    if (chart?.results) {
      let last = chart.results.sort((a, b) =>
        dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1
      );
      if (last && last.length > 0) {
        const date = dayjs(last[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [chart]);
  const difference = useMemo(() => {
    if (chart?.results && formattedData) { 
      const this_month = formattedData[Object.keys(formattedData)[Object.keys(formattedData).length - 1]] || {CDP:0,Members:0};
      const this_month_percentage =  (this_month.Members/this_month.CDP * 100).toFixed(1)
       const last_month = Object.values(groupByMonth(chart.results.filter((item) => {
        // Inicio del mes pasado
        let startOfLastMonth = dayjs().subtract(1, 'month').startOf('month');
        
        // Misma fecha y hora actuales, pero en el mes pasado
        let sameMomentLastMonth = dayjs().subtract(1, 'month');
      
        // Fecha del elemento convertida a un objeto dayjs
        let itemDate = dayjs(item.date);
      
        // Comprobar si la fecha del elemento está en el rango deseado
        return (itemDate.isSame(startOfLastMonth) || itemDate.isAfter(startOfLastMonth)) && itemDate.isBefore(sameMomentLastMonth);
      })))[0] || {CDP:0,Members:0};
      const last_month_percentage =  !last_month.CDP ? 0 : (last_month.Members/last_month.CDP * 100).toFixed(1)
      const percentageDifference =
      (((this_month_percentage - last_month_percentage) / (last_month_percentage == 0 ? 1 : last_month_percentage)) * 100).toFixed(0);   
       return   {
        percentage:percentageDifference,
        same_moment :last_month_percentage
       }
    }
    return {
      percentage:0,
      same_moment :0
    }
  }, [chart,formattedData]);
  const CustomizedLabel = (props) => {
    const { x, y, value, color } = props;
    return (
      <text x={x} y={y} dy={-10} fill={color} fontSize={12} textAnchor='middle'>
        {value}
      </text>
    );
  };
  const LINES_GENERAL = () => {
    return (
      <>
       <Line
          type='monotone'
          dataKey='Members'
          stroke='#0079A8'
          activeDot={{ r: 8 }}
          isAnimationActive={false}

          label={<CustomizedLabel color={'#0079A8'} />}
        />
        <Line
          type='monotone'
          dataKey='CDP'
          isAnimationActive={false}
          stroke='#CEF5CE'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#CEF5CE'} />}
        /> 
      </>
    );
  };
  const LINES_CHANNELVIEW = () => {
    return (
      <>
        <Line
          type='monotone'
          isAnimationActive={false}
          dataKey='Online'
          stroke='#0079A8'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#0079A8'} />}
        />
        <Line
          type='monotone'
          isAnimationActive={false}
          dataKey='Referido'
          stroke='#0079A8'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#0079A8'} />}
        />
         <Line
          type='monotone'
          isAnimationActive={false}
          dataKey='Visita'
          stroke='#0079A8'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#0079A8'} />}
        />
        <Line
          type='monotone'
          isAnimationActive={false}
          dataKey='Otros'
          stroke='#0079A8'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#0079A8'} />}
        />
      </>
    );
  };
  return (
    <Card
      title={'Conversión CDP'}
      size='large'
      description={chart?.description?.description}
      Extraclass={`col-span-2`}
      description_last_month={`${difference.same_moment}% de tasa de conversión al mismo momento el mes pasado`}
      percentage={isNaN(difference.percentage)? false: difference.percentage}
    >
      <LineChart
      // height={1000}
data={Object.values(
  view == 'General' ? formattedData : formattedDataChannels
)}        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: -20,
        }}
      >
     {chart?.results && chart.results?.length > 0 && (
          <XAxis dataKey='value' tickSize={0}>
            <Label
              value={first_month ? first_month : ''}
              offset={-10}
              // position='left'
              position='insideLeft'
              className='text-white'
            />
            <Label
              value={last_month ? last_month : ''}
              position='insideRight'
              offset={-10}
              className=' text-white'
            />
          </XAxis>
        )}
          <Legend verticalAlign='bottom' height={34}
        formatter={(value, entry, index) => {
          return (
            <span className='text-sm'> 
              {view !== 'General' ?value: value == 'Members' ? 'Clases de prueba convertidas' : 'Clases de prueba'}
            </span>
          );
        }
      }
        />
        <Tooltip
          animationDuration={300}
          animationEasing='ease-in-out'
          cursor={{ stroke: '#CCCCCC', strokeWidtsh: 3, radius: 4 }}
          content={({ active, payload, label }) => {
            if (active && payload && payload?.length > 0) {
              return (
                <div className=' bg-background px-10 py-6 rounded-md'>
  {payload[0]?.payload?.date && (
                    <p className='label font-MessinaSansBold capitalize'>{`Mes: ${dayjs(
                      payload[0]?.payload?.date
                    ).format('MMMM YYYY')}`}</p>
                  )}
                     {view !== 'General' ? (
                    Object.keys(payload[0]?.payload)
                      .filter(
                        (key) =>
                          !key.includes('converted-') &&
                          key !== 'date' &&
                          key !== 'month' &&
                          key !== 'year'
                      )
                      .sort((a, b) =>
                        payload[0]?.payload[a] > payload[0]?.payload[b] ? -1 : 1
                      )
                      .map((key) => {
                        if (payload[0]?.payload[key] > 0) {
                          return (
                            <p className='label text-[#CEF5CE]' key={key}>
                              {`${key} : ${payload[0]?.payload[key]}`}
                            </p>
                          );
                        }
                        return null;
                      })
                  ) : (
                    <>
                     <p
                    className={`label`}
                    style={{color:payload[1]?.stroke}}
                  >{`${payload[1]?.name} : ${payload[1]?.value}`}</p>
                  <p
                    className={`label `}
                    style={{color:payload[0]?.stroke}}

                  >{`${payload[0]?.name} : ${payload[0]?.value}`}</p>

                  <p className='label font-MessinaSansBold'>{`Tasa conversión ${((payload[0].value/payload[1].value) * 100).toFixed(1)}% `}</p>
                  </>
                  )}

                </div>
              );
            }
          }}
        />
                {view == 'General' ? LINES_GENERAL() : LINES_CHANNELVIEW()}
      </LineChart>
    </Card>
  );
}
