import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';

export const createSubscription = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/subscriptions?location_id=${location_id}`,
    'POST',
    data
  );
};

export const updateSubscription = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/subscriptions/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
