import { useTransition } from 'react';
import {
  CC_Columns,
  DebitPresentationColumns,
  DiscountDetailsColumns,
  ExpirationsColumns,
  HourspercoachColumns,
  IndicatorsCrmColumns,
  InterusosColumns,
  LastContactColumns,
  MembersCDPColumns,
  MembersCrmsColumns,
  ReservesPerHourColumns,
  SalesPerDayColumns,
  SalesPerUserColumns,
  activation_and_desactivations_columns,
  creditNotesColumns,
  evolution_reseves_active,
  refundsColumns,
  transactionsColumns,
  NewAccountsColumns,
  AssignmentsCoachColumns
} from './ReportColumns';

import { t } from 'i18next';

export const use_REPORT_LIST_OPTIONS = (start,end)=> {

  const salesPerUserValues = SalesPerUserColumns();
  const salesPerDayValues = SalesPerDayColumns();
  const reservesPerHourValues = ReservesPerHourColumns();
  const indicatorColumns = IndicatorsCrmColumns();
  const transactionsValues = transactionsColumns();
  const discountDetailsValues = DiscountDetailsColumns();
  const membersCrmsValues = MembersCrmsColumns();
  const LastContactValues = LastContactColumns();
  const debitPresentationValues = DebitPresentationColumns();
  const refundsValues = refundsColumns();
  const expirationValues = ExpirationsColumns();
  const membersCDPValues = MembersCDPColumns();
  const hoursPerCoachValues = HourspercoachColumns();
  const creditNotesValues = creditNotesColumns();
  const interusosValues = InterusosColumns();
  const CC_Values = CC_Columns();
const useevolution_reseves_active = evolution_reseves_active(start,end)
const useactivation_and_desactivations_columns = activation_and_desactivations_columns()
const new_accounts = NewAccountsColumns()
const assignments_coach = AssignmentsCoachColumns()
 return [
  {
    label: t('admin_singular','Administración'),
    options: [
      {
        label: t('hoursPerCoach_singular','Horas por coach'),
        value: 12,
        columns: hoursPerCoachValues,
      },
      {
        label: t('transactionsValues_singular','Reporte de operaciónes'),
        value: 13,
        columns: transactionsValues,
      },
      {
        label: t('debit_automatic','Debitos Automaticos'),
        value: 14,
        columns: debitPresentationValues,
        activeFilter: true,
        showFooterTotal: true,

      },

      {
        label: t('returns_singular','Devoluciónes'),
        value: 'returns',
        columns: refundsValues,
        hideDates: true,
        showFooterTotal: true,
      },
      {
        label: t('creditNotes_singular','Notas de Crédito'),
        value: 'credit_notes',
        columns: creditNotesValues,
        hideDates: true,
        showFooterTotal: true,
      },
      {
        label: t('interuses_singular','Interusos'),
        value: 16,
        columns: interusosValues,
        pickerMonth: true,
      },
     {
        label: t('activation_and_desactivations','Activaciones y Desactivaciones de debitos automaticos'),
        value: 19,
        columns: useactivation_and_desactivations_columns,
        // hasAttendance: true,
      },
    ],
  },
  {
    label: t('operations_singular','Operaciones'),
    options: [
      {
        label: t('reservationsPerHour_singular','Reservas por hora'),
        value: 5,
        columns: reservesPerHourValues,
        hasAttendance: true,
        selectClass: true,
      },
      {
        label: t('indicationsCRM_singular','Indicadores Crm'),
        value: 6,
        columns:indicatorColumns
      },
      {
        label: t('membersReached_sigunlar','Socios Contactados (CRM)'),
        value: 7,
        columns: membersCrmsValues,
      },

      {
        label: t('membersTrialClass_singular','Socios con Clase de Prueba'),
        value: 9,
        columns: membersCDPValues,
      },
      {
        label: t('lastContact_singular','Último contacto'),
        value: 15,
        columns: LastContactValues,
        hideDates: true,
      },
      {
        label: t('evolution_reseves_active','Evolucion reservas socios activos'),
        value: 18,
        columns: useevolution_reseves_active,
        hasAttendance: true,
      },
      {
        label: 'Asignaciones coaches',
        value: 21,
        columns: assignments_coach,
        // hasAttendance: true,
      },
    ],
  },
  {
    label: t('sales.title','Ventas'),
    options: [
      {
        label: t('sales.per.salesman_singular','Ventas por Vendedor'),
        value: 1,
        columns: salesPerUserValues,
        pickerMonth: true,
        sameMoment: true,
      },
      {
        label: t('sales.per.day_singular','Ventas por día'),
        value: 2,
        columns: salesPerDayValues,
      },
      {
        label: t('discount.details_singular','Detalle Descuentos'),
        value: 11,
        columns: discountDetailsValues,
        pickerMonth: true,
      },
      {
        label: t('cuenta.corriente_singular','Cuenta Corriente'),
        value: 17,
        columns: CC_Values,
        showFooterTotal: true,

      },
      {
        label: t('expiration_singular','Vencimientos'),
        value: 8,
        columns: expirationValues,
      },
      {
        label: t('new_accounts_detail','Detalle Altas'),
        value: 20,
        columns: new_accounts,
      },
    ],
  },
  
 
];
}
