import axiosInstance from './gym_classes';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';

export const getTransactions = async () => {
  const location_id = getLocationId();
  return await fetchData(`/transactions?location_id=${location_id}`);
};
export const getTransaction = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/transactions/${id}/?location_id=${location_id}`);
};
export const useGetTrasactions = () => {
  return useQuery({
    queryKey: ['transactions'],
    queryFn: async () => getTransactions(),
  });
};

export const getTransactionCategories = async () => {
  const location_id = getLocationId();
  return await fetchData(`/transaction_categories?location_id=${location_id}`);
};
export const updateTransaction = async (id, data) => {
  const location_id = getLocationId();

  return await fetchData(
    `/transactions/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createTransaction = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/transactions?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteTransaction = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/transactions/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export default axiosInstance;
