import React, { useMemo } from 'react';
import {
  LineChart,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  Legend,
  Label,
} from 'recharts';
import Card from './Card';
import { useGetPXq, useGetPXqSameMoment } from '../../api/charts';
import dayjs from 'dayjs';
import { formatPrice } from '../../services/utils/formatPrice';
import { t } from 'i18next';
export default function pxQ() {
  const { data, isLoading } = useGetPXq();
  const { data: same_moment, loading: isLoadingSameMoment } =
    useGetPXqSameMoment();

  const first_month = useMemo(() => {
    if (data?.results) {
      let first = data.results.sort((a, b) =>
        dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
      );
      if (first && first.length > 0) {
        const date = dayjs(first[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [data]);
  const last_month = useMemo(() => {
    if (data?.results) {
      let last = data.results.sort((a, b) =>
        dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1
      );
      if (last && last.length > 0) {
        const date = dayjs(last[0].date).format('MMMM YYYY');
        return date.charAt(0).toUpperCase() + date.slice(1);
      }
      return false;
    }
    return false;
  }, [data]);
  const calculatePercentageInsight = () => {
    const this_month = same_moment?.results
      ?.filter((item) =>
        dayjs(item.date, 'YYYY-MM-DD').isSame(dayjs(), 'month')
      )
      .find((p) => p.sales_quantity)?.sales_quantity;
    const last_month = same_moment?.results
      ?.filter((item) =>
        dayjs(item.date, 'YYYY-MM-DD').isSame(
          dayjs().subtract(1, 'month'),
          'month'
        )
      )
      .find((p) => p.sales_quantity)?.sales_quantity;
    if (this_month && last_month) {
      const percentage = ((this_month - last_month) / last_month) * 100;
      return {
        percentage: percentage.toFixed(0),
        last_month: parseInt(last_month),
      };
    }
    return 0;
  };
  const CustomizedLabel = (props) => {
    const { x, y, value, color } = props;
    return (
      <text x={x} y={y} dy={-10} fill={color} fontSize={12} textAnchor='middle'>
        {value}
      </text>
    );
  };
  return (
    <Card
      //   title={t('dashboard.usage_title')}
      title={'Membresias Vendidas y Ticket Promedio'}
      // description_last_month={`${difference.same_moment} membresias vendidas al mismo momento el mes pasado`}
      description_last_month={`${
        calculatePercentageInsight()?.last_month
      } membresias vendidas el mes anterior al mismo momento`}
      size='large'
      description={data?.description?.description}
      percentage={calculatePercentageInsight()?.percentage}
      icon={''}
      Extraclass={`col-span-2`}
    >
      <LineChart
        data={data?.results
          .map((p) => ({
            date: p.date,
            avg_sales: parseFloat(p.avg_sales),
            sales_quantity: parseFloat(p.sales_quantity),
            avg_membership: parseFloat(p.avg_membership),
          }))
          .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))}
        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: -10,
        }}
      >
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload?.length > 0) {
              return (
                <div className=' bg-background px-10 py-6 rounded-md'>
                  <p className='label font-MessinaSansBold mb-1'>Mes: {<span className='capitalize'>{dayjs(
                    payload[0]?.payload?.date
                  ).format('MMMM YYYY')}</span>}</p>
                  <p className='label font-MessinaSansSemiBold'>{`Ticket promedio: ${formatPrice(
                    payload[0]?.value
                  )}`}</p>
                  <p className='label font-MessinaSansSemiBold'>{`Membresias vendidas: ${payload[1]?.value}`}</p>
                  <p className='label font-MessinaSansSemiBold'>{`${ t('avg_membership_monthly','Valor mensual membresia')} ${formatPrice(
                    payload[2]?.value
                  )}`}</p>
                </div>
              );
            }
          }}
        />

        {data?.results && data.results?.length > 0 && (
          <XAxis dataKey='value' tickSize={0}>
            <Label
              value={first_month ? first_month : ''}
   
              position='insideLeft'
              className='text-white'
            />
            <Label
              value={last_month ? last_month : ''}
              position='insideRight'
              className=' text-white'
            />
          </XAxis>
        )}
        <Legend
          formatter={(value, entry, index) => {
            return (
              <span className='text-sm'>
                {value == 'avg_sales'
                  ? 'Ticket Promedio'
                  : value == 'avg_membership'
                  ? t('avg_membership_monthly','Valor mensual membresia')
                  : 'Membresias vendidas'}
              </span>
            );
          }}
        />
        <Line
          yAxisId='left'
          type='monotone'
          dataKey='avg_sales'
          stroke='#8884d8'
          activeDot={{ r: 8 }}
          isAnimationActive={false}
        />
        <Line
          isAnimationActive={false}
          yAxisId='right'
          type='monotone'
          dataKey='sales_quantity'
          stroke='#82ca9d'
          activeDot={{ r: 8 }}
          label={<CustomizedLabel color={'#82ca9d'} />}
        />
        <Line
          isAnimationActive={false}
          yAxisId='left'
          type='monotone'
          dataKey='avg_membership'
          stroke='#FFA500'
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </Card>
  );
}
