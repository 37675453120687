import { Tooltip, Dropdown, Menu, Checkbox, Button } from 'antd';
import React, { useState } from 'react';
import { getRoles, updateUser } from '../../api/users';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getLocationId,
  getLocationName,
} from '../../services/utils/getLocation';
import { createCoach, createCoachLocation } from '../../api/coaches';
import { useGlobalContext } from '../../context/GlobalState';
import { canCreateHQUser, canCreateManagerUser, canCreateOwnerUser, canCreateSalesUser, isAdmin } from '../../services/can_user';
import { t } from 'i18next';

export default function AddMemberRoles({
  user_id,
  openNotification,
  member_id,
}) {
  const { state: user } = useGlobalContext();

  const [fetchedRoles, setfetchedRoles] = useState(false);
  const [roles, setroles] = useState([]);
  const [open, setopen] = useState(false);
  const [selectedRole, setselectedRole] = useState([]);
  const [loading, setloading] = useState(false);
  const queryClient = useQueryClient();
  const location_id = getLocationId();
  const location_name = getLocationName();
  const isAdminUser =  isAdmin(user.user.roles)
  const canCreateHQ = canCreateHQUser()
  const canCreateManager = canCreateManagerUser()
  const canCreateOwner = canCreateOwnerUser()
  const canCreateSales = canCreateSalesUser()
  const fetchRoles = async () => {
    const roles = await getRoles();
    setroles(roles);
    setfetchedRoles(true);
  };
  const items = roles?.map((role) => ({
    label: <p className='w-full'>{role.name}</p>,
    key: role.id,

  }));
  const handleUpdate = async () => {
    setloading(true);
    const user = {
      id: user_id,
      user_id: user_id,
      user: {
        role_ids: selectedRole,
        location_ids: [location_id],
      },
    };
    if(selectedRole.some((role)=>role==3)){
      let coach = {
        user_id: user_id,
        user_attributes: {
          id:user_id,
          user_id: user_id,
      role_ids: selectedRole,
        },
      };
      await useCreateCoach.mutateAsync(coach)
    }
    await useUpdateUser.mutateAsync(user);
    setopen(false);
    setselectedRole([]);
    setloading(false);
  };
  const useCreateCoach = useMutation({
    mutationFn: (coach) => createCoach(coach),
    onSuccess: async (data) => {
      const location_id = getLocationId()
      const coach_location = {
        location_id: location_id,
        coach_id: data.id,
      }
      const result = await createCoachLocation(coach_location)
      openNotification('success',t('profile_coach_created','Perfil de coach creado correctamente'));
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  const useUpdateUser = useMutation({
    mutationFn: (user) => updateUser(user.id, user),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', member_id],
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key}: ${errorParse[key]}`)
      );
    },
  });
  const DropdownMenu = () => (
    <Menu className='flex items-center flex-col  ' >
      {items?.map((item) => (
        <div
          className='
              hover:bg-lightGrey
              ease-in-out duration-300
              cursor-pointer
              flex flex-row w-full py-2 px-2 items-center justify-between'
        >
          <div
            onClick={() => {
              if (selectedRole.includes(item.key)) {
                setselectedRole(selectedRole.filter((i) => i !== item.key));
              } else {
                setselectedRole([...selectedRole, item.key]);
              }
            }}
            className={`w-full pl-2`}
            key={item.key}
            disabled={item.disabled}
          >
            {item.label}
          </div>
          <Checkbox
          disabled={
          isAdminUser ? false :item.key == 1 ? true: item.key == 2 ? !canCreateManager : item.key == 3 ? false : item.key == 4 ? true : item.key == 5 ? !canCreateSales : item.key == 6 ? !canCreateOwner : item.key == 7 ? !canCreateHQ : true 
          }
            onClick={() => {
              if (selectedRole.includes(item.key)) {
                setselectedRole(selectedRole.filter((i) => i !== item.key));
              } else {
                setselectedRole([...selectedRole, item.key]);
              }
            }}
            checked={selectedRole.includes(item.key)}
          />
        </div>
      ))}
      <Menu.Item key='footer' disabled>
        <Footer />
      </Menu.Item>
    </Menu>
  );
  const Footer = () => (
    <div className='flex flex-row gap-4'>
      <Button
        onClick={() => {
          setopen(false);
          setselectedRole([]);
        }}
        danger
      >
        {t('cancel','Cancelar')}
      </Button>
      <Button onClick={() => handleUpdate()} loading={loading} type='primary'>
      {t('accept','Aceptar')}
      </Button>
    </div>
  );
  return (
    <Tooltip
      title={
     t('generate_user_access','Esta accion le dara al usuario acceso al sistema en la sede') +' ' +
        location_name
      }
      placement='top'
    >
      <Dropdown
        placement='bottomCenter'
        onOpenChange={async (open) => {
          if (open) {
            if (!fetchedRoles) {
              await fetchRoles();
            }
          }
          setopen(open);
          if (!open) {
            setselectedRole([]);
          }
        }}
        open={open}
        overlay={<DropdownMenu />}
        menu={{
          multiple: true,
        }}
        trigger={['click']}
      >
        <div className=' cursor-pointer text-center rounded-sm bg-lightGreen w-full mt-6 mb-1 py-2'>
          <span className=' font-MessinaSansBold text-black uppercase text-md'>
            {t('generate_role','Generar rol del sistema en')} {location_name}
          </span>
        </div>
      </Dropdown>
    </Tooltip>
  );
}
