import React, { useMemo } from 'react';
import Card from './Card';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { useGetRenovation, useGetRenovationSameMoment } from '../../api/charts';
import dayjs from 'dayjs';
import { Tooltip as TooltipAntd } from 'antd';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';
export default function RenovationChart() {
  const { data, loading } = useGetRenovation();
  const { data: same_moment, loading: isLoadingSameMoment } =
    useGetRenovationSameMoment();

  const COLORS2 = ['#ADFF19', '#ED4C5C', '#1E89A0'];
  const COLORS = ['#ED4C5C', '#ED4C5C', '#FFBB28', '#FF8042'];

  const insights = useMemo(() => {
    if (data?.results) {
      const expired = data.results
        ?.filter((item) =>
          dayjs(item.date, 'YYYY-MM-DD').isSame(dayjs(), 'month')
        )
        .reduce(
          (acc, curr) => {
            if (curr.type === 'Vencimientos') {
              acc.vencimientos += curr.members;
            } else if (curr.type === 'Renovaciones') {
              acc.renovaciones += curr.members;
              acc[curr.renovation_type] = curr.members;
            }
            return acc;
          },
          { vencimientos: 0, renovaciones: 0 }
        );
      // console.log('expired',expired);
      return {
        renovaciones:
          expired.renovaciones - (expired['renovacion anticipada'] || 0),
        vencimientos: expired.vencimientos - (expired['renovacion anticipada'] || 0),
        'Renovaciones anticipadas': expired['renovacion anticipada'] || 0,
        arrData: [
          {
            name: 'Renovaciones',
            value:
              expired.renovaciones - (expired['renovacion anticipada'] || 0),
          },
          { name: 'Bajas', value: expired.vencimientos - expired.renovaciones },
          {
            name: 'Renovaciones anticipadas',
            value: expired['renovacion anticipada'] || 0,
          },
        ],
      };
    }
    return {
      renovaciones: 0,
      vencimientos: 0,
      arrData: [
        { name: 'Renovaciones', value: 0 },
        { name: 'Vencimientos', value: 0 },
      ],
    };
  }, [data]);
  function calculateCompletionPercentage(renovaciones, vencimientos) {
    if (vencimientos === 0) return '0.00%'; // Para evitar división por cero
    const percentage = (renovaciones / vencimientos) * 100;
    return `${percentage.toFixed(0)}%`;
  }
  const calculatePercentageInsight = () => {
    const this_month =
      insights.renovaciones + (insights['Renovaciones anticipadas'] || 0);
    const last_month = same_moment?.results
      ?.filter((item) =>
        dayjs(item.date, 'YYYY-MM-DD').isSame(
          dayjs().subtract(1, 'month'),
          'month'
        )
      )
      .find((t) => t.type == 'Renovaciones')?.members;
    if (this_month && last_month) {
      const percentage = ((this_month - last_month) / last_month) * 100;
      return {
        percentage: percentage.toFixed(0),
        last_month: last_month,
      };
    }
    return 0;
  };
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  return (
    <Card
      title={t('dashboard.renovations_title')}
      insight={false}
      percentage={calculatePercentageInsight()?.percentage}
      description_last_month={`${
        calculatePercentageInsight()?.last_month
      } renovaciones el mes anterior al mismo momento`}
      size='small'
      icon={''}
      description={data?.description?.description}
      TopInsight={
        <div className='flex flex-row gap-3 pt-3 '>
          <TooltipAntd title='Renovaciones anticipadas'>
            <span className=' font-MessinaSansSemiBold text-primaryBlue flex items-center'>
              {insights['Renovaciones anticipadas'] || 0}
              <BsArrowUp />
            </span>
          </TooltipAntd>
          <TooltipAntd title='Renovaciones este mes'>
            <span className=' font-MessinaSansSemiBold text-primaryGreen flex items-center'>
              {insights.renovaciones}
              <BsArrowUp />
            </span>
          </TooltipAntd>
          <TooltipAntd title='Vencimientos'>
            <span className=' font-MessinaSansSemiBold text-primaryRed flex items-center'>
              {insights.vencimientos - insights.renovaciones}
              <BsArrowDown />
            </span>
          </TooltipAntd>
        </div>
      }
    >
      <PieChart margin={{ top: 0, left: 0, bottom: 10, right: 0 }}>
        <Tooltip />
        <Pie
          labelLine={false}
          label={({ cx, cy }) => {
            return (
              <text
                x={cx}
                y={cy}
                fontFamily='Druk-WideMedium'
                fill='#FFFFFF'
                fontSize={isMobile ? 21 : 25}
                dy={-10}
                textAnchor='middle'
              >
                {calculateCompletionPercentage(
                  insights.renovaciones,
                  insights.vencimientos
                )}
              </text>
            );
          }}
          data={insights.arrData}
          cx={'50%'}
          cy={'100%'}
          startAngle={180}
          endAngle={0}
          innerRadius={isMobile ? 55 : 70}
          outerRadius={isMobile ? 64 : 90}
          stroke='none'
          paddingAngle={1}
          dataKey='value'
        >
          {insights.arrData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </Card>
  );
}
