// import * as XLSX from 'xlsx';

// export function exportExcel(data, fileName) {
//   let filterFields = data.map((item) => {
//     if (item.url) {
//       delete item.url;
//     }
//     if (item.key) {
//       delete item.key;
//     }
//     if (item.uuid) {
//       delete item.uuid;
//     }
//     return item;
//   });
//   console.log('filterFields', filterFields);
//   const ws = XLSX.utils.json_to_sheet(filterFields);
//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   XLSX.writeFile(wb, `${fileName}.xlsx`);
// }
import * as XLSX from 'xlsx';

export function exportExcel(data, fileName) {
  let filterFields = data.map((item) => {
    // Convertir cada campo que es un array a un string, separado por comas
    Object.keys(item).forEach((key) => {
      if (Array.isArray(item[key])) {
        item[key] = Array.from(new Set(item[key].map((i) => i.name))).join(
          ', '
        ); // Usa el delimitador que prefieras
      }
    });

    // Eliminar campos no deseados
    if (item.url) {
      delete item.url;
    }
    if (item.key) {
      delete item.key;
    }
    if (item.uuid) {
      delete item.uuid;
    }

    return item;
  });
  const ws = XLSX.utils.json_to_sheet(filterFields);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}
