import { getConsumables } from '../../api/consumables';
import { getDiscounts } from '../../api/discounts';
import { getMemberships } from '../../api/memberships';
import { getProducts } from '../../api/products';
import { getSessions } from '../../api/sessions';
import { useGenericQuery } from './rootLoader';

//Products
export const useGetProducts = () => useGenericQuery(['products'], getProducts);
export const ProductLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetProducts();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
//Consumables
export const useGetConsumables = () =>
  useGenericQuery(['consumables'], getConsumables);

export const ConsumableLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetConsumables();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

  //Memberships
export const useGetMemberships = () =>
  useGenericQuery(['memberships'], getMemberships);

export const MembershipLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetMemberships();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

    
  //Discounts
export const useGetDiscounts = () =>
useGenericQuery(['discounts'], getDiscounts);

export const DiscountsLoader =
(queryClient) =>
async ({ params }) => {
  const query = useGetDiscounts();
  // ⬇️ return data or fetch it
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

  //Sessions
  export const useGetSessions = () =>
  useGenericQuery(['sessions'], getSessions);
  
  export const SessionsLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetSessions();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
  