import React, { useState, useMemo } from 'react';
import { Button, Modal, Select, Tag } from 'antd';
import { BsArrowRight } from 'react-icons/bs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../../../services/utils/formatError';
import { useGlobalContext } from '../../../context/GlobalState';
import { t } from 'i18next';
import { formatExtraInfo } from '../../../services/membersUtils';
import SelectMember from '../SelectMember';
import { createMemberCreditCard } from '../../../api/mp';
const CloneCreditCard = ({
  openNotification,
  setClose,
  data,
  customer_id,
  document_types
}) => {
  if (!data) return <></>;

  const queryClient = useQueryClient();
  const { state } = useGlobalContext();
  const [selected_member, setselected_member] = useState(false);
  const useCloneCard = useMutation({
    mutationFn: (card) => createMemberCreditCard(card),
    onSuccess: (data) => {
      openNotification(
        'success',
        t('credit_card_assigned', 'Tarjeta asignada correctamente')
      );
      setClose();
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });
  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        data ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[35%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <h2 className='text-3xl  font-MessinaSansSemiBold'>
          {t('assign_card_to_member', 'Asignar esta tarjeta a otro socio')}
        </h2>
        <div className='flex flex-row items-center justify-between my-5 w-full '>
          <div className='flex-1 '>
            <p className='text-secondaryGrey opacity-80 max-w-xs break-words'>
              {t('current_card', 'Tarjeta actual')}
            </p>
            <div className='py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey'>
              {`${data?.card_brand} ${
                formatExtraInfo(data?.extra_info)?.first_six_digits || ''
              }**** ${data?.last_four}`}
            </div>
          </div>
          <BsArrowRight
            className='mx-4 self-center mt-4'
            color={'#ADFF19'}
            size={22}
          />
          <div className='flex-1 '>
            <p className='text-secondaryGrey opacity-80 max-w-xs break-all'>
              Seleccionar socio
            </p>
            <SelectMember
              value={selected_member.id}
              setValue={(id, user_id, email,full_data) => {
                setselected_member(full_data);
              }}
              styles={`my-2`}
              width='100%'
            />
          </div>
        </div>

        <div className='flex flex-items self-start gap-5  mt-4'></div>
        <div className='flex items-end self-end  justify-end gap-3 mt-4'>
          <Button danger onClick={() => setClose()}>
            Cancelar
          </Button>
          <Button
            loading={useCloneCard.isLoading}
            type='primary'
            onClick={async () => {
              const card = {
                card_brand: data.card_brand,
                external_payment_token: data.external_payment_token,
                user_id:selected_member.user_id,
                payment_platform_id: data.payment_platform_id,
                last_four: data.last_four,
                payment_method_id: data.payment_method_id,
                external_email: selected_member.email,
                preferred: false,
                payer: {
                  email: selected_member.email,
                  identification:{
                    type:document_types.find((item)=>item.id == selected_member.identification.type).name,
                    number:selected_member.identification.number
                  }
                },
              };
              await useCloneCard.mutateAsync(card);
            }}
          >
            Aceptar
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CloneCreditCard;
