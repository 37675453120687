import React, { useCallback, useMemo ,useState} from 'react';
import { Select, Divider, Space ,Cascader, Tag} from 'antd';
import { isUserAdmin } from '../services/can_user';
import { useGetLocationCategories } from '../api/locations';
import {
  MdOutlineChevronRight,
} from 'react-icons/md';
import { getLocationId, getLocationName } from '../services/utils/getLocation';
const SelectLocation = ({
  openLocations,
  setopenLocations,
  setlocation_selected,
  selectRef,
  state,
  location_selected,
  handleChange
}) => {
  const [testvalue, settestvalue] = useState([getLocationId()])
  const [searchTerm, setSearchTerm] = useState(''); // Estado para manejar el término de búsqueda
  const {data:location_categories,isLoading}= useGetLocationCategories();
  const locations = useMemo(() => {
    if(!location_categories) return [];
    const filteredLocations = state.state.availableLocations.filter(location =>
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (isUserAdmin()) {
      if(!location_categories) return [];
      const groupedLocations = Object.values(
        filteredLocations
        .sort((a, b) => a.location_category_id > b.location_category_id  ? 1 :-1).
        reduce((acc, location) => {
          const type =  location_categories.find((l)=>l.id == location.location_category_id)?.name ;
          if (!acc[type]) {
            acc[type] = { label: type, options: [] };
          }
          acc[type].options.push({
            label: location.name,
            value: location.id,
            title: location.name,
            key: location.id
          });
          return acc;
        }, {})
      );
  
      // Ordena las opciones en cada grupo por nombre
      return groupedLocations.map(group => ({
        ...group,
        options: group.options.sort((a, b) => a.label.localeCompare(b.label))
      }));
    } else {
      return filteredLocations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(p => ({
          label: p.name,
          value: p.id
        }));
    }
  }, [state.state.availableLocations, state.state.location_id, searchTerm,location_categories]);
  const handleLocationChange = useCallback((newValue) => {
    setlocation_selected(newValue);
    // Puedes descomentar la siguiente sección si necesitas invalidar queries cuando cambie la ubicación
    handleChange()
  }, [setlocation_selected]);
  const handleLocationCascaderChange = useCallback((newValue,option) => {
    let flatValue = newValue.flat();
    // if(flatValue)
    let location_values = []
    flatValue.forEach((value)=>{
      if(typeof value == 'number'){
        if(location_values.includes(value)) return
        location_values.push(value)
      } else {
        let optionLocations = locations.find((l)=>l.label == value).options.map((o)=>o.value);
          console.log('ss',optionLocations.some((o)=>flatValue.includes(o)));
          
        if(optionLocations.some((o)=>flatValue.includes(o))) return
        
 locations.find((l)=>l.label == value).options.forEach((option)=>{
    location_values.push(option.value)
 })
      }
    })
      console.log('location_values',location_values);
    settestvalue(newValue)
    setlocation_selected(location_values);
    // Puedes descomentar la siguiente sección si necesitas invalidar queries cuando cambie la ubicación
    handleChange()
  }, [setlocation_selected,locations]);
  const handleDropdownVisibleChange = useCallback((open) => {
    if (!open) {
      setopenLocations(false);
    }
  }, [setopenLocations]);

  const handleDropdownClick = useCallback(() => {
    if (openLocations) {
      // setopenLocations(false);
    } else {
      setopenLocations(true);
    }
  }, [openLocations, setopenLocations]);

  const handleSelectAllLocations = useCallback(() => {
    setlocation_selected(state.state.availableLocations.map((l) => l.id));
  }, [setlocation_selected, state.state.availableLocations]);

  
  const displayRender = (labels) => {
    console.log('labels',labels);
      
      if(labels.length == 1) {
        if(typeof labels[0] == 'number') return getLocationName()
        return labels[0];
      }
    // Busca el nombre correspondiente de cada label seleccionado
      const foundOption = locations.map(group => group.options).flat().find(option => option.title == labels[1]);
      console.log('foundOption',foundOption);
      
      return foundOption ? foundOption.label : labels[1]; // Mostrar el label correspondiente
  };
  const dropdown = (menu)=>{
    return (  <div>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <Space style={{ padding: '0 8px 4px', alignItems: 'center' }}>
        <Divider style={{ margin: '8px 0' }} />
    <div>
        <input
            value={searchTerm} // Asociar el estado del término de búsqueda
            placeholder='Buscar sede'
            onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el término de búsqueda

            className='!text-black cursor-pointer outline-none border-none z-10'
          >
          </input>
        <Divider style={{ margin: '8px 0' }} />
          <span
            onClick={handleSelectAllLocations}
            className='underline text-darkGreen cursor-pointer text-center'
          >
            Buscar en todas las sedes
          </span>
          </div>
        </Space>
      </div>
    )}
    
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
//   if(isUserAdmin()){
//     return (
//       <Cascader
//       options={locations.map((p)=>({
//        value:p.label,
//        label:p.label,
//        children:p.options
//       }))}
//       showSearch={{
//        filter,
//      }}
//      multiple
// // defaultValue={[1]}
//       allowClear={true}
//       // showCheckedStrategy={Cascader.SHOW_PARENT}
//       value={testvalue}
//       expandIcon={<MdOutlineChevronRight size={22} color='#444444' />}
//       expandTrigger="hover"
//       bordered={false}
//       placeholder='Buscar sede'
//       displayRender={displayRender}
//       onChange={handleLocationCascaderChange}
//       className=' text-white outline-none border-none py-1 rounded-md  '
//     />
//     )
//   }
if(isLoading) return <p>Cargando...</p>
  return (
    <Select
      open={openLocations}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onClick={handleDropdownClick}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // ref={selectRef}
      dropdownRender={dropdown}
      bordered={false}
      mode={'multiple'}
      value={location_selected}
      onChange={handleLocationChange}
      className='bg-transparent  outline-none border-none py-1 rounded-md cursor-pointer'
      style={{
        width: 220,
        maxHeight: 100,
        overflowY: 'auto',
        cursor: 'pointer',
      }}
      options={locations}
    />
  );
}

export default SelectLocation;
