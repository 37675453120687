import React from 'react'

export default function WhatsAppSvg({large,bg}) {
    let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width={width} height={height}  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18.208" cy="18" r="17.2764" fill={ bg ?`${bg}` : `${'#58B99E'}`} />
<path d="M24.013 12.1632C23.2872 11.43 22.4227 10.8487 21.4699 10.4532C20.5171 10.0577 19.4951 9.85583 18.4634 9.85942C14.1409 9.85942 10.618 13.3823 10.618 17.7048C10.618 19.0903 10.9822 20.4361 11.663 21.6236L10.5547 25.6928L14.7109 24.6003C15.8589 25.2257 17.1493 25.5582 18.4634 25.5582C22.7859 25.5582 26.3089 22.0353 26.3089 17.7128C26.3089 15.6148 25.4934 13.6436 24.013 12.1632ZM18.4634 24.2282C17.2918 24.2282 16.1439 23.9115 15.1384 23.3178L14.9009 23.1753L12.4309 23.8244L13.088 21.4178L12.9297 21.1723C12.2787 20.1329 11.9331 18.9313 11.9322 17.7048C11.9322 14.1107 14.8614 11.1815 18.4555 11.1815C20.1972 11.1815 21.8359 11.8623 23.063 13.0973C23.6706 13.7021 24.1521 14.4215 24.4796 15.2138C24.8071 16.0061 24.9741 16.8555 24.9709 17.7128C24.9868 21.3069 22.0576 24.2282 18.4634 24.2282ZM22.0418 19.3515C21.8439 19.2565 20.878 18.7815 20.7039 18.7103C20.5218 18.6469 20.3951 18.6153 20.2605 18.8053C20.1259 19.0032 19.7539 19.4465 19.643 19.5732C19.5322 19.7078 19.4134 19.7236 19.2155 19.6207C19.0176 19.5257 18.3843 19.3119 17.6401 18.6469C17.0543 18.1244 16.6664 17.4832 16.5476 17.2853C16.4368 17.0873 16.5318 16.9844 16.6347 16.8815C16.7218 16.7944 16.8326 16.6519 16.9276 16.5411C17.0226 16.4303 17.0622 16.3432 17.1255 16.2165C17.1889 16.0819 17.1572 15.9711 17.1097 15.8761C17.0622 15.7811 16.6664 14.8153 16.508 14.4194C16.3497 14.0394 16.1834 14.0869 16.0647 14.079H15.6847C15.5501 14.079 15.3443 14.1265 15.1622 14.3244C14.988 14.5223 14.4814 14.9973 14.4814 15.9632C14.4814 16.929 15.1859 17.8632 15.2809 17.9898C15.3759 18.1244 16.6664 20.1036 18.6297 20.9507C19.0968 21.1565 19.4609 21.2753 19.7459 21.3623C20.213 21.5128 20.6405 21.489 20.9809 21.4415C21.3609 21.3861 22.1447 20.9665 22.303 20.5073C22.4693 20.0482 22.4693 19.6603 22.4139 19.5732C22.3584 19.4861 22.2397 19.4465 22.0418 19.3515Z" fill="#0C634B"/>
</svg>


  )
}
