import axiosInstance from './gym_classes';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { compareByCreatedAt } from '../services/utils/compareByDate';
export const getSales = async (start, end) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sales?location_id=${location_id}&start=${start}&end=${end}`
  );
};
export const getCustomerSales = async (customer_id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sales?location_id=${location_id}&member_id=${customer_id}`
  );
};
export const getProspectSales = async (customer_id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sales?location_id=${location_id}&prospect_id=${customer_id}`
  );
};
export const getSale = async (id, location, token) => {
  const location_id = getLocationId() || location;
  return await fetchData(
    `/sales/${id}?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};
export const useGetSale = (
  id,
  location_id,
  token,
  enabled = { enabled: true }
) => {
  return useQuery({
    queryKey: ['sale', id],
    queryFn: async () => getSale(id, location_id, token),
    enabled: enabled.enabled,
  });
};
export const useGetSales = (start, end) => {
  if (!start && !end) {
    return {
      data: [],
      isLoading: false,
    };
  }
  return useQuery({
    queryKey: ['sales', start, end],
    queryFn: async () => getSales(start, end),
    select: (data) =>
      data
        .map((p) => ({
          ...p.product,
          ...p,
          payments: p.payments.map((payment) => ({
            ...payment,
            name: payment.payment_method_name,
          })),

          // product_type_name_es
        }))
        .sort((a, b) => compareByCreatedAt(a, b)),
  });
};
export const updateSale = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sales/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createSale = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/sales?location_id=${location_id}`, 'POST', data);
};
export const deleteSale = async (id, sale_location) => {
  const location_id = getLocationId();

  let sale_location_id = sale_location ? sale_location : location_id;
  return await fetchData(
    `/sales/${id}?location_id=${sale_location_id}`,
    'DELETE'
  );
};
export const createRefund = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/refunds?location_id=${location_id}`, 'POST', data);
};
export const createCreditNotes = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/credit_notes?location_id=${location_id}`,
    'POST',
    data
  );
};
export const getRefunds = async (start, end, sale_id, location_ids) => {
  const location_id = getLocationId();
  let q = `/refunds?location_id=${location_id}`;
  if (start) {
    q = `${q}&start=${start}`;
  }
  if (end) {
    q = `${q}&end=${end}`;
  }
  if (sale_id) {
    q = `${q}&sale_id=${sale_id}`;
  }
  if (location_ids) {
    q = `${q}&location_ids=${location_ids}`;
  }
  return await fetchData(q);
};
export const getCreditNotes = async (start, end, sale_id, location_ids) => {
  const location_id = getLocationId();
  let q = `/credit_notes?location_id=${location_id}`;
  if (start) {
    q = `${q}&start=${start}`;
  }
  if (end) {
    q = `${q}&end=${end}`;
  }
  if (sale_id) {
    q = `${q}&sale_id=${sale_id}`;
  }
  if (location_ids) {
    q = `${q}&location_ids=${location_ids}`;
  }
  return await fetchData(q);
};
export const useGetRefunds = (sale_id) => {
  return useQuery({
    queryKey: ['refunds', sale_id],
    queryFn: async () => getRefunds(null, null, sale_id),
  });
};
export const useGetCreditNotes = (sale_id, start, end) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['credit_notes', location_id, start, end],
    queryFn: async () => getCreditNotes(start, end, sale_id),
  });
};

export default axiosInstance;
