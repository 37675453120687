import { Popover } from 'antd';
import React from 'react';

import { BsQuestion } from 'react-icons/bs';
import { Link, redirect } from 'react-router-dom';
export default function HelpQuestion({ description, link }) {
  const content = (
    <div  >
      {link ? (
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className='hover:underline'
        >
          <span>{description}</span>
        </a>
      ) : (
        <p className='max-w-[270px]'>{description}</p>
      )}
    </div>
  );
  return (
    <Link
    to={'/reports?r_id=20'}
    className='bg-primaryGrey rounded-full h-4 w-4 cursor-pointer flex items-center justify-center ml-1'>
      <Popover placement='bottom' 
      content={content}>
        <div
        >
          <BsQuestion size={15} />
        </div>
      </Popover>
    </Link>
  );
}
