import React from 'react';
import { useGetCreditNotes, useGetRefunds } from '../../api/sales';
import SpinIndicador from '../helpers/SpinIndicador';
import { formatPrice } from '../../services/utils/formatPrice';
import { t } from 'i18next';

export default function DetailOperations({ sale_id, card_attempts ,paymentPlatform}) {
  const { data: refunds, isLoading: isLoadingRefunds } = useGetRefunds(sale_id);
  const { data: creditNotes, isLoading: isLoadingCreditNotes } =
    useGetCreditNotes(sale_id);
  if (isLoadingRefunds || isLoadingCreditNotes) {
    return (
      <div className='w-full flex items-center justify-center my-2'>
        <SpinIndicador
          className='m-auto self-center'
          color={'fill-secondaryGrey'}
        />
      </div>
    );
  }
  const OperationList = ({ title, data, noDataMessage }) => {
    return (
      <div className='mt-4 border-lightGrey border-[1px] rounded-md relative'>
        <span className='text-md font-MessinaSansSemiBold mb-2 bg-primaryGrey z-50 absolute top-[-10px] left-5 px-3'>
          {title}
        </span>
        <div className=' flex flex-row items-center justify-around   mt-4 mx-5 mb-2'>
          <span className=' text-secondaryGrey w-[5%] font-MessinaSansSemiBold '>
            ID
          </span>
          <span className=' text-secondaryGrey w-[11%] font-MessinaSansSemiBold'>
            {t('seller', 'Vendedor')}
          </span>

          <span className=' text-secondaryGrey w-[20%] font-MessinaSansSemiBold'>
            {t('return.reason', 'Motivo de devolución')}
          </span>
          <span className=' text-secondaryGrey w-[11%] font-MessinaSansSemiBold'>
            {t('amount', 'Monto')}
          </span>
          <span className=' text-secondaryGrey w-[20%] font-MessinaSansSemiBold'>
            {title !== t('creditNotes_singular', 'Notas de Crédito')
              ? t('sales.payment_method', 'Forma de pago')
              : ''}
          </span>
        </div>
        {data.length === 0 && (
          <div className='text-center text-primaryRed my-3'>
            {noDataMessage}
          </div>
        )}
        {data?.map((refund, index) => (
          <div
            key={index}
            className='flex flex-row items-center justify-between  my-3'
          >
            <span className='w-[7%] pl-10'>{refund.id}</span>
            <span className='w-[11%] pl-2'> {refund.user_name}</span>
            <span className='w-[18%]'> {refund.discharge_reason}</span>
            <span className='w-[13%]'>
              {' '}
              {formatPrice(refund.amount, refund.currency)}
            </span>
            <span className='w-[20%]'> {refund.payment_method}</span>
          </div>
        ))}
      </div>
    );
  };
  const OperationListAttemps = ({ title, data, noDataMessage }) => {
    return (
      <div className='mt-4 border-lightGrey border-[1px] rounded-md relative'>
        <span className='text-md font-MessinaSansSemiBold mb-2 bg-primaryGrey z-50 absolute top-[-10px] left-5 px-3'>
          {title}
        </span>
        <div className=' flex flex-row items-center justify-around   mt-4 mx-5 mb-2'>
          <span className=' text-secondaryGrey w-[5%] font-MessinaSansSemiBold '>
            ID
          </span>
          <span className=' text-secondaryGrey w-[11%] font-MessinaSansSemiBold'>
            {t('payment.id_singular', 'Pago ID')}
          </span>
         
          <span className=' text-secondaryGrey w-[11%] font-MessinaSansSemiBold'>
            {t('amount', 'Monto')}
          </span>
          <span className=' text-secondaryGrey w-[20%] font-MessinaSansSemiBold'>
            {t('sales.operation_number_short', 'Nº operación')}
          </span>
          <span className=' text-secondaryGrey w-[20%] font-MessinaSansSemiBold'>
            {t('description', 'Descripción')}
          </span>
          <span className=' text-secondaryGrey w-[11%] font-MessinaSansSemiBold'>
            {t('state_singular', 'Estado')}
          </span>
        </div>
        {  (!data || data?.length === 0) && (
          <div className='text-center text-primaryRed my-3'>
            {noDataMessage}
          </div>
        )}
        {data?.map((attempt, index) => (
          <div
            key={index}
            className='flex flex-row items-center justify-between  my-3'
          >
            <span className='w-[7%] pl-8'>{attempt.id}</span>
            <span className='w-[12%]'> {attempt.payment_id}</span>
            <span className='w-[12%]'>
              {' '}
              {formatPrice(attempt.amount, attempt.currency)}
            </span>
            <span className='w-[20%]'> {attempt.operation_reference}</span>
            <span className='w-[18%]'> {attempt.rejected_description}</span>
            <span className='w-[15%]'> {attempt.status}</span>
          
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className='my-1 w-[100%] bg-primaryGrey  p-5 rounded-md'>
        {(paymentPlatform && paymentPlatform?.length > 0 || card_attempts?.length > 0) && (
        <OperationListAttemps
          title={t('card_attempt_payment', 'Intentos de cobro con tarjeta')}
          // title={t('card_attempts','Intentos de tarjeta')}
          data={card_attempts}
          noDataMessage={t('card_attempts_none','No hay intentos de tarjeta para la venta') +" #" + sale_id}
        />
      )}
      <OperationList
        title={t('creditNotes_singular', 'Notas de Crédito')}
        data={creditNotes}
        noDataMessage={
          t('creditNotes.none', 'No hay notas de crédito para la venta') +
          ' #' +
          sale_id
        }
      />
      <OperationList
        title={t('refunds_plural', 'Devoluciones')}
        data={refunds}
        noDataMessage={
          t('refunds.none', 'No hay devoluciones para la venta') +
          ' #' +
          sale_id
        }
      />
    
    </div>
  );
}
