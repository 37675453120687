import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';

export const createDebit = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/debits?location_id=${location_id}`, 'POST', data);
};

export const updateDebit = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/debits/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const updateDebitSplit = async (id, data) => {
  const location_id = getLocationId() || data.location_id;
  return await fetchData(
    `/debit_splits/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const getDebitSplits = async (id, location, token) => {
  const location_id = getLocationId() || location;
  return await fetchData(
    `/debit_splits/${id}?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};
export const useGetDebitSplits = (
  id,
  location_id,
  token,
  enabled = { enabled: true }
) => {
  return useQuery({
    queryKey: ['debit_splits', id],
    queryFn: async () => getDebitSplits(id, location_id, token),
    enabled: enabled.enabled,
  });
};
export const getDebits = async (id, location, token) => {
  const location_id = getLocationId() || location;
  return await fetchData(
    `/debits/${id}?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};
export const useGetDebits = (
  id,
  location_id,
  token,
  enabled = { enabled: true }
) => {
  return useQuery({
    queryKey: ['debits', id],
    queryFn: async () => getDebits(id, location_id, token),
    enabled: enabled.enabled,
  });
};
