import React from 'react';
import { RiAddFill } from 'react-icons/ri';
import { Tooltip } from 'antd';

export default function AddButton({ greenColor, onClick, className, title ,icon,bg}) {
  return (
    <Tooltip title={title} >
      <button
        onClick={() => onClick()}
        className={`${
          greenColor ? `bg-lightGreen` :bg  ?bg: `bg-background `
        } p-2 rounded-full  ease-in-out duration-300 transition-opacity  hover:opacity-90 hover:scale-105 ${className}`}
      >
        {icon ? icon : <RiAddFill size={22} color={greenColor ? '#1EA05A' : '#FFFFFF'} />}
      </button>
    </Tooltip>
  );
}
