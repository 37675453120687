import React, { useState, useRef } from 'react';
import { Modal, DatePicker, ConfigProvider } from 'antd';
import CardRounded from '../CardRounded';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSubscription } from '../../api/subscriptions';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

// IMPORT
import es_ES from 'antd/locale/es_ES';
import {
  formatSuscriptionDaysLeft,
  isValidSuscription,
} from '../../services/membersUtils';
import { formatError } from '../../services/utils/formatError';
import { BsArrowRight } from 'react-icons/bs';
import SuscriptionsList from './SuscriptionsList';
import {
  canUpdateProductDays,
  canUpdateProductSessions,
} from '../../services/can_user';
import { t } from 'i18next';
const { confirm } = Modal;
import dayjs from 'dayjs';
// import {ExclamationCircleFilled}
export default function ListMemberProducts({
  products,
  isProspect,
  customer_id,
  openNotification,
  customer_type,
  showInvalid
}) {
  const queryClient = useQueryClient();
  const datepickerRef = React.useRef();
  const newDaysAmountRef = useRef();
  const new_sessions_amountRef = useRef();
  const [showExpireProducts, setshowExpireProducts] = useState(false);
  const new_start_dateRef = useRef();
  const [newStartDate, setnewStartDate] = useState();

  const useUpdateMembership = useMutation({
    mutationFn: (membership) =>
      updateSubscription(membership.id, membership.data),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', customer_id],
      });
    },
    onError: (error) => {
      console.log('error', error);
      formatError(error, openNotification);
    },
  });
  const editProduct = [
    {
      label: <p className=' text-primaryRed'>{t('delete', 'Eliminar')}</p>,
      key: 'delete',
      disabled: true,
    },

    {
      label: <p>{t('modify_start', 'Modificar Inicio')}</p>,
      key: 'edit_start',
      visible: true,
    },
    {
      type: 'divider',
    },
    {
      label: <p>{t('modify_days', 'Modificar Dias')}</p>,
      key: 'edit_days',
      visible: canUpdateProductDays(),
    },

    {
      label: <p>{t('modify_sessions', 'Modificar Sesiones')}</p>,
      key: 'edit_sessions',
      visible: canUpdateProductSessions(),
    },
  ];
  const updateActiveField =(suscription,ref_sessions,ref_days)=>{
    let current_days_left = formatSuscriptionDaysLeft(
      suscription.started_at,
      suscription.days_left
    );
    
        let hasSessions = ref_sessions != null ? (ref_sessions > 0) : true;

          if(hasSessions && suscription.sessions_left <= 0  && current_days_left > 0) return true
        if(!hasSessions) return false
        let hasDays = ref_days ? suscription.days_left + (ref_days - formatSuscriptionDaysLeft(suscription.started_at, suscription.days_left)) < 0 ? false : true : true;
        if(hasDays && current_days_left< 0 && suscription.sessions_left > 0) return true
        if(!hasDays) return false
        return suscription.active
    
  }
  const showPromiseConfirm = (key, suscription) => {
    confirm({
      title: `${t('modify', 'Modificar')} ${
        key == 'edit_days'
          ? t('days_left_membership', 'días restantes a la membresia')
          : key == 'edit_start'
          ? t('start_date_membership', 'fecha de inicio')
          : t('sessions_left_membership', 'sesiones restantes a la membresia')
      }`,
      cancelButtonProps: {
        className: 'text-white',
      },
      icon: <></>,
      content: (
        <div>
          {key == 'edit_start' ? (
            <ConfigProvider locale={es_ES}>
              <DatePicker
                defaultValue={false}
                placeholder={t(
                  'enter_new_start',
                  'Ingrese nueva fecha de inicio'
                )}
                ref={datepickerRef}
                allowClear={false}
                value={newStartDate}
                onChange={(date, dateString) => {
                  datepickerRef.current.blur();
                  // setnewStartDate(date);
                  new_start_dateRef.current = dateString;
                }}
                // name='newStartDate'
                // format={'YYYY-MM-DD'}
                showToday
                className=' shadow-none bg-primaryGrey outline-none border-none  py-2 rounded-sm w-full  my-2 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
              />
            </ConfigProvider>
          ) : (
            <div className='flex flex-row items-center justify-between my-3'>
              <div>
                <p className=' text-secondaryGrey opacity-80'>
                  {t('actual', 'Actuales')}
                </p>
                <div className='py-2 my-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey'>
                  {key == 'edit_days'
                    ? formatSuscriptionDaysLeft(
                        suscription.started_at,
                        suscription.days_left,
                        customer_id
                      )
                    : suscription.sessions_left}
                </div>
              </div>
              <BsArrowRight
                className='mx-4 self-center mt-4'
                color={'#ADFF19'}
                size={22}
              />
              <div>
                <span className=' text-secondaryGrey'>
                  {t('new_amout_of', 'Nueva cantidad de')}{' '}
                  {key == 'edit_days'
                    ? t('days', 'Días')
                    : t('sessions', 'Sesiones')}
                </span>

                <input
                  placeholder={`Ingrese aquí...`}
                  className='rounded-sm py-2 appearance-none  outline-none w-full !text-white my-2'
                  type={'number'}
                  min={1}
                  max={366}
                  inputMode='numeric'
                  pattern='[0-9]*'
                  value={
                    key == 'edit_days'
                      ? newDaysAmountRef.current
                      : new_sessions_amountRef.current
                  }
                  // value={new_days_amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      // Solo permitir números
                      const numericValue = parseInt(value, 10);
                      if (!isNaN(numericValue)) {
                        if (key === 'edit_days') {
                          newDaysAmountRef.current = numericValue;
                        } else {
                          new_sessions_amountRef.current = numericValue;
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
          <span className='text-primaryRed'>
            {t(
              'auditory_save_warn',
              'Recordá que esta modificacion quedara guardada en auditoria'
            )}
          </span>
        </div>
      ),
      okText: t('confirm', 'Confirmar'),
      cancelText: t('cancel', 'Cancelar'),
      onOk() {
        return new Promise((resolve, reject) => {
          updateMembership(suscription, key, resolve, reject);
        });
      },
      onCancel() {
        newDaysAmountRef.current = null;
        new_sessions_amountRef.current = null;
      },
    });
  };

  const updateMembership = async (suscription, key, resolve, reject) => {
    let current_days_left = formatSuscriptionDaysLeft(
      suscription.started_at,
      suscription.days_left
    );

     
    let updatedMembership = {
      id: suscription.id,
      data: {
        owner_id: customer_id,
        owner_type: isProspect ? 'Prospect' : 'Member',
        membership_id: suscription.membership_id,
        point_of_sale_id: suscription.point_of_sale_id,
        membership: suscription.membership,
        active: updateActiveField(suscription,new_sessions_amountRef.current,newDaysAmountRef.current),
      },
    };

    if (key == 'edit_days') {
      if (newDaysAmountRef.current > current_days_left) {
        updatedMembership.data.days_left =
          suscription.days_left +
          (newDaysAmountRef.current - current_days_left);
      } else {
        updatedMembership.data.days_left =
          suscription.days_left -
          (current_days_left - newDaysAmountRef.current);
      }
    } else if (key == 'edit_start') {
      updatedMembership.data.started_at = new_start_dateRef.current;
    } else if (key == 'edit_sessions') {
      updatedMembership.data.sessions_left = new_sessions_amountRef.current;
    }
    newDaysAmountRef.current = null;
    new_sessions_amountRef.current = null;
    // return console.log('updatedMembership', updatedMembership);
    try {
      await useUpdateMembership.mutateAsync(updatedMembership);
      resolve();
    } catch (error) {
      reject(error);
    }
  };
  
  const validSubscriptions = products.filter((suscription) =>
    isValidSuscription(
      suscription.started_at,
      suscription.days_left,
      suscription.sessions_left,
      suscription.membership.product.parent_product_id,
      customer_type,
      suscription.owner_type
    )
  );
  const invalidSubscriptions = products
    .filter(
      (suscription) =>
        !isValidSuscription(
          suscription.started_at,
          suscription.days_left,
          suscription.sessions_left,
          suscription.membership.product.parent_product_id,
          customer_type,
          suscription.owner_type
        ) && suscription.membership.product.parent_product_id !== 32,
    )
    .sort((a, b) => {
      const dateA = dayjs(a.started_at);
      const dateB = dayjs(b.started_at);
      if (dateA.isBefore(dateB)) {
        return 1;
      }
      if (dateA.isAfter(dateB)) {
        return -1;
      }
      return 0;
    });
  return (
    <div className='mb-10'>
    {!showInvalid &&
    <>
    <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
        {t('active_products', 'Productos Activos')}
      </h3>
      <CardRounded>
        {validSubscriptions.length == 0 && (
          <div className='flex flex-row items-center ml-4'>
            <p className='text-white  my-7'>
              {t('the', 'El') + ' '}
              {customer_type == 'Member'
                ? t('member', 'Socio')
                : t('prospect', 'Prospecto')}{' '}
              {t('cc_no_activeProduct', 'no posee un producto activo')}
            </p>
          </div>
        )}
        {validSubscriptions.map((suscription, index) => (
          <SuscriptionsList
            customer_id={customer_id}
            suscription={suscription}
            customer_type={customer_type}
            editProduct={editProduct}
            showPromiseConfirm={showPromiseConfirm}
            index={index}
          />
        ))}
        
      </CardRounded>
      </>}
      {invalidSubscriptions.length > 0 && showInvalid && (
        <div className='pt-10'>
          <div className='flex flex-row items-center justify-between'>
            <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
              {/* {t('expired_products', 'Productos Vencidos')} */}
              {t('history_products', 'Historial de productos')}

            </h3>
          </div>
          <CardRounded>
            {invalidSubscriptions
              .slice(0, showExpireProducts ? invalidSubscriptions.length : 2)
              .map((suscription, index) => (
                <SuscriptionsList
                  expired
                  customer_id={customer_id}
                  suscription={suscription}
                  editProduct={editProduct}
                  showPromiseConfirm={showPromiseConfirm}
                  index={index}
                />
              ))}

            {invalidSubscriptions.length > 2 && (
              <button
                onClick={() =>
                  showExpireProducts
                    ? setshowExpireProducts(false)
                    : setshowExpireProducts(true)
                }
                className='flex flex-row items-center gap-2  text-center w-full justify-center bg-primaryGrey text-white py-2 rounded-sm  ease-in-out duration-300 transition-opacity  hover:opacity-80 '
              >
                <span>{showExpireProducts ? 'Ocultar' : 'Ver todos'}</span>
                {showExpireProducts ? (
                  <BsChevronUp
                    size={22}
                    className='cursor-pointer text-white'
                  />
                ) : (
                  <BsChevronDown
                    size={22}
                    className='cursor-pointer text-white'
                  />
                )}
              </button>
            )}
          </CardRounded>
        </div>
      )}
    </div>
  );
}
