import React from 'react';
import { createdAtColumn, generateColumn ,IDCOLUMN,product_type_column,sorterText} from './ColumnUtils';
import { formatPrice } from '../utils/formatPrice';
import { BiCheckboxChecked } from 'react-icons/bi';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const commonColumns = () =>{
    const { t, ready } = useTranslation();
  if (!ready) return [];
   return  [
  IDCOLUMN,
  generateColumn(t('first_name', 'Nombre'), 'name','12%',false,sorterText('name')),
  generateColumn(t('description', 'Descripción'), 'description','10%',false,sorterText('description')),
     ]}
const CheckboxRenderer = (text) => <span>{text ? <BiCheckboxChecked size={29} color='#1EA05A' /> : ''}</span>;
const PriceRenderer = (text) => <span>{formatPrice(text)}</span>;
const ActiveStateRenderer = (text) => <span>{text == 1 ? t('active', 'Activo') : t('paused','Pausado')}</span>;


const RecurrentColumn = ()=> generateColumn(t('recurrent','Cobro recurrente'), 'recurrent', '10%', CheckboxRenderer,{
    ...sorterText('recurrent'),
    filters:[{
        text:t('yes_singular','Si'),
        value:true
    },{
        text: t('no_singular','No'),
        value:false
    }],
    onFilter:(value,record) => value ?  record.recurrent : !record.recurrent
   
});
const PriceColumn = (dataIndex = 'price') => generateColumn(t('price','Precio'), dataIndex, '10%', PriceRenderer, sorterText(dataIndex));

export const USEProductColumns = ()=>
{
  const { t, ready } = useTranslation();
if (!ready) return [];
const ProductCommons = commonColumns()
const useproduct_type_column = product_type_column();
const useRecurrentColumn = RecurrentColumn()
return [
 ...ProductCommons,
 useproduct_type_column,
    // generateColumn('Tipo producto', 'product_type_name', '10%'),
    generateColumn(t('region_config', 'Conf. Regional'), 'region_config_type_name', '10%',false,sorterText('region_config_type_name')),
    useRecurrentColumn,
    generateColumn(t('available_config','Región Disponible'), 'available_region_config_type_name', '10%',false,sorterText('available_region_config_type_name')),
    PriceColumn(),
    createdAtColumn,
];
}
export const USEConsumablesColumns = ()=>
{
  const { t, ready } = useTranslation();
if (!ready) return [];
return [
    IDCOLUMN,
    generateColumn(t('product.id_product','ID Producto'), 'product_id', '7%', false),
    generateColumn(t('first_name', 'Nombre'), '', '15%', (_, record) => <span>{record.product.name}</span>),
    generateColumn(t('brand', 'Marca'), 'brand', '10%'),
    generateColumn(t('description', 'Descripción'), '', '10%', (_, record) => <span>{record.product.description}</span>),
    generateColumn(t('price','Precio'), '', '10%', (_, record) => PriceRenderer(record.product.price)),
    createdAtColumn,
]}
export const USEMembershipColumns = ()=>
{
  const { t, ready } = useTranslation();
if (!ready) return [];
const ProductCommons = commonColumns()

return [
    ...ProductCommons,
    generateColumn('Sesiones', 'sessions_amount', '8%', false, sorterText('sessions_amount')),
    generateColumn('Días', 'days_amount', '8%', false, sorterText('days_amount')),
    generateColumn('Cobro Recurrente', '', '10%', 
    (text,record) => <span>{record.product.recurrent ? <BiCheckboxChecked size={29} color='#1EA05A' /> : ''}</span>
    ,{
        ...sorterText('recurrent'),
        filters:[{
            text:'Si',
            value:true
        },{
            text:'No',
            value:false
        }],
        onFilter:(value,record) => value ?  record.product.recurrent : !record.product.recurrent
       
    
    }),
    PriceColumn(),
    generateColumn(t('state_singular', 'Estado'), 'status_id', '10%', ActiveStateRenderer, {
        sorter: (a, b) => (a.status_id > b.status_id ? 1 : -1),
        filters: [
            { text: t('active', 'Activo'), value: 1 },
            { text: t('paused','Pausado'), value: 2 }
        ],
        onFilter: (value, record) => record.status_id == value,
    }),
    createdAtColumn,
];
}
export const USESessionsColumns = ()=>
{
  const { t, ready } = useTranslation();
if (!ready) return [];
  return [  IDCOLUMN,
    generateColumn( t('product', 'Producto'), 'name', '15%', (text, record) => <span>{record.product.name}</span>,),
    generateColumn(t('description', 'Descripción'), 'description', (text, record) => <span>{record.product.description || ""}</span>,),
    generateColumn(t('sessions', 'Sesiones'), 'sessions_amount', false, ),
    generateColumn(t('days', 'Días'), 'days_amount',false),
    createdAtColumn
]
}
export const USEDiscountsColumns = ()=>
{
  const { t, ready } = useTranslation();
if (!ready) return [];
const ProductCommons = commonColumns()
const useRecurrentColumn = RecurrentColumn()

  return [ 
    ...ProductCommons,
    useRecurrentColumn,
    generateColumn(t('active', 'Activo'), 'active', '10%', CheckboxRenderer,{
        ...sorterText('active'),
        filters:[{
            text:'Si',
            value:true
        },{
            text:'No',
            value:false
        }],
        onFilter:(value,record) => value ?  record.active : !record.active
       

    }),
    generateColumn(t('region_config', 'Conf. Regional'), 'region_config_type_name', '10%'),
    generateColumn(t('total_singular', 'Total'), 'amount', '10%', (text) => <span>{parseFloat(text)}%</span>),
    createdAtColumn,
];
}