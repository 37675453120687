 import React,{useEffect} from "react";
import { redirect, useNavigate, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
export default function NotFound() {
  const error = useRouteError();
  // Enviar el error a Sentry
  const navigate = useNavigate();

  useEffect(() => {
    // Enviar el error a Sentry
    Sentry.captureException(error);

    // Redirigir después de un tiempo o inmediatamente
    const timer = setTimeout(() => {
      navigate('/', { replace: true });
    }, 1000); // Puedes ajustar el tiempo de espera en milisegundos

    // Limpiar el temporizador en caso de que el componente se desmonte
    return () => clearTimeout(timer);
  }, [error, navigate]);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Ocurrio un error</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}