import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { createUser, deleteUser, updateUser } from '../api/users';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { USEUsersColumns  } from '../services/Constants/UsersColumns';
import { getDocumentTypes, getRoles, getUser } from '../api/users';
import { getLocationId, getMaster_account_id } from '../services/utils/getLocation';
import { useGetUsers } from '../router/loaders/UsersLoaders';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { getAvailableLocations, getLocations, useGetPointOfSales } from '../api/locations';

import { canCreateHQUser, canCreateManagerUser, canCreateOwnerUser, canCreateSalesUser, canCreateUser, canUpdateUsers, isAdmin } from '../services/can_user';
import { createCoach, createCoachLocation } from '../api/coaches';
import { useGlobalContext } from '../context/GlobalState';
import { createMember } from '../api/members';
import { createProspect } from '../api/prospects';
import { t } from 'i18next';
import { USE_FORM_USER } from '../services/Constants/FORMS';

export default function Users() {
  const originalColumns = USEUsersColumns();
const TITLE = t('users.users','Usuarios');
const TITLE_SINGULAR = t('users.user','Usuario');
const FORM_USER = USE_FORM_USER()
  const { state: user } = useGlobalContext();
  const { data, isLoading } = useQuery(useGetUsers());
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const canCreateHQ = canCreateHQUser()
  const canCreateManager = canCreateManagerUser()
  const canCreateOwner = canCreateOwnerUser()
  const canCreateSales = canCreateSalesUser()
  const { data: point_of_sales, isLoading: isLoadingPoints } = useGetPointOfSales();
  const isAdminUser =  isAdmin(user?.user?.roles)
  const [columns, setColumns] = useState(originalColumns);

  useEffect(() => {
    const createFilters = (data, key) =>
      [...new Set(data.map((d) => d[key]))].flat()
      .reduce((acc, value) => {
       if(!acc.some((item) => item.value == value.id)){
        acc.push({ text: value.name, value:value.id });
       }
        return acc;
      } ,[]);
    setfilterTable(isLoading ? [] : data);
    if (data) {
      setColumns(
        columns.map((column) => {
          if (column.dataIndex === 'roles') {
            return {
              ...column,
              filters: createFilters(data, column.dataIndex),
              onFilter: (value, record) => record.roles.some((r) => r.id == value),
            };
          }
     
          return column;
        }))
      }
  }, [data]);
  
 
  const handleDelete = async (id) => {
    let user = {
      user_id: id,
      id: id,
      observation: data.observation,
      user_attributes: {
        ...data.find((user) => user.id == id),
        id: id,
        user_id: id,
        _destroy: true,
        master_account_id: getMaster_account_id(),
      },
    };
    // return console.log('user',user);
    await useDeleteUser.mutateAsync(user);
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('user_success','Usuario actualizado correctamente')
          :  t('user_error','Ocurrió un error al actualizar el usuario'),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_USER);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let user = {
        user: {
          ...data,
          master_account_id: getMaster_account_id(),
          observation: data.observation,
        },
      };
      if (data.roles) {
        user.user.role_ids = data.roles;
      }
      await useCreateUser.mutateAsync(user);
      return setopenDrawer({ visible: false, record: null });
    }
    let user = {
      user_id: openDrawer.record.id,
      id: openDrawer.record.id,
      user: {
        master_account_id: getMaster_account_id(),
        // observation: data.observation,
        ...data,
        birthdate: data.birthdate ? data.birthdate : '',
      },
    };
    if (data.roles) {
      user.user.role_ids = user.user.roles;
      // delete user.roles
    }
    if(user.user.roles.some((r)=>r == 3) &&(openDrawer.record && !openDrawer?.record?.coach_id)){
      let coach = {
        user_id: openDrawer.record.id,
        user_attributes: {
          id: openDrawer.record.id,
          user_id: openDrawer.record.id,
        ...user.user,
        },
      };
      await useCreateCoach.mutateAsync(coach)
    }   
    await useUpdateUser.mutateAsync(user);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let user_fetched;
        if (record) {
          user_fetched = await getUser(record.id);
          FORM.forEach((item) => {
            if (item.name === 'roles') {
              setValue(
                'roles',
                user_fetched.roles.map((item) => item.id)
              );
            } else if (item.name === 'location_ids') {
              setValue(
                'location_ids',
                user_fetched.locations.map((item) => item.id)
              );
            } else {
              if(item.name == 'birthdate' && user_fetched[item.name] ==null){
                return setValue(item.name, false);
              }
              setValue(item.name, user_fetched[item.name]);
            }
          });
        }
        const roles = await getRoles();
        const document_types = await getDocumentTypes();
        const locations = await getAvailableLocations();
        const locations_all = await getLocations();
        setFORM(
          FORM.map((item) => {
            if (item.name === 'roles') {
              return {
                ...item,
                options: roles.map((item) => ({
                  label: item.name,
                  value: item.id,
                  disabled: isAdminUser ? false :item.id == 1 ? true: item.id == 2 ? !canCreateManager : item.id == 3 ? false : item.id == 4 ? true : item.id == 5 ? !canCreateSales : item.id == 6 ? !canCreateOwner : item.id == 7 ? !canCreateHQ : true ,
                
                })),
              };
            }
            if (item.name === 'document_type_id') {
              return {
                ...item,
                options: document_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'location_ids') {
              return {
                ...item,
                options: locations_all.map((item) => ({
                  label: item.name,
                  value: item.id,
                  // removeIcon
                  disabled: isAdmin ? false : !locations.locations.map((item) => item.id).includes(item.id) ? true : false,
                }))
                // .filter((l)=> locations.locations.map((item) => item.id).includes(l.value) ? true : false)
              };
            }
            return item;
          })
        );
        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateUser = useMutation({
    mutationFn: (user) => updateUser(user.id, user),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries('users');
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key}: ${errorParse[key]}`)
      );
    },
  });
  const useCreateUser = useMutation({
    mutationFn: (user) => createUser(user),
    onSuccess:async(data) => {
      if(data.roles.some((r)=>r.id == 3)){
        let coach = {
          user_id: data.id,
          user_attributes: {
            id:data.id,
            user_id: data.id,
        ...data,
        role_ids: data.roles.map((r)=>r.id),
        // roles: data.role_ids,
          },
        };
        await useCreateCoach.mutateAsync(coach)
      }
      let userObj = {
        location_id: getLocationId(),
        coach_id:1,
        user_id: data.id,
        user_attributes:{
          id:data.id,
          user_id: data.id,
        }
      }
      let prospectObj = {
        approach_type_id:15,
        channel_id:5,
        point_of_sale_id:point_of_sales[0].id,
        ...userObj
      }
    const prospect = await createProspect(prospectObj)
    let memberObj = {
      prospect_id:prospect.id,
      ...userObj
    }
    const member = await createMember(memberObj)
    // const legacy = await create_legacy_member(member.id,`email=${member.email}&name=${member.first_name}&lastname=${member.last_name}&box=2&box_id=2`);

      openNotification('success');
      queryClient.invalidateQueries('users');
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key}: ${errorParse[key]}`)
      );
    },
  });
  const useDeleteUser = useMutation({
    mutationFn: (user) => deleteUser(user.user_id, user),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries('users');
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key}: ${errorParse[key]}`)
      );
    },
  });
  const useCreateCoach = useMutation({
    mutationFn: (coach) => createCoach(coach),
    onSuccess: async (data) => {
      const location_id = getLocationId()
      const coach_location = {
        location_id: location_id,
        coach_id: data.id,
      }
      const result = await createCoachLocation(coach_location)
      openNotification('success', t('profile_coach_created','Perfil de coach creado correctamente'));
      queryClient.invalidateQueries({
        queryKey:['coaches',location_id]
      });
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={canCreateUser()}
        canUpdate={canUpdateUsers()}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        handleDelete={handleDelete}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useCreateUser.isLoading || useUpdateUser.isLoading}
      />
      {contextHolder}
    </>
  );
}
