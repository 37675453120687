import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';

export default function ActiveMembersTooltip({ active, payload, label }) {
  if (!active || !payload) return null;

  const totalSum = useMemo(() => {
    return payload.reduce((acc, item) => acc + item.value, 0);
  }, [payload]);

  return (
    <div className='bg-background w-64 rounded-md px-3 py-2'>
      <p className='font-MessinaSansSemiBold mb-2 text-lg capitalize'>
        {dayjs(payload[0]?.payload.date).format('MMMM YYYY')}
      </p>
      <p className='font-MessinaSansSemiBold'>
        Tasa de uso:
        {/* {dayjs(payload[0]?.payload.date).format('MMMM YYYY')} */}
      </p>
      {payload.map((item, index) => {
        const percentage = ((parseFloat(item.value) / totalSum) * 100).toFixed(1);
        const labelText = {
          'no_reserve': t('no_reserve', 'Sin reservas'),
          '8 or more': t('8_or_more', '8 o más'),
          // '7 or less': t('7_or_less', '7 o menos'),
          '7 uses': t('7_uses', '7 usos'),
          '6 uses': t('6_uses', '6 usos'),
// 
          // '4 or less': t('4_or_less', '4 o menos')
          '5 or less': t('5_or_less', '5 o menos')

        }[item.name] || item.name;

        return (
          <div key={index}>
            <p style={{ color: item.stroke }} className='font-MessinaSansSemiBold'>
              {labelText} ({percentage}%): {item.value}
            </p>
          </div>
        );
      })}
      <p className='font-MessinaSansSemiBold mt-2'>
        {t('total_pay_members',  'Total socios pagos')}: {totalSum}
      </p>
    </div>
  );
}
