import React from 'react'

export default function TextArea({placeholder,value,onChange,styles}) {
  return (
    <textarea 
    // ref={ref}
    autoFocus
    value={value}
    onChange={onChange}
    onBlur={()=>{
      console.log('blue');
    }}
    className={`bg-primaryGrey min-h-[10rem] rounded-md resize-none  p-6 outline-none w-[63%] ${styles}`} placeholder={placeholder}></textarea>

  )
}
