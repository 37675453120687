import {  Dropdown, Modal } from 'antd';
import React, { useRef } from 'react';
import { BsChevronLeft, BsThreeDots } from 'react-icons/bs';
import SpinIndicador from '../helpers/SpinIndicador';
import { getMaster_account_id } from '../../services/utils/getLocation';
import { modify_password } from '../../api/users';
import { formatError } from '../../services/utils/formatError';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { track_modify_password } from '../../services/utils/CustomerEvents';
import { customerIoIdentify } from '../../services/utils/CustomerIoUtils';
import { useMediaQuery } from 'react-responsive';
const { confirm } = Modal;

export default function Header({
  menu_selected,
  setMenu_selected,
  setModalMemberOpen,
  handleSaveMember,
  isLoading,
  FORM_MEMBER,
  member,
  isProspect,
  openNotification,
  customer_type
}) {
  const { t, ready } = useTranslation();
  if (!ready) return null;
  const menuItems = 
  customer_type== 'Lead' ?
  [
    t('header_member.general', 'General'),
  ]:
  [
    t('header_member.general', 'General'),
    t('products', 'Productos'),
    t('training', 'Entrenamiento'),
    t('tasks', 'Tareas'),
  ];
 
  const new_user_password = useRef();
  const user = useAuth();
  const handleModalBgClick = () => {
    setModalMemberOpen({ id: null });
  };
  const useUpdatePassword = useMutation({
    mutationFn: (data) => modify_password(data),
    onSuccess: (data) => {
     
      openNotification('success');
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });
  const canNotModifyPassword = () => {
    // Retorna true si no hay member o si el member no es el usuario y tiene alguno de los roles 1 (admin), 7 (HQ), o 6 (owner).
    if (
      !member ||
      (member.user_id !== user.user.user_id &&
        member?.roles?.some((r) => [1, 7, 6].includes(r.id)))
    ) {
      // si el user es admin o hq puede
      if (user.state.user.roles.some((r) => [1, 7].includes(r.id))) {
        return false;
      }
      return true;
    }

    // Retorna false si alguna de las ubicaciones disponibles coincide con la ubicación del miembro.
    if (
      user.state.availableLocations.some(
        (location) => location.id === member.location_id
      )
    ) {
      return false;
    }

    return true;
  };
  const items = [
    {
      label: <p>{t('modify_password', 'Modificar contraseña')}</p>,
      key: 'modify_password',
      disabled: canNotModifyPassword(),
    },
    // {
    //   label: <p>Activar vacaciones</p>,
    //   key: 'vacation',
    //   disabled: true,
    // },
    // {
    //   type: 'divider',
    // },
    // {
    //   label: <p className=' text-primaryRed'>Borrar socio</p>,
    //   key: 'delete_member',
    //   disabled: true,
    // },
  ];
  const handleSave = () => {
    const user_attributes = {
      id: member.user_id,
      master_account_id: getMaster_account_id(),
      document_type_id: FORM_MEMBER.document_type_id,
      email: FORM_MEMBER.email.replace(/\s/g, '').toLowerCase(),
      phone: FORM_MEMBER.phone,
      first_name: FORM_MEMBER.first_name.trimEnd(),
      last_name: FORM_MEMBER.last_name.trimEnd(),
      gender: FORM_MEMBER.gender,
      document_number: FORM_MEMBER.document_number,
      birthdate: FORM_MEMBER.birthdate,
      observation: FORM_MEMBER.observation,
      username: FORM_MEMBER.username?.replace(/\s/g, ''),
    };
    const prospect_data = {
      discharge_reason_id: FORM_MEMBER.discharge_reason_id,
      channel_id: FORM_MEMBER.channel_id,
      approach_type_id: FORM_MEMBER.approach_type_id,
      campaign_id: FORM_MEMBER.campaign_id,
      point_of_sale_id: FORM_MEMBER.point_of_sale_id,
      referrer_id: FORM_MEMBER.referrer_id,
      owner_user_id: FORM_MEMBER.owner_user_id,
      coach_id: !isProspect ? FORM_MEMBER.prospect_coach_id : FORM_MEMBER.coach_id,
    };
    let data = {
      coach_id: FORM_MEMBER.coach_id,
      observation: FORM_MEMBER.observation,
      location_id: FORM_MEMBER.location_id,
      user_id: member.user_id,
      user_attributes,
      coach: FORM_MEMBER.coach,
      instagram: FORM_MEMBER.instagram,
    };
    if (isProspect || member.prospect_id) {
      // data = {...data,...prospect_data}
    }
    handleSaveMember(data, prospect_data);
  };
  const showPromiseConfirm = () => {
    confirm({
      title: t('modify_password', 'Modificar contraseña'),
      icon: <></>,
      cancelText: t('cancel', 'Cancelar'),
      cancelButtonProps: {
        // type:''
        className: 'text-white hover:text-white',
        // danger: true,
      },
      okText: 'Modificar',
      content: (
        <div>
          <input
            placeholder={t('new_password', `Ingrese nueva contraseña`)}
            className='rounded-sm py-2 appearance-none  outline-none w-full !text-white my-2'
            type={'text'}
            value={new_user_password.current}
            onChange={(e) => {
              new_user_password.current = e.target.value;
            }}
          />
        </div>
      ),
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            await useUpdatePassword.mutateAsync({
              user_id: member.user_id,
              new_password: new_user_password.current,
            });
            customerIoIdentify(member.user_id);
            track_modify_password({ user_id: member.user_id,current_user_id:user.user.user_id,
              new_password: new_user_password.current,
             })
            new_user_password.current = null;
            resolve();
          } catch (error) {
            new_user_password.current = null;
            reject();
          }
        });
      },
      onCancel() {
        new_user_password.current = null;
      },
    });
  };
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  return (
    <div className=' z-10 flex jic modal-members-header mb-7'>
      <button
        onClick={handleModalBgClick}
        className='flex w-[30%] bg-red gap-2 underline '
      >
        <BsChevronLeft className=' self-center' />
        { !isMobile &&  t('back', 'Volver')}
      </button>
      <div className='flex jic  w-[100%] flex-wrap lg:w-[70%] pl-11 '>
        <div className='w-[70%] flex jic gap-2 lg:gap-0'>
          {menuItems.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                console.log('item', item);
                setMenu_selected(item);
              }}
              className={`${menu_selected == item && ' border-b-[1px] '}`}
            >
              <span className='text-white'>   {item}</span>
           
            </button>
          ))}
        </div>
        <div className='flex flex-row items-center  gap-2 lg:gap-4  mt-2 lg:mt-0'>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key == 'modify_password') {
                  showPromiseConfirm();
                }
              },
            }}
            trigger={['click']}
          >
            <button className=' bg-lightGreen rounded-lg px-3 py-1 hover:opacity-80 ease-in-out duration-500'>
              <BsThreeDots size={30} color='#1EA05A' />
            </button>
          </Dropdown>
          <button
            disabled={isLoading}
            onClick={() => handleSave()}
            className=' bg-lightGreen rounded-lg px-3 py-1 hover:opacity-80 ease-in-out duration-500 flex flex-row items-center gap-2'
          >
            <p className=' font-MessinaSansBold  text-darkGreen text-md py-1'>
              {t('save', 'Guardar')}
            </p>
            {isLoading && <SpinIndicador />}
          </button>
        </div>
      </div>
    </div>
  );
}
