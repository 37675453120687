import React from 'react'

export default function Texture() {
  return (
    <svg width="911" height="979" viewBox="0 0 911 979" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_13580_1420)">
    <rect width="911" height="979" fill="#111111"/>
    <path d="M-41.2945 629.573L-21.5054 648.682V629.573H-0.0167863V671.634H-21.9024L-44.0739 650.604V671.634H-65.5625V629.573H-41.3025H-41.2945Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M75.6544 629.573V641.331H47.0426V644.721H75.2017V656.423H47.0426V671.634H25.8955V629.573H75.6623H75.6544Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M102.971 671.634H81.5382V629.573H102.971V671.634Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M126.836 644.046H108.683V629.573H166.478V644.046H148.325V671.634H126.836V644.046V644.046Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M196.456 629.573L216.245 648.683V629.573H237.734V671.634H215.848L193.677 650.604V671.634H172.188V629.573H196.448H196.456Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M294.797 629.573V641.331H265.224V644.61H294.463V656.145H265.224V659.765H295.027V671.634H244.077V629.573H294.805H294.797Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M327.205 672.483C301.698 672.483 299.379 663.211 299.379 657.78V657.328H324.544C324.544 659.138 325.338 661.853 331.215 661.853C336.194 661.853 337.663 660.44 337.663 658.916V658.749C337.663 657.336 337.099 656.034 329.857 655.756L320.979 655.47C303.675 654.851 300.054 648.746 300.054 642.125V641.617C300.054 633.701 306.391 628.843 326.744 628.843H333.923C356.483 628.843 361.748 634.837 361.748 642.069V642.355H337.091C336.924 640.997 336.241 638.965 330.643 638.965C325.497 638.965 324.536 639.759 324.536 641.172V641.283C324.536 642.807 326.172 643.657 332.676 643.824L341.157 644.046C357.555 644.498 363.329 648.452 363.329 656.994V657.788C363.329 665.251 358.524 672.491 334.598 672.491H327.189L327.205 672.483Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M395.182 672.483C369.675 672.483 367.356 663.211 367.356 657.78V657.328H392.522C392.522 659.138 393.316 661.853 399.192 661.853C404.171 661.853 405.64 660.44 405.64 658.916V658.749C405.64 657.336 405.077 656.034 397.834 655.756L388.956 655.47C371.653 654.851 368.031 648.746 368.031 642.125V641.617C368.031 633.701 374.368 628.843 394.722 628.843H401.9C424.461 628.843 429.726 634.837 429.726 642.069V642.355H405.069C404.902 640.997 404.219 638.965 398.621 638.965C393.475 638.965 392.514 639.759 392.514 641.172V641.283C392.514 642.807 394.15 643.657 400.653 643.824L409.134 644.046C425.533 644.498 431.306 648.452 431.306 656.994V657.788C431.306 665.251 426.502 672.491 402.575 672.491H395.166L395.182 672.483Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M505.625 629.573V641.331H477.013V644.721H505.172V656.423H477.013V671.634H455.866V629.573H505.633H505.625Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M511.112 629.573H532.712V646.872C532.712 651.564 534.69 656.542 543.679 656.542H543.79C552.613 656.542 554.765 651.564 554.765 646.872V629.573H576.364V649.079C576.364 657.503 573.14 672.372 545.092 672.372H542.265C514.217 672.372 511.104 657.503 511.104 649.079V629.573H511.112Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M606.572 629.573L626.361 648.682V629.573H647.849V671.634H625.964L603.792 650.604V671.634H582.304V629.573H606.564H606.572Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M723.52 629.573V641.331H694.908V644.721H723.067V656.423H694.908V671.634H673.761V629.573H723.528H723.52Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M750.831 671.634H729.398V629.573H750.831V671.634Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M774.702 644.046H756.549V629.573H814.344V644.046H796.191V671.634H774.702V644.046V644.046Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M844.312 629.573L864.101 648.683V629.573H885.59V671.634H863.704L841.532 650.604V671.634H820.044V629.573H844.304H844.312Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M942.655 629.573V641.331H913.082V644.61H942.321V656.145H913.082V659.765H942.885V671.634H891.935V629.573H942.663H942.655Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M5.52684 890.039C-19.9799 890.039 -22.2987 880.766 -22.2987 875.336V874.884H2.8666C2.8666 876.694 3.66073 879.409 9.53713 879.409C14.5162 879.409 15.9853 877.996 15.9853 876.471V876.305C15.9853 874.891 15.4215 873.589 8.17924 873.312L-0.69894 873.026C-18.0026 872.407 -21.6237 866.301 -21.6237 859.68V859.172C-21.6237 851.257 -15.2867 846.398 5.0663 846.398H12.2451C34.8057 846.398 40.0706 852.392 40.0706 859.625V859.911H15.4136C15.2468 858.553 14.5639 856.521 8.9654 856.521C3.81957 856.521 2.85865 857.315 2.85865 858.728V858.839C2.85865 860.363 4.49452 861.213 10.9983 861.379L19.4794 861.602C35.8777 862.054 41.6508 866.008 41.6508 874.55V875.344C41.6508 882.807 36.8465 890.047 12.92 890.047H5.51095L5.52684 890.039Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M87.6927 877.718V889.197H66.2041V847.137H107.998C124.285 847.137 128.637 853.583 128.637 861.498V862.967C128.637 870.882 124.055 877.726 107.998 877.726H87.6927V877.718ZM87.6927 864.999H102.733C105.671 864.999 106.521 863.697 106.521 862.284V862.173C106.521 860.649 105.671 859.458 102.733 859.458H87.6927V864.999Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M184.399 847.129V858.887H154.827V862.165H184.066V873.701H154.827V877.321H184.63V889.19H133.68V847.129H184.407H184.399Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M211.994 889.19H190.506V847.129H236.993C246.038 847.129 253.566 849.392 253.566 857.759V858.267C253.566 864.539 247.682 867.199 243.838 867.993C250.564 869.295 253.788 873.081 253.788 878.567V884.958C253.788 887.046 254.074 888.015 254.527 888.856V889.198H232.419C232.133 888.912 232.133 888.523 232.133 887.896V882.807C232.133 878.512 230.044 875.741 223.993 875.741H212.002V889.198L211.994 889.19ZM227.662 863.92C230.6 863.92 231.561 862.793 231.561 861.26C231.561 859.728 230.545 858.601 227.662 858.601H211.994V863.912H227.662V863.92Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M309.265 847.129V858.887H280.653V862.276H308.812V873.979H280.653V889.19H259.506V847.129H309.273H309.265Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M349.812 846.279C378.257 846.279 382.553 858.378 382.553 867.199V868.897C382.553 877.551 378.202 890.039 349.812 890.039H346.985C318.651 890.039 314.244 877.543 314.244 868.897V867.199C314.244 858.378 318.54 846.279 346.985 846.279H349.812ZM360.39 867.54C360.39 863.864 357.563 859.68 348.343 859.68C339.124 859.68 336.352 863.864 336.352 867.54V868.215C336.352 872.002 339.29 876.527 348.343 876.527C357.396 876.527 360.39 872.176 360.39 868.326V867.532V867.54Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M409.475 889.19H387.986V847.129H434.473C443.518 847.129 451.047 849.392 451.047 857.759V858.267C451.047 864.539 445.162 867.199 441.319 867.993C448.045 869.295 451.269 873.081 451.269 878.567V884.958C451.269 887.046 451.555 888.015 452.007 888.856V889.198H429.899C429.614 888.912 429.614 888.523 429.614 887.896V882.807C429.614 878.512 427.525 875.741 421.474 875.741H409.483V889.198L409.475 889.19ZM425.143 863.92C428.081 863.92 429.042 862.793 429.042 861.26C429.042 859.728 428.025 858.601 425.143 858.601H409.475V863.912H425.143V863.92Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M456.978 847.129H485.311L495.206 865.841L505.498 847.129H532.474V889.19H511.096V868.382L499.788 889.19H489.552L478.299 868.382V889.19H456.978V847.129V847.129Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M581.168 884.164H560.133L557.925 889.197H536.206L556.059 847.137H586.259L607.35 889.197H583.376L581.168 884.164ZM570.535 860.077L565.501 871.724H575.681L570.535 860.077Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M635.349 847.129L655.138 866.238V847.129H676.627V889.19H654.741L632.57 868.159V889.19H611.081V847.129H635.341H635.349Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M714.801 890.039C686.07 890.039 682.06 877.654 682.06 868.897V867.198C682.06 858.378 685.848 846.279 714.801 846.279H716.779C744.66 846.279 748.217 857.926 748.217 864.142V864.991H726.443C726.157 864.086 725.252 859.68 715.468 859.68C705.685 859.68 704.16 863.864 704.16 867.54V868.214C704.16 871.835 706.026 876.527 715.468 876.527C724.91 876.527 726.268 871.779 726.443 870.763H748.217V872.232C748.217 878.114 744.652 890.039 716.779 890.039H714.801Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M804.266 847.128V858.886H774.693V862.164H803.932V873.7H774.693V877.32H804.496V889.189H753.546V847.128H804.274H804.266Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M879.705 847.128V858.886H851.094V862.276H879.253V873.978H851.094V889.189H829.946V847.128H879.713H879.705Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M885.193 847.129H906.793V864.428C906.793 869.12 908.771 874.098 917.76 874.098H917.871C926.694 874.098 928.846 869.12 928.846 864.428V847.129H950.446V866.635C950.446 875.058 947.221 889.928 919.173 889.928H916.346C888.298 889.928 885.186 875.058 885.186 866.635V847.129H885.193Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M4.80412 726.872C-20.7027 726.872 -23.0215 717.599 -23.0215 712.169V711.717H2.14378C2.14378 713.527 2.93791 716.242 8.81431 716.242C13.7934 716.242 15.2625 714.829 15.2625 713.304V713.138C15.2625 711.724 14.6987 710.422 7.45642 710.145L-1.42172 709.859C-18.7253 709.24 -22.3465 703.134 -22.3465 696.513V696.005C-22.3465 688.09 -16.0095 683.231 4.34347 683.231H11.5222C34.0829 683.231 39.3479 689.225 39.3479 696.458V696.744H14.6907C14.524 695.386 13.841 693.354 8.24258 693.354C3.09675 693.354 2.13583 694.148 2.13583 695.561V695.672C2.13583 697.196 3.77169 698.046 10.2754 698.212L18.7566 698.435C35.1549 698.887 40.9281 702.841 40.9281 711.383V712.177C40.9281 719.64 36.1237 726.88 12.1972 726.88H4.78822L4.80412 726.872Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M72.7807 726.873C47.2739 726.873 44.9551 717.6 44.9551 712.17V711.717H70.1204C70.1204 713.528 70.9145 716.243 76.7909 716.243C81.7699 716.243 83.239 714.83 83.239 713.305V713.139C83.239 711.725 82.6753 710.423 75.433 710.146L66.5549 709.86C49.2513 709.241 45.6301 703.135 45.6301 696.514V696.006C45.6301 688.091 51.967 683.232 72.32 683.232H79.4988C102.059 683.232 107.324 689.226 107.324 696.459V696.745H82.6673C82.5005 695.387 81.8176 693.355 76.2191 693.355C71.0733 693.355 70.1125 694.149 70.1125 695.562V695.673C70.1125 697.197 71.7484 698.047 78.2521 698.213L86.7331 698.436C103.131 698.888 108.905 702.842 108.905 711.384V712.178C108.905 719.641 104.1 726.881 80.1738 726.881H72.7648L72.7807 726.873Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M154.954 714.543V726.023H133.466V683.962H175.26C191.547 683.962 195.899 690.408 195.899 698.324V699.792C195.899 707.707 191.317 714.551 175.26 714.551H154.954V714.543ZM154.954 701.825H169.995C172.933 701.825 173.783 700.523 173.783 699.11V698.998C173.783 697.474 172.933 696.283 169.995 696.283H154.954V701.825Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M251.653 683.962V695.72H222.081V698.998H251.32V710.534H222.081V714.154H251.884V726.023H200.934V683.962H251.661H251.653Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.257 726.024H257.769V683.963H304.256C313.301 683.963 320.829 686.226 320.829 694.593V695.101C320.829 701.373 314.944 704.033 311.101 704.827C317.827 706.129 321.051 709.915 321.051 715.401V721.792C321.051 723.88 321.337 724.849 321.79 725.69V726.032H299.682C299.396 725.746 299.396 725.357 299.396 724.73V719.641C299.396 715.346 297.307 712.575 291.256 712.575H279.265V726.032L279.257 726.024ZM294.925 700.754C297.863 700.754 298.824 699.627 298.824 698.094C298.824 696.562 297.808 695.435 294.925 695.435H279.257V700.746H294.925V700.754Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M376.518 683.962V695.72H347.906V699.11H376.065V710.812H347.906V726.023H326.759V683.962H376.526H376.518Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M417.075 683.112C445.52 683.112 449.816 695.211 449.816 704.032V705.731C449.816 714.384 445.464 726.872 417.075 726.872H414.248C385.914 726.872 381.507 714.376 381.507 705.731V704.032C381.507 695.211 385.803 683.112 414.248 683.112H417.075ZM427.652 704.373C427.652 700.697 424.825 696.513 415.606 696.513C406.386 696.513 403.615 700.697 403.615 704.373V705.048C403.615 708.835 406.553 713.36 415.606 713.36C424.659 713.36 427.652 709.009 427.652 705.159V704.365V704.373Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M476.737 726.024H455.248V683.963H501.735C510.78 683.963 518.308 686.226 518.308 694.593V695.101C518.308 701.373 512.424 704.033 508.58 704.827C515.306 706.129 518.531 709.915 518.531 715.401V721.792C518.531 723.88 518.816 724.849 519.269 725.69V726.032H497.161C496.875 725.746 496.875 725.357 496.875 724.73V719.641C496.875 715.346 494.787 712.575 488.736 712.575H476.745V726.032L476.737 726.024ZM492.404 700.754C495.343 700.754 496.303 699.627 496.303 698.094C496.303 696.562 495.287 695.435 492.404 695.435H476.737V700.746H492.404V700.754Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M524.237 683.962H552.571L562.466 702.674L572.757 683.962H599.733V726.023H578.356V705.215L567.048 726.023H556.812L545.559 705.215V726.023H524.237V683.962Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M648.421 720.99H627.385L625.178 726.024H603.459L623.312 683.963H653.512L674.603 726.024H650.629L648.421 720.99ZM637.788 696.903L632.754 708.55H642.934L637.788 696.903Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M702.604 683.963L722.393 703.072V683.963H743.882V726.024H721.996L699.825 704.993V726.024H678.336V683.963H702.596H702.604Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M782.061 726.872C753.331 726.872 749.32 714.487 749.32 705.731V704.032C749.32 695.211 753.108 683.112 782.061 683.112H784.039C811.92 683.112 815.477 694.759 815.477 700.975V701.825H793.703C793.417 700.919 792.512 696.513 782.729 696.513C772.945 696.513 771.42 700.697 771.42 704.373V705.048C771.42 708.668 773.287 713.36 782.729 713.36C792.17 713.36 793.528 708.612 793.703 707.596H815.477V709.065C815.477 714.948 811.912 726.872 784.039 726.872H782.061V726.872Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M871.527 683.962V695.72H841.955V698.998H871.194V710.534H841.955V714.154H871.758V726.023H820.808V683.962H871.535H871.527Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M946.967 683.962V695.72H918.355V699.11H946.514V710.812H918.355V726.023H897.208V683.962H946.975H946.967Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M4.80412 998.816C-20.7027 998.816 -23.0215 989.544 -23.0215 984.113V983.661H2.14378C2.14378 985.471 2.93791 988.186 8.81431 988.186C13.7934 988.186 15.2625 986.773 15.2625 985.249V985.082C15.2625 983.669 14.6987 982.367 7.45642 982.089L-1.42172 981.803C-18.7253 981.184 -22.3465 975.079 -22.3465 968.458V967.95C-22.3465 960.034 -16.0095 955.176 4.34347 955.176H11.5222C34.0829 955.176 39.3479 961.17 39.3479 968.402V968.688H14.6907C14.524 967.33 13.841 965.298 8.24258 965.298C3.09675 965.298 2.13583 966.092 2.13583 967.505V967.616C2.13583 969.14 3.77169 969.99 10.2754 970.157L18.7566 970.379C35.1549 970.831 40.9281 974.785 40.9281 983.327V984.121C40.9281 991.584 36.1237 998.824 12.1972 998.824H4.78822L4.80412 998.816Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M72.7807 998.816C47.2739 998.816 44.9551 989.544 44.9551 984.113V983.661H70.1204C70.1204 985.471 70.9145 988.186 76.7909 988.186C81.7699 988.186 83.239 986.773 83.239 985.249V985.082C83.239 983.669 82.6753 982.367 75.433 982.089L66.5549 981.803C49.2513 981.184 45.6301 975.079 45.6301 968.458V967.95C45.6301 960.034 51.967 955.176 72.32 955.176H79.4988C102.059 955.176 107.324 961.17 107.324 968.402V968.688H82.6673C82.5005 967.33 81.8176 965.298 76.2191 965.298C71.0733 965.298 70.1125 966.092 70.1125 967.505V967.616C70.1125 969.14 71.7484 969.99 78.2521 970.157L86.7331 970.379C103.131 970.831 108.905 974.785 108.905 983.327V984.121C108.905 991.584 104.1 998.824 80.1738 998.824H72.7648L72.7807 998.816Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M154.954 986.495V997.975H133.466V955.914H175.26C191.547 955.914 195.899 962.361 195.899 970.276V971.744C195.899 979.66 191.317 986.503 175.26 986.503H154.954V986.495ZM154.954 973.777H169.995C172.933 973.777 173.783 972.475 173.783 971.062V970.951C173.783 969.426 172.933 968.235 169.995 968.235H154.954V973.777V973.777Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M251.653 955.906V967.664H222.081V970.943H251.32V982.478H222.081V986.098H251.884V997.967H200.934V955.906H251.661H251.653Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.257 997.967H257.769V955.906H304.256C313.301 955.906 320.829 958.169 320.829 966.537V967.045C320.829 973.316 314.944 975.976 311.101 976.77C317.827 978.072 321.051 981.859 321.051 987.345V993.736C321.051 995.824 321.337 996.792 321.79 997.634V997.975H299.682C299.396 997.689 299.396 997.3 299.396 996.673V991.584C299.396 987.289 297.307 984.518 291.256 984.518H279.265V997.975L279.257 997.967ZM294.925 972.697C297.863 972.697 298.824 971.57 298.824 970.038C298.824 968.505 297.808 967.378 294.925 967.378H279.257V972.689H294.925V972.697Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M376.518 955.906V967.664H347.906V971.054H376.065V982.756H347.906V997.967H326.759V955.906H376.526H376.518Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M417.075 955.057C445.52 955.057 449.816 967.156 449.816 975.976V977.675C449.816 986.328 445.464 998.816 417.075 998.816H414.248C385.914 998.816 381.507 986.32 381.507 977.675V975.976C381.507 967.156 385.803 955.057 414.248 955.057H417.075ZM427.652 976.317C427.652 972.642 424.825 968.458 415.606 968.458C406.386 968.458 403.615 972.642 403.615 976.317V976.992C403.615 980.779 406.553 985.304 415.606 985.304C424.659 985.304 427.652 980.954 427.652 977.103V976.309V976.317Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M476.737 997.967H455.248V955.906H501.735C510.78 955.906 518.308 958.169 518.308 966.537V967.045C518.308 973.316 512.424 975.976 508.58 976.77C515.306 978.072 518.531 981.859 518.531 987.345V993.736C518.531 995.824 518.816 996.792 519.269 997.634V997.975H497.161C496.875 997.689 496.875 997.3 496.875 996.673V991.584C496.875 987.289 494.787 984.518 488.736 984.518H476.745V997.975L476.737 997.967ZM492.404 972.697C495.343 972.697 496.303 971.57 496.303 970.038C496.303 968.505 495.287 967.378 492.404 967.378H476.737V972.689H492.404V972.697Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M524.237 955.906H552.571L562.466 974.618L572.757 955.906H599.733V997.967H578.356V977.159L567.048 997.967H556.812L545.559 977.159V997.967H524.237V955.906Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M648.421 992.942H627.385L625.178 997.975H603.459L623.312 955.914H653.512L674.603 997.975H650.629L648.421 992.942ZM637.788 968.855L632.754 980.501H642.934L637.788 968.855Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M702.604 955.906L722.393 975.015V955.906H743.882V997.967H721.996L699.825 976.937V997.967H678.336V955.906H702.596H702.604Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M782.061 998.816C753.331 998.816 749.32 986.432 749.32 977.675V975.976C749.32 967.156 753.108 955.057 782.061 955.057H784.039C811.92 955.057 815.477 966.703 815.477 972.919V973.769H793.703C793.417 972.864 792.512 968.458 782.729 968.458C772.945 968.458 771.42 972.642 771.42 976.317V976.992C771.42 980.612 773.287 985.304 782.729 985.304C792.17 985.304 793.528 980.557 793.703 979.541H815.477V981.009C815.477 986.892 811.912 998.816 784.039 998.816H782.061Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M871.527 955.906V967.664H841.955V970.943H871.194V982.478H841.955V986.098H871.758V997.967H820.808V955.906H871.535H871.527Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M946.967 955.906V967.664H918.355V971.054H946.514V982.756H918.355V997.967H897.208V955.906H946.975H946.967Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M2.71573 738.344V750.101H-25.896V753.491H2.26303V765.193H-25.896V780.405H-47.0432V738.344H2.72367H2.71573Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M8.20326 738.344H29.8031V755.643C29.8031 760.335 31.7804 765.313 40.7697 765.313H40.8809C49.7034 765.313 51.8555 760.335 51.8555 755.643V738.344H73.4552V757.85C73.4552 766.273 70.2311 781.143 42.1832 781.143H39.3562C11.3083 781.143 8.19531 766.273 8.19531 757.85V738.344H8.20326Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M103.663 738.344L123.452 757.453V738.344H144.94V780.405H123.055L100.883 759.374V780.405H79.3945V738.344H103.655H103.663Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M220.609 738.344V750.101H191.998V753.491H220.157V765.193H191.998V780.405H170.851V738.344H220.617H220.609Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M247.928 780.412H226.495V738.352H247.928V780.412Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M271.792 752.824H253.639V738.352H311.434V752.824H293.281V780.412H271.792V752.824V752.824Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M341.411 738.344L361.2 757.453V738.344H382.688V780.405H360.803L338.631 759.374V780.405H317.143V738.344H341.403H341.411Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M439.755 738.344V750.102H410.182V753.38H439.421V764.916H410.182V768.536H439.985V780.405H389.035V738.344H439.762H439.755Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M472.153 781.262C446.646 781.262 444.327 771.989 444.327 766.559V766.106H469.492C469.492 767.916 470.287 770.631 476.163 770.631C481.142 770.631 482.611 769.218 482.611 767.694V767.527C482.611 766.114 482.047 764.812 474.805 764.534L465.927 764.248C448.623 763.629 445.002 757.524 445.002 750.903V750.395C445.002 742.48 451.339 737.621 471.692 737.621H478.871C501.432 737.621 506.696 743.615 506.696 750.847V751.133H482.039C481.873 749.776 481.19 747.743 475.591 747.743C470.445 747.743 469.484 748.537 469.484 749.95V750.062C469.484 751.586 471.12 752.435 477.624 752.602L486.105 752.824C502.504 753.277 508.277 757.23 508.277 765.773V766.567C508.277 774.029 503.472 781.27 479.546 781.27H472.137L472.153 781.262Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M540.13 781.262C514.623 781.262 512.304 771.989 512.304 766.559V766.106H537.469C537.469 767.916 538.264 770.631 544.14 770.631C549.119 770.631 550.588 769.218 550.588 767.694V767.527C550.588 766.114 550.024 764.812 542.782 764.534L533.904 764.248C516.6 763.629 512.979 757.524 512.979 750.903V750.395C512.979 742.48 519.316 737.621 539.669 737.621H546.848C569.409 737.621 574.674 743.615 574.674 750.847V751.133H550.016C549.85 749.776 549.167 747.743 543.568 747.743C538.422 747.743 537.462 748.537 537.462 749.95V750.062C537.462 751.586 539.097 752.435 545.601 752.602L554.082 752.824C570.481 753.277 576.254 757.23 576.254 765.773V766.567C576.254 774.029 571.449 781.27 547.523 781.27H540.114L540.13 781.262Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M622.303 768.933V780.412H600.814V738.352H642.608C658.895 738.352 663.247 744.798 663.247 752.713V754.182C663.247 762.097 658.665 768.94 642.608 768.94H622.303V768.933ZM622.303 756.214H637.343C640.281 756.214 641.131 754.912 641.131 753.499V753.388C641.131 751.864 640.281 750.673 637.343 750.673H622.303V756.214Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M719.009 738.344V750.102H689.436V753.38H718.675V764.916H689.436V768.536H719.239V780.405H668.289V738.344H719.017H719.009Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M746.604 780.412H725.115V738.352H771.602C780.647 738.352 788.175 740.614 788.175 748.982V749.49C788.175 755.762 782.291 758.421 778.448 759.215C785.174 760.517 788.398 764.304 788.398 769.79V776.181C788.398 778.269 788.684 779.237 789.136 780.079V780.42H767.028C766.742 780.134 766.742 779.745 766.742 779.118V774.029C766.742 769.734 764.654 766.964 758.603 766.964H746.612V780.42L746.604 780.412ZM762.272 755.143C765.21 755.143 766.171 754.015 766.171 752.483C766.171 750.951 765.154 749.823 762.272 749.823H746.604V755.135H762.272V755.143Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M843.873 738.344V750.102H815.262V753.492H843.421V765.194H815.262V780.405H794.115V738.344H843.881H843.873Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M884.423 737.502C912.868 737.502 917.164 749.601 917.164 758.421V760.12C917.164 768.774 912.812 781.262 884.423 781.262H881.596C853.262 781.262 848.855 768.766 848.855 760.12V758.421C848.855 749.601 853.151 737.502 881.596 737.502H884.423ZM895 758.763C895 755.087 892.173 750.903 882.954 750.903C873.734 750.903 870.963 755.087 870.963 758.763V759.438C870.963 763.225 873.901 767.75 882.954 767.75C892.007 767.75 895 763.399 895 759.549V758.755V758.763Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M2.74707 792.74H31.0809L40.9755 811.452L51.2672 792.74H78.243V834.801H56.8656V813.993L45.5575 834.801H35.3214L24.069 813.993V834.801H2.74707V792.74Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M126.94 829.775H105.904L103.696 834.809H81.9775L101.83 792.748H132.03L153.122 834.809H129.148L126.94 829.775ZM116.307 805.689L111.272 817.335H121.453L116.307 805.689Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M181.111 792.74L200.9 811.849V792.74H222.389V834.801H200.503L178.331 813.771V834.801H156.843V792.74H181.103H181.111Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M260.571 835.65C231.84 835.65 227.83 823.266 227.83 814.509V812.81C227.83 803.99 231.618 791.891 260.571 791.891H262.548C290.43 791.891 293.987 803.537 293.987 809.753V810.603H272.213C271.927 809.698 271.022 805.292 261.238 805.292C251.455 805.292 249.93 809.475 249.93 813.151V813.826C249.93 817.446 251.796 822.138 261.238 822.138C270.68 822.138 272.038 817.391 272.213 816.374H293.987V817.843C293.987 823.726 290.422 835.65 262.548 835.65H260.571V835.65Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M350.034 792.74V804.498H320.462V807.777H349.701V819.312H320.462V822.932H350.264V834.801H299.314V792.74H350.042H350.034Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M425.475 792.74V804.498H396.864V807.888H425.023V819.59H396.864V834.801H375.717V792.74H425.483H425.475Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M430.961 792.74H452.561V810.039C452.561 814.731 454.538 819.709 463.527 819.709H463.639C472.461 819.709 474.613 814.731 474.613 810.039V792.74H496.213V812.246C496.213 820.67 492.989 835.539 464.941 835.539H462.114C434.066 835.539 430.953 820.67 430.953 812.246V792.74H430.961Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M526.422 792.74L546.211 811.849V792.74H567.7V834.801H545.814L523.643 813.771V834.801H502.154V792.74H526.414H526.422Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M643.371 792.74V804.498H614.76V807.888H642.919V819.59H614.76V834.801H593.612V792.74H643.379H643.371Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M670.688 834.801H649.255V792.74H670.688V834.801Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M694.551 807.213H676.398V792.74H734.193V807.213H716.04V834.801H694.551V807.213V807.213Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M764.164 792.74L783.953 811.85V792.74H805.441V834.801H783.556L761.384 813.771V834.801H739.896V792.74H764.156H764.164Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M862.514 792.74V804.498H832.941V807.777H862.18V819.312H832.941V822.932H862.744V834.801H811.794V792.74H862.522H862.514Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M894.913 835.65C869.406 835.65 867.087 826.378 867.087 820.947V820.495H892.252C892.252 822.305 893.046 825.02 898.923 825.02C903.902 825.02 905.371 823.607 905.371 822.083V821.916C905.371 820.503 904.807 819.201 897.565 818.923L888.687 818.637C871.383 818.018 867.762 811.913 867.762 805.292V804.784C867.762 796.868 874.099 792.01 894.452 792.01H901.631C924.191 792.01 929.456 798.004 929.456 805.236V805.522H904.799C904.632 804.164 903.95 802.132 898.351 802.132C893.205 802.132 892.244 802.926 892.244 804.339V804.45C892.244 805.974 893.88 806.824 900.384 806.991L908.865 807.213C925.263 807.665 931.037 811.619 931.037 820.161V820.955C931.037 828.418 926.232 835.658 902.306 835.658H894.897L894.913 835.65Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M27.8504 901.518V913.275H-0.761268V916.665H27.3978V928.367H-0.761268V943.579H-21.9084V901.518H27.8583H27.8504Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M55.162 943.578H33.729V901.518H55.162V943.578Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M79.0327 915.99H60.8794V901.518H118.675V915.99H100.521V943.578H79.0327V915.99V915.99Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M148.641 901.518L168.431 920.627V901.518H189.919V943.579H168.034L145.862 922.548V943.579H124.374V901.518H148.634H148.641Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M246.985 901.518V913.275H217.412V916.554H246.651V928.089H217.412V931.71H247.215V943.578H196.265V901.518H246.993H246.985Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.392 944.428C253.886 944.428 251.567 935.155 251.567 929.725V929.272H276.732C276.732 931.082 277.526 933.797 283.403 933.797C288.382 933.797 289.851 932.384 289.851 930.86V930.693C289.851 929.28 289.287 927.978 282.045 927.7L273.167 927.414C255.863 926.795 252.242 920.69 252.242 914.069V913.561C252.242 905.646 258.579 900.787 278.932 900.787H286.111C308.671 900.787 313.936 906.781 313.936 914.013V914.299H289.279C289.112 912.942 288.429 910.909 282.831 910.909C277.685 910.909 276.724 911.703 276.724 913.116V913.227C276.724 914.752 278.36 915.601 284.864 915.768L293.345 915.99C309.743 916.443 315.516 920.396 315.516 928.939V929.733C315.516 937.195 310.712 944.436 286.786 944.436H279.377L279.392 944.428Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M347.369 944.428C321.862 944.428 319.543 935.155 319.543 929.725V929.272H344.709C344.709 931.082 345.503 933.797 351.379 933.797C356.358 933.797 357.827 932.384 357.827 930.86V930.693C357.827 929.28 357.264 927.978 350.021 927.7L341.143 927.414C323.84 926.795 320.218 920.69 320.218 914.069V913.561C320.218 905.646 326.555 900.787 346.908 900.787H354.087C376.648 900.787 381.913 906.781 381.913 914.013V914.299H357.256C357.089 912.942 356.406 910.909 350.808 910.909C345.662 910.909 344.701 911.703 344.701 913.116V913.227C344.701 914.752 346.337 915.601 352.84 915.768L361.322 915.99C377.72 916.443 383.493 920.396 383.493 928.939V929.733C383.493 937.195 378.689 944.436 354.762 944.436H347.353L347.369 944.428Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M429.537 932.099V943.578H408.048V901.518H449.842C466.129 901.518 470.481 907.964 470.481 915.879V917.348C470.481 925.263 465.899 932.106 449.842 932.106H429.537V932.099ZM429.537 919.38H444.577C447.516 919.38 448.365 918.078 448.365 916.665V916.554C448.365 915.03 447.516 913.839 444.577 913.839H429.537V919.38V919.38Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M526.243 901.518V913.275H496.67V916.554H525.909V928.09H496.67V931.71H526.473V943.579H475.523V901.518H526.251H526.243Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M553.838 943.579H532.349V901.518H578.836C587.881 901.518 595.409 903.78 595.409 912.148V912.656C595.409 918.928 589.525 921.588 585.682 922.381C592.408 923.683 595.632 927.47 595.632 932.956V939.347C595.632 941.435 595.918 942.404 596.37 943.245V943.587H574.262C573.976 943.301 573.976 942.912 573.976 942.285V937.196C573.976 932.901 571.888 930.13 565.837 930.13H553.846V943.587L553.838 943.579ZM569.506 918.309C572.444 918.309 573.405 917.181 573.405 915.649C573.405 914.117 572.388 912.99 569.506 912.99H553.838V918.301H569.506V918.309Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M651.107 901.518V913.275H622.495V916.665H650.654V928.367H622.495V943.578H601.348V901.518H651.115H651.107Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M691.655 900.668C720.1 900.668 724.396 912.767 724.396 921.587V923.286C724.396 931.94 720.044 944.428 691.655 944.428H688.828C660.494 944.428 656.087 931.932 656.087 923.286V921.587C656.087 912.767 660.383 900.668 688.828 900.668H691.655ZM702.232 921.929C702.232 918.253 699.405 914.069 690.186 914.069C680.966 914.069 678.195 918.253 678.195 921.929V922.603C678.195 926.39 681.133 930.916 690.186 930.916C699.239 930.916 702.232 926.565 702.232 922.715V921.921V921.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M751.316 943.579H729.828V901.518H776.315C785.36 901.518 792.888 903.78 792.888 912.148V912.656C792.888 918.928 787.004 921.588 783.16 922.381C789.886 923.683 793.11 927.47 793.11 932.956V939.347C793.11 941.435 793.396 942.404 793.849 943.245V943.587H771.741C771.455 943.301 771.455 942.912 771.455 942.285V937.196C771.455 932.901 769.366 930.13 763.315 930.13H751.324V943.587L751.316 943.579ZM766.984 918.309C769.922 918.309 770.883 917.181 770.883 915.649C770.883 914.117 769.867 912.99 766.984 912.99H751.316V918.301H766.984V918.309Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M798.828 901.518H827.161L837.056 920.23L847.348 901.518H874.324V943.578H852.946V922.77L841.638 943.578H831.402L820.15 922.77V943.578H798.828V901.518Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M923.012 938.545H901.976L899.768 943.578H878.049L897.902 901.518H928.102L949.194 943.578H925.219L923.012 938.545ZM912.379 914.458L907.344 926.105H917.524L912.379 914.458Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M5.52684 509.318C-19.9799 509.318 -22.2987 500.045 -22.2987 494.615V494.162H2.8666C2.8666 495.972 3.66073 498.687 9.53713 498.687C14.5162 498.687 15.9853 497.274 15.9853 495.75V495.583C15.9853 494.17 15.4215 492.868 8.17924 492.59L-0.69894 492.304C-18.0026 491.685 -21.6237 485.58 -21.6237 478.959V478.451C-21.6237 470.536 -15.2867 465.677 5.0663 465.677H12.2451C34.8057 465.677 40.0706 471.671 40.0706 478.903V479.189H15.4136C15.2468 477.832 14.5639 475.799 8.9654 475.799C3.81957 475.799 2.85865 476.593 2.85865 478.006V478.117C2.85865 479.642 4.49452 480.491 10.9983 480.658L19.4794 480.88C35.8777 481.333 41.6508 485.286 41.6508 493.829V494.623C41.6508 502.085 36.8465 509.326 12.92 509.326H5.51095L5.52684 509.318Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M87.6926 496.988V508.468H66.204V466.407H107.998C124.285 466.407 128.637 472.854 128.637 480.769V482.238C128.637 490.153 124.055 496.996 107.998 496.996H87.6926V496.988ZM87.6926 484.27H102.733C105.671 484.27 106.521 482.968 106.521 481.555V481.444C106.521 479.919 105.671 478.729 102.733 478.729H87.6926V484.27Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M184.399 466.407V478.165H154.827V481.444H184.066V492.979H154.827V496.599H184.63V508.468H133.68V466.407H184.407H184.399Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M211.994 508.468H190.506V466.407H236.993C246.038 466.407 253.566 468.67 253.566 477.038V477.546C253.566 483.817 247.682 486.477 243.838 487.271C250.564 488.573 253.788 492.36 253.788 497.846V504.237C253.788 506.324 254.074 507.293 254.527 508.135V508.476H232.419C232.133 508.19 232.133 507.801 232.133 507.174V502.085C232.133 497.79 230.044 495.019 223.993 495.019H212.002V508.476L211.994 508.468ZM227.662 483.198C230.6 483.198 231.561 482.071 231.561 480.539C231.561 479.006 230.545 477.879 227.662 477.879H211.994V483.19H227.662V483.198Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M309.265 466.407V478.165H280.653V481.555H308.812V493.257H280.653V508.468H259.506V466.407H309.273H309.265Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M349.812 465.558C378.257 465.558 382.553 477.657 382.553 486.477V488.176C382.553 496.829 378.202 509.317 349.812 509.317H346.985C318.651 509.317 314.244 496.821 314.244 488.176V486.477C314.244 477.657 318.54 465.558 346.985 465.558H349.812V465.558ZM360.39 486.818C360.39 483.142 357.563 478.959 348.343 478.959C339.124 478.959 336.352 483.142 336.352 486.818V487.493C336.352 491.28 339.29 495.805 348.343 495.805C357.396 495.805 360.39 491.455 360.39 487.604V486.81V486.818Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M409.475 508.468H387.986V466.407H434.473C443.518 466.407 451.047 468.67 451.047 477.038V477.546C451.047 483.817 445.162 486.477 441.319 487.271C448.045 488.573 451.269 492.36 451.269 497.846V504.237C451.269 506.324 451.555 507.293 452.007 508.135V508.476H429.899C429.614 508.19 429.614 507.801 429.614 507.174V502.085C429.614 497.79 427.525 495.019 421.474 495.019H409.483V508.476L409.475 508.468ZM425.143 483.198C428.081 483.198 429.042 482.071 429.042 480.539C429.042 479.006 428.025 477.879 425.143 477.879H409.475V483.19H425.143V483.198Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M456.978 466.407H485.311L495.206 485.119L505.498 466.407H532.474V508.468H511.096V487.66L499.788 508.468H489.552L478.299 487.66V508.468H456.978V466.407Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M581.168 503.435H560.133L557.925 508.468H536.206L556.059 466.407H586.259L607.35 508.468H583.376L581.168 503.435V503.435ZM570.535 479.348L565.501 490.994H575.681L570.535 479.348V479.348Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M635.349 466.407L655.138 485.516V466.407H676.627V508.468H654.741L632.57 487.438V508.468H611.081V466.407H635.341H635.349Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M714.801 509.317C686.07 509.317 682.06 496.933 682.06 488.176V486.477C682.06 477.657 685.848 465.558 714.801 465.558H716.779C744.66 465.558 748.217 477.204 748.217 483.42V484.27H726.443C726.157 483.365 725.252 478.959 715.468 478.959C705.685 478.959 704.16 483.142 704.16 486.818V487.493C704.16 491.113 706.026 495.805 715.468 495.805C724.91 495.805 726.268 491.058 726.443 490.041H748.217V491.51C748.217 497.393 744.652 509.317 716.779 509.317H714.801Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M804.266 466.407V478.165H774.693V481.444H803.932V492.979H774.693V496.599H804.496V508.468H753.546V466.407H804.274H804.266Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M879.705 466.407V478.165H851.094V481.555H879.253V493.257H851.094V508.468H829.946V466.407H879.713H879.705Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M885.193 466.407H906.793V483.706C906.793 488.398 908.771 493.376 917.76 493.376H917.871C926.694 493.376 928.846 488.398 928.846 483.706V466.407H950.446V485.913C950.446 494.337 947.221 509.206 919.173 509.206H916.346C888.298 509.206 885.186 494.337 885.186 485.913V466.407H885.193Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M4.80412 618.095C-20.7027 618.095 -23.0215 608.822 -23.0215 603.392V602.939H2.14378C2.14378 604.749 2.93791 607.464 8.81431 607.464C13.7934 607.464 15.2625 606.051 15.2625 604.527V604.36C15.2625 602.947 14.6987 601.645 7.45642 601.367L-1.42172 601.081C-18.7253 600.462 -22.3465 594.357 -22.3465 587.736V587.228C-22.3465 579.313 -16.0095 574.454 4.34347 574.454H11.5222C34.0829 574.454 39.3479 580.448 39.3479 587.68V587.966H14.6907C14.524 586.609 13.841 584.576 8.24258 584.576C3.09675 584.576 2.13583 585.37 2.13583 586.783V586.894C2.13583 588.419 3.77169 589.268 10.2754 589.435L18.7566 589.657C35.1549 590.11 40.9281 594.063 40.9281 602.606V603.4C40.9281 610.862 36.1237 618.103 12.1972 618.103H4.78822L4.80412 618.095Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M72.7807 618.095C47.2739 618.095 44.9551 608.822 44.9551 603.392V602.939H70.1204C70.1204 604.749 70.9145 607.464 76.7909 607.464C81.7699 607.464 83.239 606.051 83.239 604.527V604.36C83.239 602.947 82.6753 601.645 75.433 601.367L66.5549 601.081C49.2513 600.462 45.6301 594.357 45.6301 587.736V587.228C45.6301 579.313 51.967 574.454 72.32 574.454H79.4988C102.059 574.454 107.324 580.448 107.324 587.68V587.966H82.6673C82.5005 586.609 81.8176 584.576 76.2191 584.576C71.0733 584.576 70.1125 585.37 70.1125 586.783V586.894C70.1125 588.419 71.7484 589.268 78.2521 589.435L86.7331 589.657C103.131 590.11 108.905 594.063 108.905 602.606V603.4C108.905 610.862 104.1 618.103 80.1738 618.103H72.7648L72.7807 618.095Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M154.954 605.766V617.245H133.466V575.185H175.26C191.547 575.185 195.899 581.631 195.899 589.546V591.015C195.899 598.93 191.317 605.773 175.26 605.773H154.954V605.766ZM154.954 593.047H169.995C172.933 593.047 173.783 591.745 173.783 590.332V590.221C173.783 588.697 172.933 587.506 169.995 587.506H154.954V593.047V593.047Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M251.653 575.178V586.935H222.081V590.214H251.32V601.75H222.081V605.37H251.884V617.238H200.934V575.178H251.661H251.653Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.257 617.245H257.769V575.185H304.256C313.301 575.185 320.829 577.447 320.829 585.815V586.323C320.829 592.595 314.944 595.254 311.101 596.048C317.827 597.35 321.051 601.137 321.051 606.623V613.014C321.051 615.102 321.337 616.07 321.79 616.912V617.253H299.682C299.396 616.967 299.396 616.578 299.396 615.951V610.862C299.396 606.567 297.307 603.797 291.256 603.797H279.265V617.253L279.257 617.245ZM294.925 591.976C297.863 591.976 298.824 590.848 298.824 589.316C298.824 587.784 297.808 586.656 294.925 586.656H279.257V591.968H294.925V591.976Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M376.518 575.178V586.935H347.906V590.325H376.065V602.027H347.906V617.238H326.759V575.178H376.526H376.518Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M417.075 574.335C445.52 574.335 449.816 586.434 449.816 595.254V596.953C449.816 605.607 445.464 618.095 417.075 618.095H414.248C385.914 618.095 381.507 605.599 381.507 596.953V595.254C381.507 586.434 385.803 574.335 414.248 574.335H417.075ZM427.652 595.596C427.652 591.92 424.825 587.736 415.606 587.736C406.386 587.736 403.615 591.92 403.615 595.596V596.27C403.615 600.057 406.553 604.583 415.606 604.583C424.659 604.583 427.652 600.232 427.652 596.382V595.588V595.596Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M476.737 617.245H455.248V575.185H501.735C510.78 575.185 518.308 577.447 518.308 585.815V586.323C518.308 592.595 512.424 595.254 508.58 596.048C515.306 597.35 518.531 601.137 518.531 606.623V613.014C518.531 615.102 518.816 616.07 519.269 616.912V617.253H497.161C496.875 616.967 496.875 616.578 496.875 615.951V610.862C496.875 606.567 494.787 603.797 488.736 603.797H476.745V617.253L476.737 617.245ZM492.404 591.976C495.343 591.976 496.303 590.848 496.303 589.316C496.303 587.784 495.287 586.656 492.404 586.656H476.737V591.968H492.404V591.976Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M524.237 575.178H552.571L562.466 593.89L572.757 575.178H599.733V617.238H578.356V596.43L567.048 617.238H556.812L545.559 596.43V617.238H524.237V575.178Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M648.421 612.212H627.385L625.178 617.245H603.459L623.312 575.185H653.512L674.603 617.245H650.629L648.421 612.212ZM637.788 588.125L632.754 599.772H642.934L637.788 588.125Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M702.604 575.177L722.393 594.286V575.177H743.882V617.238H721.996L699.825 596.207V617.238H678.336V575.177H702.596H702.604Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M782.061 618.095C753.331 618.095 749.32 605.71 749.32 596.953V595.254C749.32 586.434 753.108 574.335 782.061 574.335H784.039C811.92 574.335 815.477 585.981 815.477 592.198V593.047H793.703C793.417 592.142 792.512 587.736 782.729 587.736C772.945 587.736 771.42 591.92 771.42 595.596V596.27C771.42 599.891 773.287 604.583 782.729 604.583C792.17 604.583 793.528 599.835 793.703 598.819H815.477V600.288C815.477 606.17 811.912 618.095 784.039 618.095H782.061V618.095Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M871.527 575.178V586.935H841.955V590.214H871.194V601.75H841.955V605.37H871.758V617.238H820.808V575.178H871.535H871.527Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M946.967 575.178V586.935H918.355V590.325H946.514V602.027H918.355V617.238H897.208V575.178H946.975H946.967Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M37.5163 520.796V532.554H8.90469V535.943H37.0637V547.646H8.90469V562.857H-12.2424V520.796H37.5243H37.5163Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M64.8283 562.857H43.3953V520.796H64.8283V562.857Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M88.699 535.269H70.5457V520.796H128.341V535.269H110.188V562.857H88.699V535.269V535.269Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M158.308 520.796L178.097 539.905V520.796H199.586V562.857H177.7L155.528 541.826V562.857H134.04V520.796H158.3H158.308Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M256.651 520.796V532.554H227.079V535.832H256.318V547.368H227.079V550.988H256.881V562.857H205.931V520.796H256.659H256.651Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M289.059 563.706C263.552 563.706 261.233 554.433 261.233 549.003V548.551H286.398C286.398 550.361 287.193 553.076 293.069 553.076C298.048 553.076 299.517 551.663 299.517 550.138V549.972C299.517 548.558 298.953 547.256 291.711 546.979L282.833 546.693C265.529 546.074 261.908 539.968 261.908 533.347V532.839C261.908 524.924 268.245 520.065 288.598 520.065H295.777C318.338 520.065 323.602 526.059 323.602 533.292V533.578H298.945C298.779 532.22 298.096 530.188 292.497 530.188C287.351 530.188 286.39 530.982 286.39 532.395V532.506C286.39 534.03 288.026 534.88 294.53 535.046L303.011 535.269C319.41 535.721 325.183 539.675 325.183 548.217V549.011C325.183 556.474 320.378 563.714 296.452 563.714H289.043L289.059 563.706Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M357.035 563.706C331.528 563.706 329.21 554.433 329.21 549.003V548.551H354.375C354.375 550.361 355.169 553.076 361.046 553.076C366.025 553.076 367.494 551.663 367.494 550.138V549.972C367.494 548.558 366.93 547.256 359.688 546.979L350.809 546.693C333.506 546.074 329.885 539.968 329.885 533.347V532.839C329.885 524.924 336.222 520.065 356.575 520.065H363.753C386.314 520.065 391.579 526.059 391.579 533.292V533.578H366.922C366.755 532.22 366.072 530.188 360.474 530.188C355.328 530.188 354.367 530.982 354.367 532.395V532.506C354.367 534.03 356.003 534.88 362.507 535.046L370.988 535.269C387.386 535.721 393.159 539.675 393.159 548.217V549.011C393.159 556.474 388.355 563.714 364.428 563.714H357.019L357.035 563.706Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M439.203 551.385V562.864H417.715V520.804H459.509C475.796 520.804 480.147 527.25 480.147 535.165V536.634C480.147 544.549 475.565 551.393 459.509 551.393H439.203V551.385ZM439.203 538.666H454.244C457.182 538.666 458.032 537.364 458.032 535.951V535.84C458.032 534.316 457.182 533.125 454.244 533.125H439.203V538.666Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M535.909 520.796V532.554H506.336V535.832H535.575V547.368H506.336V550.988H536.139V562.857H485.189V520.796H535.917H535.909Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M563.504 562.857H542.015V520.796H588.503C597.547 520.796 605.076 523.059 605.076 531.426V531.934C605.076 538.206 599.191 540.866 595.348 541.66C602.074 542.962 605.298 546.748 605.298 552.234V558.625C605.298 560.713 605.584 561.682 606.036 562.523V562.865H583.928C583.643 562.579 583.643 562.19 583.643 561.563V556.474C583.643 552.179 581.554 549.408 575.503 549.408H563.512V562.865L563.504 562.857ZM579.172 537.587C582.11 537.587 583.071 536.46 583.071 534.927C583.071 533.395 582.054 532.268 579.172 532.268H563.504V537.579H579.172V537.587Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M660.773 520.796V532.554H632.162V535.943H660.321V547.646H632.162V562.857H611.014V520.796H660.781H660.773Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M701.321 519.946C729.766 519.946 734.062 532.045 734.062 540.866V542.564C734.062 551.218 729.71 563.706 701.321 563.706H698.494C670.16 563.706 665.753 551.21 665.753 542.564V540.866C665.753 532.045 670.049 519.946 698.494 519.946H701.321V519.946ZM711.899 541.207C711.899 537.531 709.072 533.347 699.852 533.347C690.632 533.347 687.861 537.531 687.861 541.207V541.882C687.861 545.669 690.799 550.194 699.852 550.194C708.905 550.194 711.899 545.843 711.899 541.993V541.199V541.207Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M760.982 562.857H739.494V520.796H785.981C795.026 520.796 802.554 523.059 802.554 531.426V531.934C802.554 538.206 796.67 540.866 792.826 541.66C799.552 542.962 802.776 546.748 802.776 552.234V558.625C802.776 560.713 803.062 561.682 803.515 562.523V562.865H781.407C781.121 562.579 781.121 562.19 781.121 561.563V556.474C781.121 552.179 779.033 549.408 772.981 549.408H760.99V562.865L760.982 562.857ZM776.65 537.587C779.588 537.587 780.549 536.46 780.549 534.927C780.549 533.395 779.533 532.268 776.65 532.268H760.982V537.579H776.65V537.587Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M808.494 520.796H836.828L846.722 539.508L857.014 520.796H883.99V562.856H862.612V542.048L851.304 562.856H841.068L829.816 542.048V562.856H808.494V520.796V520.796Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M932.678 557.823H911.642L909.435 562.856H887.716L907.568 520.796H937.768L958.86 562.856H934.886L932.678 557.823ZM922.045 533.736L917.01 545.383H927.191L922.045 533.736Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M36.8624 85.6858V97.4434H8.25063V100.833H36.4097V112.535H8.25063V127.747H-12.8965V85.6858H36.8703H36.8624Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M42.3497 85.6855H63.9495V102.985C63.9495 107.677 65.9268 112.654 74.9161 112.654H75.0273C83.8498 112.654 86.0019 107.677 86.0019 102.985V85.6855H107.602V105.192C107.602 113.615 104.378 128.485 76.3296 128.485H73.5026C45.4547 128.485 42.3418 113.615 42.3418 105.192V85.6855H42.3497Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M137.809 85.6855L157.598 104.795V85.6855H179.087V127.746H157.201L135.03 106.716V127.746H113.541V85.6855H137.801H137.809Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M254.758 85.6855V97.4432H226.146V100.833H254.305V112.535H226.146V127.746H204.999V85.6855H254.766H254.758Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M282.075 127.747H260.642V85.6858H282.075V127.747Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M305.939 100.158H287.786V85.6855H345.581V100.158H327.428V127.746H305.939V100.158V100.158Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M375.56 85.6858L395.349 104.795V85.6858H416.838V127.747H394.952L372.78 106.716V127.747H351.292V85.6858H375.552H375.56Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M473.9 85.6858V97.4434H444.328V100.722H473.567V112.258H444.328V115.878H474.131V127.747H423.181V85.6858H473.908H473.9Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M506.308 128.596C480.801 128.596 478.482 119.323 478.482 113.893V113.44H503.648C503.648 115.25 504.442 117.965 510.318 117.965C515.297 117.965 516.766 116.552 516.766 115.028V114.861C516.766 113.448 516.203 112.146 508.96 111.868L500.082 111.582C482.779 110.963 479.157 104.858 479.157 98.237V97.7289C479.157 89.8137 485.494 84.9551 505.847 84.9551H513.026C535.587 84.9551 540.852 90.949 540.852 98.1814V98.4672H516.195C516.028 97.1097 515.345 95.0773 509.746 95.0773C504.601 95.0773 503.64 95.8712 503.64 97.2843V97.3955C503.64 98.9198 505.276 99.7692 511.779 99.9359L520.26 100.158C536.659 100.611 542.432 104.564 542.432 113.107V113.901C542.432 121.363 537.628 128.604 513.701 128.604H506.292L506.308 128.596Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M574.286 128.596C548.779 128.596 546.46 119.323 546.46 113.893V113.44H571.625C571.625 115.25 572.419 117.965 578.296 117.965C583.275 117.965 584.744 116.552 584.744 115.028V114.861C584.744 113.448 584.18 112.146 576.938 111.868L568.06 111.582C550.756 110.963 547.135 104.858 547.135 98.237V97.7289C547.135 89.8137 553.472 84.9551 573.825 84.9551H581.004C603.564 84.9551 608.829 90.949 608.829 98.1814V98.4672H584.172C584.005 97.1097 583.322 95.0773 577.724 95.0773C572.578 95.0773 571.617 95.8712 571.617 97.2843V97.3955C571.617 98.9198 573.253 99.7692 579.757 99.9359L588.238 100.158C604.636 100.611 610.41 104.564 610.41 113.107V113.901C610.41 121.363 605.605 128.604 581.679 128.604H574.27L574.286 128.596Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M684.728 85.6858V97.4434H656.117V100.833H684.276V112.535H656.117V127.747H634.97V85.6858H684.736H684.728Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M690.216 85.6855H711.816V102.985C711.816 107.677 713.793 112.654 722.782 112.654H722.894C731.716 112.654 733.868 107.677 733.868 102.985V85.6855H755.468V105.192C755.468 113.615 752.244 128.485 724.196 128.485H721.369C693.321 128.485 690.208 113.615 690.208 105.192V85.6855H690.216Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M785.675 85.6855L805.464 104.795V85.6855H826.953V127.746H805.067L782.896 106.716V127.746H761.407V85.6855H785.667H785.675Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M902.623 85.6858V97.4434H874.011V100.833H902.171V112.535H874.011V127.747H852.864V85.6858H902.631H902.623Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M929.935 127.746H908.502V85.6855H929.935V127.746Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M13.3234 303.241L33.1126 322.35V303.241H54.6011V345.302H32.7155L10.544 324.272V345.302H-10.9446V303.241H13.3154H13.3234Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M111.667 303.241V314.999H82.0949V318.278H111.334V329.813H82.0949V333.433H111.898V345.302H60.9478V303.241H111.675H111.667Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M144.075 346.151C118.568 346.151 116.25 336.879 116.25 331.448V330.996H141.415C141.415 332.806 142.209 335.521 148.085 335.521C153.064 335.521 154.534 334.108 154.534 332.584V332.417C154.534 331.004 153.97 329.702 146.727 329.424L137.849 329.138C120.546 328.519 116.924 322.414 116.924 315.793V315.285C116.924 307.369 123.262 302.511 143.615 302.511H150.793C173.354 302.511 178.619 308.505 178.619 315.737V316.023H153.962C153.795 314.665 153.112 312.633 147.514 312.633C142.368 312.633 141.407 313.427 141.407 314.84V314.951C141.407 316.475 143.043 317.325 149.546 317.492L158.028 317.714C174.426 318.166 180.199 322.12 180.199 330.662V331.456C180.199 338.919 175.395 346.159 151.468 346.159H144.059L144.075 346.151Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M212.051 346.151C186.544 346.151 184.225 336.879 184.225 331.448V330.996H209.391C209.391 332.806 210.185 335.521 216.061 335.521C221.04 335.521 222.509 334.108 222.509 332.584V332.417C222.509 331.004 221.945 329.702 214.703 329.424L205.825 329.138C188.521 328.519 184.9 322.414 184.9 315.793V315.285C184.9 307.369 191.237 302.511 211.59 302.511H218.769C241.33 302.511 246.594 308.505 246.594 315.737V316.023H221.937C221.771 314.665 221.088 312.633 215.489 312.633C210.343 312.633 209.383 313.427 209.383 314.84V314.951C209.383 316.475 211.018 317.325 217.522 317.492L226.003 317.714C242.402 318.166 248.175 322.12 248.175 330.662V331.456C248.175 338.919 243.37 346.159 219.444 346.159H212.035L212.051 346.151Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M294.217 333.83V345.31H272.728V303.249H314.522C330.809 303.249 335.161 309.695 335.161 317.611V319.079C335.161 326.995 330.579 333.838 314.522 333.838H294.217V333.83ZM294.217 321.112H309.257C312.195 321.112 313.045 319.81 313.045 318.397V318.285C313.045 316.761 312.195 315.57 309.257 315.57H294.217V321.112Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M390.923 303.241V314.999H361.351V318.278H390.59V329.813H361.351V333.433H391.154V345.302H340.204V303.241H390.931H390.923Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M418.518 345.302H397.03V303.241H443.517C452.562 303.241 460.09 305.504 460.09 313.872V314.38C460.09 320.651 454.206 323.311 450.362 324.105C457.088 325.407 460.312 329.194 460.312 334.68V341.07C460.312 343.158 460.598 344.127 461.051 344.969V345.31H438.943C438.657 345.024 438.657 344.635 438.657 344.008V338.919C438.657 334.624 436.568 331.853 430.517 331.853H418.526V345.31L418.518 345.302ZM434.186 320.032C437.124 320.032 438.085 318.905 438.085 317.373C438.085 315.84 437.069 314.713 434.186 314.713H418.518V320.024H434.186V320.032Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M515.789 303.241V314.999H487.177V318.389H515.336V330.091H487.177V345.302H466.03V303.241H515.797H515.789Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M556.336 302.392C584.781 302.392 589.077 314.491 589.077 323.311V325.01C589.077 333.663 584.726 346.151 556.336 346.151H553.509C525.175 346.151 520.768 333.655 520.768 325.01V323.311C520.768 314.491 525.064 302.392 553.509 302.392H556.336ZM566.914 323.652C566.914 319.976 564.087 315.793 554.867 315.793C545.648 315.793 542.876 319.976 542.876 323.652V324.327C542.876 328.114 545.814 332.639 554.867 332.639C563.92 332.639 566.914 328.289 566.914 324.438V323.644V323.652Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M615.999 345.302H594.51V303.241H640.997C650.042 303.241 657.57 305.504 657.57 313.872V314.38C657.57 320.651 651.686 323.311 647.843 324.105C654.569 325.407 657.793 329.194 657.793 334.68V341.07C657.793 343.158 658.079 344.127 658.531 344.969V345.31H636.423C636.137 345.024 636.137 344.635 636.137 344.008V338.919C636.137 334.624 634.049 331.853 627.998 331.853H616.007V345.31L615.999 345.302ZM631.667 320.032C634.605 320.032 635.566 318.905 635.566 317.373C635.566 315.84 634.549 314.713 631.667 314.713H615.999V320.024H631.667V320.032Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M663.501 303.241H691.835L701.73 321.953L712.022 303.241H738.997V345.302H717.62V324.494L706.312 345.302H696.076L684.823 324.494V345.302H663.501V303.241V303.241Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M787.692 340.276H766.656L764.449 345.31H742.73L762.583 303.249H792.783L813.874 345.31H789.9L787.692 340.276ZM777.059 316.19L772.025 327.836H782.205L777.059 316.19Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M841.873 303.241L861.662 322.35V303.241H883.151V345.302H861.265L839.094 324.272V345.302H817.605V303.241H841.865H841.873Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M921.325 346.151C892.594 346.151 888.584 333.766 888.584 325.01V323.311C888.584 314.49 892.372 302.391 921.325 302.391H923.303C951.184 302.391 954.741 314.038 954.741 320.254V321.104H932.967C932.681 320.199 931.776 315.792 921.992 315.792C912.209 315.792 910.684 319.976 910.684 323.652V324.327C910.684 327.947 912.55 332.639 921.992 332.639C931.434 332.639 932.792 327.891 932.967 326.875H954.741V328.344C954.741 334.227 951.176 346.151 923.303 346.151H921.325Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M10.9663 154.547H-7.18701V140.074H50.6082V154.547H32.4549V182.135H10.9663V154.547Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M80.579 140.075L100.368 159.184V140.075H121.857V182.136H99.9711L77.7996 161.106V182.136H56.311V140.075H80.571H80.579Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M178.929 140.075V151.833H149.357V155.112H178.596V166.647H149.357V170.267H179.159V182.136H128.209V140.075H178.937H178.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M211.328 182.984C185.821 182.984 183.502 173.712 183.502 168.281V167.829H208.668C208.668 169.639 209.462 172.354 215.338 172.354C220.317 172.354 221.786 170.941 221.786 169.417V169.25C221.786 167.837 221.223 166.535 213.98 166.257L205.102 165.971C187.799 165.352 184.177 159.247 184.177 152.626V152.118C184.177 144.202 190.514 139.344 210.867 139.344H218.046C240.607 139.344 245.872 145.338 245.872 152.57V152.856H221.215C221.048 151.498 220.365 149.466 214.767 149.466C209.621 149.466 208.66 150.26 208.66 151.673V151.784C208.66 153.308 210.296 154.158 216.799 154.325L225.28 154.547C241.679 154.999 247.452 158.953 247.452 167.495V168.289C247.452 175.752 242.648 182.992 218.721 182.992H211.312L211.328 182.984Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.305 182.985C253.798 182.985 251.479 173.713 251.479 168.282V167.83H276.644C276.644 169.64 277.438 172.355 283.315 172.355C288.294 172.355 289.763 170.942 289.763 169.418V169.251C289.763 167.838 289.199 166.536 281.957 166.258L273.079 165.972C255.775 165.353 252.154 159.248 252.154 152.627V152.119C252.154 144.203 258.491 139.345 278.844 139.345H286.023C308.583 139.345 313.848 145.339 313.848 152.571V152.857H289.191C289.024 151.499 288.342 149.467 282.743 149.467C277.597 149.467 276.636 150.261 276.636 151.674V151.785C276.636 153.309 278.272 154.159 284.776 154.326L293.257 154.548C309.655 155 315.429 158.954 315.429 167.496V168.29C315.429 175.753 310.624 182.993 286.698 182.993H279.289L279.305 182.985Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M361.478 170.655V182.135H339.99V140.074H381.784C398.071 140.074 402.423 146.521 402.423 154.436V155.905C402.423 163.82 397.841 170.663 381.784 170.663H361.478V170.655ZM361.478 157.937H376.519C379.457 157.937 380.307 156.635 380.307 155.222V155.111C380.307 153.586 379.457 152.396 376.519 152.396H361.478V157.937Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M458.177 140.074V151.832H428.605V155.111H457.844V166.646H428.605V170.266H458.407V182.135H407.458V140.074H458.185H458.177Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M485.781 182.136H464.292V140.075H510.78C519.825 140.075 527.353 142.338 527.353 150.706V151.214C527.353 157.485 521.468 160.145 517.625 160.939C524.351 162.241 527.575 166.028 527.575 171.514V177.904C527.575 179.992 527.861 180.961 528.314 181.803V182.144H506.206C505.92 181.858 505.92 181.469 505.92 180.842V175.753C505.92 171.458 503.831 168.687 497.78 168.687H485.789V182.144L485.781 182.136ZM501.449 156.866C504.387 156.866 505.348 155.739 505.348 154.207C505.348 152.674 504.331 151.547 501.449 151.547H485.781V156.858H501.449V156.866Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M583.042 140.074V151.832H554.43V155.222H582.589V166.924H554.43V182.135H533.283V140.074H583.05H583.042Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M623.599 139.225C652.044 139.225 656.34 151.324 656.34 160.144V161.843C656.34 170.496 651.988 182.984 623.599 182.984H620.772C592.438 182.984 588.031 170.488 588.031 161.843V160.144C588.031 151.324 592.327 139.225 620.772 139.225H623.599ZM634.176 160.485C634.176 156.809 631.349 152.626 622.13 152.626C612.91 152.626 610.139 156.809 610.139 160.485V161.16C610.139 164.947 613.077 169.472 622.13 169.472C631.183 169.472 634.176 165.122 634.176 161.271V160.477V160.485Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M683.261 182.136H661.772V140.075H708.259C717.304 140.075 724.832 142.338 724.832 150.706V151.214C724.832 157.485 718.948 160.145 715.104 160.939C721.83 162.241 725.054 166.028 725.054 171.514V177.904C725.054 179.992 725.34 180.961 725.793 181.803V182.144H703.685C703.399 181.858 703.399 181.469 703.399 180.842V175.753C703.399 171.458 701.311 168.687 695.259 168.687H683.268V182.144L683.261 182.136ZM698.928 156.866C701.866 156.866 702.827 155.739 702.827 154.207C702.827 152.674 701.811 151.547 698.928 151.547H683.261V156.858H698.928V156.866Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M730.761 140.074H759.095L768.99 158.786L779.281 140.074H806.257V182.135H784.88V161.327L773.572 182.135H763.336L752.083 161.327V182.135H730.761V140.074Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M854.945 177.103H833.909L831.702 182.136H809.983L829.836 140.075H860.036L881.127 182.136H857.153L854.945 177.103ZM844.312 153.016L839.278 164.662H849.458L844.312 153.016Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M909.128 140.075L928.917 159.184V140.075H950.406V182.136H928.52L906.348 161.106V182.136H884.86V140.075H909.12H909.128Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M10.9663 426.491H-7.18701V412.019H50.6082V426.491H32.4549V454.079H10.9663V426.491Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M80.579 412.019L100.368 431.128V412.019H121.857V454.079H99.9711L77.7996 433.049V454.079H56.311V412.019H80.571H80.579Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M178.929 412.019V423.776H149.357V427.055H178.596V438.59H149.357V442.211H179.159V454.079H128.209V412.019H178.937H178.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M211.328 454.929C185.821 454.929 183.502 445.656 183.502 440.226V439.773H208.668C208.668 441.583 209.462 444.298 215.338 444.298C220.317 444.298 221.786 442.885 221.786 441.361V441.194C221.786 439.781 221.223 438.479 213.98 438.201L205.102 437.915C187.799 437.296 184.177 431.191 184.177 424.57V424.062C184.177 416.147 190.514 411.288 210.867 411.288H218.046C240.607 411.288 245.872 417.282 245.872 424.514V424.8H221.215C221.048 423.443 220.365 421.41 214.767 421.41C209.621 421.41 208.66 422.204 208.66 423.617V423.728C208.66 425.253 210.296 426.102 216.799 426.269L225.28 426.491C241.679 426.944 247.452 430.897 247.452 439.44V440.234C247.452 447.696 242.648 454.937 218.721 454.937H211.312L211.328 454.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.305 454.929C253.798 454.929 251.479 445.656 251.479 440.226V439.773H276.644C276.644 441.583 277.438 444.298 283.315 444.298C288.294 444.298 289.763 442.885 289.763 441.361V441.194C289.763 439.781 289.199 438.479 281.957 438.201L273.079 437.915C255.775 437.296 252.154 431.191 252.154 424.57V424.062C252.154 416.147 258.491 411.288 278.844 411.288H286.023C308.583 411.288 313.848 417.282 313.848 424.514V424.8H289.191C289.024 423.443 288.342 421.41 282.743 421.41C277.597 421.41 276.636 422.204 276.636 423.617V423.728C276.636 425.253 278.272 426.102 284.776 426.269L293.257 426.491C309.655 426.944 315.429 430.897 315.429 439.44V440.234C315.429 447.696 310.624 454.937 286.698 454.937H279.289L279.305 454.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M361.478 442.607V454.087H339.99V412.026H381.784C398.071 412.026 402.423 418.473 402.423 426.388V427.857C402.423 435.772 397.841 442.615 381.784 442.615H361.478V442.607ZM361.478 429.889H376.519C379.457 429.889 380.307 428.587 380.307 427.174V427.063C380.307 425.539 379.457 424.348 376.519 424.348H361.478V429.889V429.889Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M458.177 412.019V423.776H428.605V427.055H457.844V438.59H428.605V442.211H458.407V454.079H407.458V412.019H458.185H458.177Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M485.781 454.079H464.292V412.019H510.78C519.825 412.019 527.353 414.281 527.353 422.649V423.157C527.353 429.429 521.468 432.088 517.625 432.882C524.351 434.184 527.575 437.971 527.575 443.457V449.848C527.575 451.936 527.861 452.904 528.314 453.746V454.087H506.206C505.92 453.801 505.92 453.412 505.92 452.785V447.696C505.92 443.401 503.831 440.631 497.78 440.631H485.789V454.087L485.781 454.079ZM501.449 428.81C504.387 428.81 505.348 427.682 505.348 426.15C505.348 424.618 504.331 423.49 501.449 423.49H485.781V428.802H501.449V428.81Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M583.042 412.019V423.776H554.43V427.166H582.589V438.868H554.43V454.079H533.283V412.019H583.05H583.042Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M623.599 411.169C652.044 411.169 656.34 423.268 656.34 432.088V433.787C656.34 442.441 651.988 454.929 623.599 454.929H620.772C592.438 454.929 588.031 442.433 588.031 433.787V432.088C588.031 423.268 592.327 411.169 620.772 411.169H623.599ZM634.176 432.43C634.176 428.754 631.349 424.57 622.13 424.57C612.91 424.57 610.139 428.754 610.139 432.43V433.104C610.139 436.891 613.077 441.417 622.13 441.417C631.183 441.417 634.176 437.066 634.176 433.216V432.422V432.43Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M683.261 454.079H661.772V412.019H708.259C717.304 412.019 724.832 414.281 724.832 422.649V423.157C724.832 429.429 718.948 432.088 715.104 432.882C721.83 434.184 725.054 437.971 725.054 443.457V449.848C725.054 451.936 725.34 452.904 725.793 453.746V454.087H703.685C703.399 453.801 703.399 453.412 703.399 452.785V447.696C703.399 443.401 701.311 440.631 695.259 440.631H683.268V454.087L683.261 454.079ZM698.928 428.81C701.866 428.81 702.827 427.682 702.827 426.15C702.827 424.618 701.811 423.49 698.928 423.49H683.261V428.802H698.928V428.81Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M730.761 412.019H759.095L768.99 430.731L779.281 412.019H806.257V454.079H784.88V433.271L773.572 454.079H763.336L752.083 433.271V454.079H730.761V412.019Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M854.945 449.054H833.909L831.702 454.087H809.983L829.836 412.026H860.036L881.127 454.087H857.153L854.945 449.054ZM844.312 424.967L839.278 436.613H849.458L844.312 424.967Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M909.128 412.019L928.917 431.128V412.019H950.406V454.079H928.52L906.348 433.049V454.079H884.86V412.019H909.12H909.128Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M-35.1251 194.456L-15.3359 213.565V194.456H6.15265V236.517H-15.733L-37.9045 215.486V236.517H-59.3931V194.456H-35.1331H-35.1251Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M44.3353 237.374C15.6045 237.374 11.5942 224.989 11.5942 216.232V214.534C11.5942 205.713 15.3821 193.614 44.3353 193.614H46.3126C74.1938 193.614 77.7514 205.261 77.7514 211.477V212.326H55.9769C55.6911 211.421 54.7858 207.015 45.0024 207.015C35.2189 207.015 33.6942 211.199 33.6942 214.875V215.55C33.6942 219.17 35.5604 223.862 45.0024 223.862C54.4443 223.862 55.8022 219.114 55.9769 218.098H77.7514V219.567C77.7514 225.45 74.1859 237.374 46.3126 237.374H44.3353V237.374Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M133.799 194.456V206.214H104.226V209.493H133.466V221.028H104.226V224.648H134.029V236.517H83.0793V194.456H133.807H133.799Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M209.24 194.456V206.214H180.628V209.604H208.787V221.306H180.628V236.517H159.481V194.456H209.248H209.24Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M214.727 194.456H236.327V211.755C236.327 216.447 238.304 221.425 247.294 221.425H247.405C256.227 221.425 258.379 216.447 258.379 211.755V194.456H279.979V213.962C279.979 222.385 276.755 237.255 248.707 237.255H245.88C217.832 237.255 214.719 222.385 214.719 213.962V194.456H214.727Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M310.186 194.456L329.976 213.565V194.456H351.464V236.517H329.579L307.407 215.486V236.517H285.918V194.456H310.179H310.186Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M427.133 194.456V206.214H398.522V209.604H426.681V221.306H398.522V236.517H377.375V194.456H427.141H427.133Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M454.452 236.525H433.019V194.464H454.452V236.525Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M478.316 208.937H460.163V194.464H517.958V208.937H499.804V236.525H478.316V208.937V208.937Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M547.935 194.456L567.724 213.565V194.456H589.212V236.517H567.327L545.155 215.487V236.517H523.667V194.456H547.927H547.935Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M646.278 194.456V206.214H616.706V209.493H645.945V221.028H616.706V224.648H646.509V236.517H595.559V194.456H646.286H646.278Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M678.677 237.374C653.17 237.374 650.851 228.101 650.851 222.671V222.219H676.016C676.016 224.029 676.81 226.744 682.687 226.744C687.666 226.744 689.135 225.331 689.135 223.806V223.64C689.135 222.226 688.571 220.924 681.329 220.647L672.451 220.361C655.147 219.742 651.526 213.636 651.526 207.015V206.507C651.526 198.592 657.863 193.733 678.216 193.733H685.395C707.955 193.733 713.22 199.727 713.22 206.96V207.246H688.563C688.397 205.888 687.714 203.856 682.115 203.856C676.969 203.856 676.008 204.65 676.008 206.063V206.174C676.008 207.698 677.644 208.548 684.148 208.714L692.629 208.937C709.027 209.389 714.801 213.343 714.801 221.885V222.679C714.801 230.142 709.996 237.382 686.07 237.382H678.661L678.677 237.374Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M746.654 237.374C721.147 237.374 718.828 228.101 718.828 222.671V222.219H743.993C743.993 224.029 744.788 226.744 750.664 226.744C755.643 226.744 757.112 225.331 757.112 223.806V223.64C757.112 222.226 756.548 220.924 749.306 220.647L740.428 220.361C723.124 219.742 719.503 213.636 719.503 207.015V206.507C719.503 198.592 725.84 193.733 746.193 193.733H753.372C775.932 193.733 781.197 199.727 781.197 206.96V207.246H756.54C756.374 205.888 755.691 203.856 750.092 203.856C744.946 203.856 743.986 204.65 743.986 206.063V206.174C743.986 207.698 745.621 208.548 752.125 208.714L760.606 208.937C777.005 209.389 782.778 213.343 782.778 221.885V222.679C782.778 230.142 777.973 237.382 754.047 237.382H746.638L746.654 237.374Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M828.827 225.045V236.525H807.338V194.464H849.132C865.419 194.464 869.771 200.91 869.771 208.825V210.294C869.771 218.209 865.189 225.053 849.132 225.053H828.827V225.045ZM828.827 212.327H843.867C846.805 212.327 847.655 211.025 847.655 209.611V209.5C847.655 207.976 846.805 206.785 843.867 206.785H828.827V212.327Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M925.533 194.456V206.214H895.96V209.493H925.199V221.028H895.96V224.648H925.763V236.517H874.813V194.456H925.541H925.533Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M-35.7092 290.913H-57.1978V248.853H-10.7106C-1.66575 248.853 5.8624 251.115 5.8624 259.483V259.991C5.8624 266.263 -0.0219452 268.922 -3.86543 269.716C2.86067 271.018 6.08477 274.805 6.08477 280.291V286.682C6.08477 288.77 6.37065 289.738 6.82329 290.58V290.921H-15.2847C-15.5706 290.635 -15.5706 290.246 -15.5706 289.619V284.53C-15.5706 280.235 -17.6591 277.465 -23.7102 277.465H-35.7013V290.921L-35.7092 290.913ZM-20.0414 265.643C-17.1032 265.643 -16.1424 264.516 -16.1424 262.984C-16.1424 261.452 -17.1588 260.324 -20.0414 260.324H-35.7092V265.636H-20.0414V265.643Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M61.5601 248.853V260.61H32.9483V264H61.1074V275.702H32.9483V290.914H11.8012V248.853H61.568H61.5601Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M102.108 248.003C130.553 248.003 134.849 260.102 134.849 268.922V270.621C134.849 279.275 130.497 291.763 102.108 291.763H99.2807C70.9469 291.763 66.5396 279.267 66.5396 270.621V268.922C66.5396 260.102 70.8357 248.003 99.2807 248.003H102.108ZM112.685 269.264C112.685 265.588 109.858 261.404 100.639 261.404C91.419 261.404 88.6476 265.588 88.6476 269.264V269.938C88.6476 273.725 91.5857 278.25 100.639 278.25C109.691 278.25 112.685 273.9 112.685 270.049V269.256V269.264Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M161.769 290.913H140.281V248.853H186.768C195.813 248.853 203.341 251.115 203.341 259.483V259.991C203.341 266.263 197.457 268.922 193.613 269.716C200.339 271.018 203.563 274.805 203.563 280.291V286.682C203.563 288.77 203.849 289.738 204.302 290.58V290.921H182.194C181.908 290.635 181.908 290.246 181.908 289.619V284.53C181.908 280.235 179.819 277.465 173.768 277.465H161.777V290.921L161.769 290.913ZM177.437 265.643C180.375 265.643 181.336 264.516 181.336 262.984C181.336 261.452 180.32 260.324 177.437 260.324H161.769V265.636H177.437V265.643Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M209.271 248.853H237.605L247.499 267.565L257.791 248.853H284.767V290.913H263.39V270.105L252.081 290.913H241.845L230.593 270.105V290.913H209.271V248.853Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M333.464 285.888H312.428L310.22 290.921H288.501L308.354 248.86H338.554L359.646 290.921H335.672L333.464 285.888ZM322.831 261.801L317.796 273.447H327.977L322.831 261.801Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M387.635 248.853L407.424 267.962V248.853H428.912V290.913H407.027L384.855 269.883V290.913H363.367V248.853H387.627H387.635Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M467.095 291.763C438.364 291.763 434.354 279.378 434.354 270.621V268.922C434.354 260.102 438.142 248.003 467.095 248.003H469.072C496.954 248.003 500.511 259.649 500.511 265.866V266.715H478.737C478.451 265.81 477.546 261.404 467.762 261.404C457.979 261.404 456.454 265.588 456.454 269.264V269.938C456.454 273.559 458.32 278.25 467.762 278.25C477.204 278.25 478.562 273.503 478.737 272.487H500.511V273.955C500.511 279.838 496.946 291.763 469.072 291.763H467.095V291.763Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M556.558 248.853V260.61H526.986V263.889H556.225V275.424H526.986V279.045H556.788V290.913H505.838V248.853H556.566H556.558Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M631.999 248.853V260.61H603.388V264H631.547V275.702H603.388V290.914H582.24V248.853H632.007H631.999Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M637.485 248.853H659.085V266.152C659.085 270.844 661.062 275.821 670.051 275.821H670.163C678.985 275.821 681.137 270.844 681.137 266.152V248.853H702.737V268.359C702.737 276.782 699.513 291.652 671.465 291.652H668.638C640.59 291.652 637.477 276.782 637.477 268.359V248.853H637.485Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M732.946 248.853L752.735 267.962V248.853H774.224V290.913H752.338L730.167 269.883V290.913H708.678V248.853H732.938H732.946Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M849.895 248.853V260.61H821.283V264H849.443V275.702H821.283V290.914H800.136V248.853H849.903H849.895Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M877.212 290.914H855.779V248.853H877.212V290.914Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M901.075 263.326H882.922V248.853H940.717V263.326H922.564V290.914H901.075V263.326V263.326Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M58.3755 357.63V369.388H29.7638V372.778H57.9229V384.48H29.7638V399.691H8.6167V357.63H58.3835H58.3755Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M63.8552 357.63H85.455V374.929C85.455 379.621 87.4323 384.599 96.4216 384.599H96.5328C105.355 384.599 107.507 379.621 107.507 374.929V357.63H129.107V377.136C129.107 385.56 125.883 400.429 97.8351 400.429H95.0081C66.9602 400.429 63.8473 385.56 63.8473 377.136V357.63H63.8552Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M159.314 357.63L179.104 376.739V357.63H200.592V399.691H178.706L156.535 378.66V399.691H135.046V357.63H159.306H159.314Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M276.27 357.63V369.388H247.659V372.778H275.818V384.48H247.659V399.691H226.511V357.63H276.278H276.27Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M303.582 399.691H282.149V357.63H303.582V399.691Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M327.453 372.103H309.299V357.63H367.094V372.103H348.941V399.691H327.453V372.103V372.103Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M397.061 357.63L416.85 376.739V357.63H438.339V399.691H416.453L394.282 378.661V399.691H372.793V357.63H397.053H397.061Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M495.405 357.63V369.388H465.832V372.666H495.071V384.202H465.832V387.822H495.635V399.691H444.685V357.63H495.413H495.405Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M527.812 400.54C502.306 400.54 499.987 391.267 499.987 385.837V385.385H525.152C525.152 387.195 525.946 389.91 531.823 389.91C536.802 389.91 538.271 388.497 538.271 386.972V386.806C538.271 385.392 537.707 384.09 530.465 383.813L521.587 383.527C504.283 382.908 500.662 376.802 500.662 370.181V369.673C500.662 361.758 506.999 356.899 527.352 356.899H534.531C557.091 356.899 562.356 362.893 562.356 370.126V370.412H537.699C537.532 369.054 536.849 367.022 531.251 367.022C526.105 367.022 525.144 367.816 525.144 369.229V369.34C525.144 370.864 526.78 371.714 533.284 371.88L541.765 372.103C558.163 372.555 563.936 376.509 563.936 385.051V385.845C563.936 393.308 559.132 400.548 535.206 400.548H527.796L527.812 400.54Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M595.789 400.54C570.282 400.54 567.963 391.267 567.963 385.837V385.385H593.129C593.129 387.195 593.923 389.91 599.799 389.91C604.778 389.91 606.247 388.497 606.247 386.972V386.806C606.247 385.392 605.684 384.09 598.441 383.813L589.563 383.527C572.259 382.908 568.638 376.802 568.638 370.181V369.673C568.638 361.758 574.975 356.899 595.328 356.899H602.507C625.068 356.899 630.333 362.893 630.333 370.126V370.412H605.676C605.509 369.054 604.826 367.022 599.227 367.022C594.082 367.022 593.121 367.816 593.121 369.229V369.34C593.121 370.864 594.757 371.714 601.26 371.88L609.741 372.103C626.14 372.555 631.913 376.509 631.913 385.051V385.845C631.913 393.308 627.109 400.548 603.182 400.548H595.773L595.789 400.54Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M677.957 388.211V399.691H656.468V357.63H698.262C714.549 357.63 718.901 364.076 718.901 371.991V373.46C718.901 381.375 714.319 388.219 698.262 388.219H677.957V388.211ZM677.957 375.493H692.997C695.935 375.493 696.785 374.191 696.785 372.777V372.666C696.785 371.142 695.935 369.951 692.997 369.951H677.957V375.493V375.493Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M774.663 357.63V369.388H745.09V372.667H774.329V384.202H745.09V387.822H774.893V399.691H723.943V357.63H774.671H774.663Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M802.258 399.691H780.769V357.63H827.256C836.301 357.63 843.829 359.893 843.829 368.26V368.769C843.829 375.04 837.945 377.7 834.101 378.494C840.828 379.796 844.052 383.583 844.052 389.069V395.459C844.052 397.547 844.337 398.516 844.79 399.357V399.699H822.682C822.396 399.413 822.396 399.024 822.396 398.397V393.308C822.396 389.013 820.308 386.242 814.257 386.242H802.266V399.699L802.258 399.691ZM817.925 374.421C820.864 374.421 821.824 373.294 821.824 371.762C821.824 370.229 820.808 369.102 817.925 369.102H802.258V374.413H817.925V374.421Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M899.527 357.63V369.388H870.915V372.777H899.074V384.48H870.915V399.691H849.768V357.63H899.535H899.527Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M940.075 356.78C968.52 356.78 972.816 368.879 972.816 377.699V379.398C972.816 388.052 968.464 400.54 940.075 400.54H937.248C908.914 400.54 904.507 388.044 904.507 379.398V377.699C904.507 368.879 908.803 356.78 937.248 356.78H940.075ZM950.652 378.041C950.652 374.365 947.825 370.181 938.606 370.181C929.386 370.181 926.615 374.365 926.615 378.041V378.716C926.615 382.503 929.553 387.028 938.606 387.028C947.659 387.028 950.652 382.677 950.652 378.827V378.033V378.041Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M10.9663 45.7697H-7.18701V31.2969H50.6082V45.7697H32.4549V73.3576H10.9663V45.7697Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M80.579 31.2891L100.368 50.3982V31.2891H121.857V73.3498H99.9711L77.7996 52.3194V73.3498H56.311V31.2891H80.571H80.579Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M178.929 31.2891V43.0467H149.357V46.3255H178.596V57.8609H149.357V61.481H179.159V73.3498H128.209V31.2891H178.937H178.929Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M211.328 74.207C185.821 74.207 183.502 64.9343 183.502 59.504V59.0515H208.668C208.668 60.8616 209.462 63.5767 215.338 63.5767C220.317 63.5767 221.786 62.1636 221.786 60.6393V60.4726C221.786 59.0594 221.223 57.7574 213.98 57.4796L205.102 57.1938C187.799 56.5745 184.177 50.4695 184.177 43.8483V43.3402C184.177 35.4251 190.514 30.5664 210.867 30.5664H218.046C240.607 30.5664 245.872 36.5603 245.872 43.7928V44.0786H221.215C221.048 42.721 220.365 40.6886 214.767 40.6886C209.621 40.6886 208.66 41.4825 208.66 42.8957V43.0068C208.66 44.5311 210.296 45.3806 216.799 45.5473L225.28 45.7696C241.679 46.2221 247.452 50.1757 247.452 58.7181V59.512C247.452 66.9746 242.648 74.215 218.721 74.215H211.312L211.328 74.207Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M279.305 74.207C253.798 74.207 251.479 64.9343 251.479 59.504V59.0515H276.644C276.644 60.8616 277.438 63.5767 283.315 63.5767C288.294 63.5767 289.763 62.1636 289.763 60.6393V60.4726C289.763 59.0594 289.199 57.7574 281.957 57.4796L273.079 57.1938C255.775 56.5745 252.154 50.4695 252.154 43.8483V43.3402C252.154 35.4251 258.491 30.5664 278.844 30.5664H286.023C308.583 30.5664 313.848 36.5603 313.848 43.7928V44.0786H289.191C289.024 42.721 288.342 40.6886 282.743 40.6886C277.597 40.6886 276.636 41.4825 276.636 42.8957V43.0068C276.636 44.5311 278.272 45.3806 284.776 45.5473L293.257 45.7696C309.655 46.2221 315.429 50.1757 315.429 58.7181V59.512C315.429 66.9746 310.624 74.215 286.698 74.215H279.289L279.305 74.207Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M361.478 61.8779V73.3576H339.99V31.2969H381.784C398.071 31.2969 402.423 37.7433 402.423 45.6585V47.1272C402.423 55.0424 397.841 61.8858 381.784 61.8858H361.478V61.8779ZM361.478 49.1596H376.519C379.457 49.1596 380.307 47.8576 380.307 46.4445V46.3333C380.307 44.809 379.457 43.6182 376.519 43.6182H361.478V49.1596V49.1596Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M458.177 31.29V43.0477H428.605V46.3265H457.844V57.8618H428.605V61.482H458.407V73.3508H407.458V31.29H458.185H458.177Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M485.781 73.3576H464.292V31.2969H510.78C519.825 31.2969 527.353 33.5595 527.353 41.9272V42.4353C527.353 48.7071 521.468 51.3666 517.625 52.1605C524.351 53.4625 527.575 57.2494 527.575 62.7353V69.1262C527.575 71.2141 527.861 72.1827 528.314 73.0242V73.3656H506.206C505.92 73.0798 505.92 72.6908 505.92 72.0636V66.9747C505.92 62.6797 503.831 59.909 497.78 59.909H485.789V73.3656L485.781 73.3576ZM501.449 48.0878C504.387 48.0878 505.348 46.9605 505.348 45.4283C505.348 43.8961 504.331 42.7687 501.449 42.7687H485.781V48.0799H501.449V48.0878Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M583.042 31.29V43.0477H554.43V46.4376H582.589V58.1397H554.43V73.3508H533.283V31.29H583.05H583.042Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M623.599 30.4473C652.044 30.4473 656.34 42.5463 656.34 51.3665V53.0654C656.34 61.7189 651.988 74.207 623.599 74.207H620.772C592.438 74.207 588.031 61.711 588.031 53.0654V51.3665C588.031 42.5463 592.327 30.4473 620.772 30.4473H623.599ZM634.176 51.7079C634.176 48.0321 631.349 43.8483 622.13 43.8483C612.91 43.8483 610.139 48.0321 610.139 51.7079V52.3827C610.139 56.1696 613.077 60.6948 622.13 60.6948C631.183 60.6948 634.176 56.3442 634.176 52.4938V51.6999V51.7079Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M683.261 73.3576H661.772V31.2969H708.259C717.304 31.2969 724.832 33.5595 724.832 41.9272V42.4353C724.832 48.7071 718.948 51.3666 715.104 52.1605C721.83 53.4625 725.054 57.2494 725.054 62.7353V69.1262C725.054 71.2141 725.34 72.1827 725.793 73.0242V73.3656H703.685C703.399 73.0798 703.399 72.6908 703.399 72.0636V66.9747C703.399 62.6797 701.311 59.909 695.259 59.909H683.268V73.3656L683.261 73.3576ZM698.928 48.0878C701.866 48.0878 702.827 46.9605 702.827 45.4283C702.827 43.8961 701.811 42.7687 698.928 42.7687H683.261V48.0799H698.928V48.0878Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M730.761 31.29H759.095L768.99 50.0022L779.281 31.29H806.257V73.3508H784.88V52.5427L773.572 73.3508H763.336L752.083 52.5427V73.3508H730.761V31.29Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M854.945 68.3243H833.909L831.702 73.3576H809.983L829.836 31.2969H860.036L881.127 73.3576H857.153L854.945 68.3243ZM844.312 44.2374L839.278 55.8839H849.458L844.312 44.2374Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M909.128 31.2891L928.917 50.3982V31.2891H950.406V73.3498H928.52L906.348 52.3194V73.3498H884.86V31.2891H909.12H909.128Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M58.3755 -23.0918V-11.3341H29.7638V-7.9442H57.9229V3.75787H29.7638V18.969H8.6167V-23.0918H58.3835H58.3755Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M63.8551 -23.0918H85.4548V-5.79274C85.4548 -1.10079 87.4322 3.87696 96.4215 3.87696H96.5327C105.355 3.87696 107.507 -1.10079 107.507 -5.79274V-23.0918H129.107V-3.5857C129.107 4.83757 125.883 19.7073 97.835 19.7073H95.008C66.9601 19.7073 63.8472 4.83757 63.8472 -3.5857V-23.0918H63.8551Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M159.314 -23.0918L179.104 -3.98265V-23.0918H200.592V18.969H178.706L156.535 -2.06141V18.969H135.046V-23.0918H159.306H159.314Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M276.27 -23.0918V-11.3341H247.658V-7.9442H275.817V3.75787H247.658V18.969H226.511V-23.0918H276.278H276.27Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M303.582 18.969H282.149V-23.0918H303.582V18.969Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M327.453 -8.61902H309.299V-23.0918H367.094V-8.61902H348.941V18.969H327.453V-8.61902V-8.61902Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M397.061 -23.0918L416.851 -3.98265V-23.0918H438.339V18.969H416.454L394.282 -2.06141V18.969H372.793V-23.0918H397.054H397.061Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M495.405 -23.0918V-11.3341H465.832V-8.05535H495.071V3.48H465.832V7.10018H495.635V18.969H444.685V-23.0918H495.413H495.405Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M527.812 19.8184C502.306 19.8184 499.987 10.5456 499.987 5.11536V4.66283H525.152C525.152 6.47292 525.946 9.18806 531.823 9.18806C536.802 9.18806 538.271 7.77492 538.271 6.25063V6.08391C538.271 4.67077 537.707 3.36878 530.465 3.09091L521.587 2.80511C504.283 2.18587 500.662 -3.91921 500.662 -10.5403V-11.0484C500.662 -18.9636 506.999 -23.8223 527.352 -23.8223H534.531C557.091 -23.8223 562.356 -17.8283 562.356 -10.5959V-10.3101H537.699C537.532 -11.6677 536.849 -13.7001 531.251 -13.7001C526.105 -13.7001 525.144 -12.9062 525.144 -11.493V-11.3819C525.144 -9.85758 526.78 -9.00811 533.284 -8.84139L541.765 -8.6191C558.163 -8.16658 563.936 -4.21296 563.936 4.32939V5.12329C563.936 12.5859 559.132 19.8263 535.206 19.8263H527.796L527.812 19.8184Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M595.789 19.8184C570.282 19.8184 567.963 10.5456 567.963 5.11536V4.66283H593.129C593.129 6.47292 593.923 9.18806 599.799 9.18806C604.778 9.18806 606.247 7.77492 606.247 6.25063V6.08391C606.247 4.67077 605.684 3.36878 598.441 3.09091L589.563 2.80511C572.259 2.18587 568.638 -3.91921 568.638 -10.5403V-11.0484C568.638 -18.9636 574.975 -23.8223 595.328 -23.8223H602.507C625.068 -23.8223 630.333 -17.8283 630.333 -10.5959V-10.3101H605.676C605.509 -11.6677 604.826 -13.7001 599.227 -13.7001C594.082 -13.7001 593.121 -12.9062 593.121 -11.493V-11.3819C593.121 -9.85758 594.757 -9.00811 601.26 -8.84139L609.741 -8.6191C626.14 -8.16658 631.913 -4.21296 631.913 4.32939V5.12329C631.913 12.5859 627.109 19.8263 603.182 19.8263H595.773L595.789 19.8184Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M677.957 7.49701V18.9768H656.468V-23.084H698.262C714.549 -23.084 718.901 -16.6375 718.901 -8.72235V-7.25364C718.901 0.661535 714.319 7.50494 698.262 7.50494H677.957V7.49701ZM677.957 -5.22126H692.997C695.935 -5.22126 696.785 -6.52325 696.785 -7.93639V-8.04754C696.785 -9.57182 695.935 -10.7627 692.997 -10.7627H677.957V-5.22126Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M774.663 -23.0918V-11.3341H745.09V-8.05535H774.329V3.48H745.09V7.10018H774.893V18.969H723.943V-23.0918H774.671H774.663Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M802.258 18.969H780.769V-23.0918H827.256C836.301 -23.0918 843.829 -20.8292 843.829 -12.4615V-11.9534C843.829 -5.68159 837.945 -3.02202 834.101 -2.22812C840.828 -0.92613 844.052 2.86077 844.052 8.34661V14.7375C844.052 16.8254 844.337 17.794 844.79 18.6355V18.9769H822.682C822.396 18.6911 822.396 18.3021 822.396 17.6749V12.586C822.396 8.29103 820.308 5.52033 814.257 5.52033H802.266V18.9769L802.258 18.969ZM817.925 -6.30083C820.864 -6.30083 821.824 -7.42817 821.824 -8.96039C821.824 -10.4926 820.808 -11.62 817.925 -11.62H802.258V-6.30877H817.925V-6.30083Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M899.527 -23.0918V-11.3341H870.915V-7.9442H899.074V3.75787H870.915V18.969H849.768V-23.0918H899.535H899.527Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    <path d="M940.075 -23.9414C968.52 -23.9414 972.816 -11.8424 972.816 -3.02216V-1.32322C972.816 7.33028 968.464 19.8183 940.075 19.8183H937.248C908.914 19.8183 904.507 7.32234 904.507 -1.32322V-3.02216C904.507 -11.8424 908.803 -23.9414 937.248 -23.9414H940.075V-23.9414ZM950.652 -2.68079C950.652 -6.35654 947.825 -10.5404 938.606 -10.5404C929.386 -10.5404 926.615 -6.35654 926.615 -2.68079V-2.00597C926.615 1.78092 929.553 6.30615 938.606 6.30615C947.659 6.30615 950.652 1.95558 950.652 -1.89483V-2.68873V-2.68079Z" stroke="#ADFF19" stroke-width="1.55401" stroke-miterlimit="10"/>
    </g>
    <defs>
    <clipPath id="clip0_13580_1420">
    <rect width="911" height="979" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}
