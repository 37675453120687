import React, { useState, useEffect, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import { Select, notification,message } from 'antd';
import DrawerForm from '../components/DrawerForm';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import { getLocationId, getLocationName } from '../services/utils/getLocation';
import { useGetCoaches } from '../api/coaches';
import SpinIndicador from '../components/helpers/SpinIndicador';
import {
  DeleteCoachClass,
  getCalendarCoaches,
  updateCoachClass,
  useGetCalendarClasses,
} from '../api/calendar';
import { useMutation } from '@tanstack/react-query';
import { formatError } from '../services/utils/formatError';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canUpdateCalendar } from '../services/can_user';
import { useAuth } from '../context/AuthProvider';
import { t } from 'i18next';
const start =
  dayjs().day() === 0
    ? dayjs().subtract(1, 'week').day(1).format('YYYY-MM-DD')
    : dayjs().day(1).format('YYYY-MM-DD');
const end =
  dayjs().day() === 0
    ? dayjs().subtract(1, 'week').day(7).format('YYYY-MM-DD')
    : dayjs().day(7).format('YYYY-MM-DD');
export default function CalendarClasses() {
  const calendarRef = React.useRef(null);
  const canUpdate = canUpdateCalendar();
  const {user} = useAuth()
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const { isLoading: isLoadingClases, data: classes ,isFetching} = useGetCalendarClasses(
    startDate,
    endDate
  );
  const [messageApi, messageContext] = message.useMessage();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('calendar.success',`La clase se ha actualizado correctamente`)
          : t('calendar.error',`Ocurrió un error al actualizar la clase`),
      ...openNotificationFields(type, description),
    });
  };
  const { isLoading: isLoadingCoaches, data: coaches } = useGetCoaches();
  useEffect(() => {
    if(isFetching){
     init_loading()
    } else {
     messageApi.destroy();
   
    }
    
   }, [isFetching])
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const [initial_selectedClassCoach, setinitial_selectedClassCoach] = useState(
    []
  );

  const [selectedClassCoach, setselectedClassCoach] = useState([]);
  const handleDateClick = (arg) => {
    // bind with an arrow function
  };
  useEffect(() => {
    if (openDrawer.visible == false && selectedClassCoach.length > 0) {
      setselectedClassCoach([]);
      setinitial_selectedClassCoach([]);
    }
  }, [openDrawer, selectedClassCoach]);
  const formatClasses = useMemo(() => {
    if (isLoadingClases) return [];
    if(isFetching) return [];
    if (!classes) return [];
    const formattedClasses = [];
    classes.forEach((clase) => {
      const formattedClass = {
        title: clase.gym_class_name,
        start:clase.start,
        end:clase.end,
        id: clase.id,
        color: '#CEF5CE',
      };
      formattedClasses.push(formattedClass);
    });
    return formattedClasses;
  }, [classes, isFetching]);
  const slotLabelContent = (arg) => {
    const hour = dayjs(arg.date).tz(user.country.time_zone).format('HH');
    // const hour = dayjs(date).format('HH');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}${ampm}`;
  };
  const useUpdateCoach = useMutation({
    mutationFn: (data) => updateCoachClass(data.data),
    onSuccess: () => {
      openNotification('success');
      setopenDrawer({
        visible: false,
        record: null,
      });
      // queryClient.invalidateQueries('cal');
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteCoach = useMutation({
    mutationFn: (id) => DeleteCoachClass(id),
    onSuccess: () => {
      openNotification('success');
      setopenDrawer({
        visible: false,
        record: null,
      });
      // queryClient.invalidateQueries('cal');
    },
    onError: (error) => formatError(error, openNotification),
  });
  const init_loading = () => {
    messageApi.open({
      type: 'loading',
      content: 'Cargando...',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };
  const handleUpdate = async () => {
    let toRemove  = initial_selectedClassCoach.filter((coach)=>!selectedClassCoach.map((c)=>c.coach_id).includes(coach.coach_id))

    if(toRemove.length > 0){
      toRemove.forEach(async (data,i) => {
        await useDeleteCoach.mutateAsync(data.coach_gym_class);
      });
    }

    selectedClassCoach.forEach(async (coach,i) => {
      const data = {
        id: openDrawer.record.id,
        data: {
          location_id: getLocationId(),
          coach_id: coach.coach_id,
          gym_class_id: openDrawer.record.id,
          scheduled_at: dayjs(openDrawer.record.start).tz(user.country.time_zone).format(),
        },
      };
      if(initial_selectedClassCoach.map((c)=>c.coach_id).includes(coach.coach_id)){
          if(initial_selectedClassCoach.length && selectedClassCoach.length  && i == selectedClassCoach.length -1){
            openNotification('success');
            setopenDrawer({
              visible: false,
              record: null,
            }); 
          }
      } else {
        await useUpdateCoach.mutateAsync(data);
      }
    });
  };
  return (
    <div className='h-screen pl-[1rem] lg:pl-[3rem] mr-10  mt-10'>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin,momentTimezonePlugin]}
        initialView='timeGridWeek'
        // timeZone='UTC'
        timeZone={user.country.time_zone}
        allDaySlot={false}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        buttonText={{
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
        }}
        
        locale={'es'}
        slotDuration={'01:00:00'}
        nowIndicator
        expandRows={true}
        dateClick={handleDateClick}
        editable={true}
        selectable={true}
        
      
        slotMinTime={'05:00'
        } // Hora mínima
        slotMaxTime={
         '23:00'
        } // Hora máxima agarrar la ultima del array de classes
        datesSet={(date) => {
          if (!isLoadingClases) {
            setStartDate(dayjs(date.start).format('YYYY-MM-DD'));
            setEndDate(dayjs(date.end).subtract(1,'day').format('YYYY-MM-DD'));
          }
          // Aquí puedes agregar el código que deseas ejecutar al avanzar a la semana siguiente
        }}
        eventClick={async (info) => {
          if (!canUpdate) return;
          info.jsEvent.preventDefault();
          const calendarDay = await getCalendarCoaches(
            dayjs(info.event.start).format('YYYY-MM-DD'),
            dayjs(info.event.end).format('YYYY-MM-DD')
          );
          if (calendarDay && calendarDay.length > 0) {
            let findCoachClasses = calendarDay.filter(
              (day) =>
                dayjs(info.event.start).tz(user.country.time_zone).format('YYYY-MM-DD') == dayjs(day.start).tz(user.country.time_zone).format('YYYY-MM-DD')&&
                info.event.id == day.id

            );
            if (findCoachClasses && findCoachClasses.length > 0) {
              findCoachClasses.forEach((coachClass) => {
                coachClass.classes.forEach((clase) => {
                  if (
                    dayjs(clase.start).isSame(info.event.start) &&
                    dayjs(clase.end).isSame(info.event.end)
                  ) {
                    let selectedCoaches = (selectedClassCoach) => [
                      ...selectedClassCoach,
                      {
                        coach_id: coachClass.coach_id,
                        coach_name: coachClass.coach_name,
                        resourceId: coachClass.resourceId,
                        coach_gym_class:clase.coach_gym_class_id
                      },
                    ];
                    setselectedClassCoach(selectedCoaches);
                    setinitial_selectedClassCoach(selectedCoaches);
                  }
                });
              });

     
            }
          }

          setopenDrawer({
            visible: true,
            record: {
              id: info.event.id,
              title: info.event.title,
              start: info.event.start,
              end: info.event.end,
            },
          });
          // console.log(info.event.title);
        }}
        eventBackgroundColor='#CEF5CE'
        firstDay={1}
        events={formatClasses}
        
        slotLabelContent={slotLabelContent}
      />
      <DrawerForm
        title={t('calendar.title','Calendario')}
        openDrawer={openDrawer}
        handleOpenDrawer={(visible, record) =>
          setopenDrawer({ visible, record })
        }
        width={'30%'}
      >
        <div className=' relative bg-background flex flex-col'>
          <div>
            <h2 className='text-2xl font-MessinaSansSemiBold'>
              Clase de las {dayjs(openDrawer?.record?.start).tz(user.country.time_zone).format('HH:mm')} en{' '}
              {getLocationName()}
            </h2>
            <p className='mt-1'>
             {t('coach_class_assigned','Coach asignado a esta clase')}:{' '}
              <span className=' text-primaryGreen'>
                {selectedClassCoach.length == 0
                  ? t('reserve_cdp.no_coach','Sin asignar')
                  : selectedClassCoach
                      .map((coach) => coach.coach_name)
                      .join(', ')}
              </span>
            </p>
            <Select
              // ref={memberRef}
              loading={isLoadingCoaches}
              showSearch
              placeholder={
                <div className='flex items-center font-MessinaSansSemiBold'>
                  <span className='text-white'>{t('assing_coach','Asignar coach')}</span>
                </div>
              }
              mode='multiple'
              allowClear
              optionFilterProp='children'
              onChange={(value) => {
                setselectedClassCoach(
                  coaches
                    .filter((coach) => value.includes(coach.id) && coach.active)
                    .map((coach) => ({
                      coach_id: coach.id,
                      coach_name: coach.first_name + ' ' + coach.last_name,
                    }))
                );
              }}
              value={selectedClassCoach.map((coach) => coach.coach_id)}
              // onSearch={onSearch}
              bordered={false}
              rootClassName='text-white'
              className={`bg-primaryGrey text-white outline-none   rounded-sm  font-DrukWideMedium py-1  border-2  
         border-transparent my-5
             `}
              style={{
                width: '100%',
                color: '#FFFFFF',
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={coaches?.map((coach) => ({
                value: coach.id,
                label: coach.first_name + ' ' + coach.last_name,
                disabled : !coach.active
              }))}
            />
          </div>
        </div>
        <button
          onClick={() => handleUpdate()}
          disabled={useUpdateCoach.isLoading || useDeleteCoach.isLoading}
          className='uppercase flex flex-row items-center justify-center gap-6 !text-black font-MessinaSansBold text-xl bg-lightGreen rounded-md px-5 h-14 w-full mt-10'
          type='submit'
        >
         {t('calendar.update',' Actualizar clase')}
          {(useUpdateCoach.isLoading || useDeleteCoach.isLoading) && (
            <SpinIndicador />
          )}
        </button>
      </DrawerForm>
      {messageContext}
      {contextHolder}
    </div>
  );
}
