export const customerIoIdentify = (id, email) => {
  const _cio = window._cio || [];
  window._cio = _cio;
  if (process.env.ENVIROMENT !== 'production') return;
  let q = {};
  if (id) {
    q.id = id;
  }
  if (email) {
    q.email = email;
  }
  _cio.identify(q);
};

export const customerIoTrack = (name, data) => {
  const _cio = window._cio || [];

  window._cio = _cio;
  if (process.env.ENVIROMENT !== 'production') return;

  _cio.track(name, data);
};
export const customerIoIdentifyWithData = (data) => {
  const _cio = window._cio || [];
  window._cio = _cio;
  if (process.env.ENVIROMENT !== 'production') return;

  _cio.identify(data);
};
