import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { USE_FORM_STOCK } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification} from 'antd';
import {  USEStockColumns  } from '../services/Constants/AccountingColumns';
import { formatError } from '../services/utils/formatError';
import {  useGetStock } from '../router/loaders/AccountingLoaders';
import { createProductStock, deleteProductStock, getProductStock, getProducts, updateProductStock } from '../api/products';
import { getLocationId } from '../services/utils/getLocation';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canCreateStock, isUserManager } from '../services/can_user';
import { t } from 'i18next';

export default function ProductStocks() {
  const TITLE =t('stocks','Stock de consumibles')
const TITLE_SINGULAR =t('stock','Stock de consumible')
  const columns = USEStockColumns();
  const FORM_STOCK = USE_FORM_STOCK()
  const { data, isLoading } = useQuery(useGetStock());
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data])
  

const handleDelete = async (id) => {
    await useDeleteStock.mutateAsync(id);
    

  };
  const openNotification = (type,description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('stock_success','Stock actualizado correctamente')
          :  t('stock_error','Ocurrió un error al actualizar el stock'),
...openNotificationFields(type,description)
    });
  };
 
  const [FORM, setFORM] = useState(FORM_STOCK);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
 
  });
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let stock = {
        data: {
          ...data,
          location_id: getLocationId(),

        },
      };
      await useCreateStock.mutateAsync(stock);
      return setopenDrawer({ visible: false, record: null });
    }
    let stock = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateStock.mutateAsync(stock);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let stock;
        if (record) {
          stock = await getProductStock(record.id);
          setValue('product_id', stock.product_id);
          setValue('units', stock.units);
        }
        const products = await getProducts();
        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'product_id') {
              return {
                ...item,
                options: products.filter((p)=>p.product_type_name == 'Consumable'&& !data.map((m)=>m.product_id).includes(
                  p.id
                )).map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
    
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateStock = useMutation({
    mutationFn: (stock) => updateProductStock(stock.id, stock.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['stock']
      });
    },
    onError: (error) => formatError(error, openNotification)

  });
  const useCreateStock = useMutation({
    mutationFn: (stock) => createProductStock(stock.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['stock']
      });    },
    onError: (error) => formatError(error, openNotification)
  });
  const useDeleteStock = useMutation({
    mutationFn: (id) => deleteProductStock(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['stock']
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
     formatError(error, openNotification)

      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={canCreateStock()}
        openDrawer={openDrawer}
        canUpdate={isUserManager()}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        handleDelete={handleDelete}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateStock.isLoading || useCreateStock.isLoading}
      />
      {contextHolder}
    </>
  );
}
