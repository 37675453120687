import React from 'react';
import { Popconfirm, Tooltip } from 'antd';
import { BiTrash } from 'react-icons/bi';
import dayjs from 'dayjs';
import { t } from 'i18next';

export const deleteColumn = (
  id,
  open,
  setOpen,
  title,
  description,
  confirmLoading,
  handleDelete,
  tooltip
) => {
  // return false
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setOpen(id);
      }}
      className='flex z-50'
    >
      <Popconfirm
        title={title}
        description={description}
        onConfirm={async () => {
          handleDelete(id);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        okText={t('accept','Aceptar')}
        open={open == id}
        placement='topLeft'
        cancelText={t('cancel','Cancelar')}
        okButtonProps={{
          loading: confirmLoading,
          className:
            'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
        }}
      >
        <div className='rounded-full  border-primaryRed border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'>
          <Tooltip title={ t('delete','Borrar') +' ' + tooltip}>
            <BiTrash size={19} color='#ED4C5C' />
          </Tooltip>
        </div>
      </Popconfirm>
    </div>
  );
};
export const generateColumn = (
  title,
  dataIndex,
  width,
  render,
  sortOptions,
  filters,
  hidden = false

) => ({
  title,
  dataIndex,
  width,
  render,
  ...sortOptions,
  ...filters,
  hidden,
});
export const formatDateColumn = (date) => {
  return dayjs(date).format('DD/MM/YYYY');
};
export const createdAtColumn = generateColumn(
  'Creado',
  '',
  '7%',
  (text,record) => <span>{formatDateColumn(record.created_at)}</span>,
  {
    sorter: (a, b) => (dayjs(a.created_at) - dayjs(b.created_at) ? 1 : -1),
  }
);

export const IDCOLUMN = generateColumn('ID', 'id', '5%', false, {
  sorter: (a, b) => a.id - b.id,
  defaultSortOrder: 'ascend',
});
export const IDCOLUMNOWNER = generateColumn('ID', 'member_id', '4%', false, {
  sorter: (a, b) => a.member_id - b.member_id,
  defaultSortOrder: 'ascend',
});

export const sorterText = (field) => {
  return {
    sorter: (a, b) => (a[field] > b[field] ? 1 : -1),
    // defaultSortOrder: 'ascend',
  };
};

export const canDeleteColumn = (roles) => { 
return roles?.some((r) => r.id ==1 || r.id == 7)
}

export const product_type_column = ()=>
{
  return generateColumn(t('product.type_singular','Tipo producto'), 'product_type_name', '8%',(text)=>t(text.toLowerCase()), {
  filters: [
    {
      text:t('membership','Membresía'),
      value: 1,
    },
    {
      text: t('consumable', 'Consumible'),
      value: 2,
    },
  
  ],
  sorter: (a, b) => a.product_type_id - b.product_type_id,
  onFilter: (value, record) => record.product_type_id == value,
})
}