import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';

import { USE_FORM_PAYMENT } from '../services/Constants/FORMS';
import {useMutation, useQueryClient } from '@tanstack/react-query';
import {  USEPaymentsColumns  } from '../services/Constants/AccountingColumns';
import { formatError } from '../services/utils/formatError';
import { 
createPayment,
updatePayment,
deletePayment,
useGetPayments

} from '../api/payments';
import { notification, ConfigProvider, DatePicker } from 'antd';
import es_ES from 'antd/locale/es_ES';
import { getPointSales } from '../api/locations';
import { getPayment, getPayment_methods } from '../api/payments';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canUpdateSales } from '../services/can_user';
import dayjs from 'dayjs';
import CardRounded from '../components/CardRounded';
import { getLocationId } from '../services/utils/getLocation';
import { t } from 'i18next';


const start = dayjs().startOf('month');
const end = dayjs();
export default function Payments() {
  const originalColumns = USEPaymentsColumns();
  const TITLE =t('payments.title','Pagos')
const TITLE_SINGULAR = t('payments.title_singular','Pago')
  const FORM_PAYMENT = USE_FORM_PAYMENT()
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { data, isLoading } =useGetPayments(start_date.format('YYYY-MM-DD'),
  end_date.format('YYYY-MM-DD'))
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();
  const [columns, setColumns] = useState(originalColumns);

  useEffect(() => {
    const createFilters = (data, key) =>
      [...new Set(data.filter(Boolean).map((d) => d.card_attempts.map((item) => item[key])))].flat()
        // .filter(Boolean)
        .reduce((acc, current) => {
          const x = acc.find((item) => item.value === current);
          if (!x) {
            return acc.concat({ text: current, value: current });
          } else {
            return acc;
          }
        }
        , []);
        // .map((value) => ({ text: value, value }));
    setfilterTable(isLoading ? [] : data);
    if (data) {
      let hasCardAttempts = data.some((item) => item.card_attempts.length > 0);
      if(hasCardAttempts){
      setColumns(
        columns.map((column) => {
          if (column.dataIndex === 'operation_number' || column.dataIndex === 'status') {
            return {
              ...column,
              hidden:false,
              filters: column.dataIndex === 'operation_number' ? []:  createFilters(data, column.dataIndex),
              onFilter: (value, record) => record.card_attempts.some((item) => item[column.dataIndex] === value),

            };
          }
          return column;
        }))
      }
      }
  }, [data]);
const handleDelete = async (id) => {
    await useDeletePayment.mutateAsync(id);

  };
  const openNotification = (type,description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('payment_success','Pago actualizado correctamente')
          :  t('payment_error','Ocurrió un error al actualizar el pago'),
  ...openNotificationFields(type,description)
    });
  };
 
  const [FORM, setFORM] = useState(FORM_PAYMENT);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let payment = {
        data: data,
      };
      await useCreatePayment.mutateAsync(payment);
      return setopenDrawer({ visible: false, record: null });
    }
    let payment = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdatePayment.mutateAsync(payment);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let payment;
        if (record) {
          payment = await getPayment(record.id);
          setValue('payment_method_id', payment.payment_method_id);
          setValue('point_of_sale_id', payment.point_of_sale_id);
          setValue('amount', parseFloat(payment.amount));
          setValue('customer_id', payment.customer_name);
        }
        const point_of_sale_id = await getPointSales();
        const payment_methods = await getPayment_methods();
        // const populate_members = await getMembersPopulate();
        setFORM(
          FORM.map((item) => {
            // if (item.name === 'customer_id') {
            //   return {
            //     ...item,
            //     options: populate_members.map((item) => ({
            //       label: item.name,
            //       value: item.id,
            //     })),
            //   };
            // }
            if (item.name === 'point_of_sale_id') {
              return {
                ...item,
                options: point_of_sale_id.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'payment_method_id') {
              return {
                ...item,
                options: payment_methods.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
          
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
    
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdatePayment = useMutation({
    mutationFn: (sale) => updatePayment(sale.id, sale.data),
    onSuccess: () => {
      const location_id = getLocationId()
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['payments',start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD'),location_id]
      });
    },
    onError: (error) => formatError(error, openNotification)

  });
  const useCreatePayment= useMutation({
    mutationFn: (sale) => createPayment(sale.data),
    onSuccess: () => {
      const location_id = getLocationId()
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey:['payments',start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD'),location_id]
      });
    },
    onError: (error) => formatError(error, openNotification)
  });
  const useDeletePayment = useMutation({
    mutationFn: (id) => deletePayment(id),
    onSuccess: () => {
      openNotification('success');
      const location_id = getLocationId()
      queryClient.invalidateQueries({
        queryKey:['payments',start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD'),location_id]
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
     formatError(error, openNotification)

      setConfirmLoading(false);
    },
  });
  return (
  <>
  <div className='h-screen pl-[1rem] lg:pl-[3rem]   mt-8 gap-9  '>
    <div className='mb-10 flex-grow flex flex-col mt-2'>
      <h2 className='text-2xl font-MessinaSansSemiBold'>{TITLE}</h2>
      <CardRounded styles={' mt-6 py-1 relative flex flex-row w-[25%]'}>
        {[t('fromLabel','Desde'),  t('toLabel','Hasta')].map((item, i) => (
          <div
            key={i}
            className='relative   border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center'
          >
            <span className=' opacity-40 absolute top-2 font-MessinaSansSemiBold'>
              {item}
            </span>
            <ConfigProvider locale={es_ES}>
              <DatePicker
                allowClear={false}
                ref={i == 0 ? datepickerFrom : datepickerAt}
                onChange={(date, dateString) => {
                  if (i == 0) {
                    datepickerFrom.current.blur();
                    return setstart_date(date);
                  }
                  if (i == 1) {
                    datepickerAt.current.blur();
                    return setend_date(date);
                  }
                }}
                // name='started_at'
                value={i == 0 ? start_date : end_date}
                // defaultValue={product.started_at}
                showToday
                className=' bg-transparent outline-none border-none  h-12  w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
              />
            </ConfigProvider>
          </div>
        ))}
      </CardRounded>
    </div>
    <div className='w-[100%] mt-[-40px]'>
      <TableCustom
        hideTitle
        filterTable={filterTable}
        data={data}
        canUpdate={canUpdateSales()}
        multipleTables
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        handleDelete={handleDelete}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdatePayment.isLoading || useCreatePayment.isLoading}
      />
    </div>
    {contextHolder}
  </div>
</> )

}
