import { t } from 'i18next';
import RefreshSvg from '../../assets/icons/RefreshSvg';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FaRegListAlt } from 'react-icons/fa';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
export const Statistics = ({
  filteredReserves,
  countByCondition,
  max_attendances,
  init_loading,
  hour_selected,
  messageApi,
  wait_list_members,
  settoggle_wait_list,
  toggle_wait_list,
}) => {
  const queryClient = useQueryClient();
  return (
    <div className='px-5 py-5 flex jic border-b-primaryWhite border-b-[1px] flex-wrap'>
      <div className='flex  flex-wrap'>
        {!toggle_wait_list ? (
          <div className='flex gap-3 flex-wrap'>
            {filteredReserves.filter(
              (r) => r?.subscription?.owner_type == 'Prospect'
            ).length > 0 && (
              <>
                <p className=' text-center'>
                  <span id='reservas'>
                    {
                      filteredReserves.filter(
                        (r) => r?.subscription?.owner_type == 'Prospect'
                      ).length
                    }
                  </span>{' '}
                  CDP
                </p>
                <p> | </p>
              </>
            )}
            <p className=' text-center'>
              <span id='presentes'>
                {countByCondition((reserve) => reserve?.has_attended)}
              </span>{' '}
              {t('reserves.present', 'Presentes')}
            </p>
            <p>|</p>
            <p className=' text-center'>
              <span id='ausentes'>
                {countByCondition((reserve) => !reserve?.has_attended)}
              </span>{' '}
              {t('reserves.absent', 'Ausentes')}
            </p>
            <p>|</p>
            <p className=' text-center'>
              <span id='reservas'>{filteredReserves.length}</span>{' '}
              {t('reserves.reserves', 'Reservas')}
            </p>
            <p>|</p>
            <p
              className={`text-center mr-2 ${
                max_attendances - filteredReserves.length == 0 &&
                'text-primaryRed'
              }`}
            >
              {t('reserves.available_reserves', 'Cupo Disponible')}:{' '}
              <span id='cupo'>{max_attendances - filteredReserves.length}</span>
            </p>
          </div>
        ) : (
          <p className='mr-3'>
            {t('reserves.count_wait_list', 'Lista de espera')}:{' '}
            {wait_list_members.length}
          </p>
        )}
        <button
          className=' ml-auto'
          onClick={() => {
            init_loading();
            queryClient
              .invalidateQueries({
                queryKey: [
                  'day_reserves',
                  dayjs(hour_selected).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                ],
              })
              .then(() => {
                messageApi.destroy();
              });
          }}
        >
          <RefreshSvg />
        </button>
      </div>
      {((wait_list_members.length > 0 ) ||
        (toggle_wait_list && wait_list_members.length == 0)) && (
        <button
          onClick={() => {
            settoggle_wait_list(!toggle_wait_list);
          }}
          className='flex flex-row items-center gap-2'
        >
          <FaRegListAlt color={toggle_wait_list ? '#1EA05A' : '#ED4C5C'} />
          <span
            className={` ${
              toggle_wait_list ? 'text-darkGreen' : 'text-primaryRed'
            }`}
          >
            {!toggle_wait_list
              ? `Ver lista de espera (${wait_list_members.length})`
              : `Ver lista de anotados (${filteredReserves.length})`}{' '}
          </span>
        </button>
      )}
    </div>
  );
};
