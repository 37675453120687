import React from 'react'

export default function AppSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.2764" cy="17.2764" r="17.2764" fill="#ADFF19"/>
<path d="M20.9419 8.7207H13.0586C12.5358 8.75211 12.0464 8.98853 11.6969 9.37861C11.3474 9.76869 11.1659 10.2809 11.1919 10.804V23.1957C11.1659 23.7188 11.3474 24.2311 11.6969 24.6211C12.0464 25.0112 12.5358 25.2476 13.0586 25.279H20.9419C21.4647 25.2476 21.954 25.0112 22.3035 24.6211C22.653 24.2311 22.8345 23.7188 22.8086 23.1957V10.804C22.8345 10.2809 22.653 9.76869 22.3035 9.37861C21.954 8.98853 21.4647 8.75211 20.9419 8.7207ZM21.9752 23.1957C22.0007 23.4978 21.9067 23.7978 21.7136 24.0315C21.5204 24.2652 21.2434 24.4139 20.9419 24.4457H13.0586C12.7571 24.4139 12.4801 24.2652 12.2869 24.0315C12.0937 23.7978 11.9998 23.4978 12.0252 23.1957V10.804C11.9998 10.5019 12.0937 10.2019 12.2869 9.96823C12.4801 9.73457 12.7571 9.58588 13.0586 9.55404H14.3336V9.97904C14.3336 10.2001 14.4214 10.412 14.5776 10.5683C14.7339 10.7246 14.9459 10.8124 15.1669 10.8124H18.8336C19.0546 10.8124 19.2665 10.7246 19.4228 10.5683C19.5791 10.412 19.6669 10.2001 19.6669 9.97904V9.55404H20.9419C21.2434 9.58588 21.5204 9.73457 21.7136 9.96823C21.9067 10.2019 22.0007 10.5019 21.9752 10.804V23.1957Z" fill="black"/>
<path d="M15.3337 22.7786H18.667C18.7775 22.7786 18.8835 22.7347 18.9616 22.6566C19.0398 22.5785 19.0837 22.4725 19.0837 22.362C19.0837 22.2515 19.0398 22.1455 18.9616 22.0674C18.8835 21.9892 18.7775 21.9453 18.667 21.9453H15.3337C15.2232 21.9453 15.1172 21.9892 15.039 22.0674C14.9609 22.1455 14.917 22.2515 14.917 22.362C14.917 22.4725 14.9609 22.5785 15.039 22.6566C15.1172 22.7347 15.2232 22.7786 15.3337 22.7786Z" fill="black"/>
</svg>

  )
}



