import React, { useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Dot,
  Legend,
  Tooltip,
} from 'recharts';
import Card from './Card';
import dayjs from 'dayjs';
import { isUserSuperAdmin } from '../../services/can_user';

export default function RatingSellersChart({ convertion, lead_to_cdp_convertion }) {
  const [selected_seller, setselected_seller] = useState(false);
  const [selectedConvertion, setselectedConvertion] = useState('general_convertion');

  const reduceBySellerConvertion = (data) => {
    return convertion?.results
      .filter((d) => dayjs(d.date).isSame(dayjs(), 'month'))
      .reduce((acc, item) => {
        const key = item.seller_name;
        if (key.trim() === '') return acc;
        if (!acc[key]) {
          acc[key] = { seller: key, convertion: 0, prospect: 0 };
        }
        if (item.type == 'Members') {
          acc[key].convertion += parseFloat(item.quantity);
        } else {
          acc[key].prospect += parseFloat(item.quantity);
        }
        return acc;
      }, {});
  };

  const reduceBySellerConvertionCdp = (data) => {
    return lead_to_cdp_convertion?.results
      .filter((d) => dayjs(d.date).isSame(dayjs(), 'month'))
      .reduce((acc, item) => {
        const key = item.seller_name;
        if (key.trim() === '') return acc;
        if (!acc[key]) {
          acc[key] = { seller: key, convertion: 0, prospect: 0 };
        }
        if (item.type == 'CDP') {
          acc[key].convertion += parseFloat(item.quantity);
        } else {
          acc[key].prospect += parseFloat(item.quantity);
        }
        return acc;
      }, {});
  };

  const selectedData = selectedConvertion === 'general_convertion'
    ? reduceBySellerConvertion(convertion?.results)
    : reduceBySellerConvertionCdp(lead_to_cdp_convertion?.results);

  return (
    <Card
      title={'Vendedores'}
      headerChildren={
          <div className='flex flex-row items-center w-full'>
          <button
            onClick={() => setselectedConvertion('general_convertion')}
            className={` border-b-[1px] ${
              selectedConvertion === 'general_convertion' ? 'border-primaryGreen' : 'border-transparent'
            }`}
          >
            Conversion
          </button> <span className='text-gray-400 ml-2'>/</span>
          <button
            onClick={() => setselectedConvertion('cdp_convertion')}
            className={` border-b-[1px] ml-2 ${
              selectedConvertion === 'cdp_convertion' ? 'border-primaryGreen' : 'border-transparent'
            }`}
          >
            Conversion a CDP
          </button>
        </div>
      }
      size='large'
      description={ selectedConvertion == 'general_convertion'? `El lead se asigna en base a quien lo cargo o quien lo contacto primero. Conversiones en base a quien carga la primera compra de producto. Los colores están dados según: Verde +60%. Amarillo 30-59%. Rojo -30%`:`El lead se asigna en base a quien lo cargo o quien lo contacto primero. Conversiones en base a quien carga la primera compra de producto membresia o quien cargo el producto CDP según corresponda. Los colores están dados según: Verde +60%. Amarillo 30-59%. Rojo -30%`}
      Extraclass={`col-span-2`}
    >
      <ScatterChart
        margin={{
          top: 20,
          right: 10,
        }}
      >
        <CartesianGrid />
        <XAxis type='number' dataKey='convertion' name='Convertidos' />
        <YAxis
          type='number'
          dataKey='percentage'
          name='Tasa conversion'
          unit='%'
        />

        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={({ active, payload, label }) => {
            if (active && payload) {
              return (
                <div className=' bg-background px-10 py-6 rounded-md'>
                  <p className='label'>{`Vendedor : ${payload[0].payload.coach_name}`}</p>
                  <p className='label'>{`Prospectos : ${payload[0].payload.prospect}`}</p>
                  <p className='label'>{` ${ selectedConvertion == 'general_convertion' ? payload[0].name:'CDP'} : ${payload[0].value}`}</p>
                  <p className='label'>{`${selectedConvertion == 'general_convertion' ?payload[1].name :'Conversion a CDP'} : ${payload[1].value}%`}</p>
                </div>
              );
            }
          }}
        />
        <Legend
          align='left'
          layout='vertical'
          verticalAlign='top'
          content={({ payload }) => {
            return (
              <ul className='flex flex-col'>
                {payload.map((entry, index) => {
                  return (
                    <button
                      key={`item-${index}`}
                      className='flex items-center space-x-2 m-[1px] '
                      onClick={() => setselected_seller(
                        selected_seller === entry.value ? false : entry.value
                      )}
                    >
                      <span
                        className='h-4 w-4 bg-primaryGreen rounded-full'
                        style={{
                          opacity: selected_seller && selected_seller !== entry.value ? 0.2 : 1,
                          backgroundColor: entry.color,
                        }}
                      ></span>
                      <span
                        style={{ color: entry.color }}
                        className='text-sm'
                      >
                        {entry.value}
                      </span>
                    </button>
                  );
                })}
              </ul>
            );
          }}
        />
        {selectedData &&
          Object.values(selectedData)
            .sort((a, b) => (parseInt((b.convertion / (b.prospect)) * 100) 
              - parseInt((a.convertion / (a.prospect)) * 100)
            ))
            .map((entry) => {
              return (
                <Scatter
                  key={entry.seller}
                  name={entry.seller}
                  data={[
                    {
                      convertion: entry.convertion,
                      prospect: entry.prospect,
                      percentage: parseInt((entry.convertion / (entry.prospect)) * 100),
                      coach_name: entry.seller,
                    },
                  ]}
                  shape={(props) => {
                    return (
                      <>
                        <Dot
                          visibility={selected_seller && selected_seller !== entry.seller ? 'hidden' : 'visible'}
                          cx={props.cx}
                          style={{ opacity: selected_seller && selected_seller !== entry.seller ? 0.2 : 1 }}
                          cy={props.cy}
                          fill={props.fill}
                          r={6}
                        />
                      </>
                    );
                  }}
                  fill={`${
                    parseInt((entry.convertion / (entry.prospect)) * 100) >= 60 ? '#ADFF19' :
                    parseInt((entry.convertion / (entry.prospect)) * 100) >= 30 ? '#FFD500' : '#ED4C5C'
                  }`}
                />
              );
            })}
      </ScatterChart>
    </Card>
  );
}
