import React, { useMemo } from 'react';
import { formatPrice } from '../../../services/utils/formatPrice';
import { t } from 'i18next';

export default function SalesTooltip({ payload }) {
  if (!payload || payload.length === 0) return null;

  const totalValue = useMemo(() => {
    return payload.reduce((acc, { value }) => acc + parseFloat(value), 0);
  }, [payload]);

  const sortedPayload = useMemo(() => {
    return payload
      .map(item => ({
        ...item,
        share: ((parseFloat(item.value) / totalValue) * 100).toFixed(1)
      }))
      .filter(item => item.value > 0)
      .sort((a, b) => b.share - a.share);
  }, [payload, totalValue]);

  return (
    <div className='bg-background w-80 rounded-md px-3 py-2'>
      <h2 className='text-white font-MessinaSansSemiBold text-lg mb-1 capitalize'>
        {payload[0]?.payload?.name}
      </h2>
      {sortedPayload.map(({ dataKey, share, stroke, value }, index) => (
        <div key={index} className='flex justify-between pb-1'>
          <p className='font-MessinaSansSemiBold' style={{ color: stroke }}>
            {`${t(dataKey, dataKey)} (${share}%)`}
          </p>
          <p className='font-MessinaSansSemiBold' style={{ color: stroke }}>
            {formatPrice(value)}
          </p>
        </div>
      ))}
      <h3 className='text-white font-MessinaSansSemiBold mt-2 text-lg'>
        {`${t('sales.title', 'Ventas')} : ${formatPrice(totalValue)}`}
      </h3>
    </div>
  );
}
