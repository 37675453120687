import React, { useMemo,useCallback} from 'react';
import {

  ConfigProvider,
  DatePicker,
  Select,
  Checkbox,

Cascader
} from 'antd';

import es_ES from 'antd/locale/es_ES';
import {
  MdOutlineChevronRight,
} from 'react-icons/md';
import SelectLocation from '../../pages/SelectLocation';
import { use_REPORT_LIST_OPTIONS } from '../../services/Constants/Reports';
import { useQueryClient } from '@tanstack/react-query';

export default function ReportsHeader({ gym_classes,item, index, reportSelected, setreportSelected, location_selected, setlocation_selected, setfilterTable, active_checkbox, setactive_checkbox, start_date, setstart_date, end_date, setend_date, month_selected, setMonthSelected, isSelectAttendance, setisAttendance, selectedClass, setselectedClass, selectSameMoment, setSelectSameMoment, openLocations, setopenLocations, state, selectRef, datepickerFrom, datepickerAt, datepickerMonth }) {
    const REPORT_LIST_OPTIONS = use_REPORT_LIST_OPTIONS(start_date, end_date);
    const REPORT_LIST = REPORT_LIST_OPTIONS.reduce((acc, curr) => {
      return acc.concat(curr.options);
    }, []);
  const queryClient = useQueryClient();
    const report = useMemo(() => REPORT_LIST.find((r) => r.value == reportSelected), [reportSelected]);
        const isSelect = index === 0;
        const isDatePicker = !report?.pickerMonth && index > 0 && index < 3;
        const isMonthPicker = report?.pickerMonth && index === 1;
        const isActiveCheckbox = report?.activeFilter && index === 3;
        const isSameMoment = report?.sameMoment && index === 2;
        const isSelectClass = report?.selectClass && index === 4;
        const isAttendance = report?.hasAttendance && index === 3;
      
        const handleSelectChange = useCallback((newValue) => {
          setfilterTable([]);
          setlocation_selected(location_selected);
          
          setreportSelected(newValue[1]);
          // selectRef?.current.blur();
        }, [setfilterTable, location_selected, setlocation_selected, setreportSelected]);
      
        const handleDatePickerChange = useCallback((date) => {
          if (index === 1) {
            datepickerFrom.current.blur();
            console.log('date',date);
            
            setstart_date(date);
          } else if (index === 2) {
            datepickerAt.current.blur();
            setend_date(date);
          }
        }, [index, setstart_date, setend_date]);
      
        const handleMonthPickerChange = useCallback((date) => {
          setMonthSelected(date);
          datepickerMonth.current.blur();
        }, [setMonthSelected]);
      
        const handleCheckboxChange = useCallback((e) => {
          setactive_checkbox(e.target.checked);
        }, [setactive_checkbox]);
      
        const handleAttendanceChange = useCallback((newValue) => {
          setisAttendance(newValue);
        }, [setisAttendance]);
      
        const handleClassChange = useCallback((newValue) => {
          setselectedClass(newValue);
        }, [setselectedClass]);
      
        const handleSameMomentChange = useCallback((newValue) => {
          setSelectSameMoment(newValue);
        }, [setSelectSameMoment]);
        const displayRender = (labels) => {
          // console.log('labels',labels[1]);
          return REPORT_LIST.find((r) => r.value == labels[0])?.label;
        }
        const filter = (inputValue, path) =>
      path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    return (
          <div className='relative border-b-primaryGrey pt-10 pb-2 px-5 last:border-b-transparent flex flex-row items-center  '>
            <p className='opacity-40 absolute top-2 font-MessinaSansSemiBold pl-3 w-full'>{item}</p>
            {isSelect && (
               <Cascader
               options={REPORT_LIST_OPTIONS.map((p)=>({
                value:p.label,
                label:p.label,
                children:p.options
               }))}
               showSearch={{
                filter,
              }}
               allowClear={false}
               value={reportSelected}
               expandIcon={<MdOutlineChevronRight size={22} color='#444444' />}
               expandTrigger="hover"
               bordered={false}
               disabled={location_selected.length === 0}
               placeholder='Buscar reporte'
               displayRender={displayRender}
               onChange={handleSelectChange}
               className=' text-white outline-none border-none py-1 rounded-md font-DrukWideMedium '
             />
         
            )}
            {isDatePicker && (
              <ConfigProvider locale={es_ES}>
                <DatePicker
                  disabled={active_checkbox}
                  picker={report?.value === 18 ? 'month' : 'date'}
                  allowClear={false}
                  ref={index === 1 ? datepickerFrom : datepickerAt}
                  onChange={handleDatePickerChange}
                  format={report?.value === 18 ? 'MMMM YYYY' : 'DD/MM/YYYY'}
                  value={index === 1 ? start_date : end_date}
                  showToday
                  className={`bg-transparent outline-none border-none h-12 w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey ${active_checkbox && 'opacity-30'}`}
                />
              </ConfigProvider>
            )}
            {isMonthPicker && (
              <ConfigProvider locale={es_ES}>
                <DatePicker
                  picker='month'
                  allowClear={false}
                  ref={datepickerMonth}
                  onChange={handleMonthPickerChange}
                  format='MMMM YYYY'
                  value={month_selected}
                  showToday
                  className='bg-transparent outline-none border-none h-12 w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                />
              </ConfigProvider>
            )}
            {isActiveCheckbox && (
              <Checkbox
                className='w-16 flex justify-center'
                checked={active_checkbox}
                onChange={handleCheckboxChange}
              />
            )}
            {isAttendance && (
              <Select
                ref={selectRef}
                bordered={false}
                value={isSelectAttendance}
                onChange={handleAttendanceChange}
                className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
                style={{ width: 220 }}
                options={[
                  { label: 'Asistido', value: true },
                  { label: 'No Asistido', value: false },
                ]}
              />
            )}
            {isSelectClass && (
              <Select
                ref={selectRef}
                bordered={false}
                value={selectedClass}
                onChange={handleClassChange}
                className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
                style={{ width: 220 }}
                options={gym_classes}
              />
            )}
            {isSameMoment && (
              <Select
                ref={selectRef}
                bordered={false}
                value={selectSameMoment}
                onChange={handleSameMomentChange}
                className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
                style={{ width: 220 }}
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
              />
            )}
            {item === 'Sede' && state.state?.availableLocations && (
              <SelectLocation
                openLocations={openLocations}
                setopenLocations={setopenLocations}
                location_selected={location_selected}
                state={state}
                handleChange={(newValue)=>{
                    if(!reportSelected) return;
                  queryClient.invalidateQueries({
                    queryKey: [
                      'reports',
                      location_selected,
                      reportSelected,
                      start_date.format('YYYY-MM-DD'),
                      end_date.format('YYYY-MM-DD'),
                      REPORT_LIST.find((r) => r.value == reportSelected)?.pickerMonth
                        ? month_selected.format('YYYY-MM-DD')
                        : null,
                      active_checkbox,
                      isSelectAttendance,
                      selectedClass,
                      selectSameMoment,
                    ],
                  });
                }}
                setlocation_selected={setlocation_selected}
              />
            )}
          </div>
    )
}