import React from 'react';
import { Drawer as DrawerAnt } from 'antd';

export default function Drawer({ openDrawer, setopenDrawer, width, children }) {
  return (
    <DrawerAnt
      placement='right'
      width={width ? width : '35%'}
      open={openDrawer ? true : false}
      destroyOnClose
      onClose={() => setopenDrawer(false)}
      bodyStyle={{ background: '#111', marginTop: 0, paddingTop: 0 }}
      headerStyle={{ background: '#111', marginTop: 0 }}
      closeIcon={<></>}
    >
      {children}
    </DrawerAnt>
  );
}
