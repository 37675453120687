import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { getRegion_config_types } from '../api/config';
import { USE_FORM_GYM_CLASS } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { USEClassColumn  } from '../services/Constants/ClasesColumns';
import { formatError } from '../services/utils/formatError';
import { useGetClasses } from '../router/loaders/ClassesLoaders';
import {
  createClasses,
  deleteClasses,
  getClass,
  getClasses,
  getGymClassTypes,
  updateClasses,
} from '../api/gym_classes';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { getLocations } from '../api/locations';
import { useGlobalContext } from '../context/GlobalState';
import { canUpdateClasses, isUserAdmin } from '../services/can_user';
import { t } from 'i18next';

export default function Gym_classes() {
  const columns = USEClassColumn();
  const TITLE = t('gym_classes.title', 'Clases');
const TITLE_SINGULAR = t('gym_classes.title_singular', 'Clase');
  const FORM_GYM_CLASS = USE_FORM_GYM_CLASS();
  const { data, isLoading } = useQuery(useGetClasses());
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  
  const handleDelete = async (id) => {
    await useDeleteClasses.mutateAsync(id);
  
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('gym_class_success', 'Clase actualizada correctamente')
          : t('gym_class_error', 'Ocurrió un error al actualizar la clase'),
      ...openNotificationFields(type, description),
    });
  };
  const { state: user } = useGlobalContext();

  const [FORM, setFORM] = useState(FORM_GYM_CLASS);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const [openDrawerFather, setopenDrawerFather] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let gym_class = {
        data: data
      };
      await useCreateClasses.mutateAsync(gym_class);
      return setopenDrawer({ visible: false, record: null });
    }
    let gym_class = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateClasses.mutateAsync(gym_class);
    if (openDrawer.visible) {
      setopenDrawer({ visible: false, record: null });
    } else {
      setopenDrawerFather({ visible: false, record: null });
    }
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let gym_class;
        if (record) {
          gym_class = await getClass(record.id);
          FORM.forEach((item) => {
            setValue(item.name, gym_class[item.name]);
          });
        }
        const gym_class_types = await getGymClassTypes();
        const region_config_types = await getRegion_config_types();
        const locations = await getLocations();
        const classes = await getClasses();
        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if(item.name == 'parent_id'){
              return {
                ...item,
                options: classes.filter((p)=>!p.parent_id).map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'gym_class_type_id') {
              return {
                ...item,
                options: gym_class_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'region_config_type_id') {
              return {
                ...item,
                options: region_config_types.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'location_id') {
              return {
                ...item,
                options: locations.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );
        if (gym_class?.parent_id || !record) {
          setopenDrawer({ visible: visible, record: record });
        } else if(record) {
          setopenDrawerFather({ visible: visible, record: record });
        }
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
      setopenDrawerFather({ visible: visible, record: record });
    }
  };
  const useUpdateClasses = useMutation({
    mutationFn: (gym_class) => updateClasses(gym_class.id, gym_class.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['gym_classes'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateClasses = useMutation({
    mutationFn: (gym_class) => createClasses(gym_class.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['gym_classes'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteClasses = useMutation({
    mutationFn: (id) => deleteClasses(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['gym_classes'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  console.log('c',columns);
  return (
    <div>
      <TableCustom
        filterTable={filterTable.filter((c) => c.parent_id !== null)}
        data={data}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        canUpdate={canUpdateClasses()}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        canCreate={isUserAdmin()}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns.filter((f)=>f.dataIndex == 'region_config_type_name'?false:true)}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mainStyles={`h-min`}
        mutateLoading={useUpdateClasses.isLoading || useCreateClasses.isLoading}
      />
      <div className='mt-[-320px]'></div>
      {isUserAdmin() && (
        <TableCustom
          filterTable={filterTable.filter((c) => c.parent_id == null)}
          data={data}
          multipleTables
          openDrawer={openDrawerFather}
          setfilterTable={setfilterTable}
          handleOpenDrawer={handleOpenDrawer}
          title={t('gym_classes.title_father', 'Clases Padre')}
          title_singular={t('gym_classes.title_father', 'Clases Padre')}
          searchInput={searchInput}
          onSubmit={onSubmit}
          control={control}
          FORM={FORM}
          originalColumns={columns}
          register={register}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          setsearchInput={setsearchInput}
          isLoading={isLoading}
          mutateLoading={
            useUpdateClasses.isLoading || useCreateClasses.isLoading
          }
        />
      )}

      {contextHolder}
    </div>
  );
}
