import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import {
  getProducts,
  getStatuses,
} from '../api/products';
import { USE_FORM_MEMBERSHIP } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetMemberships } from '../router/loaders/ProductsLoaders';
import { notification} from 'antd';
import {  USEMembershipColumns  } from '../services/Constants/ProductColumns';
import { createMembership, deleteMembership, getMembership, getMemberships, updateMembership } from '../api/memberships';
import { formatError } from '../services/utils/formatError';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canUpdateMemberships, canUpdateProducts, isUserAdmin } from '../services/can_user';
import { t } from 'i18next';

export default function Memberships() {
  const columns = USEMembershipColumns();
  const TITLE =t('memberships','Membresías');
const TITLE_SINGULAR =t('membership','Membresía');
  const { data, isLoading } = useQuery(useGetMemberships());
  const FORM_MEMBERSHIP = USE_FORM_MEMBERSHIP();
  const isAdmin = isUserAdmin()
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [FORM, setFORM] = useState(FORM_MEMBERSHIP);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data])
  

const handleDelete = async (id) => {
    await useDeleteMembership.mutateAsync(id);
 

  };
  const openNotification = (type,description) => {
    api[type]({
      message:
        type == 'success'
          ? t('membership_success','Membresía actualizada correctamente')
          : t('membership_error','Ocurrió un error al actualizar la membresía'),
...openNotificationFields(type,description)
    });
  };
 

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let membership = {
        data: data,
      };
      await useCreateMembership.mutateAsync(membership);
      return setopenDrawer({ visible: false, record: null });
    }
    let membership = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateMembership.mutateAsync(membership);
    setopenDrawer({ visible: false, record: null });
  
  };
  const handleOpenDrawer = async (visible, record) => {
    reset()
    if (visible) {
      try {
       
        let membership
        if (record) {
        membership = await getMembership(record.id);
        FORM.forEach((item) => {
         
          setValue(item.name, membership[item.name]);
        });
        // setValue('product_id', membership.product_id);
        // setValue('name', membership.name);
        // setValue('sessions_amount', membership.sessions_amount);
        // setValue('days_amount', membership.days_amount);
        // setValue('status_id', membership.status_id);
        // setValue('recurrent', membership.product.recurrent);

        }
      const products = await getProducts();
      const status = await getStatuses();

        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'product_id') {
              return {
                ...item,
                options: products.filter((membership)=>membership.product_type_id==1 && !data.map((m)=>m.product_id).includes(
                  membership.id
                )).map((item) => ({
                  label: item.name,
                  value: item.id,
                  disabled: isAdmin? false:true
                })),
              };
            }
            if (item.name === 'status_id') {
              return {
                ...item,
                options: status.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
           

            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
  
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateMembership = useMutation({
    mutationFn: (membership) => updateMembership(membership.id, membership.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['memberships']
      });    },
    onError: (error) => formatError(error, openNotification)

  });
  const useCreateMembership = useMutation({
    mutationFn: (membership) => createMembership(membership.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['memberships']
      });
    },
    onError: (error) => formatError(error, openNotification)

  });
  const useDeleteMembership= useMutation({
    mutationFn: (id) => deleteMembership(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey:['memberships']
      });      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification)
      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        canCreate={isUserAdmin()}
        openDrawer={openDrawer}
        canUpdate={canUpdateMemberships()}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        handleDelete={handleDelete}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateMembership.isLoading}
      />
      {contextHolder}
    </>
  );
}
