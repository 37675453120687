 import React from 'react'
 
 export default function CallSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
   return (
    <svg width={width} height={height}  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.7607" cy="18" r="17.2764" fill="#437EB5"/>
    <path d="M24.2791 20.2619L20.8811 18.7392L20.8717 18.7349C20.6953 18.6594 20.5029 18.6291 20.3119 18.6468C20.1208 18.6644 19.9372 18.7294 19.7775 18.8359C19.7587 18.8483 19.7407 18.8618 19.7234 18.8762L17.9678 20.3729C16.8556 19.8327 15.7073 18.693 15.167 17.5952L16.6659 15.8129C16.6803 15.7949 16.694 15.7768 16.707 15.7574C16.8112 15.5982 16.8744 15.4157 16.891 15.2262C16.9076 15.0367 16.8771 14.846 16.8022 14.6711V14.6624L15.2752 11.2587C15.1762 11.0302 15.006 10.8399 14.7899 10.7161C14.5739 10.5924 14.3236 10.5418 14.0764 10.572C13.0991 10.7006 12.2019 11.1806 11.5526 11.9223C10.9032 12.664 10.5461 13.6168 10.5479 14.6026C10.5479 20.3297 15.2074 24.9892 20.9345 24.9892C21.9203 24.991 22.873 24.6338 23.6148 23.9845C24.3565 23.3352 24.8365 22.438 24.9651 21.4606C24.9953 21.2136 24.9449 20.9634 24.8212 20.7473C24.6976 20.5313 24.5075 20.361 24.2791 20.2619ZM20.9345 23.8351C18.4867 23.8325 16.1399 22.8589 14.409 21.128C12.6782 19.3972 11.7046 17.0504 11.7019 14.6026C11.6992 13.8982 11.953 13.217 12.4158 12.686C12.8787 12.1551 13.5189 11.8108 14.2171 11.7174C14.2168 11.7203 14.2168 11.7232 14.2171 11.7261L15.7318 15.1161L14.2409 16.9006C14.2258 16.918 14.212 16.9366 14.1998 16.9562C14.0912 17.1227 14.0276 17.3145 14.0149 17.5129C14.0023 17.7113 14.0411 17.9096 14.1276 18.0886C14.7811 19.4252 16.1278 20.7617 17.4788 21.4145C17.6591 21.5002 17.8585 21.5377 18.0576 21.5233C18.2568 21.5089 18.4487 21.443 18.6148 21.3323C18.6333 21.3198 18.6512 21.3063 18.6682 21.2919L20.4217 19.7959L23.8117 21.3142H23.8197C23.7274 22.0133 23.3836 22.6549 22.8526 23.1189C22.3216 23.5829 21.6397 23.8375 20.9345 23.8351Z" fill="#09345C"/>
    </svg>
    
   )
 }
 