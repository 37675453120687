import { getCurrency, getCurrencyLocale } from './getLocation';

export const formatPrice = (price, currency, locale) => {
  if (!currency) currency = getCurrency();
  const loc = locale ? locale : getCurrencyLocale();
  return Intl.NumberFormat(loc, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(price);
};
