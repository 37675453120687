import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';

export const search_members = async (term) => {
  const location_id = getLocationId();
  return await fetchData(
    `/search_members?location_id=${location_id}&term=${term}`
  );
};
export const search_prospects = async (term) => {
  const location_id = getLocationId();
  return await fetchData(
    `/search_prospects?location_id=${location_id}&term=${term}`
  );
};
export const search_all = async (term) => {
  const location_id = getLocationId();
  return await fetchData(`/search?location_id=${location_id}&term=${term}`);
};
