import { Select, Tooltip } from 'antd';
import React, { useRef, useEffect } from 'react';
import {
  useGetLocationPayment_methods,
  useGetPayment_methods,
} from '../api/payments';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { useMemo } from 'react';
import {
  getLocationId,
  getLocationRegionConfig,
} from '../services/utils/getLocation';
import { useGlobalContext } from '../context/GlobalState';
import PaymentMethodsMember from './helpers/PaymentMethodsMember';

import { t } from 'i18next';
export default function SelectPaymentMethod({
  setSelectedPaymentMethods,
  selectedPaymentMethods,
  product_name,
  totalAmount,
  selectedProducts,
  discount,
  showPercentage,
  debit,
  owner_user_id,
  paymentPlatform,
  openAddCreditCard,  
  setopenAddCreditCard
}) {
  const paymentTypeRef = useRef(null);
  const { state } = useGlobalContext();

  const location_id = getLocationId();
  const { data: payment_methods, isLoading: isLoadingPaymentTypes } =
    useGetLocationPayment_methods();
  const onChangePaymentType = (value, index) => {
    paymentTypeRef.current.blur();
    if (!value) {
      const updatedPaymentMethods = [...selectedPaymentMethods];
      updatedPaymentMethods[index].paymentType = null;
      updatedPaymentMethods[index].amount = 0;
      return setSelectedPaymentMethods(updatedPaymentMethods);
    }
    const updatedPaymentMethods = [...selectedPaymentMethods];
    updatedPaymentMethods[index].paymentType = value;
    if (index == 0) {
      updatedPaymentMethods[index].amount = totalAmount;
    }
    setSelectedPaymentMethods(updatedPaymentMethods);
  };
  const handleAddPaymentMethod = () => {
    setSelectedPaymentMethods([
      ...selectedPaymentMethods,
      { paymentType: null, amount: 0 }, // Set initial amount as 0
    ]);
  };
  if (selectedPaymentMethods?.length === 0) {
    selectedPaymentMethods.push(null);
  }
  // const validatePaymentIntegration = useMemo(() => {
  //   if (!paymentPlatform) return false;
  //   return paymentPlatform.some((p) => p.payment_method_id == 1);
  // },[paymentPlatform,selectedPaymentMethods]);
  useEffect(() => {
    if (isLoadingPaymentTypes) return;
    if (selectedProducts.length == 0) return;

    if (payment_methods.length === 0) return;
    const updatedPaymentMethods = selectedPaymentMethods.map(
      (paymentMethod) => {
        let calculatedAmount = 0;
        selectedProducts.forEach((selectedProduct) => {
          if (selectedProduct) {
            const productPrice =
              selectedProduct.price * selectedProduct.countProducts;
            calculatedAmount +=
              productPrice *
              (selectedProduct.discount?.id
                ? 1 - parseFloat(selectedProduct.discount.amount) / 100
                : 1);

            if (!selectedProduct.discount && paymentMethod.hasDiscount) {
              calculatedAmount =
                calculatedAmount + paymentMethod.discountAmount;
            }
          }
        });
        // price = calculatedAmount + price
        let price = calculatedAmount;

        return {
          ...paymentMethod,
          amount: price,
          hasDiscount: discount ? true : false,
          discountAmount: discount
            ? paymentMethod.amount * (parseFloat(discount?.amount) / 100)
            : 0,
        };
      }
    );
    setSelectedPaymentMethods(updatedPaymentMethods);
    // }
  }, [totalAmount]);
  const categorizePayments = useMemo(() => {
    if (!payment_methods) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = payment_methods.filter((payment_m) =>
      AvailableRegionConfig.includes(payment_m.region_config_type_id)
    );
    let filterByLocation = payment_methods.filter(
      (payment_m) => payment_m.location_id == location_id
    );
    return filterByLocation
      .filter((p) => (debit ? p.recurrent : true))
      .map((paymentType) => ({
        label: paymentType.name,
        value: paymentType.id,
        title: paymentType.name,
        disabled: selectedPaymentMethods.some(
          (pm) => pm.paymentType === paymentType.id 
          //||paymentType.id == 52
        ),
      }));
  });
  const onChangeAmount = (amount, index) => {
    if (/^\d*[\.]?\d*$/.test(amount)) {
      if (parseInt(amount) > totalAmount) return;
      // setValue(newValue.replace(',', '.'));

      // console.log('amount',amount);
      // return
      const updatedPaymentMethods = [...selectedPaymentMethods];
      updatedPaymentMethods[index].amount = amount;
      // Limit the amount to not exceed totalAmount
      // amount = Math.min(amount, totalAmount);
      updatedPaymentMethods[index].amount = amount;
      const totalSelectedAmount = updatedPaymentMethods.reduce(
        (total, pm) => total + (parseInt(pm.amount) || 0),
        0
      );
      if (totalSelectedAmount > totalAmount) {
        // Reduce the amount of other payment methods proportionally
        const diff = totalSelectedAmount - totalAmount;
        const otherPaymentMethodsCount = updatedPaymentMethods.length - 1;
        const avgDiff = diff / otherPaymentMethodsCount;

        for (let i = 0; i < updatedPaymentMethods.length; i++) {
          if (i !== index) {
            updatedPaymentMethods[i].amount = Math.max(
              0,
              updatedPaymentMethods[i].amount - avgDiff
            );
          }
        }
      } else if ((amount === 0 || !amount) && debit) {
        // Reset the amount if it becomes 0
        const remainingAmount = totalAmount;
        const remainingPaymentMethodsCount = updatedPaymentMethods.length - 1;
        if (updatedPaymentMethods.length == 1) {
          updatedPaymentMethods[index].amount = remainingAmount;
        } else {
          if (remainingPaymentMethodsCount > 0) {
            const avgRemainingAmount =
              remainingAmount / remainingPaymentMethodsCount;
            for (let i = 0; i < updatedPaymentMethods.length; i++) {
              if (i !== index) {
                updatedPaymentMethods[i].amount = avgRemainingAmount;
              }
            }
          }
        }
      }

      setSelectedPaymentMethods(updatedPaymentMethods);
      // }  ;
      // setSelectedPaymentMethods(updatedPaymentMethods);
    }
  };

  return (
    <div className='pt-6'>
      <h2 className=' text-md lg:text-xl font-MessinaSansSemiBold'>
        {t('sales.payment_method', 'Forma de pago')}{' '}
        {!showPercentage && product_name}
      </h2>
      <div className='flex flex-row items-center'>
        <div className='flex flex-col '>
          {selectedPaymentMethods.map((paymentMethod, index) => (
            <>
              <div key={index} className='flex flex-row items-center mt-5 '>
                <Select
                  key={index}
                  ref={paymentTypeRef}
                  showSearch
                  placeholder={
                    <div className='flex items-center'>
                      <span className='text-white'>
                        {t('sales.search_payment', 'Buscar forma de pago...')}
                      </span>
                    </div>
                  }
                  allowClear
                  optionFilterProp='children'
                  onChange={(value) => onChangePaymentType(value, index)}
                  value={paymentMethod.paymentType}
                  loading={isLoadingPaymentTypes}
                  bordered={false}
                  rootClassName=' text-white '
                  className={`bg-primaryGrey text-white outline-none   rounded-sm  font-DrukWideMedium py-1 border-[1px] 
                ${
                  paymentMethod?.paymentType
                    ? 'border-lightGreen '
                    : 'border-transparent'
                }
                `}
                  style={{
                    width: 250,
                    color: '#FFFFFF',
                  }}
                  // optionLabelProp='title'
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={isLoadingPaymentTypes ? [] : categorizePayments}
                />
                <div className='flex flex-row items-center'>
                  {paymentMethod.paymentType && (
                    <div className='h-11 w-28 bg-secondaryGrey  ml-5  rounded-sm  px-4 flex flex-row items-center'>
                      <input
                        type='text'
                        // step={'0.01'}
                        value={paymentMethod.amount}
                        // value={120.222}
                        onChange={(e) => {
                          onChangeAmount(e.target.value, index);
                        }}
                        className={`cursor-pointer appearance-none outline-none   !text-black font-MessinaSansSemiBold text-lg  bg-transparent ${
                          showPercentage && 'w-[85%]'
                        }`}
                      />
                      {showPercentage && (
                        <span className='!text-black font-MessinaSansSemiBold text-lg w-[15%]'>
                          %
                        </span>
                      )}
                    </div>
                  )}
                  {selectedProducts.length > 0 &&
                    payment_methods?.find(
                      (p) => p.id == paymentMethod?.paymentType
                    )?.operation_number_validation &&
                    !debit &&
                    paymentMethod.paymentType !== null && (
                      <Tooltip
                        title={t(
                          'sales.operation_number',
                          'Número de operación'
                        )}
                      >
                        <div className='h-11 w-32 bg-primaryGrey  ml-2  rounded-sm mr-4 px-4 flex flex-row items-center'>
                          <input
                            type='number'
                            // step={'0.01'}
                            placeholder={t(
                              'sales.operation_number_short',
                              'Nº operación'
                            )}
                            value={paymentMethod.operation_number}
                            // value={payment_methods.some((p)=>p.id ==paymentMethod.paymentType).operation_number_validation}
                            onChange={(e) => {
                              //check if the value is a number
                              setSelectedPaymentMethods(
                                selectedPaymentMethods.map((pm, i) =>
                                  i === index
                                    ? {
                                        ...pm,
                                        operation_number: e.target.value,
                                      }
                                    : pm
                                )
                              );
                            }}
                            className={`cursor-pointer appearance-none outline-none    font-MessinaSansSemiBold text-sm  bg-transparent `}
                          />
                        </div>
                      </Tooltip>
                    )}
                  {selectedPaymentMethods[0]?.paymentType != null && (
                    <Tooltip
                      title={t(
                        'sales.addpaymentMethod',
                        'Agregar metodo de pago'
                      )}
                    >
                      <div
                        onClick={() => handleAddPaymentMethod()}
                        className={`${
                          selectedPaymentMethods[0]?.paymentType == null
                            ? 'ml-5'
                            : selectedPaymentMethods.filter(
                                (pm) => pm.paymentType !== null
                              ).length > 1
                            ? ''
                            : ''
                        }  ml-5 self-center h-8 w-8 bg-lightGreen rounded-full items-center justify-center flex  cursor-pointer`}
                      >
                        <AiOutlinePlus color='#111' size={22} />
                      </div>
                    </Tooltip>
                  )}
                  {index > 0 && (
                    <Tooltip
                      title={t(
                        'sales.delete_paymentMethod',
                        'Eliminar forma de pago'
                      )}
                      className=''
                    >
                      <div
                        onClick={() =>
                          setSelectedPaymentMethods(
                            selectedPaymentMethods.filter((_, i) => i !== index)
                          )
                        }
                        className='h-10 w-10  items-center justify-center flex ml-2 cursor-pointer'
                      >
                        <IoIosRemoveCircleOutline color='#ED4C5C' size={24} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              {selectedPaymentMethods.filter((pm) => pm.paymentType !== null)
                .length > 0 &&
                product_name &&
                owner_user_id &&
                paymentPlatform?.some(
                  (p) => p?.payment_method_id == paymentMethod.paymentType
                ) && (
                  <PaymentMethodsMember
                  index={index}
                    owner_user_id={owner_user_id}
                    selectedPaymentMethods={selectedPaymentMethods}
                    setSelectedPaymentMethods={setSelectedPaymentMethods}
                    paymentMethod={paymentMethod}
                    openAddCreditCard = {openAddCreditCard}
                    setopenAddCreditCard = {setopenAddCreditCard}
                  />
                )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
