import { getRegion_config_types } from '../../api/config';
import { useGenericQuery } from './rootLoader';

//Region config
export const useGetRegionConfig = () => useGenericQuery(['region_config_types'], getRegion_config_types);
export const RegionConfigLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetRegionConfig();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
