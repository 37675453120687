import { Select } from 'antd';
import React ,{useMemo}from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { useGetUsers } from '../../api/users';
import { BsChevronDown } from 'react-icons/bs';

export default function SelectUser({ setValue, value ,disabled,from_location,styles,width,suffix,initialUser}) {
  const { isLoading, data: users } = useGetUsers(from_location);
  const filteredUsers = useMemo(() => {
    let c = users
    if (!c) return [];
    if (!c.find((user) => user.id == value && initialUser?.full_name)) {
      let formatUser = {
        id: value,
        full_name: initialUser?.full_name,
        active: true,
      };
      // console.log('entra?',formatUser);
      c.push(formatUser);
    } else {
      c = c.map((user) => {
        return {
          ...user,
          active: user.active,
        };
      });
    }

    return c.filter((c) =>c.active).sort((a, b) => a?.full_name?.localeCompare(b?.full_name))
  }, [users, value]);
  // console.log('users',initialUser,);

  const selectRef = React.useRef();
  return (
    <Select
      ref={selectRef}
      disabled={disabled}
      // defaultValue={value}
      placeholder={
        <div className='flex flex-row items-center gap-2'>
          <AiOutlineUser />
          Usuario
        </div>
      }
      bordered={false}
      value={value}
      onSelect={(newValue) => {
        if (newValue == value) {
          setValue(undefined);
        }
      }}
      suffixIcon={suffix && <BsChevronDown color='#ADFF19' />}
      onChange={(newValue) => {
        setValue(newValue);
        selectRef?.current.blur();
      }}
      className={` bg-primaryGrey text-white outline-none  border-none  py-1 pl-2 rounded-md ml-3 font-DrukWideMedium ${styles}`}
      style={{
        width: width,
      }}
      loading={isLoading}
      options={filteredUsers?.map((user) => ({
        label: user.full_name,
        value: user.id,
        disabled: !user.active,
      }))}
    />
  );
}
