
import React, { useState } from 'react';

export default function HamburgerButton({toggleMenu, isOpen}) {
  return (
    <button className="focus:outline-none block lg:hidden" onClick={toggleMenu}>
      <div className="space-y-2">
        <span className={`block w-6 h-0.5 bg-gray-600 transition transform ${isOpen ? 'rotate-45 translate-y-2.5' : ''}`}></span>
        <span className={`block w-6 h-0.5 bg-gray-600 transition transform ${isOpen ? 'opacity-0' : ''}`}></span>
        <span className={`block w-6 h-0.5 bg-gray-600 transition transform ${isOpen ? '-rotate-45 -translate-y-2.5' : ''}`}></span>
      </div>
    </button>
  );
};
