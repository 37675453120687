import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';

export const getRegion_config_types = async () => {
  const location_id = getLocationId();
  return await fetchData(`/region_config_types?location_id=${location_id}`);
};

export const getRegion_config_type = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/region_config_types/${id}?location_id=${location_id}`
  );
};

export const create_region_config_type = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/region_config_types?location_id=${location_id}`,
    'POST',
    data
  );
};

export const patch_region_config_type = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/region_config_types?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const delete_region_config_type = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/region_config_types?/${id}location_id=${location_id}`,
    'DELETE'
  );
};
