import React from 'react'

export default function PinSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width={width} height={height} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.3135" cy="18" r="17.2764" fill="#888888" fillOpacity="0.533333"/>
<path d="M21.3164 16.191L18.5758 18.9385C17.9819 19.5328 17.5972 20.304 17.4795 21.1359L14.1911 17.839C15.0227 17.7231 15.7932 17.3371 16.3839 16.7403L19.1236 13.9928L21.3164 16.191ZM24.0569 16.7403L18.5751 11.246C18.428 11.1072 18.2327 11.0313 18.0305 11.0343C17.8283 11.0373 17.6353 11.119 17.4925 11.2622C17.3497 11.4053 17.2683 11.5985 17.2657 11.8006C17.2632 12.0028 17.3396 12.198 17.4787 12.3447L18.0273 12.8941L15.2867 15.6416C15.0712 15.8584 14.8149 16.0304 14.5327 16.1478C14.2505 16.2652 13.9478 16.3256 13.6421 16.3256C13.3365 16.3256 13.0338 16.2652 12.7516 16.1478C12.4693 16.0304 12.2131 15.8584 11.9976 15.6416L10.9012 16.7403L14.1733 20.0209L10.3371 23.8671L10.3371 24.9658L11.4335 24.9658L15.2704 21.1196L18.5758 24.4327L19.6722 23.334C19.2352 22.8967 18.9898 22.3038 18.9898 21.6856C18.9898 21.0674 19.2352 20.4745 19.6722 20.0372L22.4135 17.2897L22.9613 17.8398C23.0333 17.9127 23.119 17.9706 23.2135 18.0102C23.308 18.0498 23.4094 18.0703 23.5118 18.0706C23.6143 18.0708 23.7158 18.0508 23.8104 18.0116C23.9051 17.9725 23.9911 17.9149 24.0634 17.8424C24.1358 17.7699 24.193 17.6837 24.2319 17.5889C24.2708 17.4941 24.2906 17.3926 24.29 17.2902C24.2895 17.1877 24.2687 17.0864 24.2288 16.992C24.1889 16.8976 24.13 16.8121 24.0569 16.7403Z" fill="#110F0F"/>
</svg>

  )
}
