import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import { FORM_REGION_CONFIG } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { RegionConfigTypeColumns as columns } from '../services/Constants/ConfigColumns';
import { formatError } from '../services/utils/formatError';
import { useGetRegionConfig } from '../router/loaders/ConfigLoaders';
import { create_region_config_type, delete_region_config_type, getRegion_config_type, getRegion_config_types, patch_region_config_type } from '../api/config';
import { getCountries, getLocations } from '../api/locations';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canAccessRegionConfigs } from '../services/can_user';
import { t } from 'i18next';
const TITLE = 'RegionConfigTypes';
const TITLE_SINGULAR = 'RegionConfigTypes';
export default function RegionConfigTypes() {
  const { data, isLoading } = useQuery(useGetRegionConfig());
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);
 
  const handleDelete = async (id) => {
    await useDeleteRegion.mutateAsync(id);
 
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('region_config_success','Configuración actualizada correctamente')
          :  t('region_config_error','Ocurrió un error al actualizar la configuración'),
...openNotificationFields(type,description)
    });
  };

  const [FORM, setFORM] = useState(FORM_REGION_CONFIG);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let region = {
        data: data,
      };
      await useCreateRegion.mutateAsync(region);
      return setopenDrawer({ visible: false, record: null });
    }
    let product = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateRegion.mutateAsync(product);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let region_config;
        if (record) {
          region_config = await getRegion_config_type(record.id);
          FORM.forEach((item) => {
            if(item.name == 'countries'){
              setValue(item.name, region_config[item.name].map((item)=>item.id));
              return;
            }
            if(item.name == 'locations'){
              setValue(item.name, region_config[item.name].map((item)=>item.id));
              return;
            }
            setValue(item.name, region_config[item.name]);
          });
         
        }
        const countries = await getCountries();
        const locations = await getLocations();

        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'countries') {
              return {
                ...item,
                options: countries.map((item) => ({
                  label: item.description,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'locations' ) {
              return {
                ...item,
                options: locations.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateRegion= useMutation({
    mutationFn: (product) => patch_region_config_type(product.id, product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['region_config_types'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateRegion = useMutation({
    mutationFn: (product) => create_region_config_type(product.data),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['region_config_types'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteRegion = useMutation({
    mutationFn: (id) => delete_region_config_type(id),
    onSuccess: () => {
      openNotification('success');
      
      queryClient.invalidateQueries({
        queryKey: ['region_config_types'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
canCreate={canAccessRegionConfigs()}
        canUpdate={canAccessRegionConfigs()}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        handleDelete={handleDelete}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        mutateLoading={useUpdateRegion.isLoading || useCreateRegion.isLoading}
      />
      {contextHolder}
    </>
  );
}
