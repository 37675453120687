import axios from 'axios';
import * as Sentry from '@sentry/react';

const axiosInstance = axios.create({
  baseURL: process.env.api_url,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const value = window.localStorage.getItem('user');
    const user = JSON.parse(value);
    const token = config.token || user?.single_access_token; // Coloca aquí tu token de autorización

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (window.location.pathname == '/mp') {
      const token = config.token || '88224c27a0de9817d3249ee3af412f8d';
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchData = async (url, method = 'GET', data, token) => {
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
      token,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log('error.response.data', error.response.data);
      // Sentry.captureException(new Error(JSON.stringify(error.response.data)), {
      //   extra: {
      //     endpoint: url,
      //     method,
      //     data,
      //     bearer: axiosInstance.defaults.headers.common.Authorization,
      //   },
      // });
      throw new Error(JSON.stringify(error.response.data));
    } else {
      console.log('Error al obtener los datos:', error.message);
      // Sentry.captureException(new Error(error.message), {
      //   extra: {
      //     endpoint: url,
      //     method,
      //     data,
      //     bearer: axiosInstance.defaults.headers.common.Authorization,
      //   },
      // });
      throw new Error(error.message);
    }
  }
};

export default axiosInstance;
