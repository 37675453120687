import axios from 'axios';

const authApi = axios.create({
  baseURL: process.env.api_url,
});

export const loginApi = async (email, password) =>
  // authApi.post('/', { email, password });
  {
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const isEmail = regex.test(email);
    let q = { password, remember_me: true, email };
    // if (isEmail) {
    //   q.email = email;
    // } else {
    //   q.username = email;
    // }
    const res = await authApi.post(
      '/enter',
      {
        user_session: q,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    return res.data;
  };

export default authApi;
