import axiosInstance from './prospects';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';

export const getSessions = async () => {
  const location_id = getLocationId();
  const response = await fetchData(`/sessions?location_id=${location_id}`);
  const formatResponse = response.map((session) => ({
    ...session,
    ...session.product,
    product_type_name: 'Sesion',
    product_type_name_es: 'Sesiones',
  }));
  return formatResponse;
};
export const getSession = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/sessions/${id}?location_id=${location_id}`);
};
export const updateSession = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sessions/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createSession = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/sessions?location_id=${location_id}`, 'POST', data);
};
export const deleteSession = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sessions/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const useGetSessions = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['sessions', location_id],
    queryFn: async () => getSessions(),
  });
};
export default axiosInstance;
