import React from 'react';
import SpinIndicador from './SpinIndicador';
import { formatPrice } from '../../services/utils/formatPrice';
import { t } from 'i18next';

export default function FooterSaleModal({
  handleCreateSale,
  setmodalSale,
  isLoading,
  totalAmount,
  sendCC,
  selectedProducts,
  totalSale,
  ModalReturn,
  cancelProducts,
  legend,
  title,
  bg
}) {
  
  const calculateCC = () => {
   
    
    if (sendCC) {
      // if(selectedProducts?.selectedPaymentMethods?.filter((item) => item.paymentType == 52 && !item.credit_card_id).length > 0){
      //   return totalSale;
      // }
      return (
        totalSale -
        totalAmount
      );
    }
  };
  return (
    <>
    <div className='flex flex-row mt-20 mb-2'>
      <button
        disabled={isLoading}
        onClick={async () => await handleCreateSale()}
        className={`${
        
          sendCC ? 'bg-primaryRed' :bg ? bg : 'bg-lightGreen'
        } text-white outline-none border-none  rounded-sm  py-4 MessinaSansBold w-[75%] flex items-center justify-center gap-2  
        ${
          selectedProducts?.length === 0 && 'opacity-70'
        }
        
        `}
      >
        <span className='text-md lg:text-lg text-black font-MessinaSansBold'>
          { title ? title : cancelProducts
            ? t('sales.process_nc','Procesar N/C')
            : sendCC
            ? t('sales.process_send','PROCESAR PAGO Y ENVIAR')
            : ModalReturn
            ? t('sales.return','DEVOLVER')
            :t('sales.process', 'PROCESAR')}{' '}
          {totalSale > 0 && !title
            ? sendCC
              ? isNaN(calculateCC()) ? totalSale: formatPrice(calculateCC())
              :   isNaN(totalAmount)? totalSale  : formatPrice(totalAmount)
            : ''}{' '}
          {sendCC && !title && t('sales.send_cc','a C.C')}
        </span>
        {isLoading && <SpinIndicador />}
       
      </button>
     
      <button
        className='  ml-3 py-2 MessinaSansBold w-[30%]'
        onClick={() => setmodalSale(false)}
      >
        <span className='text-xs lg:text-lg text-white font-MessinaSansBold border-b-[1px]'>
          {t('sales.cancel','CANCELAR')}
        </span>
      
      </button>
     
    </div>
    {legend && <span
      className='text-sm opacity-80 text-white font-MessinaSansRegular'
      >
        {legend}</span>}
    </>
  );
}
