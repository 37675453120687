import React, { useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Dot,
  Legend,
  Tooltip,
} from 'recharts';
import Card from './Card';
import { useCdpConvertion } from '../../api/charts';
import dayjs from 'dayjs';

export default function RatingCoachesChart({chart}) {
  const [selected_seller, setselected_seller] = useState(false)
  const reduceByCoach = (data) => {
    return chart?.results
      .filter((d) => dayjs(d.date).isSame(dayjs(), 'month'))
      .reduce((acc, item) => {
        const key = item.coach;
        if (!acc[key]) {
          acc[key] = { coach: key, convertion: 0, cdp: 0 };
        }
        if (item.type == 'Members') {
          acc[key].convertion += parseFloat(item.quantity);
        } else {
          acc[key].cdp += parseFloat(item.quantity);
        }
        return acc;
      }, {});
  };
  return (
    <Card
      title={'Coaches'}
      size='large'
      description={`En caso que tenga 2 CDP se considera la ultima. Solo se consideran presentes. Tasa de conversion de CDP y cantidad de convertidos por Coach en el mes en curso. Los colores estan dados segun:
      Verde +60%. Amarillo 50-59%. Rojo -50%`}
      Extraclass={`col-span-2`}
    >
      <ScatterChart
        margin={{
          top: 10,
          right: 10,
        }}
      >
        <CartesianGrid />
        <XAxis type='number' dataKey='convertion' name='Convertidos' />
        <YAxis
          type='number'
          dataKey='percentage'
          name='Tasa conversion'
          unit='%'
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          active={false}
       
          content={({ active, payload, label }) => {
            if (active && payload) {
              return (
                <div className=' bg-background px-10 py-6 rounded-md'>
                  <p className='label'>{`Coach : ${payload[0].payload.coach_name}`}</p>
                  <p className='label'>{`Clases de prueba : ${payload[0].payload.cdp}`}</p>
                  <p className='label'>{`${payload[0].name} : ${payload[0].value}`}</p>
                  <p className='label'>{`${payload[1].name} : ${payload[1].value}%`}</p>
                </div>
              );
            }
          }}
        />
        <Legend
          align='left'
          layout='vertical'
          verticalAlign='top'
          content={({ payload }) => {
            return (
              <ul className='flex flex-col'>
                {payload.map((entry, index) => {
                  return (
                    <button
                      key={`item-${index}`}
                      className='flex items-center space-x-2 m-[1px] '
                      onClick={() => setselected_seller(
 selected_seller === entry.value ? false : entry.value
                      )}
                    >
                      <span className='h-4 w-4 bg-primaryGreen rounded-full'
                      style={{
                        opacity: selected_seller && selected_seller !== entry.value ? 0.2 : 1,
                       backgroundColor: entry.color
                      }}
                      ></span>
                      <span 
                      style={{color:entry.color}}
                      className='text-sm'>{entry.value}</span>
                    </button>
                  );
                })}
              </ul>
            );
          } }
          // iconType='none'
        />
        {chart?.results &&
          Object.values(reduceByCoach(chart?.results))
          .sort((a, b) => (parseInt(
            (b.convertion / (b.cdp + b.convertion)) * 100
          ) 
          - parseInt(
            (a.convertion / (a.cdp + a.convertion)) * 100
          )
          
          ))
          .map((entry, index) => {
            return (
              <Scatter
                key={entry.coach}
                name={entry.coach}
                data={[
                  {
                    convertion: entry.convertion,
                    cdp: entry.cdp + entry.convertion,
                    percentage: parseInt(
                      (entry.convertion / (entry.cdp + entry.convertion)) * 100
                    ),
                    coach_name: entry.coach,
                  },
                ]}
                // legendType={shapes[index]}
                shape={(props) => {
                  // console.log('props', props);
                  return (
                    <>
                      <Dot
                      style={{opacity: selected_seller && selected_seller !== entry.coach ? 0.2 : 1}}
                        cx={props.cx}
                        cy={props.cy}
                        fill={props.fill}
                        r={6}
                      />
                    </>
                  );
                }}
                fill={`${
                  parseInt(
                    (entry.convertion / (entry.cdp + entry.convertion)) * 100
                  ) < 50
                    ? '#ED4C5C'
                    : parseInt(
                        (entry.convertion / (entry.cdp + entry.convertion)) *
                          100
                      ) < 60
                    ? '#FFD500'
                    : '#ADFF19'
                }`}
              />
            );
          })}
      </ScatterChart>
    </Card>
  );
}
