import { useQuery } from '@tanstack/react-query';
import axiosInstance, { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { getProfile } from './members';

export const getProspects = async (start, end) => {
  const location_id = getLocationId();
  return await fetchData(
    `/prospects?location_id=${location_id}&start=${start}&end=${end}&converted=${true}`
  );
};
export const useGetProspects = (start, end) => {
  // console.log('start',start);
  return useQuery({
    queryKey: ['prospects', start, end],
    queryFn: async () => getProspects(start, end),
    select: (data) =>
      data
        .map((item) => ({
          ...item,
          ...item.user,
          name: `${item.user.first_name} ${item.user.last_name}`,
          // start: formatUTC(item.start),
        }))
        .sort((a, b) => b.id > a.id),
  });
};
export const getProspect = async (id, isMember) => {
  const location_id = getLocationId();
  const prospect = await fetchData(
    `/prospects/${id}?location_id=${location_id}`
  );

  if (prospect.member_id && !isMember) {
    console.log('get profile', prospect);

    const member = await getProfile(prospect.member_id, true, true);
    return {
      ...prospect,
      ...member,
    };
  }
  return prospect;
};
export const useGetProspect = (id) => {
  return useQuery({
    queryKey: ['prospect', id],
    queryFn: async () => getProspect(id),
    select: (data) => ({
      ...data,
      ...data.user,
      name: `${data.user.first_name} ${data.user.last_name}`,
    }),
    // start: formatUTC(item.start),
  });
};
export const getProspectsPopulate = async () => {
  const location_id = getLocationId();
  return await fetchData(`/prospects_populate?location_id=${location_id}`);
};
export const getUncontactedProspects = async () => {
  const location_id = getLocationId();
  return await fetchData(`/prospects_no_contact?location_id=${location_id}`);
};
export const useGetUncontactedProspects = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['prospects_no_contact', location_id],
    queryFn: async () => getUncontactedProspects(),
    // refetchOnWindowFocus: 'always',
  });
};
export const getApproachTypes = async () => {
  const location_id = getLocationId();
  return fetchData(`/approach_types?location_id=${location_id}`);
};
export const useGetApproachTypes = () => {
  return useQuery({
    queryKey: ['approach_types'],
    queryFn: async () => getApproachTypes(),
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });
};
export const getCampaings = async () => {
  const location_id = getLocationId();
  return await fetchData(`/campaigns?location_id=${location_id}`);
};
export const useGetCampaings = () => {
  return useQuery({
    queryKey: ['campaigns'],
    queryFn: async () => getCampaings(),
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });
};
export const getChannels = async () => {
  const location_id = getLocationId();
  return await fetchData(`/channels?location_id=${location_id}`);
};
export const useGetChannels = () => {
  return useQuery({
    queryKey: ['channels'],
    queryFn: async () => getChannels(),
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });
};
export const getDischargeReasons = async (discharge_reason_type_id) => {
  const location_id = getLocationId();
  let q = `/discharge_reasons?location_id=${location_id}`;
  if (discharge_reason_type_id) {
    q = `/discharge_reasons?location_id=${location_id}&discharge_reason_type_id=${discharge_reason_type_id}`;
  }
  return await fetchData(q);
};
export const useGetDischargeReasons = (discharge_reason_type_id) => {
  return useQuery({
    queryKey: ['discharge_reasons', discharge_reason_type_id],
    queryFn: async () => getDischargeReasons(discharge_reason_type_id),
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });
};

export const createProspect = async (data) => {
  const location_id = getLocationId();

  return await fetchData(`/prospects?location_id=${location_id}`, 'POST', data);
};
export const deleteProspect = async (id) => {
  const location_id = getLocationId();

  return await fetchData(
    `/prospects/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const updateProspect = async (id, data) => {
  const location_id = getLocationId();

  return await fetchData(
    `/prospects/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const get_mobile_prospect = async (token) => {
  return await fetchData(`/mobile/prospect`, 'GET', null, token);
};
export default axiosInstance;
