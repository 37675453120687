import React from 'react'

export default function NoteSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17.2764" cy="17.3896" r="17.2764" fill="#E7A13B"/>
<path d="M14.2037 14.902H20.3485M14.2037 17.9744H18.8123M24.189 19.9862V13.3658C24.189 12.7547 23.9462 12.1686 23.5141 11.7364C23.0819 11.3043 22.4958 11.0615 21.8847 11.0615H12.6676C12.0564 11.0615 11.4703 11.3043 11.0382 11.7364C10.6061 12.1686 10.3633 12.7547 10.3633 13.3658V19.5106C10.3633 20.1217 10.6061 20.7078 11.0382 21.1399C11.4703 21.5721 12.0564 21.8148 12.6676 21.8148H14.2037V24.1191L17.2838 22.2711H20.3485" stroke="#A66405" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}



