import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';

import {
  USE_FORM_EDIT_BALANCE,
  USE_FORM_PAYMENT,
} from '../services/Constants/FORMS';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  notification,
  ConfigProvider,
  DatePicker,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { USEBalanceColumns } from '../services/Constants/AccountingColumns';
import { formatError } from '../services/utils/formatError';
import { createPayment, updatePayment, deletePayment } from '../api/payments';
import { formatPrice } from '../services/utils/formatPrice';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import dayjs from 'dayjs';
import {
  getCurrency,
  getLocationId,
  getTimeZone,
} from '../services/utils/getLocation';
import { updateBalance, useGetSalesBalance } from '../api/balances';
import CardRounded from '../components/CardRounded';
import es_ES from 'antd/locale/es_ES';
import { compareByCreatedAt } from '../services/utils/compareByDate';
import { t } from 'i18next';
import { getPointSales } from '../api/locations';
import { getUsers } from '../api/users';
import { isUserManager } from '../services/can_user';

const start = dayjs().subtract(1, 'week').startOf('week');
const end = dayjs().endOf('week');
export default function Balances() {
  const columns = USEBalanceColumns();
  const TITLE = t('balances.title', 'Balances');
  const TITLE_SINGULAR = t('balances.title_singular', 'Balance');
  const FORM_BALANCE = USE_FORM_EDIT_BALANCE();
  const location_id = getLocationId();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { data, isLoading } = useGetSalesBalance(
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD')
  );

  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();

  const [filterTable, setfilterTable] = useState([]);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    setfilterTable(isLoading ? [] : data.balances);
  }, [data]);
  console.log('data',data);
  
  // const handleDelete = async (id) => {
  //   await useDeletePayment.mutateAsync(id);
  // };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t(
              'sales.balance_succes',
              `El balance se ha actualizado correctamente`
            )
          : t('sales.balance_error', `Ocurrió un error al actualizar el balance`),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_BALANCE);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let balance = [];
    
    if (data.user_close || data.total_closed || data.close_balance_id) {
      // update close balance
      balance.push({
        id: openDrawer.record.close_balance_id,
        data: {
          user_id: data.user_close,
          amount: data.total_closed,
          location_id: location_id,
          operation_type: 'close',
        },
      });
    }
    // update open balance
    balance.push({
      id: openDrawer.record.id,
      data: {
        user_id: data.user_open,
        amount: data.balance_opened_amount,
        location_id: location_id,
        operation_type: 'open',
      },
    });
  
    await Promise.all(balance.map((b) => useUpdateBalance.mutateAsync(b)));
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (record, visible) => {
    reset();
    const point_of_sale_id = await getPointSales();
    const users = await getUsers();
    if (visible) {
      if (record) {
        setValue('point_of_sale_id', record.point_of_sale.id);
        setValue('user_open', record.user.id);
        setValue(
          'balance_opened_amount',
          parseFloat(record.balance_opened_amount ? record.balance_opened_amount : record.open_amount)
        );
        if (record.balance_close_amount){
          setValue('total_closed', parseFloat(record.balance_close_amount));

        }
        if (record.close_user) {
          setValue('user_close', record.close_user.id);
        }

        setFORM(
          FORM_BALANCE.map((item) => {
            if (item.name === 'point_of_sale_id') {
              return {
                ...item,
                options: point_of_sale_id.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'user_close' || item.name === 'user_open') {
              return {
                ...item,
                options: users.map((item) => ({
                  label: item.first_name + ' ' + item.last_name,
                  value: item.id,
                })),
              };
            }

            return item;
          }).filter((item) => {
            if (!record.balance_close_amount) {
              return item.name !== 'total_closed' && item.name !== 'user_close';
            }
            return item;
          })
        );
      }

      setopenDrawer({ visible: visible, record: record });
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
    // let findClose = data.find((b)=>b.operation_type == 'close' && b.id > record.id)
    // if(findClose){
    //   setstart_date(dayjs(record.created_at).format('YYYY-MM-DD'));
    //   setend_date(dayjs(findClose.created_at).format('YYYY-MM-DD'));
    //   return
    // }
  };
  const useUpdateBalance = useMutation({
    mutationFn: (balance) => updateBalance(balance.id, balance.data),
    onSuccess: (data) => {
      
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: [
          'sales_balances',
          location_id,
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD'),
        ],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });

  const expandedRowRender = (record) => {
    let transactions = [];
    let arr = [
      ...record.transactions,
      ...record.refunds.map((r) => ({
        ...r,
        transaction_type: 'refund',
        seller_name: r.user_name,
        payment_method_name: r.payment_method,
        total: r.amount,
      })),
    ];
    arr.forEach((t, i) => {
      transactions.push({
        key: t.id,
        id: t.id,
        date: t.created_at,
        customer_name: t.customer_name,
        seller_name: t.seller_name,
        product_name: t.product_name,
        payment_method_name: t.payment_method_name,
        amount: t.total,
        transaction_type: t.transaction_type,
      });
    });
    const sumByPaymentMethod = arr.reduce((acc, transaction) => {
      if (!acc[transaction.payment_method_name]) {
        acc[transaction.payment_method_name] = 0;
      }
      if (transaction.transaction_type == 'refund') {
        acc[transaction.payment_method_name] -= parseFloat(transaction.total);
      } else {
        acc[transaction.payment_method_name] += parseFloat(transaction.total);
      }

      return acc;
    }, {});
    //payments_methods
    const payments_methods = record.transactions.reduce((acc, transaction) => {
      if (!acc.some((a) => a.text == transaction.payment_method_name)) {
        acc.push({
          text: transaction.payment_method_name,
          value: transaction.payment_method_name,
        });
      }

      return acc;
    }, []);
    const columns = [
      {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
        render: (text) => (
          <p>{dayjs(text).tz(getTimeZone()).format('DD/MM/YYYY HH:mm A')}</p>
        ),
        sorter: (a, b) => compareByCreatedAt(a, b),
        defaultSortOrder: 'ascend',

        // render: (text) => <p className='text-sm'>hola</p>,
      },
      {
        title: 'ID operación',
        dataIndex: 'id',
        key: 'id',
        // render: (text) => <p></p>,
        render: (text) => <p className='text-sm'>{text}</p>,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Tipo de operación',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        render: (text) => (
          <p>
            {text == 'payment'
              ? 'Pago'
              : text == 'expense'
              ? t('expense', 'Gasto')
              : text == 'refund'
              ? t('sale.filter.refund', 'Devolucion')
              : t('income', 'Ingreso')}
          </p>
        ),
        filters: [
          {
            text: t('payments.title_singular', 'Pago'),
            value: 'payment',
          },
          {
            text: t('expense', 'Gasto'),
            value: 'expense',
          },
          {
            text: t('income', 'Ingreso'),
            value: 'income',
          },
          {
            text: t('sale.filter.refund', 'Devolucion'),
            value: 'refund',
          },
        ],
        onFilter: (value, record) => record.transaction_type.includes(value),
        // render: (text) => <p className='text-sm'>hola</p>,
      },

      {
        title: t('buyier', 'Comprador'),
        dataIndex: 'customer_name',
        key: 'name',
      },
      {
        title: t('seller', 'Vendedor'),
        dataIndex: 'seller_name',
        key: 'state',
      },
      {
        title: t('product', 'Producto'),
        dataIndex: 'product_name',
        key: 'product_name',
        sorter: (a, b) => (a.product_name > b.product_name ? 1 : -1),
      },
      {
        title: t('sales.payment_method', 'Forma de pago'),
        dataIndex: 'payment_method_name',
        key: 'operation',

        filters: payments_methods,
        onFilter: (value, record) => record.payment_method_name == value,
        //remove duplicates
      },
      {
        title: t('total_singular', 'Total'),
        dataIndex: 'amount',
        key: 'operation',
        render: (text) => (
          <p
            className={`${
              parseInt(text) < 0 ? 'text-primaryRed' : 'text-white'
            }`}
          >
            {formatPrice(text, getCurrency())}
          </p>
        ),
      },
    ];
    const sumTransactions = (type, method = null) =>
      transactions
        .filter(
          (t) =>
            t.transaction_type === type &&
            (!method || t.payment_method_name === method)
        )
        .reduce((acc, t) => acc + parseFloat(t.amount), 0);

    const parcialAmount =
    parseFloat(record.balance_opened_amount)+
      sumTransactions('payment', 'Efectivo') +
      sumTransactions('expense') +
      sumTransactions('income');
    return (
      <ConfigProvider
        theme={{
          components: {
            Table: {
              footerColor: '#FFD500',
              footerBg: '#FFD500',
              /* here is your component tokens */
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={transactions}
          // tableLayout=''
          pagination={false}
          showHeader={true}
          footer={() => (
            <div
              className={`flex items-end justify-between flex-col bg-primaryGrey  p-4 text-white`}
            >
              <div className='flex jic gap-5'>
                <div className='flex gap-5'>
                  {Object.entries(sumByPaymentMethod).map(([method, total]) => (
                    <div className='rounded-md p-5 bg-primaryDark flex flex-col items-center justify-center'>
                      <p key={method} className='mb-2'>
                        {method}:
                      </p>
                      <p className='text-2xl'>
                        {formatPrice(total, getCurrency())}
                      </p>
                    </div>
                  ))}
                </div>

                <div className='rounded-md p-5 bg-lightGreen text-darkGreen flex flex-col items-center justify-center'>
                  <p> {t('total_singular', 'Total')}: </p>
                  <p className='text-2xl'>
                    {formatPrice(
                      transactions.reduce(
                        (acc, t) =>
                          t.transaction_type == 'refund'
                            ? acc - parseFloat(t.amount)
                            : acc + parseFloat(t.amount),
                        0
                      ),
                      getCurrency()
                    )}
                  </p>
                </div>
              </div>

              <div className=' border-b-[1px] border-white w-full my-5'></div>
              {/* <p className='mb-2'>Monto Cuenta corriente: {' '}
                    {formatPrice(record.balace_cc_total, getCurrency())}
                </p> */}

              <div className='flex jic gap-5'>
                <div className='flex gap-5'>
                  <div className='rounded-md p-5 bg-primaryDark flex flex-col items-center justify-center'>
                    <p className='mb-2'>
                      {t('amount_open', 'Monto Apertura Efectivo')}:{' '}
                    </p>
                    <p className='text-2xl'>
                      {formatPrice(record.balance_opened_amount, getCurrency())}
                    </p>
                  </div>
                  {!record.balance_close_amount && (
                    <div className='rounded-md p-5 bg-primaryDark flex flex-col items-center justify-center'>
                      <p className='mb-2'>
                        {t(
                          'amount_parcial_close',
                          'Monto Parcial Cierre Efectivo'
                        )}
                        :{' '}
                      </p>
                      <p className='text-2xl'>
                        {formatPrice(parcialAmount, getCurrency())}
                      </p>
                    </div>
                  )}
                  <div className='rounded-md p-5 bg-primaryDark flex flex-col items-center justify-center'>
                    <p className='mb-2'>
                      {t('amount_close', 'Monto Cierre Efectivo')}:{' '}
                    </p>
                    <p className='text-2xl'>
                      {record.balance_close_amount
                        ? formatPrice(
                            record.balance_close_amount,
                            getCurrency()
                          )
                        : '-'}
                    </p>
                  </div>
                </div>

                <div
                  className={`rounded-md p-5  ${
                    record.balance_close_difference &&
                    parseInt(record.balance_close_difference) < 0
                      ? 'text-black bg-primaryRed'
                      : 'bg-lightGreen text-darkGreen'
                  } flex flex-col items-center justify-center`}
                >
                  <p>{t('difference', 'Diferencia')}: </p>
                  <p className='text-2xl'>
                    {record.balance_close_difference
                      ? formatPrice(
                          record.balance_close_difference,
                          getCurrency()
                        )
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
          )}
        />
      </ConfigProvider>
    );
  };
  return (
    <>
      <div className='h-screen pl-[1rem] lg:pl-[3rem]    mt-8 gap-9  '>
        <div className='mb-10 flex-grow flex flex-col mt-2'>
          <h2 className='text-2xl font-MessinaSansSemiBold'>Balances</h2>
          <CardRounded styles={' mt-6 py-1 relative flex flex-row w-[25%]'}>
            {[t('fromLabel', 'Desde'), t('toLabel', 'Hasta')].map((item, i) => (
              <div
                key={i}
                className='relative   border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center'
              >
                <span className=' opacity-40 absolute top-2 font-MessinaSansSemiBold'>
                  {item}
                </span>
                <ConfigProvider locale={es_ES}>
                  <DatePicker
                    allowClear={false}
                    ref={i == 0 ? datepickerFrom : datepickerAt}
                    onChange={(date, dateString) => {
                      if (i == 0) {
                        datepickerFrom.current.blur();
                        return setstart_date(date);
                      }
                      if (i == 1) {
                        datepickerAt.current.blur();
                        return setend_date(date);
                      }
                    }}
                    // name='started_at'
                    value={i == 0 ? start_date : end_date}
                    // defaultValue={product.started_at}
                    showToday
                    className=' bg-transparent outline-none border-none  h-12  w-full appearance-none border-transparent shadow-nonehover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                  />
                </ConfigProvider>
              </div>
            ))}
          </CardRounded>
        </div>
        <div className='w-[100%] mt-[-40px]'>
          <TableCustom
            filterTable={filterTable}
            data={filterTable}
            openDrawer={openDrawer}
            setfilterTable={setfilterTable}
            handleOpenDrawer={() => {
              setopenDrawer({ visible: false, record: null });
            }}
            canUpdate={isUserManager()}
            canCreate={isUserManager()}
            hideTitle
            handleEditRow={handleOpenDrawer}
            title={TITLE}
            title_singular={TITLE_SINGULAR}
            searchInput={searchInput}
            onSubmit={onSubmit}
            control={control}
            FORM={FORM}
            originalColumns={columns}
            register={register}
            handleSubmit={handleSubmit}
            setsearchInput={setsearchInput}
            expandedRowRender={expandedRowRender}
            isLoading={isLoading}
            mutateLoading={useUpdateBalance.isLoading}
          />
        </div>
        {contextHolder}
      </div>
    </>
  );
}
