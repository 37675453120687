import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import axiosInstance from '../services/utils/axiosInstance';

export const getUsers = async (from_location, token) => {
  // const location_id = getLocationId();
  const location_id = from_location ? from_location : getLocationId();
  const user = await fetchData(
    `/users?location_id=${location_id}`,
    'GET',
    null,
    token
  );
  return user.map((user) => ({
    ...user,
    name: user.first_name + ' ' + user.last_name,
  }));
};
export const getUser = async (id, token, location) => {
  const location_id = getLocationId() || location;
  return await fetchData(
    `/users/${id}?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};
export const getRoles = async () => {
  const location_id = getLocationId();
  return await fetchData(`/roles?location_id=${location_id}`);
};
export const getDocumentTypes = async (token, location) => {
  const location_id = getLocationId() || location;
  return await fetchData(
    `/document_types?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};

export const useGetDocumentTypes = (token, enabled = { enabled: true }) => {
  return useQuery({
    queryKey: ['document_types'],
    queryFn: async () => getDocumentTypes(token, enabled.location_id),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled.enabled,
  });
};
export const useGetUsers = (from_location) => {
  const location_id = from_location ? from_location : getLocationId();
  return useQuery({
    queryKey: ['users', location_id],
    queryFn: async () => getUsers(from_location),
    select: (data) =>
      data
        .map((user) => ({
          ...user,
          full_name: user.first_name + ' ' + user.last_name,
        }))
        .sort((a, b) => a.full_name.localeCompare(b.full_name)),
  });
};
export const useGetUser = (id, token, enabled = { enabled: true }) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: async () => getUser(id, token, enabled.location_id),
    enabled: enabled.enabled,
  });
};

export const updateUser = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/users/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createUser = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/users?location_id=${location_id}`, 'POST', data);
};
export const deleteUser = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/users/${id}?location_id=${location_id}`,
    'DELETE',
    data
  );
};
export const modify_password = async (data) => {
  return await fetchData(`/mobile/set_password`, 'PATCH', data);
};
export const get_user_lookup = async (email) => {
  const location_id = getLocationId();
  return await fetchData(
    `/mobile/user_lookup?location_id=${location_id}&email=${email}`,
    'GET'
  );
};
export default axiosInstance;
