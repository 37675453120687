import React from 'react';
import { Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { formatPrice } from '../../services/utils/formatPrice';

import { useAuth } from '../../context/AuthProvider';
import { useGlobalContext } from '../../context/GlobalState';
import { canCreateCreditNote, isUserAdmin } from '../../services/can_user';
import { useGetMemberCC } from '../../api/members';
import { t } from 'i18next';
import { getLocationId } from '../../services/utils/getLocation';

export default function ListMemberCC({
  customer_id,
  customer_type,
  setopenCC,
  openNotification
}) {
  
  const { user } = useAuth();
  const { state } = useGlobalContext();
  const { data, isLoading } = useGetMemberCC(customer_id, customer_type);
  if (isLoading) return <Skeleton active />;
  if (data.sales?.length == 0 || data.total_due == 0) {
    return (
      <h3 className='py-7 mx-5'>
        {t('no_debt', 'Este socio no posee ninguna deuda en cuenta corriente')}
      </h3>
    );
  } 

  return (
    <div>
      {data.sales?.map((sale, index) => (
        <div
          key={index}
          className={` mx-5 flex flex-row border-b-[1px] border-b-primaryGrey py-3 justify-between items-center last:border-transparent column-mobile tc-m `}
        >
          <Tooltip title={t('sale.titleID', 'Venta ID')}>
            <span className={`w-[6%]`}>{sale.id}</span>
          </Tooltip>
          <span className={`w-[6%]`}>
            {dayjs(sale.created_at).tz(user.country.time_zone).format('DD/MM')}
          </span>
          <div className='w-[45%] items-center justify-center'>
            <Tooltip
              zIndex={customer_id ? false : 1}
              title={`Vendido por ${sale.seller_name} el ${dayjs(
                sale.created_at
              )
                .tz(user.country.time_zone)
                .format('DD/MM/YYYY HH:mm A')} en ${sale.location_name}`}
            >
              <p className='font-MessinaSansSemiBold'>{sale.product?.name} </p>
            </Tooltip>
            <span>
              <p className=' text-primaryRed'>{sale.seller_name}</p>
            </span>
          </div>

          <span className='text-lg  whitespace-nowrap sm:whitespace-normal w-[14%] text-primaryRed font-MessinaSansSemiBold'>
            {formatPrice(sale.total_due, sale.currency)}
          </span>
          {customer_id && (
            <>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if(sale.location_id !== getLocationId()){
                    return openNotification('error', t('cc_location_error', 'No puedes imputar pagos de otra sucursal. La venta fue realizada en la sucursal: ') + sale.location_name)
                  }
                  setopenCC({
                    ...sale,
                    cancelProducts: false,
                  });
                }}
                className='  border-primaryGrey bg-primaryGrey rounded-md border-[1px] p-2 cursor-pointer hover:opacity-70 ease-in-out duration-300'
              >
                <Tooltip
                  title={t(
                    'cc_pay_desc',
                    'Agregar un pago a esta venta (Puede ser total o parcial)'
                  )}
                >
                  <span>{t('cc_pay', 'Imputar pago')}</span>
                </Tooltip>
              </div>
              {(canCreateCreditNote(state.user.roles) ||
                (!isUserAdmin(state.user.roles) &&
                  state.user.locations
                    .map((l) => l.id)
                    .includes(sale.location_id))) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if(sale.location_id !== getLocationId()){
                      return openNotification('error', t('nc_location_error', 'No puedes generar Notas de Crédito de otra sucursal. La venta fue realizada en la sucursal: ') + sale.location_name)
                    }
                    setopenCC({
                      ...sale,
                      cancelProducts: true,
                    });
                  }}
                  className='  border-primaryGrey bg-primaryRed rounded-md  p-2 cursor-pointer hover:opacity-70 ease-in-out duration-300'
                >
                  <Tooltip
                    title={t(
                      'cc_explain_action',
                      'Este botón generará una nota de crédito y cancelará los productos asociados a la venta'
                    )}
                  >
                    <span className='text-black font-MessinaSansSemiBold '>
                      {t('cc_button_action', 'Generar N/C')}
                    </span>
                  </Tooltip>
                </div>
              )}
            </>
          )}
        </div>
      ))}
      {data.length == 0 && customer_id && (
        <p className='mb-7'>
          {t('this', Este)}{' '}
          {customer_type == 'Member'
            ? t('member', 'Socio')
            : t('prospect', 'Prospecto')}{' '}
          {t('cc_no_debt', 'no posee deuda en cuenta corriente')}
        </p>
      )}
    </div>
  );
}
