import React from 'react';
import { Modal, Select } from 'antd';
const { confirm } = Modal;
import { BsArrowRight } from 'react-icons/bs';
import { BsChevronDown } from 'react-icons/bs';
import { t } from 'i18next';
import dayjs from 'dayjs';

export const ModalConfirmCoach = async (
  old_coach,
  new_coach,
  dischargeReasonsFilter,
  reasonSelectedRef,
  openNotification,
  member_id,
  useCreateHistory
) => {
  return new Promise((resolve, reject) => {
    confirm({
      title: t('change_coach', 'Modificar coach asignado'),
      cancelButtonProps: {
        className: 'text-white',
      },
      icon: <></>,
      content: (
        <div className="flex items-center flex-col justify-center">
          <div className="flex flex-row items-center justify-between my-3">
            <div>
              <p className="text-secondaryGrey opacity-80">
                {t('actual_coach_change', 'Coach actual')}
              </p>
              <div className="py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey">
                {old_coach}
              </div>
            </div>
            <BsArrowRight
              className="mx-4 self-center mt-4"
              color={'#ADFF19'}
              size={22}
            />
            <div>
              <span className="text-secondaryGrey">
                {t('new_coach_change', 'Nuevo coach')}
              </span>
              <div className="py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey">
                {new_coach.name}
              </div>
            </div>
          </div>
          <Select
            value={reasonSelectedRef.current}
            onChange={(value) => {
              reasonSelectedRef.current = value;
            }}
          
            bordered={false}
            placeholder={t('select_reason', 'Seleccione un motivo')}
            suffixIcon={<BsChevronDown color="#ADFF19" />}
            className="bg-primaryGrey text-white outline-none border-none rounded-md font-DrukWideMedium py-1 my-4"
            style={{ width: '100%' }}
            options={dischargeReasonsFilter?.map((reason) => ({
              label:
                reason.name +
                (reason.description ? ' - ' + reason.description : ''),
              value: reason.id,
            }))}
          />
        </div>
      ),
      okText: t('confirm', 'Confirmar'),
      cancelText: t('cancel', 'Cancelar'),
      onOk() {
        return new Promise(async (resolveInner, rejectInner) => {
          if (reasonSelectedRef.current === null) {
            openNotification(
              'error',
              t('change_reason_coach', 'Debe seleccionar un motivo de cambio de coach')
            );
            rejectInner();
            return; // Agregar return para detener la ejecución
          }
          const data = {
            coach_id: new_coach.id,
            discharge_reason_id: reasonSelectedRef.current,
            member_id: member_id,
            starts_at: dayjs().format('YYYY-MM-DD'),
          };
          reasonSelectedRef.current = null;
          try {
            await useCreateHistory.mutateAsync(data);
            resolveInner();
            resolve(); // Resolver la promesa externa también
          } catch (error) {
            console.log('error', error);
            rejectInner();
            reject(); // Rechazar la promesa externa también
          }
        });
      },
      onCancel() {
        if (reasonSelectedRef.current === null) {
          openNotification(
            'error',
            t('change_reason_coach', 'Debe seleccionar un motivo de cambio de coach')
          );
        }
        reasonSelectedRef.current = null;
        reject(); // Rechazar la promesa externa en caso de cancelación
      },
    });
  });
};
