import React from 'react';
import Badge from './utils/Badge';
import { Dropdown, Popover, Statistic, Tooltip } from 'antd';
import CountUp from 'react-countup';
import { BsChevronDown } from 'react-icons/bs';

import { formatPrice } from '../../services/utils/formatPrice';
import HelpQuestion from '../helpers/popovers/HelpQuestion';
export default function CardHeader({
  icon,
  title,
  insight,
  percentage,
  todayTotal,
  multipleInsights,
  popover,
  description,
  description_last_month,
  percentage2,
  description_last_month2,
  headerChildren
}) {
  const determineStatus = (value) => {
    const result = value?.match(/-?\d+(\.\d+)?/);
    if (result) {
      return result[0] > 0 ? 'green' : result[0] === '0' ? 'yellow' : 'red';
    }
    return 'red';
  };

  return (
    <div className={`flex flex-row justify-between items-start`}>
      <div
        className={`flex flex-row items-center w-full flex-wrap lg:flex-nowrap  ${
          insight == null && !multipleInsights && 'justify-between'
        }`}
      >
        {icon && (
    <span
            className={`${
              percentage > 0 || !percentage
                ? 'bg-primaryGreen'
                :  percentage == 0 ? 'bg-primaryYellow':  'bg-primaryRed'
            } rounded-full p-2  items-center justify-center text-primaryDark mr-3 hidden  lg:flex`}
          >
            {icon}
          </span>
      
        )}

        <div className='flex flex-row gap-1 items-center'>
          <h2 className='self-center'>{title}</h2>
         <HelpQuestion
         description={description}
        //  description={`Ayuda sobre ${title}`}
         link={null}
         />
        </div>
        <div className='flex flex-row gap-1'>

        {percentage && (
          <Badge
          description_last_month={description_last_month}
            percentage={percentage == false ? 0 : percentage}
            status={determineStatus(percentage)}
          />
        )}
        {percentage2 && (
          <Badge
          description_last_month={description_last_month2}
            percentage={percentage2 == false ? 0 : percentage2}
 status={determineStatus(percentage2)}
            />
        
        )}
         </div>
      </div>
      <div className='flex flex-col items-end'>
        <div
          className={`${
            percentage > 0 || !percentage
              ? 'text-primaryGreen'
              : 'text-primaryRed'
          } font-DrukWideMedium  lg:text-2xl md:text-xl text-lg `}
        >
          {insight && (
            <Popover
              placement='bottomLeft'
              color='#222'
              content={popover ? popover : null}
            >
              <div className=''>
                <Statistic
          
                  value={insight}
                  formatter={() => (
                    <CountUp
                      start={insight}
                      end={insight}
                      duration={0}
                      decimals={title == 'Uso promedio' ? 1 : 0}
                      formattingFn={(value) =>
                        title == 'Ventas'
                          ? formatPrice(value)
                          : title == 'Uso promedio'
                          ? value.toFixed(1)
                          : value.toString()
                      }
                      className={`${
                        percentage > 0 || !percentage
                          ? 'text-primaryGreen'
                          :  percentage == 0 ? 'text-primaryYellow':  'text-primaryRed'
                      } font-DrukWideMedium  lg:text-2xl md:text-xl text-lg  `}
                    />
                  )}
                />
              </div>
            </Popover>
          )}
        </div>
        {multipleInsights?.length > 0 && (
          <div className='flex flex-row gap-3'>
            {multipleInsights
            .filter((m)=> m.member_type == 'Free')
            .concat({member_type: 'Pago', members: multipleInsights.filter((m)=> m.member_type !== 'Free').reduce((acc, curr) => acc + curr.members, 0)})
              .map((data, index) => (
                <Popover
                key={index}
                placement='bottomLeft'
                color='#222'
                content={popover ? () => popover(data.member_type) : null}
              >
                  <div className=' cursor-pointer flex flex-col items-center justify-center'>
                    <Statistic
                      value={data.members}
                      formatter={() => (
                        <CountUp
                          start={0}
                          end={data.members}
                          decimals={0}
                          // formattingFn={(value) =>value.toString()}
                          className={`${
                            percentage > 0 || !percentage
                              ? 'text-primaryGreen'
                              : 'text-primaryRed'
                          } font-DrukWideMedium  lg:text-2xl md:text-xl text-lg`}
                        />
                      )}
                    />
                    <span className=' text-center'>
                      {data.member_type == 'Free' ? 'Free' : 'Pago'}
                    </span>
                  </div>
           </Popover>
              ))}
          </div>
        )}
        {(todayTotal || todayTotal == 0) && (
          <div className='bg-background rounded-md w-max py-1 px-2'>
            <span className=' font-MessinaSansBold text-primaryGrey text-sm'>
              HOY {todayTotal}
            </span>
          </div>
        )}
      </div>
      {headerChildren && headerChildren}
    </div>
  );
}
