import { Tooltip } from 'antd';
import React from 'react';

export default function Badge({ percentage, status,description_last_month }) {
  return (
    <Tooltip title={description_last_month}>

    <div className={`
    cursor-pointer
     rounded-md px-3 py-0
     ml-3
       self-center
    ${status == 'green' ? ' bg-primaryGreen': status == 'yellow' ? 'bg-primaryYellow' :'bg-primaryRed'}`}>
      <span className=' text-sm text-primaryDark'>{percentage}%</span>
    </div>
    </Tooltip>
  );
}
