import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { convertMember } from '../../api/members';
import SelectUser from '../helpers/SelectUser';
import { useAuth } from '../../context/AuthProvider';
import SelectProduct from '../SelectProduct';
import { createSale } from '../../api/sales';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetDiscounts } from '../../api/discounts';
import { getCurrency, getLocationId } from '../../services/utils/getLocation';
import SelectMember from '../helpers/SelectMember';
import { useGetMemberships } from '../../api/memberships';
import { useGetConsumables } from '../../api/consumables';
import FooterSaleModal from '../helpers/FooterSaleModal';
import dayjs from 'dayjs';
import { formatPrice } from '../../services/utils/formatPrice';
import { createSubscription } from '../../api/subscriptions';
import { openNotificationFields } from '../../services/utils/openNotificationFields';
import { canModifyUserSale, isAdmin } from '../../services/can_user';
import { useGetPointOfSales } from '../../api/locations';
import { useGetSessions } from '../../api/sessions';
import { t } from 'i18next';
import { track_sale } from '../../services/utils/CustomerEvents';
import { useGetPaymentPlatforms } from '../../api/mp';
import MPCardHolder from './MPCardHolder';
import * as Sentry from '@sentry/react';
import { customerIoIdentify } from '../../services/utils/CustomerIoUtils';
import { useGlobalContext } from '../../context/GlobalState';

export default function ModalSales({
  modalSale,
  setmodalSale,
  openNotification,
  id,
  owner_type,
  setisProspect,
  owner_user_id,
  owner_email,
  referrer_id,
}) {
  if (!modalSale) return null;
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { state } = useGlobalContext();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [member, setmember] = useState(id ? id : null);
  const [user_id, setuser_id] = useState(owner_user_id ? owner_user_id : null);
  const [user_email, setuser_email] = useState(
    owner_email ? owner_email : null
  );
  const [openAddCreditCard, setopenAddCreditCard] = useState(false);

  const [user_selected, setuser_selected] = useState(user.user_id);
  const { data: products, isLoading: isLoadingProducts } = useGetMemberships();
  const { data: point_of_sales, isLoading: isLoadingPoints } =
    useGetPointOfSales();
  const { data: consumables, isLoading: isLoadingConsumables } =
    id && modalSale == 'convert' ? { data: [] } : useGetConsumables();
  const { data: sessions, isLoading: isLoadingSessions } = useGetSessions();
  const { data: discounts, isLoading: isLoadingDiscounts } = useGetDiscounts();
  const { data: paymentPlatform, isLoading: loadingPlatforms } =
    useGetPaymentPlatforms(getLocationId());

  const [api, contextHolder] = notification.useNotification();
  const handleChangeEndDate = (date, product) => {
    const diff_days = dayjs(date).diff(dayjs(product.started_at), 'days');
    setSelectedProducts((prev) => {
      const newProducts = [...prev];
      const index = newProducts.findIndex((p) => p.id === product.id);
      let pricePerDay =   ( product.countProducts *
      parseFloat(product.product.price) 
      )/ product.product.days_amount;
      newProducts[index].sessions_amount = diff_days >    product.product.sessions_amount ? product.product.sessions_amount  : diff_days;
      newProducts[index].days_amount = diff_days;
      newProducts[index].price = parseInt(pricePerDay * diff_days);
      return newProducts;
    });
    // let diffDays = product.
  };
  const openNotificationSale = (type, description, title) => {
    api[type]({
      message: title
        ? title
        : type == 'success'
        ? t('sales.success', 'Venta procesada correctamente')
        : t('sales.error', 'Ocurrió un error al procesar la venta'),
      ...openNotificationFields(type, description),
    });
  };
  const handleCC = () => {
    // Si selecciono un socio
    // si tiene productos seleccionados
    // si la suma de los pagos es menor al total de la venta o si no metodos de pago seleccionados

    if (
      member &&
      selectedProducts.length > 0 &&
      (selectedProducts.reduce(
        (acc, product) =>
          acc +
          parseFloat(product.price) *
            product.countProducts *
            (product.discount?.id
              ? 1 - parseFloat(product.discount.amount) / 100
              : 1),
        0
      ) >
        selectedProducts
          .map((p) =>
            p.selectedPaymentMethods.reduce(
              (acc, payment) => acc + parseFloat(payment.amount),
              0
            )
          )
          .reduce((acc, payment) => acc + parseFloat(payment), 0) ||
        selectedProducts
          .map((p) => p.selectedPaymentMethods)
          .flat()
          .filter((p) => !p.paymentType).length > 0)
    ) {
      return true;
    }
    if (
      member &&
      selectedProducts.length > 0 &&
      selectedProducts
        .map((p) => p.selectedPaymentMethods)
        .flat()
        .filter((p) => p.paymentType == 52 && !p.credit_card_id).length > 0
    ) {
      return true;
    }
    return false;
  };
  const useCreateSale = useMutation({
    mutationFn: (product) => createSale(product.data),
    onSuccess: async (data) => {
      let failedAttempt = data.card_attempts?.some(
        (l) => l.status !== 'approved'
      );
      openNotification(
        failedAttempt ? 'warning' : 'success',
        failedAttempt
          ? t(
              'sales.card_failed_attempts_description',
              'La venta se realizó con un intento de pago fallido, revisa el estado del pago en la plataforma de pagos'
            )
          : t('sales.sold_product', {
              product_name: data.product.name,
              customer_name: data.customer_name,
              amount: formatPrice(
                data.payments.reduce(
                  (acc, payment) => acc + parseFloat(payment.amount),
                  0
                ),
                data.currency
              ),
            }),
        failedAttempt
          ? t(
              'sales.card_failed_attempt',
              'Ocurrio un error al procesar el pago'
            )
          : ''
      );
      if (failedAttempt) {
        queryClient.invalidateQueries({
          queryKey: ['member_cc', id, owner_type],
        });
      }
      //t('sales.card_failed_attempt','Venta procesada con intento de pago fallido')
      try {
        customerIoIdentify(user_id);
        track_sale({
          ...data,
          payments: data.payments.map((p) => ({
            amount: formatPrice(p.amount, data.currency),
            payment_method_id: p.payment_method_id,
            id: p.id,
          })),
          product: {
            name: data.product.name,
            id: data.product.id,
            price: formatPrice(data.amount, data.currency),
            recurrent: data.product.recurrent,
            product_type_id: data.product.product_type_id,
            product_type_name: data.product.product_type_name,
          },
          user_id: user_id,
        });
      } catch (error) {
        console.error('Error in customerIoIdentify:', error);
        // Maneja el error según sea necesario, por ejemplo, registrándolo o mostrando una notificación
        Sentry.captureException(new Error(error.message), {
          extra: {
            user: user,
            screen: 'createSale',
            endpoint: 'customerIoIdentify',
          },
        });
      }

      if (selectedProducts.filter((p) => p.product_type_id === 1).length > 0) {
        const currency = getCurrency();
        await handleSubscriptionProcess(
          currency,
          selectedProducts.filter((p) => p.product_type_id === 1)
        );
      } else {
        setmodalSale(false);
      }
      queryClient.invalidateQueries({
        queryKey: ['sales'],
      });

      if (id && handleCC()) {
        queryClient.invalidateQueries({
          queryKey: ['member_cc', id, owner_type],
        });
      }
    },
    onError: (error, variables) => {
      let errorParse = JSON.parse(error.message);
      openNotificationSale(
        'error',
        Object.keys(errorParse).map(
          (key) =>
            `${key} ${
              selectedProducts.find(
                (p) => p.product_id == variables.data.product_id
              )?.product?.name
            }: ${errorParse[key]}`
        )
      );
    },
  });
  const useConvertMember = useMutation({
    mutationFn: (membership) => convertMember(membership.data),

    onSuccess: async (data) => {
      let failedAttempt = data.card_attempts?.some(
        (l) => l.status !== 'approved'
      );
      openNotification(
        failedAttempt ? 'warning' : 'success',
        failedAttempt
          ? t(
              'sales.card_failed_attempts_description',
              'La venta se realizó con un intento de pago fallido, revisa el estado del pago en la plataforma de pagos'
            )
          : t('sales.sold_success', 'Venta procesada correctamente'),
        failedAttempt
          ? t(
              'sales.card_failed_attempt',
              'Ocurrio un error al procesar el pago'
            )
          : ''
      );
      if (failedAttempt) {
        queryClient.invalidateQueries({
          queryKey: ['member_cc', id, owner_type],
        });
      }
      try {
        customerIoIdentify(user_id);
        track_sale({
          ...data,
          product: {
            name: data.membership.product.name,
            id: data.membership.product.id,
            //price tampoco lo tengo
            // price:formatPrice(data.membership.product.price,data.currency),
            recurrent: data.membership.product.recurrent,
            product_type_id: data.membership.product.product_type_id,
            product_type_name: data.membership.product.product_type_name,
          },
          prospect_converted: true,
          referrer_id: referrer_id,
          user_id: user_id,
        });
      } catch (error) {
        console.error('Error in customerIoIdentify:', error);
        // Maneja el error según sea necesario, por ejemplo, registrándolo o mostrando una notificación
        Sentry.captureException(new Error(error.message), {
          extra: {
            user: user,
            screen: 'convertToMember',
            endpoint: 'customerIoIdentify',
          },
        });
      }
      let removeFirstProduct = selectedProducts.slice(1);
      setisProspect(data, false);
      if (removeFirstProduct.length > 0) {
        const currency = getCurrency();
        await handleSubscriptionProcess(
          currency,
          removeFirstProduct,
          data.owner_id
        );
      }
      queryClient.invalidateQueries({
        queryKey: ['prospects'],
      });
      console.log('removeFirstProduct', removeFirstProduct);
      if (id && removeFirstProduct.length == 0) {
        queryClient.invalidateQueries({
          queryKey: ['profile', id],
        });
        // queryClient.invalidateQueries({
        //   queryKey: ['sales', id],
        // });
        setmodalSale(false);
      }
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotificationSale(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
      return error;
    },
  });

  const useCreateMembership = useMutation({
    mutationFn: (membership) => createSubscription(membership.data),
    onSuccess: (data) => {
      let failedAttempt = data.card_attempts?.some(
        (l) => l.status !== 'approved'
      );
      openNotification(
        failedAttempt ? 'warning' : 'success',
        failedAttempt
          ? t(
              'sales.card_failed_attempts_description',
              'La venta se realizó con un intento de pago fallido, revisa el estado del pago en la plataforma de pagos'
            )
          : t('sales.sold_success', 'Venta procesada correctamente'),
        failedAttempt
          ? t(
              'sales.card_failed_attempt',
              'Ocurrio un error al procesar el pago'
            )
          : ''
      );
      if (failedAttempt) {
        queryClient.invalidateQueries({
          queryKey: ['member_cc', id, owner_type],
        });
      }

      try {
        customerIoIdentify(user_id);

        track_sale({
          ...data,
          product: {
            name: data.membership.product.name,
            id: data.membership.product.id,
            price: formatPrice(data.membership.amount),
            recurrent: data.membership.product.recurrent,
            product_type_id: data.membership.product.product_type_id,
            product_type_name: data.membership.product.product_type_name,
          },
          user_id: user_id,
        });
      } catch (error) {
        console.error('Error in customerIoIdentify:', error);
        // Maneja el error según sea necesario, por ejemplo, registrándolo o mostrando una notificación
        Sentry.captureException(new Error(error.message), {
          extra: {
            user: user,
            screen: 'createMembership',
            endpoint: 'customerIoIdentify',
          },
        });
      }
      if (selectedProducts.filter((p) => p.product_type_id === 2).length == 0) {
        queryClient.invalidateQueries({
          queryKey: ['sales'],
        });
      }
      if (id) {
        if (owner_type == 'Member') {
          queryClient.invalidateQueries({
            queryKey: ['profile', id],
          });
        } else {
          queryClient.invalidateQueries({
            queryKey: ['prospect', id],
          });
        }
        queryClient.invalidateQueries({
          queryKey: ['sales', id, owner_type],
        });
        if (handleCC()) {
          queryClient.invalidateQueries({
            queryKey: ['member_cc', id, owner_type],
          });
        }
      }

      setmodalSale(false);
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotificationSale(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
      return error;
    },
  });

  const handleSaleProcess = async (currency, consumables) => {
    consumables.forEach(async (selectedProduct) => {
      if (selectedProduct) {
        const payments_attributes = selectedProduct.selectedPaymentMethods
          .filter((p) => p.paymentType !== null)
          .map((p) => {
            let payment = {
              location_id: user.location_id,
              point_of_sale_id: point_of_sales[0].id,
              payment_method_id: p.paymentType,
              operation_number: p.operation_number,
              customer_id: member,
              customer_type: owner_type ? owner_type : 'Member',
              amount: p.amount,
              device_id: window.MP_DEVICE_SESSION_ID,
              currency,
            };
            if (p.credit_card_id) {
              payment.credit_card_id = p.credit_card_id;
            }
            return payment;
          });

        const amount = selectedProduct.price;
        const total = parseFloat(
          amount *
            selectedProduct.countProducts *
            (selectedProduct.discount?.id
              ? 1 - parseFloat(selectedProduct.discount.amount) / 100
              : 1)
        );
        const sale = {
          location_id: user.location_id,
          point_of_sale_id: point_of_sales[0].id,
          product_id: selectedProduct.product_id,
          discount_id: selectedProduct.discount?.id
            ? selectedProduct.discount.id
            : null,
          seller_id: user.user_id,
          seller_type: 'User',
          customer_id: member,
          invoice_number: selectedProduct.invoice_number,
          customer_type: 'Member',
          description: '',
          currency,
          amount: amount,
          quantity: selectedProduct.countProducts,
          tax_amount: 0,
          tax_description: '',
          tax_percentage: 0,
          sub_total: amount,
          total: total,
          payments_attributes,
        };
        await useCreateSale.mutateAsync({ data: sale });
      }
    });
  };
  const handleSubscriptionProcess = async (
    currency,
    memberships,
    converted_owner_id
  ) => {
    memberships.forEach(async (selectedProduct, index) => {
      if (selectedProduct) {
        const payments_attributes = selectedProduct.selectedPaymentMethods
          .filter((p) => p.paymentType !== null)
          .map((p) => {
            let payment = {
              location_id: user.location_id,
              point_of_sale_id: point_of_sales[0].id,
              payment_method_id: p.paymentType,
              operation_number: p.operation_number,
              customer_id: member,
              customer_type: owner_type ? owner_type : 'Member',
              amount: parseFloat(p.amount).toFixed(2),
              device_id: window.MP_DEVICE_SESSION_ID,
              currency,
            };
            if (p.credit_card_id) {
              payment.credit_card_id = p.credit_card_id;
            }
            return payment;
          });
        const amount = parseFloat(selectedProduct.price);
        const total = parseFloat(
          amount *
            selectedProduct.countProducts *
            (selectedProduct.discount?.id
              ? 1 - parseFloat(selectedProduct.discount.amount) / 100
              : 1)
        ).toFixed(2);
        const sale = {
          location_id: getLocationId(),
          point_of_sale_id: point_of_sales[0].id,
          membership_id: selectedProduct.id,
          started_at: selectedProduct.started_at,
          days_left: selectedProduct.days_amount,
          sessions_left: selectedProduct.sessions_amount,
          owner_id: converted_owner_id ? converted_owner_id : member,
          owner_type: converted_owner_id
            ? 'Member'
            : owner_type
            ? owner_type
            : 'Member',
          is_debit:
            selectedProduct.product.recurrent &&
            selectedProduct.discount?.recurrent
              ? true
              : false, //fast_debit
          sale_attributes: {
            location_id: getLocationId(),
            point_of_sale_id: point_of_sales[0].id,
            product_id: selectedProduct.product_id,
            discount_id: selectedProduct.discount?.id,
            seller_id: user.user_id,
            seller_type: 'User',
            description: '',
            customer_id: converted_owner_id ? converted_owner_id : member,
            customer_type: converted_owner_id
              ? 'Member'
              : owner_type
              ? owner_type
              : 'Member',
            currency,
            amount: amount,
            is_debit:
              selectedProduct.product.recurrent &&
              selectedProduct.discount?.recurrent
                ? true
                : false, // fast_debit
            quantity: selectedProduct.countProducts,
            tax_amount: 0,
            invoice_number: selectedProduct.invoice_number,
            tax_description: '',
            tax_percentage: 0,
            sub_total: amount,
            total,
            payments_attributes: payments_attributes,
          },
        };
        if (id && modalSale == 'convert' && !converted_owner_id && index == 0) {
          await useConvertMember.mutateAsync({ data: sale, index: index });
        } else {
          await useCreateMembership.mutateAsync({
            data: sale,
            converted_owner_id: converted_owner_id,
          });
        }
      }
    });
  };
  const handleCreateSale = async () => {
    const currency = getCurrency();
    const memberships = selectedProducts.filter((p) => p.product_type_id === 1);
    const consumables = selectedProducts.filter((p) => p.product_type_id === 2);
    const sessions = selectedProducts.filter((p) => p.product_type_id === 3);

    if (consumables.length > 0) {
      await handleSaleProcess(currency, consumables);
    }
    if (sessions.length > 0) {
      await handleSaleProcess(currency, sessions);
    }
    if (memberships.length > 0 && consumables.length == 0) {
      if (
        memberships.some((p) => p.product.parent_product_id == 29) &&
        getLocationId() == 44 &&
        ![1143, 229, 334,228,235,230].includes(user.user_id) &&
        !isAdmin(state.user.roles)
      ) {
        return openNotificationSale(
          'error',
          `
           No cuentas con los permisos para vender productos FREE. Por favor comunícate con el área de Marketing
          `,
          'Error al vender el producto'
        );
      }

      if (id && modalSale == 'convert') {
        await handleSubscriptionProcess(currency, [memberships[0]]);
      } else {
        await handleSubscriptionProcess(currency, memberships);
      }
    }
  };
  return (
    <>
      {contextHolder}
      <div
        onClick={(e) => {
          setmodalSale(false);
        }}
        className={`${
          modalSale ? 'fixed' : 'hidden'
        } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center  z-50 `}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className='bg-primaryDark rounded-xl p-7 relative lg:h-fit lg:min-w-[55%] lg:w-fit  sm:h-[90%] sm:w-[90%] overflow-y-auto lg:max-h-[96%]'
        >
          <div className='flex flex-row items-center justify-between'>
            <h1 className=' text-xl lg:text-3xl  font-MessinaSansSemiBold'>
              {id && modalSale == 'convert'
                ? t('sales.convert_member', 'Convertir a socio')
                : t('sales.create_sale', 'Nueva operacion')}
            </h1>
            <SelectUser
              width={180}
              disabled={!canModifyUserSale()}
              initialUser={{
                id: user.user_id,
                full_name: user.full_name,
              }}
              value={user_selected}
              setValue={setuser_selected}
            />
          </div>
          {selectedProducts.length === 0 &&
            ['skeleton'].map((selectedProduct, index) => (
              <SelectProduct
                owner_type={owner_type}
                sessions={sessions}
                selectedProducts={selectedProducts}
                selectedProduct={selectedProduct}
                setSelectedProducts={setSelectedProducts}
                products={products}
                isLoadingProducts={isLoadingProducts}
                consumables={consumables}
                isLoadingConsumables={isLoadingConsumables}
                discounts={discounts}
                isLoadingDiscounts={isLoadingDiscounts}
                handleChangeEndDate={handleChangeEndDate}
                index={0}
                owner_user_id={user_id}
                paymentPlatform={paymentPlatform}
                prospectConvert={modalSale == 'convert'}
                openAddCreditCard={openAddCreditCard}
                setopenAddCreditCard={setopenAddCreditCard}
                // totalAmount={totalAmount}
              />
            ))}
          {selectedProducts.map((selectedProduct, index) => (
            <SelectProduct
              sessions={sessions}
              owner_user_id={user_id}
              owner_type={owner_type}
              selectedProducts={selectedProducts}
              selectedProduct={selectedProduct}
              setSelectedProducts={setSelectedProducts}
              products={products}
              isLoadingProducts={isLoadingProducts}
              consumables={consumables}
              isLoadingConsumables={isLoadingConsumables}
              discounts={discounts}
              isLoadingDiscounts={isLoadingDiscounts}
              handleChangeEndDate={handleChangeEndDate}
              index={index}
              prospectConvert={modalSale == 'convert'}
              customer_id={member}
              paymentPlatform={paymentPlatform}
              openAddCreditCard={openAddCreditCard}
              setopenAddCreditCard={setopenAddCreditCard}
            />
          ))}

          {openAddCreditCard && (
            <div className='pt-8'>
              <h1 className='text-2xl  font-MessinaSansSemiBold  mb-8'>
                {t(
                  'sales.add_card_paymentMethod',
                  'Agregar nuevo método de pago'
                )}
              </h1>
              <MPCardHolder
                totalAmount={1}
                paymentPlatform={paymentPlatform}
                email={user_email}
                member={{
                  // prospect_id:member,
                  user_id: user_id,
                }}
                modalSale={openAddCreditCard}
                selectedProducts={selectedProducts}
                openNotification={openNotification}
                setClose={(card_id) => {
                  setopenAddCreditCard(false);
                  setSelectedPaymentMethods(
                    selectedPaymentMethods.map((pm) => {
                      if (pm.paymentType == 52 || pm.paymentType == 48) {
                        return {
                          ...pm,
                          credit_card_id: card_id,
                        };
                      }
                      return pm;
                    })
                  );
                }}
                user_id={user_id}
              />
            </div>
          )}
          {!id && (
            <div className='pt-8'>
              <h2 className='text-xl font-MessinaSansSemiBold mb-5'>
                {t('member', 'Socio')}
              </h2>
              <SelectMember
                value={member}
                setValue={(id, user_id, email) => {
                  setuser_id(user_id);
                  setmember(id);
                  setuser_email(email);
                }}
                width='45%'
              />
            </div>
          )}
          <div className='pt-8'>
            <h2 className=' text-lg lg:text-xl font-MessinaSansSemiBold mb-5'>
              {t('sales.invoice_number', 'Número de factura')}
            </h2>
            <input
              type='text'
              placeholder={t(
                'sales.invoice_number_optional',
                'Número de factura (Opcional)'
              )}
              value={selectedProducts[0]?.invoice_number}
              style={{ width: 250 }}
              onChange={(e) =>
                setSelectedProducts(
                  selectedProducts.map((p) => ({
                    ...p,
                    invoice_number: e.target.value,
                  }))
                )
              }
              className=' bg-primaryGrey  rounded-sm py-2 border-[3px] pl-2  border-primaryGrey outline-none text-sm '
            />
          </div>

          <FooterSaleModal
            cancelProducts={false}
            selectedProducts={selectedProducts}
            handleCreateSale={handleCreateSale}
            setmodalSale={setmodalSale}
            sendCC={handleCC()}
            isLoading={
              useCreateSale.isLoading ||
              useCreateMembership.isLoading ||
              useConvertMember.isLoading
            }
            totalSale={selectedProducts.reduce(
              (acc, product) =>
                acc +
                product.price *
                  product.countProducts *
                  (product.discount?.id
                    ? 1 - product.discount.amount / 100
                    : 1),
              0
            )}
            totalAmount={
              selectedProducts
                .map((p) =>
                  p.selectedPaymentMethods
                    .filter((payment) => payment.paymentType !== null)
                    .filter((payment) => {
                      // Filtrar solo si paymentType no es 52 o si tiene credit_card_id cuando es 52
                      return (
                        payment.paymentType !== 52 ||
                        (payment.paymentType === 52 && payment.credit_card_id)
                      );
                    })
                    .reduce(
                      (acc, payment) => acc + parseFloat(payment.amount),
                      0
                    )
                )
                .reduce((acc, payment) => acc + parseFloat(payment), 0) || 0
            }
          />
        </div>
      </div>
    </>
  );
}
