import React from 'react'

export default function EmailSvg({large}) {
  let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
    <svg width={width} height={height}  viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.6553" cy="18" r="17.2764" fill="#D65945"/>
    <path d="M17.4638 23.1999H11.648C11.2073 23.1999 10.7846 23.0248 10.473 22.7132C10.1614 22.4016 9.98633 21.979 9.98633 21.5383V13.23C9.98633 12.7893 10.1614 12.3667 10.473 12.055C10.7846 11.7434 11.2073 11.5684 11.648 11.5684H23.2795C23.7202 11.5684 24.1429 11.7434 24.4545 12.055C24.7661 12.3667 24.9412 12.7893 24.9412 13.23V17.7995M23.2795 25.6924V20.7074M23.2795 20.7074L25.772 23.1999M23.2795 20.7074L20.7871 23.1999" stroke="#891D0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.98633 13.2295L17.4638 18.2144L24.9412 13.2295" stroke="#891D0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}
