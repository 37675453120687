import { useQuery } from '@tanstack/react-query';

import axiosInstance, { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useGenericQuery } from '../router/loaders/rootLoader';

export const getProducts = async () => {
  const location_id = getLocationId();
  return await fetchData(`/products?location_id=${location_id}`);
};
export const getProduct = async (id, token) => {
  const location_id = getLocationId();

  return await fetchData(
    `/products/${id}?location_id=${location_id}`,
    'GET',
    null,
    token
  );
};
export const updateProduct = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/products/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};

export const createProduct = async (data) => {
  const location_id = getLocationId();
  return await fetchData(`/products?location_id=${location_id}`, 'POST', data);
};
export const deleteProduct = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/products/${id}?location_id=${location_id}`,
    'DELETE'
  );
};

export const useGetProduct = (id, token, enabled = { enabled: true }) => {
  return useQuery({
    queryKey: ['product', id],
    queryFn: async () => getProduct(id, token),
    enabled: enabled.enabled,
  });
};
export const useGetProducts = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: async () => getProducts(),
  });
};

export const getProducts_types = async () => {
  const location_id = getLocationId();
  return await fetchData(`/product_types?location_id=${location_id}`);
};
export const useGetProducts_types = () => {
  return useQuery({
    queryKey: ['products_types'],
    queryFn: async () => getProducts_types(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const getProducstStocks = async () => {
  const location_id = getLocationId();
  return await fetchData(`/product_location_stocks?location_id=${location_id}`);
};
export const getProductStock = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/product_location_stocks/${id}/?location_id=${location_id}`
  );
};
export const updateProductStock = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/product_location_stocks/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createProductStock = async (data) => {
  return await fetchData(`/product_location_stocks`, 'POST', data);
};
export const deleteProductStock = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/product_location_stocks/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const getStatuses = async () => {
  const location_id = getLocationId();
  return await fetchData(`/statuses?location_id=${location_id}`);
};
export default axiosInstance;
