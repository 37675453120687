import React, { useState ,useEffect} from 'react';
import FormBuilder from '../helpers/FormBuilder';
import { USE_FORM_TRANSACTION } from '../../services/Constants/FORMS';
import { useForm } from 'react-hook-form';
import { getPointSales } from '../../api/locations';
import { getPayment_methods } from '../../api/payments';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTransaction, getTransactionCategories } from '../../api/Transactions';
import { getLocationId, getLocationRegionConfig } from '../../services/utils/getLocation';
import { useAuth } from '../../context/AuthProvider';
import { useGlobalContext } from '../../context/GlobalState';
import { t } from 'i18next';

export default function ModalTransaction({
  modalTransaction,
  setmodalTransaction,
  openNotification
}) {
  if (!modalTransaction) return null;
  const FORM_TRANSACTION = USE_FORM_TRANSACTION();

  const {user} = useAuth()
  const queryClient = useQueryClient()
  const [visibleModal, setvisibleModal] = useState(false)
  const [FORM, setFORM] = useState(FORM_TRANSACTION)
  const { state } = useGlobalContext();

useEffect(() => {
  if(modalTransaction){
    handleForm()
    setvisibleModal(true)
  } else {
    setvisibleModal(false)
  }
}, [modalTransaction])
const useCreateTransaction = useMutation({
  mutationFn: (transaction) => createTransaction(transaction),
  onSuccess: () => {
        setmodalTransaction(false)
    openNotification('success',
     t('transaction_success','Transacción realizada correctamente')
    );

    queryClient.invalidateQueries({
      queryKey: ['transactions'],
    });
    // setvisibleModal(false)


  },
  onError: (error) =>{
    let errorParse = JSON.parse(error.message);
    openNotification('error',
    '',
    Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`),
   t('transaction_error','Ocurrió un error al realizar la transacción')
    )
  },
});
const handleForm = async () => {
  const point_of_sale_id = await getPointSales();
  const payment_methods = await getPayment_methods();
const transaction_categories = await getTransactionCategories()
const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(config => config.id);
let filterByRegionConfig = transaction_categories.filter((category)=>AvailableRegionConfig.includes(category.region_config_type_id))

if(point_of_sale_id.length == 1 ){
  setValue('point_of_sale_id', point_of_sale_id[0].id)
}
if(payment_methods.find((p)=>p.name =='Efectivo')){
  setValue('payment_method_id', payment_methods.find((p)=>p.name =='Efectivo').id)
}
  // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
  setFORM(
    FORM.map((item) => {
      if(item.name === 'transaction_category_id'){
        return {
          ...item,
          options: filterByRegionConfig.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        };
      }
      if (item.name === 'point_of_sale_id') {
        return {
          ...item,
          options: point_of_sale_id.map((item) => ({
            label: item.name,
            value: item.id,
            disabled:point_of_sale_id.length == 1 ? true : false
          })),
        };
      }

      if (item.name === 'payment_method_id') {
        return {
          ...item,
          options: payment_methods.filter((p)=>p.name =='Efectivo').map((item) => ({
            label: item.name,
            value: item.id,
            disabled: item.name !== 'Efectivo' ? true : false,
          })),
        };
      }
      return item;
    })
  );
}

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});
  const onSubmit = async (data) => {
 let body = {
  ...data,
  location_id:getLocationId(),
  user_id:user.user_id
}
    await useCreateTransaction.mutateAsync(body);
  };
  return (
    <div
      onClick={() => setmodalTransaction(false)}
      className={`${
        modalTransaction ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-[50%] lg:h-fit lg:top-[10%]  sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <FormBuilder
          title={t('transactions','Transacción')}
          // record={openDrawer.record}
          canCreate={true}
          canUpdate={true}
          control={control}
          fields={FORM}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          mutateLoading={useCreateTransaction.isLoading}
        />


      </div>
    </div>
  );
}
