import { getUsers } from '../../api/users';
import { useGenericQuery } from './rootLoader';

export const useGetUsers = () => useGenericQuery(['users'], getUsers);

export const UsersLoader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetUsers();
    // ⬇️ return data or fetch it
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
