import axiosInstance from './prospects';
import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';

export const getConsumables = async () => {
  const location_id = getLocationId();
  const result = await fetchData(`/consumables?location_id=${location_id}`);
  if (!result || result.length === 0) return [];
  return result.map((consumable) => ({
    ...consumable,
    name: consumable.product.name,
    description: consumable.product.description,
    price: consumable.product.price,
    product_type_id: consumable.product.product_type_id,
    product_type_name: 'Consumable',
    product_type_name_es: 'Consumibles',
  }));
};
export const useGetConsumables = () => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['consumables', location_id],
    queryFn: async () => getConsumables(),
  });
};

export const getConsumable = async (id) => {
  const result = await fetchData(`/consumables/${id}`);
  if (!result) return {};
  return result;
};
export const updateConsumables = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/consumables/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createConsumables = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/consumables?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteConsumables = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/consumables/${id}?location_id=${location_id}`,
    'DELETE'
  );
};

export default axiosInstance;
