import { Select } from 'antd';
import React, { useState } from 'react';
import TextArea from '../helpers/TextArea';
import SpinIndicador from '../helpers/SpinIndicador';
import { useAuth } from '../../context/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBalance } from '../../api/balances';
import { useGetPointOfSales } from '../../api/locations';
import { getLocationId } from '../../services/utils/getLocation';
import { t } from 'i18next';

export default function ModalOpenSales({ openSales, setopenSales ,openNotification}) {
  if (!openSales) return null;
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [amount, setamount] = useState('');
  const [description, setdescription] = useState('');
  const { data: point_of_sales, isLoading: isLoadingPoints } = useGetPointOfSales();

  const handleBalance = async () => {
    let operation_type = openSales == 'close' ? 'open' : 'close';
    const balance = {
      operation_type: operation_type,
      location_id: user?.location_id,
      point_of_sale_id: point_of_sales?.[0]?.id,
      user_id: user?.user_id,
      amount: amount,
      description: description,
    };
    await useCreateBalance.mutateAsync(balance);
  };
  const useCreateBalance = useMutation({
    mutationFn: (balance) => createBalance(balance),
    onSuccess: (data) => {
      const location_id = getLocationId()
      openNotification(
        'success',
        t ('box_operation_success',`La caja se ${data.operation_type == 'close'?'cerró':'abrió'} correctamente`)
        // `La caja se ${data.operation_type == 'close'?'cerró':'abrió'} correctamente`
      );
      queryClient.invalidateQueries({
        queryKey: ['balance',location_id],
      });
      setopenSales(false);
    },
    onError: (error) => {
      let errorParse = JSON.parse(error.message);
      openNotification(
        'error',
        '',
        Object.keys(errorParse).map(
          (key) =>
            `${key}: ${errorParse[key]}`
        )
      );
    },
  });
  return (
    <div
    onClick={() => setopenSales(false)}
      className={`${
        openSales ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50 `}
    >
      <div 
      onClick={(e) => e.stopPropagation()}
      className='bg-primaryDark rounded-xl  p-7 relative sm:h-[90%] sm:w-[90%]  lg:h-fit  lg:w-[40%]'>
        <div>
          <Select
            disabled
            placeholder={
              <div className='flex items-center'>
                <span className='text-white'>
                  {openSales == 'close' ? t('open_box','Apertura') : t('close_box','Cierre')}
                </span>
              </div>
            }
            allowClear
            bordered={false}
            rootClassName=' text-white '
            className={`bg-primaryGrey text-white rounded-sm   py-1   `}
            style={{
              width: '100%',
              color: 'white!important',

              fontFamily: 'MessinaSans-Black',
            }}
            options={[
              {
                label: t('open_box','Apertura'),
                value: 'open',
              },
              {
                label: t('close_box','Cierre'),
                value: 'close',
              },
            ]}
          />
          <input
            type='number'
            className=' bg-primaryGrey w-full px-5 rounded-md h-12 mt-8 outline-none'
            placeholder={t('enter_amount','Ingresar monto')}
            onChange={(e) => setamount(e.target.value)}
            value={amount}
          />
          <TextArea
            placeholder={t('description')}
            styles={'min-h-[3rem] mt-8 w-full'}
            onChange={(e) => setdescription(e.target.value)}
            value={description}
          />
          <button
            disabled={useCreateBalance.isLoading}
            onClick={() => handleBalance()}
            className='flex flex-row items-center justify-center gap-6 !text-black  font-MessinaSansBold text-xl bg-lightGreen rounded-md px-5 h-14 w-full mt-5'
            type='submit'
          >
         
            {  openSales == 'close'? t('open','Abrir'):t('close','Cerrar') } {t('box','caja')} {useCreateBalance.isLoading && <SpinIndicador />}
          </button>
        </div>
      </div>
    </div>
  );
}
